<!-- Banner Start -->
<section class="banners-section">
  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span> <strong>Crypto Launchpad </strong> Development Company
                </span>
              </h1>
              <!-- <h3 class="metaverse_subheading">
                  Launchpad development company
                </h3> -->
              <p>
                Allow projects to fundraise through an efficient Crypto
                launchpad with BlockchainX unveiling possibilities as crypto
                funding meets the financial requirements. A mutual win-win!
              </p>
              <!-- <div class="erc-link app-links">
                  <a
                    href="https://www.blockchainx.finance/launchpad"
                    target="_blank"
                rel="noopener noreferrer"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/play-store-icon2.webp"
                      />
                      <source
                        type="image/png"
                        srcset="assets-new/images/play-store-icon2.png"
                      />
                      <img loading="lazy"
                        data-src="assets-new/images/play-store-icon2.png"
                        class="img-fluid"
                        width="150"
                        height="50"
                        alt="Link-arrow"
                        title="Link-arrow"
                        
                      />
                    </picture>
                  </a>
                </div> -->
              <div class="erc-link">
                <!-- <div class="content-link">
                    <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                      <span>Telegram</span>
                      <img loading="lazy"
                        data-src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                      />
                    </a>
                  </div> -->
                <div class="content-link">
                  <a
                    href="/assets-new/pdf/launchpad-features-list.pdf"
                    download>
                    <span>Download Features List</span>
                    <i class="bi bi-download"></i>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source
                type="image/webp"
                srcset="
                    assets-new/images/launchpad-new/crypto-launchpad.webp
                  " />

              <source
                type="image/png"
                srcset="
                    assets-new/images/launchpad-new/crypto-launchpad.png
                  " />

              <img loading="lazy"
                data-src="assets-new/images/launchpad-new/crypto-launchpad.png"
                width="350"
                height="220"
                class="img-fluid"
                alt="Crypto Launchpad"
                title="Crypto Launchpad" />
            </picture>
            <picture *ngIf="isDarkmode">
              <source
                type="image/webp"
                srcset="
                    assets-new/images/launchpad-new/light-theme/crypto-launchpad.webp
                  " />

              <source
                type="image/png"
                srcset="
                    assets-new/images/launchpad-new/light-theme/crypto-launchpad.png
                  " />

              <img loading="lazy"
                data-src="assets-new/images/launchpad-new/light-theme/crypto-launchpad.png"
                width="350"
                height="220"
                class="img-fluid"
                alt="Crypto Launchpad"
                title="Crypto Launchpad" />
            </picture>
          </div>
        </div>
      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- end -->
<section class="section-service-alt about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          Our<span>Clients</span>
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- About Start -->
<section class="section-service-main" #about>
  <div class="container">
    <div class="row align-items-center">
      <div
        class="col-lg-5 mx-auto col-md-6 col-sm-12 col-12 order-md-first order-last">
        <div class="bep-work-section text-center">
          <picture *ngIf="!isDarkmode">
            <source
              type="image/webp"
              srcset="assets-new/images/launchpad-new/crypto-launchpad-development.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/launchpad-new/crypto-launchpad-development.png" />

            <img loading="lazy"
              data-src="assets-new/images/launchpad-new/crypto-launchpad-development.png"
              class="img-fluid"
              alt="Crypto Launchpad Development"
              width="450"
              height="450" />
          </picture>
          <picture *ngIf="isDarkmode">
            <source
              type="image/webp"
              srcset="assets-new/images/launchpad-new/light-theme/crypto-launchpad-development.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/launchpad-new/light-theme/crypto-launchpad-development.png" />

            <img loading="lazy"
              data-src="assets-new/images/launchpad-new/light-theme/crypto-launchpad-development.png"
              class="img-fluid"
              alt="Crypto Launchpad Development"
              width="450"
              height="450" />
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <h3 class="main-sub-heading"><span>Crypto Launchpad </span>
            Development</h3>
          <p>
            A crypto launchpad is a space for investors and new or existing
            crypto projects to build a community together. As the need for
            funding rises for a business or startup, by listing their qualities
            and business USPs the entrepreneurs can launch crypto tokens for the
            investors to buy and that in turn contributes to filling their
            financing requirements. Eventually, the investors making those
            movements gain rewards and personal benefits from the project and
            business. The Launchpad ideally connects these users and creates a
            space to seamlessly connect, interact, invest, and gain rewards
            eventually through the Launchpad.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- end -->

<!-- Service We Provide Start -->
<section class="section-service-alt">
  <div class="types-of-erc">
    <div class="container">
      <div class="row sm-pb-4 pb-2">
        <div class="metaverse_heading mx-auto">
          <h3><span> Crypto Launchpad </span> Development Services
          </h3>
        </div>
      </div>

      <div class="row types-of-token-detail justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/ico-launchpad-development.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/ico-launchpad-development.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/ico-launchpad-development.png"
                  class="img-fluid"
                  alt="ICO Launchpad Development"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">ICO Launchpad Development
            </h4>
            <p>
              Initial coin offering is one of the popular fundraising models
              among the crypto launchpad that is used by startups and business
              entities to raise capital. Its work is more similar to an Initial
              Public Offering, it encourages investors irrespective of their
              scale to make investments and open up a direct investor portfolio.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/ino-launchpad.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/ino-launchpad.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/ino-launchpad.png"
                  class="img-fluid"
                  alt="INO Launchpad Development"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">INO Launchpad Development</h4>
            <p>
              One other new trend in the crypto space is Initial NFT Offering -
              an emerging concept that's stroming. INO is for creators, like
              artists, and musicians, and so to sell their unique NFTs to
              interested buyers.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/igo-offering.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/igo-offering.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/igo-offering.png"
                  class="img-fluid"
                  alt="IGO Offering Development"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">IGO Offering Development</h4>
            <p>
              Initial Game Offering launchpads are on the latest trends. This
              concept is very similar to ICO where the only difference is the
              platform hosts gaming projects and is very specific to it which
              are based on NFT and tokens as their in-game currency or
              incorporated in their reward system.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/ifo-launchpad.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/ifo-launchpad.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/ifo-launchpad.png"
                  class="img-fluid"
                  alt="IFO Launchpad Development"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">IFO Launchpad Development</h4>
            <p>
              The Initial farming offering is a new fundraising model that is
              popularized with DEX. It generates funds through farming events.
              This fundraising model supports pre-sales hosted in DEX where the
              DEX platforms do the complete background check concerning
              investment before even being hosted on IFOs. This benefits both
              projects and users.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/ido-launchpad.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/ido-launchpad.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/ido-launchpad.png"
                  class="img-fluid"
                  alt="IDO Launchpad Development"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">IDO Launchpad Development
            </h4>
            <p>
              Initial Dex Offering is a reliable fundraising model in today’s
              trend. As the projects strive to raise funds with IDO its
              efficient model supports to kick start the process hassle-free
              through innovative mediums to introduce in society. IDO elevates
              the trust built around your project encouraging investors to
              contribute to your business.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/ilo-launchpad.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/ilo-launchpad.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/ilo-launchpad.png"
                  class="img-fluid"
                  alt="ILO Launchpad Development"
                  width="75"
                  height="75" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">ILO Launchpad Development</h4>
            <p>
              A fair Fundraising Model for startups, Initial liquidity offering.
              As the startup sells tokens in the Decentralised DEXs the tokens
              are released to the investors in exchange for liquidity funds.
              Here, Investors can purchase tokens at a lower cost in exchange
              for the liquidity they provide.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Explain About Start -->
<section class="section-service-main">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 about-content">
        <div class="common-sub-heading mb-3 pb-sm-3 pb-2">
          <h3 class="main-sub-heading"><span>Crypto Launchpad </span>
            Development Services</h3>
          <p>
            Employing your business with a win-win model amplifying your
            operation, Crypto launchpad development eases startups to gain
            traction in terms of audience and monetize funds eventually with
            Crypto tokens. The functionalities of a Crypto Launchpad platform
            are developed to meet the project's funding and gain audience
            traction. The geographical barriers are eliminated, encouraging
            investors and enthusiasts to participate from every nook and corner.
            Apart from this, the Crypto Launchpad unveils multiple revenue
            stream opportunities and helps capitalize funding and resources into
            the opportunity pool of Crypto Launchpad development.
          </p>
        </div>
        <div class="token-vs-btn">
          <a href="https://www.blockchainx.tech/contact/"
            class="our_expert_talk">Talk With Our Experts</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="bep-work-section text-center pb-0">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/launchpad-new/launch-the-next-meme-coin.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/launchpad-new/launch-the-next-meme-coin.png" />

            <img loading="lazy"
              data-src="assets-new/images/launchpad-new/launch-the-next-meme-coin.png"
              class="img-fluid"
              alt="Crypto Launchpad Development Services"
              width="500"
              height="500" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Platform Features Start -->
<section class="section-service-alt">
  <div class="types-of-erc">
    <div class="container">
      <div class="row sm-pb-4 pb-2">
        <div class="metaverse_heading mx-auto">
          <h3> Unique Features Of
            <span> Crypto DeFi Token Launchpad </span>
          </h3>
        </div>
      </div>
      <div class="row types-of-token-detail feature-space">
        <div class="col-xl-3 col-lg-4 col-6">
          <div class="pro-feature">
            <div class="pro-feature-img">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/token-investments.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/token-investments.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/token-investments.png"
                  class="img-fluid"
                  alt="Token Investments"
                  width="34"
                  height="34" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt">Token Investments</h4>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-6">
          <div class="pro-feature">
            <div class="pro-feature-img">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/optional-kyc-integration.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/optional-kyc-integration.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/optional-kyc-integration.png"
                  class="img-fluid"
                  alt="Optional KYC Integration"
                  width="34"
                  height="34" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt">Optional KYC Integration</h4>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-6">
          <div class="pro-feature">
            <div class="pro-feature-img">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/multi-wallet-integration.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/multi-wallet-integration.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/multi-wallet-integration.png"
                  class="img-fluid"
                  alt="Multi wallet integration"
                  width="34"
                  height="34" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt">Multi wallet integration</h4>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-6">
          <div class="pro-feature">
            <div class="pro-feature-img">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/staking-module.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/staking-module.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/staking-module.png"
                  class="img-fluid"
                  alt="Staking module"
                  width="34"
                  height="34" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt">Staking module</h4>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-6">
          <div class="pro-feature">
            <div class="pro-feature-img">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/transparency-visibility.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/transparency-visibility.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/transparency-visibility.png"
                  class="img-fluid"
                  alt="Transparency and visibility"
                  width="34"
                  height="34" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt">Transparency and visibility</h4>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-6">
          <div class="pro-feature">
            <div class="pro-feature-img">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/automated-liquidity-pool.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/automated-liquidity-pool.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/automated-liquidity-pool.png"
                  class="img-fluid"
                  alt="Automated liquidity pool"
                  width="34"
                  height="34" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt">Automated liquidity pool</h4>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-6">
          <div class="pro-feature">
            <div class="pro-feature-img">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/multi-language-support.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/multi-language-support.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/multi-language-support.png"
                  class="img-fluid"
                  alt="Multi-language support"
                  width="34"
                  height="34" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt">Multi-language support</h4>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-6">
          <div class="pro-feature">
            <div class="pro-feature-img">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/launchpad-new/3rd-party-integrations.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/launchpad-new/3rd-party-integrations.png" />

                <img loading="lazy"
                  data-src="assets-new/images/launchpad-new/3rd-party-integrations.png"
                  class="img-fluid"
                  alt="3rd party integrations"
                  width="34"
                  height="34" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt">3rd party integrations</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- New-Token-Generator iframe start-->
<section class="section-service-main">
  <div class="container">
    <div class="row text-center h-100 new-token-generator">
      <!-- <iframe src="https://devuser.blockchainx.finance/new-token-generator"
          frameborder="0" width="100%" height="1220"></iframe> -->
      <a href="https://devuser.blockchainx.finance/launchpad" target="_blank"
        rel="noopener noreferrer">
        <picture>
          <source
            type="image/webp"
            srcset="
                assets-new/images/launchpad-new/launchpad-bx.webp
                " />

          <source
            type="image/png"
            srcset="
                assets-new/images/launchpad-new/launchpad-bx.png
                " />

          <img loading="lazy"
            data-src="assets-new/images/launchpad-new/launchpad-bx.png"
            width="1400"
            height="789"
            class="img-fluid"
            alt="launchpad-bx"
            title="launchpad-bx" />
        </picture>
      </a>
    </div>
  </div>
</section>
<!-- End -->

<!-- Development Process Start -->
<section class="section-service-alt">
  <div class="types-of-erc">
    <div class="container">
      <div class="row sm-pb-4 pb-2">
        <div class="metaverse_heading mx-auto">
          <h3> Development
            <span> Process </span>
          </h3>
        </div>
      </div>
      <div class="row types-of-token-detail develop-pro">
        <div class="col-lg-12 d-flex justify-content-center">
          <picture *ngIf="!isDarkmode">
            <source
              type="image/webp"
              srcset="assets-new/images/launchpad-new/development-process.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/launchpad-new/development-process.png" />

            <img loading="lazy"
              data-src="assets-new/images/launchpad-new/development-process.png"
              class="img-fluid"
              alt="Development Process"
              width="1109"
              height="405" />
          </picture>
          <picture *ngIf="isDarkmode">
            <source
              type="image/webp"
              srcset="assets-new/images/launchpad-new/light-theme/development-process.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/launchpad-new/light-theme/development-process.png" />

            <img loading="lazy"
              data-src="assets-new/images/launchpad-new/light-theme/development-process.png"
              class="img-fluid"
              alt="Development Process"
              width="1118"
              height="556" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Benefits of Create Your Launchpad Platform Start -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h3><span>Benefits Of Developing </span>Crypto Launchpad Platform</h3>
        </div>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="erc-about-picture text-lg-start text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/launchpad-new/platform-admin.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/launchpad-new/platform-admin.png" />

            <img loading="lazy"
              data-src="assets-new/images/launchpad-new/platform-admin.png"
              class="img-fluid"
              alt="Platform Admin"
              width="75"
              height="75" />
          </picture>
          <h4 class="erc-20-head text-lg-start text-center">Platform Admin</h4>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Earns commission for listing crypto
            projects
          </p>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Platform fees for Launchpad users
          </p>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> User-based built to cross usages
          </p>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Opening new opportunities with new
            tech
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="erc-about-picture text-lg-start text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/launchpad-new/launchpad-user.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/launchpad-new/launchpad-user.png" />

            <img loading="lazy"
              data-src="assets-new/images/launchpad-new/launchpad-user.png"
              class="img-fluid"
              alt="Launchpad User"
              width="75"
              height="75" />
          </picture>
          <h4 class="erc-20-head text-lg-start text-center">Launchpad User</h4>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Project token purchases at lower cost
          </p>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Diverse investment portfolio
          </p>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Access to quality projects with a
            growth rate
          </p>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Reaching actual business model with
            higher ROI
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="erc-about-picture text-lg-start text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/launchpad-new/token-owners.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/launchpad-new/token-owners.png" />

            <img loading="lazy"
              data-src="assets-new/images/launchpad-new/token-owners.png"
              class="img-fluid"
              alt="Token Owners"
              width="75"
              height="75" />
          </picture>
          <h4 class="erc-20-head text-lg-start text-center">Token Owners</h4>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Efficient source to fund projects
          </p>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Complete business ownership
          </p>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Increased popularity in building
            brand identity
          </p>
          <p class="text-start">
            <i class="bi bi-check-lg"></i> Earning trust and creating demand
            over projects
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Tokenization Demo Start -->
<section class="section-service-alt">
  <div class="product-list-feature">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto text-center">
          <h3><span>Reliable Crypto Launchpad </span>Development Company</h3>
          <!-- <p class="py-3">
            Discover the true capabilities and features of our Asset Tokenization platform from our product demo. Tap to view! 
          </p> -->
        </div>
      </div>
      <div class="row metmb-3 video-section-top">
        <div class="col-lg-6">
          <div class="metaverse_subheading">
            <p class="pb-3">
              BlockchainX extends its ultimate customized crypto launchpad
              development services to scale up your business in a global forum.
              As a pioneer in blockchain and decentralized development we as a
              crypto launchpad development company provide you with
            </p>

            <p><i class="bi bi-check-lg"></i> On-time delivery</p>
            <p><i class="bi bi-check-lg"></i> 100% customization</p>
            <p><i class="bi bi-check-lg"></i> Expert approach</p>
            <p><i class="bi bi-check-lg"></i> Advanced tech integrations</p>
            <p><i class="bi bi-check-lg"></i> Real-time solutions</p>
            <p><i class="bi bi-check-lg"></i> Round-the-clock support</p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/launchpad-new/reliable-crypto.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/launchpad-new/reliable-crypto.png" />

              <img loading="lazy"
                data-src="assets-new/images/launchpad-new/reliable-crypto.png"
                width="450"
                height="450"
                class="img-fluid"
                alt="Reliable Crypto Launchpad"
                title="Reliable Crypto Launchpad" />
            </picture>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
<!-- Tokenization Demo End -->

<!-- FAQ Start -->
<section class="section-service-main">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading">
            <h4>Frequently Asked Question <span class="dots">.</span></h4>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What is a crypto development launchpad?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    A crypto launchpad is a decentralized platform for investors
                    and crypto project owners to interact and raise funds for
                    projects.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  How does a crypto launchpad benefit the entrepreneur
                  developing it?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    With various revenue stream models and subscription options
                    as an entrepreneur developing a crypto launchpad, the
                    benefits and reputation earned is pretty high.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  What is the time taken to develop a crypto launchpad?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Based on the advancements, integrations, and customizations
                    required and demanded, the time for Crypto launchpad
                    development varies subjectively.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  Can I customize Crypto launchpad development?
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Yes, of course. You can customize your Crypto launchpad
                    development with BlockchainX services. Connect with our
                    experts to know more.
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                How To Create an NFT Marketplace development platform like Opensea?
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    The very first step involved in developing an NFT marketplace like Open Sea is to get your checklist done for the platform and do some groundwork to identify the best NFT marketplace developer, set up a meeting and discuss your requirements and constraints, and proceed to develop. 
                  </p>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-alt">
  <app-blog></app-blog>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <app-get-in-touch></app-get-in-touch>
</section>
<!-- Contact End -->