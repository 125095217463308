<!-- Banner Section -->
<section class="banners-section">

    <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
        <div class="green-vector"></div>
        <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
            <div class="banner_contentWrapper">
            <div class="caption">
                <h1 class="pb-3">
                <span><strong>Enterprise Blockchain </strong> Development </span>
                </h1>
                <div class="common-sub-heading-alt pb-2">Level up your business game now!</div>

                <p>
                  Diverse problems in varied sectors, yet one solution: from managing workflow to ensuring data security, our enterprise blockchain development answers all of them by rendering avant-grade integrations.
                </p>
                <div class="erc-link">
                <div class="content-link">
                    <a
                    href="https://t.me/sparkouttechblockchainx"
                    target="_blank"
                rel="noopener noreferrer"
                    >
                    <span>Telegram</span>
                    <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                    </a>
                </div>
                <div class="content-link">
                    <a
                    href="https://www.blockchainx.tech/contact/"
                    >
                    <span>Demo</span>
                    <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                    </a>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
            <div class="banner-right-section">
            <picture>
                <source type="image/webp" srcset="assets-new/images/enterprise-blockchain-tech/enterprise-banner.webp"/>
                <source type="image/png" srcset="assets-new/images/enterprise-blockchain-tech/enterprise-banner.png"/>
                <img loading="lazy"
                data-src="assets-new/images/enterprise-blockchain-tech/enterprise-banner.png"
                class="img-fluid"
                alt="enterprise blockchain"
                width="450"
                height="450"

                />
            </picture>
            </div>
        </div>
        </div>

        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp"
              />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png"
              />
              <img loading="lazy"

                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll-icon"
                title="scroll-icon"
              />
            </picture>
          </div>
        </div>

    </div>
    </div>
</section>
<!-- Banner Section ends -->
<section class="section-service-alt about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          Our<span>Clients</span>
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Why enterprise blockchain start -->
<section class="section-service-main" #about>
    <div class="container">
    <div class="row align-items-center">
        <div class="col-lg-5 mx-auto col-md-6 col-sm-12 col-12 order-md-first order-last pt-md-0 pt-3">
        <div class="bep-work-section text-center">
            <picture>
            <source
                type="image/webp"
                srcset="assets-new/images/enterprise-blockchain-tech/why-enterprise.webp"
            />

            <source
                type="image/png"
                srcset="assets-new/images/enterprise-blockchain-tech/why-enterprise.png"
            />

            <img loading="lazy"
                data-src="assets-new/images/enterprise-blockchain-tech/why-enterprise.png"
                class="img-fluid"
                alt="enterprise blockchain development"
                width="478"
                height="478"

            />
            </picture>
        </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 about-content">
        <div class="whys_section mb-0">
            <h2><span>Why Enterprise</span> Blockchain?</h2>
            <p class="pb-3">
              A growth-driven, highly equipped, and technologically advanced way of approaching your shortcomings. Enterprise Blockchain is a shared, trusted network of record-keeping that brings together a combined system for our business to operate seamlessly yet efficiently. The transparency, interoperability, and security of blockchain networks are passed on to build it more effectively.
            </p>
            <p> <i class="bi bi-check-lg"></i> Smart Contract Audit Transactions</p>
            <p> <i class="bi bi-check-lg"></i> Automated operations that are secured</p>
            <p> <i class="bi bi-check-lg"></i> Blockchain prototyping</p>
            <p> <i class="bi bi-check-lg"></i> Customized interactions and access</p>
        </div>
        </div>
    </div>
    </div>
</section>
<!-- Why enterprise blockchain end -->

<!-- Development Platform Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading">
          <h3>
            We Built <span>Enterprise Blockchain</span> On Different Networks
            Such As
          </h3>
        </div>
      </div>
    </div>
    <div class="row row-cols-3 row-cols-sm-4 row-cols-md-6 row-cols-lg-7 justify-content-center tron-token-images">
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/ethereum-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/ethereum-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/ethereum-v2.svg"

              class="img-fluid"
              alt="Ethereum"
              title="Ethereum"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/polygon-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/polygon-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/polygon-v2.svg"

              class="img-fluid"
              alt="Polygon"
              title="Polygon"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/binance-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/binance-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/binance-v2.svg"

              class="img-fluid"
              alt="Binance"
              title="Binance"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/hyperledger-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/hyperledger-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/hyperledger-v2.svg"

              class="img-fluid"
              alt="Hyperledger"
              title="Hyperledger"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/corda-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/corda-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/corda-v2.svg"

              class="img-fluid"
              alt="Corda"
              title="Corda"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/substrate-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/substrate-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/substrate-v2.svg"

              class="img-fluid"
              alt="Substrate"
              title="Substrate"
              width="70"
              height="70"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/avalanche-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/avalanche-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/avalanche-v2.svg"

              class="img-fluid"
              alt="Avalanche"
              title="Avalanche"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/polkadot-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/polkadot-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/polkadot-v2.svg"

              class="img-fluid"
              alt="Polkadot"
              title="Polkadot"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/protocol-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/protocol-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/protocol-v2.svg"

              class="img-fluid"
              alt="NearProtocol"
              title="NearProtocol"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/hedera-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/hedera-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/hedera-v2.svg"

              class="img-fluid"
              alt="Hedera"
              title="Hedera"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/tezos-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/tezos-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/tezos-v2.svg"

              class="img-fluid"
              alt="Tezos"
              title="Tezos"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home-v2/eos-v2.webp"
            />
            <source
              type="image/svg"
              srcset="assets-new/images/home-v2/eos-v2.svg"
            />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/eos-v2.svg"

              class="img-fluid"
              alt="eos"
              title="eos"
              width="50"
              height="50"
            />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- How blockchain benefits starts -->
<section class="section-service-main">
    <div class="types-of-erc">
      <div class="container">
        <div class="row">
          <div class="metaverse_heading mx-auto">
            <h3>
              <span> Enterprise blockchain</span> offers a Wide range of benefits
            </h3>
          </div>
        </div>
        <div class="row types-of-token-detail justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/enterprise-blockchain-tech/digitalization.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/enterprise-blockchain-tech/digitalization.png"
                  />

                  <img loading="lazy"
                    data-src="assets-new/images/enterprise-blockchain-tech/digitalization.png"
                    class="img-fluid"
                    alt="digitalization"
                    width="75"
                    height="75"

                  />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left sm-pt-3 pt-2">Digitalization and sustainability</h4>
              <p>
                A sustainable solution that made business operations completely digitalized and decentralized.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/enterprise-blockchain-tech/improved-security.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/enterprise-blockchain-tech/improved-security.png"
                  />

                  <img loading="lazy"
                    data-src="assets-new/images/enterprise-blockchain-tech/improved-security.png"
                    class="img-fluid"
                    alt="improved-security"
                    width="75"
                    height="75"

                  />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left sm-pt-3 pt-2">Improved Security with Risk Mitigation</h4>
              <p>
                Integrated with high-end security that mitigates risk in the shred workflow by increasing accountability.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/enterprise-blockchain-tech/enhanced-overall-performance.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/enterprise-blockchain-tech/enhanced-overall-performance.png"
                  />

                  <img loading="lazy"
                    data-src="assets-new/images/enterprise-blockchain-tech/enhanced-overall-performance.png"
                    class="img-fluid"
                    alt="enhanced-overall-performance"
                    width="75"
                    height="75"

                  />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left sm-pt-3 pt-2">Enhanced overall performance and increased integrity</h4>
              <p>
                A customized permissioned enterprise blockchain platform instills rendering efficiency and improvements in overall performance.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/enterprise-blockchain-tech/multiprocess.webp"
                  />

                  <source
                    type="image/png"
                    srcset="assets-new/images/enterprise-blockchain-tech/multiprocess.png"
                  />

                  <img loading="lazy"
                    data-src="assets-new/images/enterprise-blockchain-tech/multiprocess.png"
                    class="img-fluid"
                    alt="multiprocess"
                    width="75"
                    height="75"

                  />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left sm-pt-3 pt-2">Multi-Process Automation: Reducing Maintenance Costs</h4>
              <p>
                Organized multi-process workflows revolve around trusted, permissioned data, which accelerates performance.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/enterprise-blockchain-tech/completely-transparent.webp"
                    />

                    <source
                      type="image/png"
                      srcset="assets-new/images/enterprise-blockchain-tech/completely-transparent.png"
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/enterprise-blockchain-tech/completely-transparent.png"
                      class="img-fluid"
                      alt="completely-transparent"
                      width="75"
                      height="75"

                    />
                  </picture>
              </div>
              <h4 class="erc-20-head-alt text-left sm-pt-3 pt-2">Completely transparent and mutable transactions</h4>
              <p>
                A very transparent ecosystem that is built on trust and technology ensures maintaining loyalty and actuality.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/enterprise-blockchain-tech/improved-business.webp"
                    />

                    <source
                      type="image/png"
                      srcset="assets-new/images/enterprise-blockchain-tech/improved-business.png"
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/enterprise-blockchain-tech/improved-business.png"
                      class="img-fluid"
                      alt="improved-business"
                      width="75"
                      height="75"

                    />
                  </picture>
              </div>
              <h4 class="erc-20-head-alt text-left sm-pt-3 pt-2">Improved Business Value Along With Additional Revenue</h4>
              <p>
                As new business opportunities are explored, trust and sales are increasing, with authenticity helping in expansion and monetary benefits.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- How blockchain benefits ends -->

<!-- Our Enterprise Blockchain Services At Scale starts -->
<section class="section-service-alt">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h3>Our <span> Enterprise Blockchain </span> Solution Package</h3>
          </div>
        </div>
      </div>
      <div class="row erc-about-content">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/enterprise-blockchain-tech/blockchain-consulting-audit.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/enterprise-blockchain-tech/blockchain-consulting-audit.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/enterprise-blockchain-tech/blockchain-consulting-audit.png"
                class="img-fluid"
                alt="enterprise-blockchain-consulting"
                width="75"
                height="75"

              />
            </picture>
            <h4 class="erc-20-head">Enterprise Blockchain Consulting and Audit</h4>
            <p class="text-center">
              Our expert team of enterprise blockchain developers take ownership in formulating and assisting you in the process of bringing out the potential of your platform.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/enterprise-blockchain-tech/custom-enterprise-blockchain.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/enterprise-blockchain-tech/custom-enterprise-blockchain.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/enterprise-blockchain-tech/custom-enterprise-blockchain.png"
                class="img-fluid"
                alt="custom-enterprise-blockchain"
                width="75"
                height="75"

              />
            </picture>
            <h4 class="erc-20-head">Custom enterprise blockchain development</h4>
            <p class="text-center">
              We have pre-built solutions, and we are 100% open to customizing your enterprise blockchain solution to meet your business and needs effectively.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/enterprise-blockchain-tech/solution-integration.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/enterprise-blockchain-tech/solution-integration.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/enterprise-blockchain-tech/solution-integration.png"
                class="img-fluid"
                alt="solution-integration"
                width="75"
                height="75"

              />
            </picture>
            <h4 class="erc-20-head">Enterprise Blockchain Solution Integration</h4>
            <p class="text-center">
              Our solutions are avant-garde, technologically advanced, and improved to be compatible and highly integrable under any circumstances.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/enterprise-blockchain-tech/smart-contract-development.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/enterprise-blockchain-tech/smart-contract-development.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/enterprise-blockchain-tech/smart-contract-development.png"
                class="img-fluid"
                alt="smart-contract-development"
                width="75"
                height="75"

              />
            </picture>
            <h4 class="erc-20-head">Smart Contract Development and Audit</h4>
            <p class="text-center">
              We curate smart contracts that are validated and audited for your enterprise blockchain, ensuring they serve in the right way.
            </p>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- Our Enterprise Blockchain Services At Scale ends -->

<!-- Our Real World Asset Tokenization Offerings Start -->
<div class="section-service-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h3>
              Stages Involved in the <span>Enterprise Blockchain </span> Development Service
            </h3>
          </div>
        </div>
      </div>
      <div
        class="row types-of-token-detail justify-content-center real-world-check">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Identifying the Core</h4>
            <p>
              The initial step of enterprise blockchain development will be collecting your idea, understanding requirements, finding the goal, and analyzing the receiver.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Details of the Crust</h4>
            <p>
              Getting keen on the details, we curate the wireframe, a basic sketch of the platform, website tone, and design.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Development</h4>
            <p>
              We then combine the design into the structure. Ensuring there is flow and efficiency and that the interface is functional.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Testing</h4>
            <p>
              Through rigorous testing levels for both performance and look. Then rectify errors to render a seamless platform.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Deployment</h4>
            <p>
              After getting clearance from all the sectors, the platform is successfully launched and can later be updated based on requirements.
            </p>
          </div>
        </div>
        <!-- <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Residential Mortgage-Backed Securities (RMBS)</h4>
            <p>
              This is a type of MBS - a residential mortgage which is a type of fixed income securities. Tokenizing RMBS opens more liquidity thereby accessible for investors.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Art and Collectibles</h4>
            <p>
              A unique set of asset classes that are difficult to value and trade, while tokenizing them makes a purchase and selling seamless.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Real Estate</h4>
            <p>
              These are asset classes that can be illiquid and expensive to invest, by tokenizing this real estate it's easier to make investments even in a fractional manner
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Gaming Token</h4>
            <p>
              From in-game assets, items, and characters, an entire game is possible to tokenize as NFTs. They are unique gaming assets that gamers can trade digitally.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail text-center">
            <h4 class="erc-20-head-alt">Sports Tokens</h4>
            <p>
              Player rights, contracts, ownership, naming rights, etc are tokenized in the blockchain. It is potentially exposed while they are utilized as security token offerings.
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
<!-- Our Real World Asset Tokenization Offerings End -->

<!-- Our Approach starts-->
<section class="section-service-alt">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>Our Extended Support for <span> Enterprise Blockchain</span></h3>
        </div>
      </div>
      <div class="row acc-sec-list">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-image text-center">
          <picture>
            <source
              type="image/webp"
              srcset="
              assets-new/images/enterprise-blockchain-tech/extended-support.webp
              "
            />

            <source
              type="image/png"
              srcset="
              assets-new/images/enterprise-blockchain-tech/extended-support.png
              "
            />

            <img loading="lazy"
              data-src="assets-new/images/enterprise-blockchain-tech/extended-support.png"
              width="400"
              height="400"
              class="img-fluid"
              alt="IDO-Token-Launchpad-Development"
              title="IDO-Token-Launchpad-Development"

            />
          </picture>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne1">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#projectOne"
                  aria-expanded="true"
                  aria-controls="projectOne"
                >
                Project Support Service
                </button>
              </div>
              <div
                id="projectOne"
                class="accordion-collapse collapse show"
                aria-labelledby="projectSupport"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    In terms of advancing and heading further, we render our support that backs you technically.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#innovativeTwo"
                  aria-expanded="false"
                  aria-controls="innovativeTwo"
                >
                Innovative Solutions
                </button>
              </div>
              <div
                id="innovativeTwo"
                class="accordion-collapse collapse"
                aria-labelledby="innovativeSolutions"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    We are open to having ideation sessions to get your raw points shaped into a potential project on the market.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#highThree"
                  aria-expanded="false"
                  aria-controls="highThree"
                >
                High Qualified Integration
                </button>
              </div>
              <div
                id="highThree"
                class="accordion-collapse collapse"
                aria-labelledby="highQualified"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    Concerning any sort of integration, from multi-chain to wallet and other additional operations, we are open.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- Our Approach ends -->

<!-- Principles Start -->
<section class="section-service-main">
  <div class="container">
  <div class="row">
      <div class="col-lg-5">
      <div class="casetudies_common">
          <div class="common-sub-heading sub-title">
          Principles<span class="dots">.</span>
          </div>
          <p class="py-3 mb-lg-0 mb-2">
            We Stick On While Developing Enterprise Blockchain
          </p>
      </div>
      </div>
      <div class="col-lg-7">
        <div class="row our-approach">
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/enterprise-blockchain-tech/technology-agnostic-approach.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/enterprise-blockchain-tech/technology-agnostic-approach.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/enterprise-blockchain-tech/technology-agnostic-approach.png"
                  class="img-fluid pb-lg-3 pb-2"
                  alt="technology-agnostic-approach"
                  width="75"
                  height="75"

                />
              </picture>
              <h4 class="erc-20-head text-start">Technology-agnostic approach</h4>
              <p>
                We approach your solutions as quality-driven and aim to meet the future.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/enterprise-blockchain-tech/ability-to-meet-required.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/enterprise-blockchain-tech/ability-to-meet-required.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/enterprise-blockchain-tech/ability-to-meet-required.png"
                  class="img-fluid pb-lg-3 pb-2"
                  alt="ability-to-meet-required"
                  width="75"
                  height="75"

                />
              </picture>
              <h4 class="erc-20-head text-start">Ability to meet required deadlines</h4>
              <p>
                We work on well-managed and pre-assigned tasks, thus ensuring that we meet deadlines.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/enterprise-blockchain-tech/challenge-resolution.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/enterprise-blockchain-tech/challenge-resolution.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/enterprise-blockchain-tech/challenge-resolution.png"
                  class="img-fluid pb-lg-3 pb-2"
                  alt="challenge-resolution"
                  width="75"
                  height="75"

                />
              </picture>
              <h4 class="erc-20-head text-start">100% challenge resolution</h4>
              <p>
                We strive to meet your problems and bring up solutions that have a forward approach.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/enterprise-blockchain-tech/full-accountability.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/enterprise-blockchain-tech/full-accountability.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/enterprise-blockchain-tech/full-accountability.png"
                  class="img-fluid pb-lg-3 pb-2"
                  alt="full-accountability"
                  width="75"
                  height="75"

                />
              </picture>
              <h4 class="erc-20-head text-start">Full accountability and engagement</h4>
              <p>
                Fully functional, engaging, and intractable solutions are crafted with aided technology.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/enterprise-blockchain-tech/domain-requirements.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/enterprise-blockchain-tech/domain-requirements.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/enterprise-blockchain-tech/domain-requirements.png"
                  class="img-fluid pb-lg-3 pb-2"
                  alt="domain-requirements"
                  width="75"
                  height="75"

                />
              </picture>
              <h4 class="erc-20-head text-start">Responsiveness to the client’s domain requirements</h4>
              <p>
                We entrust clients' suggestions and meet their requirements on par.
              </p>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</section>
<!-- Principles End -->

<!-- Tools and Frameworks Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-12">
        <div class="metaverse_heading">
          <h3><span>Tools and Frameworks </span> In Use To Curate Enterprise Blockchain Platform</h3>
        </div>
      </div>
    </div>
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-md-start text-center">
        <picture>
          <source srcset="assets-new/images/home/stak/hyperledger.webp" type="image/webp" />
          <source srcset="assets-new/images/home/stak/hyperledger.png" type="image/png" />
          <img loading="lazy"  data-src="assets-new/images/home/stak/hyperledger.png" alt="Hyperledger" title="Hyperledger" class="img-fluid py-3"  width="253" height="50" />
        </picture>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-md-start text-center">
        <picture>
          <source srcset="assets-new/images/home/stak/caliber.webp" type="image/webp" />
          <source srcset="assets-new/images/home/stak/caliber.png" type="image/png" />
          <img loading="lazy"  data-src="assets-new/images/home/stak/caliber.png" alt="caliber" title="caliber" class="img-fluid py-3"  width="185" height="54" />
        </picture>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-md-start text-center">
        <picture>
          <source srcset="assets-new/images/home/stak/hyperledger-composer.webp" type="image/webp" />
          <source srcset="assets-new/images/home/stak/hyperledger-composer.png" type="image/png" />
          <img loading="lazy"  data-src="assets-new/images/home/stak/hyperledger-composer.png" alt="hyperledger composer" title="hyperledger composer" class="img-fluid py-3"  width="235" height="51" />
        </picture>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-md-start text-center">
        <picture>
          <source srcset="assets-new/images/home/stak/hyperledger-explorer.webp" type="image/webp" />
          <source srcset="assets-new/images/home/stak/hyperledger-explorer.png" type="image/png" />
          <img loading="lazy"  data-src="assets-new/images/home/stak/hyperledger-explorer.png" alt="hyperledger explorer" title="hyperledger explorer" class="img-fluid py-3"  width="217" height="43" />
        </picture>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-md-start text-center">
        <picture>
          <source srcset="assets-new/images/home/stak/sawtooth.webp" type="image/webp" />
          <source srcset="assets-new/images/home/stak/sawtooth.png" type="image/png" />
          <img loading="lazy"  data-src="assets-new/images/home/stak/sawtooth.png" alt="sawtooth" title="sawtooth" class="img-fluid py-3"  width="232" height="50" />
        </picture>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-md-start text-center">
        <picture>
          <source srcset="assets-new/images/home/stak/iroha.webp" type="image/webp" />
          <source srcset="assets-new/images/home/stak/iroha.png" type="image/png" />
          <img loading="lazy"  data-src="assets-new/images/home/stak/iroha.png" alt="iroha" title="iroha" class="img-fluid py-3"  width="175" height="48" />
        </picture>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-md-start text-center">
        <picture>
          <source srcset="assets-new/images/home/stak/fabric.webp" type="image/webp" />
          <source srcset="assets-new/images/home/stak/fabric.png" type="image/png" />
          <img loading="lazy"  data-src="assets-new/images/home/stak/fabric.png" alt="fabric" title="fabric" class="img-fluid py-3"  width="174" height="49" />
        </picture>
      </div>
    </div>
  </div>
</section>
<!-- Tools and Frameworks End -->

<!-- Where to use Start -->
<section class="section-service-main">
  <div class="container">
    <div class="row mb-5 pb-lg-5 pb-4">
      <div class="col-lg-12">
        <div class="casetudies_common">
            <div class="common-sub-heading sub-title">
            Versatile Use Cases of Enterprise Blockchain Development <span class="dots">?</span>
            </div>
            <!-- <div class="casestudies_para">
            <p>
                This part holds the legacy of BlockchainX Tech. Establishing all
                that we have worked out for our clients as a Blockchain
                development company is brought down here. This gives an ideology
                of our expertise and could bring your idea to life and hold the
                top priority here.
            </p>
            </div> -->
        </div>
        </div>
    </div>
  <div class="row">
      <div class="col-lg-12">
        <div class="row healthcare-ro">
          <div class="col-lg-6">
            <div class="healthcare-title">
              <h4><p>01</p> Healthcare</h4>
              <ul>
                <li>Implementing a transparent network</li>
                <li>Controlled pharmaceutical supply chain</li>
                <li>Ensure there is no falsification or exclusion</li>
                <li>Easy and quick access to patient data and verification</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="healthcare-title">
              <h4><p>02</p> Banking</h4>
              <ul>
                <li>Verify individual users</li>
                <li>Exclude third-party interventions</li>
                <li>Cost reduction in international transactions</li>
                <li>Verify counterparties without intermediaries</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="healthcare-title">
              <h4><p>03</p> Logistic and supply chain</h4>
              <ul>
                <li>Enable automated payments</li>
                <li>Reduce transaction times</li>
                <li>Control and coordination in the workflow</li>
                <li>Quick customs clearance process</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="healthcare-title">
              <h4><p>04</p> Commodity Market</h4>
              <ul>
                <li>Prevents data loss and false manipulation</li>
                <li>On-time Payouts</li>
                <li>Control the supply chain</li>
                <li>Understanding customer preferences</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="healthcare-title">
              <h4><p>05</p> Gaming</h4>
              <ul>
                <li>Maintaining user anonymity</li>
                <li>Immediate transaction initiation</li>
                <li>Gaining user credibility</li>
                <li>User verification results</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="healthcare-title">
              <h4><p>06</p> Agriculture</h4>
              <ul>
                <li>Trace and track products</li>
                <li>Maintaining product authenticity</li>
                <li>Quick, cost-efficient transactions</li>
                <li>Eliminate middlemen</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="healthcare-title">
              <h4><p>07</p> Governance and Voting</h4>
              <ul>
                <li>Eradicates falsification and fraud</li>
                <li>High level of accountability</li>
                <li>Open to voting from anywhere</li>
                <li>Accurate audits and verifications</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="healthcare-title">
              <h4><p>08</p> Insurance</h4>
              <ul>
                <li>Avail faster claims</li>
                <li>Data processing is done with ease</li>
                <li>Maintain accuracy and verify clients</li>
                <li>Automated agreement settings</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
  </div>
  </div>
</section>
<!-- End -->

<!-- Why Choose Start -->
<section class="section-service-alt">
  <div class="why-alt-section">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
      <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-2">
          <h2>Why choose BlockchainX? <span> For Enterprise Blockchain Solutions! </span></h2>
          </div>
          <div class="whysection_text_wrap">
          <p class="pb-3">
            As a team of potential young ideators and developers, we at BlockchainX have hands-on experience in developing blockchain platforms. Over the years, we have mastered the skill yet are open to adopting and learning new technologies that come along the way. Our customized enterprise blockchain solutions are tailored to your preferences and meet your requirements. That in turn reflects in the growth of your business and eases operations seamlessly!
          </p>
          <p> <i class="bi bi-check-lg"></i> Technically aided team</p>
            <p> <i class="bi bi-check-lg"></i> Streamlines Operations</p>
            <p> <i class="bi bi-check-lg"></i> Top of Communications</p>
            <p> <i class="bi bi-check-lg"></i> Futuristic Approach!</p>


          <div class="pt-lg-3 pt-0">
              <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
              </a>
              <!-- <hr /> -->
          </div>
          </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
          <picture>
              <source
              type="image/webp"
              srcset="assets-new/images/metaverse/why_blockchain.webp"
              />

              <source
              type="image/png"
              srcset="assets-new/images/metaverse/why_blockchain.png"
              />

              <img loading="lazy"
              data-src="assets-new/images/metaverse/why_blockchain.png"
              width="550"
              height="450"
              class="img-fluid"
              alt="why-blockchainX"
              title="why-blockchainX"

              />
          </picture>
          </div>
      </div>
      </div>
  </div>
  </div>
</section>
<!-- Why Choose End -->

<!--call to action Start-->
<section class="section-service-main">
  <div class="our-expert-vector">
    <picture>
      <source type="image/webp" srcset="assets-new/images/home/deploy/deploy-vector.webp" />
      <source type="image/png" srcset="assets-new/images/home/deploy/deploy-vector.png" />
      <img loading="lazy"   data-src="assets-new/images/home/deploy/deploy-vector.png" width="709" height="560" class="img-fluid" alt="expert-vector" title="expert-vector" />
    </picture>
  </div>
  <div class="violet-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="our_experts">
          <h2><span>Strike The Momentum Of Your Business with</span>
            Enterprise Blockchain Development
          </h2>
          <div class="text-center my-5">
            <a class="our_expert_talk" href="contact">Connect NOW!</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--call to action End-->

<!-- FAQ Section -->
<section class="section-service-alt">
    <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
    <div class="green-vector"></div>
    <div class="violet-vector"></div>
    <div class="row align-items-center justify-content-center">
    <div class="col-lg-10 col-md-12 col-sm-12 col-12">
        <div class="common-sub-heading sub-title">
        FAQ <span class="dots">.</span>
        </div>
        <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
    </div>
    </div>
    <div class="row align-items-center justify-content-center pt-md-5 pt-4">
    <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
        <div class="accordion" id="accordionEnterprise">
        <div class="accordion-item">
            <div class="accordion-header" id="headingOne">
            <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
            >
            Where could I find an enterprise blockchain development company?
            </button>
            </div>
            <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionEnterprise"
            >
            <div class="accordion-body">
                <p>
                  Several experienced blockchain companies are developing enterprise blockchain platforms around the world. Doing proper research on any enterprise blockchain development company will help you understand the market better and choose the right developer for you.
                </p>
            </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="headingTwo">
            <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
            >
            What is the time and cost involved in developing an enterprise blockchain?
            </button>
            </div>
            <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionEnterprise"
            >
            <div class="accordion-body">
                <p>
                  Based on the intricacy of the enterprise blockchain platform, the cost and eventually the time involved vary. The more advanced and futuristic you want your platform to be, the more it will be.
                </p>
            </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="headingThree">
            <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
            >
            Who provides customized enterprise blockchain development?
            </button>
            </div>
            <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionEnterprise"
            >
            <div class="accordion-body">
                <p>
                  BlockchainX understands your business requirements and the role blockchain has to play in your business. Therefore, approach your business development from a customized enterprise blockchain development point of view to render the best.
                </p>
            </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="headingFour">
            <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
            >
            How do you develop an enterprise blockchain platform like OpenSea?
            </button>
            </div>
            <div
            id="collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionEnterprise"
            >
            <div class="accordion-body">
                <p>
                  Get your ideas straight and understand the problems you are serving. Reaching out to experts in the field will help you design the best enterprise blockchain platform. BlockchainX can help you customize your enterprise blockchain platform. Connect to learn more.
                </p>
            </div>
            </div>
        </div>
        <div class="accordion-item">
            <div class="accordion-header" id="headingFive">
            <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
            >
            What is Enterprise Blockchain Development?
            </button>
            </div>
            <div
            id="collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionEnterprise"
            >
            <div class="accordion-body">
                <p>
                  Enterprise blockchain is the application of blockchain in a business or an organization. Implementing a distributed ledger system brings security and transparency to recording transactions. An ideal tool for the supply chain as its features ensure privacy, scalability, and interoperability in the business.
                </p>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    </div>
    <!-- Accordion Section -->
    </div>
</section>
<!-- FAQ Section -->

<!-- Case studies Start -->
<section class="section-service-main">
  <div class="container">
  <div class="row">
      <div class="col-lg-5">
      <div class="casetudies_common">
          <div class="commontext_subhead">
          <h3>Case Studies<span class="dots">.</span></h3>
          </div>
          <div class="casestudies_para">
          <p>
              This part holds the legacy of BlockchainX Tech. Establishing all
              that we have worked out for our clients as a Blockchain
              development company is brought down here. This gives an ideology
              of our expertise and could bring your idea to life and hold the
              top priority here.
          </p>
          </div>
      </div>
      </div>
      <div class="col-lg-7 case-list">
      <div class="row">
          <div class="col-lg-12">
          <div class="casestudy_wrap">
              <div class="casestudy_head">
              <div class="case-sub-head">
                  <h3>EKTA</h3>
                  <div class="case-picture">
                    <picture>
                      <source type="image/webp" srcset="assets-new/images/home/case/ekta-1.webp">
                      <source type="image/png" srcset="assets-new/images/home/case/ekta-1.png">
                      <img loading="lazy"  data-src="assets-new/images/home/case/ekta-1.png" alt="ekta" class="img-fluid" width="122" height="85"  />
                  </picture>
                  </div>
              </div>
              <div class="deploy-link">
                  <a href="ektachain" target="_blank"
                rel="noopener noreferrer">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
              </div>
              </div>
              <div class="casestudy_para">
              <p>
                  A decentralized ecosystem built from scratch, along with the
                  ability to perform everything and anything. Being a blockchain
                  itself, it renders and supports DAO, and has its own
                  marketplace and token to interact with. It explores endless
                  possibilities and bridges the physical world and the
                  blockchain era.
              </p>
              </div>
          </div>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-12">
          <hr class="case_studies_hr" />
          </div>
      </div>
      <div class="row">
          <div class="col-lg-12">
          <div class="casestudy_wrap">
              <div class="casestudy_head">
              <div class="case-sub-head">
                  <h3>Minosis</h3>
                  <div class="case-picture">
                    <picture>
                      <source type="image/webp" srcset="assets-new/images/home/case/minosis.webp">
                      <source type="image/png" srcset="assets-new/images/home/case/minosis.png">
                      <img loading="lazy"  data-src="assets-new/images/home/case/minosis.png" alt="Minosis" class="img-fluid" width="122" height="85"  />
                  </picture>
                  </div>
              </div>

              <div class="deploy-link">
                  <a href="minosis" target="_blank"
                rel="noopener noreferrer">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
              </div>
              </div>
              <div class="casestudy_para">
              <p>
                  Minosis is a crypto mining startup that allows users to
                  participate in pools to earn virtual currency. In a unique
                  way, Minosis miners can earn Bitcoin regardless of their mine
                  and even achieve block rewards. Despite simplifying
                  transaction's cost, the payments are accepted as native tokens
                  or Bitcoins.
              </p>
              </div>
          </div>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-12">
          <hr class="case_studies_hr" />
          </div>
      </div>
      <div class="row">
          <div class="col-lg-12">
          <div class="casestudy_wrap">
              <div class="casestudy_head">
              <div class="case-sub-head">
                  <h3>Zuki</h3>
                  <div class="case-picture">
                    <picture>
                      <source type="image/webp" srcset="assets-new/images/home/case/zuki.webp">
                      <source type="image/png" srcset="assets-new/images/home/case/zuki.png">
                      <img loading="lazy"  data-src="assets-new/images/home/case/zuki.png" alt="Zuki" class="img-fluid" width="122" height="85"  />
                  </picture>
                  </div>
              </div>

              <div class="deploy-link">
                  <a href="zuki" target="_blank"
                rel="noopener noreferrer">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
              </div>
              </div>
          </div>
          <div class="casestudy_para">
              <p>
              Zuki is a P2E gaming platform built on a decentralized economy
              application. A community-oriented space that performs building
              characters, virtual goods, and metaverse structures all in one.
              Furthermore, the P2E mechanism is utilized to generate financial
              benefits for the players drawing a wider crowd.
              </p>
          </div>
          </div>
      </div>

      <div class="row">
          <div class="col-lg-12">
          <hr class="case_studies_hr" />
          </div>
      </div>
      <div class="row">
          <div class="col-lg-12">
          <div class="casestudy_wrap">
              <div class="casestudy_head">
              <div class="case-sub-head">
                  <h3>Starcard Sports</h3>
                  <div class="case-picture">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/home/case/star-card-sports.webp">
                    <source type="image/png" srcset="assets-new/images/home/case/star-card-sports.png">
                    <img loading="lazy"  data-src="assets-new/images/home/case/star-card-sports.png" alt="Starcard Sports" class="img-fluid" width="122" height="85"  />
                </picture>
                  </div>
              </div>

              <div class="deploy-link">
                  <a href="starcard-sports-games" target="_blank"
                rel="noopener noreferrer">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
              </div>
              </div>
              <div class="casestudy_para">
              <p>
                  This is a play-to-earn decentralized gaming arena developed to
                  connect real-world players through digital gaming. This game
                  is based on football along with metaverse abilities drawing
                  income. With the ability to monetize, it inherits an immersive
                  experience that challenges traditional gaming platforms.
              </p>
              </div>
          </div>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-12">
          <hr class="case_studies_hr" />
          </div>
      </div>
      </div>
  </div>
  </div>
</section>
<!-- Case studies End -->
<section class="section-service-alt">
<app-blog></app-blog>
</section>
<section class="section-service-alt">
  <app-get-in-touch></app-get-in-touch>
</section>
