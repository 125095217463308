<section class="banners-section" #about>

    <div class="banner_wrapper">
        <div class="container" id="common-relative">
            <div class="green-vector"></div>
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                    <div class="banner_contentWrapper">
                        <div class="caption">
                            <h1>
                                <span><strong> Fan Token </strong>
                                    Development</span>
                            </h1>
                            <p>
                                Transforming fans with fantastic returns, Fan
                                Tokens are unique crypto collectibles designed
                                and developed for the audacious sports
                                enthusiasts out there.
                            </p>

                            <div class="contact-link">
                                <a [routerLink]="['/contact']"
                                    class="home-link">Talk To Our Experts</a>
                                <a [routerLink]="['/case-studies']"
                                    class="home-link-alt">View Case Study</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12 col-sm-12 text-center">
                    <div class="banner-right-section">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/launch-your-token.webp" />
                            <source type="image/png"
                                srcset="assets-new/images/token-images/launch-your-token.png" />
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/launch-your-token.png"
                                width="500" height="500" class="img-fluid"
                                alt="Fan Token Development"
                                title="Fan Token Development" />
                        </picture>
                    </div>
                </div>

            </div>
            <div class="row d-lg-block d-none">
                <div class="scroll-section text-center"
                    (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="assets-new/images/home/scroll.webp" />
                        <source
                            type="image/png"
                            srcset="assets-new/images/home/scroll.png" />
                        <img loading="lazy"
                            data-src="assets-new/images/home/scroll.png"
                            width="25"
                            height="48"
                            class="img-fluid"
                            alt="scroll-icon"
                            title="scroll-icon" />
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
    <div class="container">
        <div class="whys_section mb-0 text-center">
            <h2 class="pb-lg-5 pb-4"><span>What Is Fan Token</span>
                Development?</h2>
        </div>
        <div class="row align-items-center">
            <div
                class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
                <div class="bep-work-section">
                    <picture>
                        <source type="image/webp"
                            srcset="assets-new/images/token-images/why-should-you-choose.webp">
                        <source type="image/png"
                            srcset="assets-new/images/token-images/why-should-you-choose.png">
                        <img loading="lazy"
                            data-src="assets-new/images/token-images/why-should-you-choose.png"
                            width="350" height="350"
                            alt="What Is Fan Token Development"
                            title="What Is Fan Token Development"
                            class="BSC-Tokens-image img-fluid">
                    </picture>
                </div>
            </div>
            <div
                class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
                <div class="common-sub-heading">
                    <p>
                        The process of creating digital tokens to represent
                        ownership and access to exclusive content in a
                        particular niche, from a Fan perspective is Fan token
                        development in blockchain. The token holders get to
                        experience content exclusivity, voting rights, community
                        connections, and more. The Fan tokens are secured and
                        safe as they are backed with blockchain technology, they
                        also provide customized development to make your
                        platform more enhanced, highly user-engaging, and
                        interactive. Apart from sports teams, musicians,
                        entertainers, celebrities, and other creators are also
                        engaged with their fans in new and innovative ways with
                        Fan tokens.
                    </p>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<section class="section-service-main">
    <div class="tending-bep-20">
        <div class="container">
            <div class="row trending-token-list">
                <div class="col-lg-6 text-center order-lg-first order-last">
                    <picture>
                        <source type="image/webp"
                            srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
                        <source type="image/png"
                            srcset="assets-new/images/erc/trending-erc20-token-list.png" />
                        <img loading="lazy"
                            data-src="assets-new/images/erc/trending-erc20-token-list.png"
                            width="405" height="405"
                            alt="Trending Fan Tokens In The Market"
                            title="Trending Fan Tokens In The Market"
                            class="img-fluid mx-auto">
                    </picture>
                </div>
                <div class="col-lg-6 mb-lg-0 mb-4">
                    <h3 class="main-sub-heading text-start"><span>Trending Fan
                            Tokens
                        </span>In The Market</h3>
                    <div class="row trending-bep">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
                            <ul>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>Gaimin
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    Fc-Barcelona fan token
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    OG Fan token
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
                            <ul>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    FC Porto Fan Token
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    Inter Milan Fan Token
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    Flamengo Fan Token
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex pt-3">
                        <a [routerLink]="['/contact']"
                            class="home-link-alt">Connect With
                            Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 metaverse_heading mx-auto">
                <h3><span>What Are The Different Fan Tokens </span>We
                    Develop?</h3>
            </div>
        </div>
        <div class="row types-of-token-detail">
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/project-consulting.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token images/project-consulting.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/project-consulting.png"
                                width="75" height="75"
                                alt="Sports"
                                title="Sports"
                                class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Sports</h4>
                    <p>
                        If you are a fan of football, basketball, rugby,
                        cricket, badminton, or any sport for that matter, Fan
                        token development is a doable thing with maximum scaling
                        opportunity.
                    </p>
                    <!-- <div class="content-link">
              <a [routerLink]="['/']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                     />
                </picture>
              </a>
              <hr />
            </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/staking-platform.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/staking-platform.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/staking-platform.png"
                                width="75" height="75" alt="Martial Arts"
                                title="Martial Arts" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">
                        Martial Arts
                    </h4>
                    <p>
                        The forgone sport with active fans all over the world, a
                        niche that is untapped but we can make it possible for
                        you.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/farming-platform.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/farming-platform.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/farming-platform.png"
                                width="75" height="75"
                                alt="Famous Personalities"
                                title="Famous Personalities" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Famous
                        Personalities</h4>
                    <p>
                        Unlike media and sports personalities, we also have a
                        few famous humans who rock the bars with a high fan
                        following, and this is a chance to honor and get
                        lucrative contracts from them.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/token-bridge.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/token-bridge.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/token-bridge.png"
                                width="75" height="75" alt="Entertainmentl"
                                title="Entertainment" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Entertainment</h4>
                    <p>
                        Fan token development for the entertainment sector is a
                        broader sector with a possibility to niche down to a
                        particular sector, with flowing content and traction
                        that is profitable.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/layer-i-blockchain.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                                width="75" height="75"
                                alt="Celebrity Fan Tokens"
                                title="Celebrity Fan Tokens" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Celebrity Fan
                        Tokens</h4>
                    <p>
                        For any celebrity, we can curate fan tokens and gather
                        the attention of the particular fan base, and by
                        collaborating with the celebrity we can monetize their
                        content most effectively.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/nft-markeplace.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/nft-markeplace.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/nft-markeplace.png"
                                width="75" height="75"
                                alt="Gaming NFTs"
                                title="Gaming NFTs" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">
                        Gaming NFTs
                    </h4>
                    <p>
                        Gaming is a great pool for Fan tokens development as
                        there are different games and audience bandwidth is also
                        high and demanding with lucrative benefits.
                    </p>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 metmb-3">
                <div class="whys_section mb-0">
                    <h2>
                        <span>Why Choose BlockchainX?</span>
                    </h2>
                    <p class="pb-3">
                        BlockchainX is a pioneer with 7 years of experience in
                        the field of blockchain development. Curating creative
                        and exciting platforms with amazing user experience like
                        now where. We also ensure to infuse top technologies and
                        advanced features to enhance the performance in the
                        market and compete with the rest. Our expertise is token
                        development, decentralized platform development,
                        decentralized exchanges, ICO development, blockchain,
                        metaverse, asset tokenization, and more.
                    </p>
                    <div class="whysection_text_wrap">
                        <ul>
                            <li>On-time product delivery</li>
                            <li>24/7 support pre/post-launch</li>
                            <li>Advanced customized solutions</li>
                            <li>Energetic team of professionals</li>
                        </ul>
                    </div>
                    <div class="content-link">
                        <a [routerLink]="['/contact']">
                            <!-- <span>Read More</span> -->
                            <picture>
                                <source type="image/webp"
                                  srcset="assets-new/images/right-arrow.webp">
                                <source type="image/png"
                                  srcset="assets-new/images/right-arrow.png">
                                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                                   />
                              </picture>
                              <picture>
                                <source type="image/webp"
                                  srcset="assets-new/images/right-arrow-dark.webp">
                                <source type="image/png"
                                  srcset="assets-new/images/right-arrow-dark.png">
                                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                                   />
                              </picture>
                        </a>
                        <hr />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 metmb-3 Why-section">
                <div class="why_right_side">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

                        <source
                            type="image/png"
                            srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

                        <img loading="lazy"
                            data-src="assets-new/images/token-images/why-choose-blockchainx.png"
                            width="624"
                            height="521"
                            class="img-fluid"
                            alt="Why Choose BlockchainX"
                            title="Why Choose BlockchainX" />
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
        <!-- Accordion Section -->
        <div class="nft-acc">
            <div class="green-vector"></div>
            <div class="violet-vector"></div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12">
                    <div class="common-sub-heading sub-title">
                        FAQ <span class="dots">.</span>
                    </div>
                    <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
                </div>
            </div>
            <div
                class="row align-items-center justify-content-center pt-md-5 pt-4">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingOne">
                                <button
                                    class="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne">
                                    Who can Develop a Fan Token?
                                </button>
                            </div>
                            <div
                                id="collapseOne"
                                class="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Anybody can develop a Fan token if they
                                        have an interest in gaming and sports
                                        and desire to monetize and build a
                                        community with like-minded people.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingTwo">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo">
                                    What is the benefit of developing Fan Token?
                                </button>
                            </div>
                            <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        The benefit of developing Fan tokens
                                        would be gaining a not-so-tedious way to
                                        monetize, build a community, and expand
                                        your passion with others, and the more
                                        creative you get the more creative you
                                        operate and engage your team.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingThree">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree">
                                    Why should I choose BlockchainX For Fan
                                    token development?
                                </button>
                            </div>
                            <div
                                id="collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        BlockchainX not only customizes your Fan
                                        token development but we also ensure to
                                        build a secured, advanced, and highly
                                        engaging platform that connects and
                                        communicates regularly.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Accordion Section -->
    </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
    <div class="green-vector"></div>
    <div class="container">
      <div class="row blog-list">
        <div class="col-lg-12">
          <div class="commontext_subhead">
            <h3>Blog<span class="dots">.</span></h3>
          </div>
          <div class="feature_wrap">
            <div class="commontext_wrap">
              <h2>Check out our Latest Blogs </h2>
            </div>
            <div class="deploy-link">
              <a [routerLink]="['/blog']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode"
                    />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode"
                    />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 left-vector">
          <owl-carousel-o [options]="blogs_details">
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                  <picture>
                    <!-- <source
                        type="image/webp"
                        srcset="
                          assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                        "
                      /> -->
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                        " />
                    <img loading="lazy"
                      data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                      width="404"
                      height="246"
                      class="img-fluid"
                      alt="Web3 Gaming"
                      title="AI Transforming Web3 Gaming And Metaverse" />
                  </picture>
                  <h3>
                    AI Transforming Web3 Gaming And Metaverse
                  </h3>
                  <p>
                    In the 1990s, the introduction of the internet, and the
                    evolution of something beyond reality kick-started ...
                  </p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode"
                    />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode"
                    />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="[
                      '/what-is-gamefi'
                    ]">
                  <picture>
                    <!-- <source
                        type="image/webp"
                        srcset="
                        assets-new/images/blog/what-is-gamefi.webp
                        "
                      /> -->
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/blog/what-is-gamefi.jpg
                        " />
                    <img loading="lazy"
                      data-src="assets-new/images/blog/what-is-gamefi.jpg"
                      width="404"
                      height="246"
                      alt="What is GameFi?"
                      title="What is GameFi?" />
                  </picture>
                  <h3>
                    What is GameFi? Diving into the world of Blockchain Games
                  </h3>
                  <p>
                    From the earlier days of the video game industry, developers
                    have aimed to create worlds as powerful as real life....</p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="[
                        '/what-is-gamefi'
                      ]">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode"
                    />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode"
                    />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="[
                      '/blockchain-game-development'
                    ]">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/industries/blockchain-in-gaming.webp
                        " />
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/industries/blockchain-in-gaming.jpg
                        " />
                    <img loading="lazy"
                      data-src="assets-new/images/industries/blockchain-in-gaming.jpg"
                      width="404"
                      height="246"
                      class="img-fluid"
                      alt="Blockchain Technology"
                      title=" The Potential of Blockchain Technology to Enhance Industry Profits " />
                  </picture>
                  <h3>
                    The Potential of Blockchain Technology to Enhance Industry
                    Profits
                  </h3>
                  <p>
                    Mario video games in the year 1981 became very famous among
                    game lovers by the name of Jumpman. In today’s world,...
                  </p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="[
                        '/blockchain-game-development'
                      ]">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode"
                    />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode"
                    />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
    <div class="app-get-in-touch-form">
        <div class="container">
            <div class="contact-form">
                <div class="row reverse-colum">
                    <div class="col-lg-5 col-md-12 contact-social-links">
                        <h3 class="main-sub-heading"><span>Community to Corporal
                                Abilities Fan Token Development</span></h3>
                        <p>Can extend in the best way possible, with manifold
                            progression on all sides.</p>
                        <div class="contact-main-list">
                            <div class="social-links">
                                <a href="mailto:contact@blockchainx.tech"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="contact-mail">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/mail.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/mail.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/mail.png"
                                            alt="linkedin"
                                            width="32"
                                            height="32" />
                                    </picture>
                                </a>

                                <a href="https://wa.me/7708889555"
                                    target="_blank"
                                    rel="noopener noreferrer" class="whatsapp">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/whatsapp-icon.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/whatsapp-icon.png"
                                            alt="contact-Whatsapp"
                                            width="32"
                                            height="32" />
                                    </picture>
                                </a>

                                <a href="https://t.me/sparkouttechblockchainx"
                                    target="_blank"
                                    rel="noopener noreferrer" class="twitter">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/telegram.webp" />

                                        <source
                                            type="image/svg"
                                            srcset="assets-new/images/contact/telegram.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/telegram.png"
                                            alt="twitter"
                                            width="40"
                                            height="40" />
                                    </picture>
                                </a>
                            </div>
                            <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            src="assets-new/images/contact/mail.png"

                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>

                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            src="assets-new/images/contact/whatsapp-icon.png"

                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div>

                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            src="assets-new/images/contact/telegram.png"

                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
                        </div>
                        <div class="contact-links">
                            <h4>Follow us on</h4>
                            <div class="social-links">
                                <a
                                    href="https://www.linkedin.com/company/blockchainxtech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="linkdin">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/linkdin.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/linkdin.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/linkdin.png"
                                            alt="linkedin"
                                            width="22"
                                            height="22" />
                                    </picture>
                                </a>

                                <a
                                    href="https://www.instagram.com/blockchainxtech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="instagram">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/instagram.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/instagram.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/instagram.png"
                                            alt="instagram"
                                            width="22"
                                            height="22" />
                                    </picture>
                                </a>

                                <a
                                    href="https://twitter.com/Blockchainxtech"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="twitter">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/twitterx.webp" />

                                        <source
                                            type="image/svg"
                                            srcset="assets-new/images/contact/twitterx.svg" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/twitterx.svg"
                                            alt="twitter"
                                            width="25"
                                            height="25" />
                                    </picture>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 contact-inputs">
                        <div
                            class="getintouch getintochform bitrix-snippet-form">
                            <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact End -->
