import {
  Component,
  OnInit,
} from '@angular/core';
// import { DOCUMENT } from '@angular/common';
import {
  Title,
  Meta,
} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../shared/canonical.service';
import { FooterServiceService } from '../shared/service/footer-service.service';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { TrackingService } from '../shared/service/tracking.service';
// import { CommonService } from '../shared/service/common.service';
// import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  title = 'Contact us | BlockchainX';

  // public contactmail!: FormGroup;
  // submitted = false;
  // countryCode: any;
  // formdata:[];
  selectedlisted: any;
  isDarkmode: boolean = false;
  // bitrixSnippetScript: any;



  constructor(
    // private formBuilder:FormBuilder,
    // private commonService:CommonService,
    // private toastr:ToastrService,
    // private trackingService: TrackingService,

    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    // private sanitizer: DomSanitizer,
    private footerservice: FooterServiceService  ) {
    // const scriptUrl = `<script data-b24-form="inline/10/jl89c0" data-skip-moving="true">${(function (
    //   w,
    //   d,
    //   u
    // ) {
    //   var s = d.createElement('script');
    //   s.async = true;
    //   s.src = u + '?' + ((Date.now() / 180000) | 0);

    //   // Perform a null check before calling parentNode.insertBefore
    //   var h = d.getElementsByTagName('script')[0];
    //   if (h && h.parentNode) {
    //     h.parentNode.insertBefore(s, h);
    //   } else {
    //     // Handle the case where the parent node is not found (e.g., script is at the top of <body>)
    //     // You can append the script to the <body> element or take another appropriate action.
    //     document.body.appendChild(s);
    //   }
    // })(
    //   window,
    //   document,
    //   'https://cdn.bitrix24.in/b26760065/crm/form/loader_10.js'
    // )};</script>`;
    // this.bitrixSnippetScript = this.sanitizer.bypassSecurityTrustHtml(
    //   `${scriptUrl}`
    // );
  }
  ngOnInit(): void {

    this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
      this.isDarkmode = response;
    });

    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'To discuss your project or ideas, please contact BlockchainX. Our team of experts will work with you to understand your business objectives & set up early milestones.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content: '',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Contact us | BlockchainX ',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/contact/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'To discuss your project or ideas, please contact BlockchainX. Our team of experts will work with you to understand your business objectives & set up early milestones.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/blockchainx-og.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/contact/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Contact us | BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'To discuss your project or ideas, please contact BlockchainX. Our team of experts will work with you to understand your business objectives & set up early milestones.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/blockchainx-og.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/contact/'
    );


    // this.contactmail = this.formBuilder.group({
    //   name: ['', [Validators.required]],
    //   email: ['', [Validators.required, Validators.email]],
    //   whatsapp_telegram: ['', [Validators.required]],
    //   country: ['', [Validators.required]],
    //   tell_us_about_your_project: ['', [Validators.required]],
    //   enquiry_type: ['', [Validators.required]],
    // });
  }
  //easy to access form control value
  // get f(){
  //   return this.contactmail.controls;
  // }
  // public onSubmit(){
  //   this.submitted = true;

  //   if (this.contactmail.valid) {
  //     let formData = {
  //       ...this.contactmail.value,
  //       source: localStorage.getItem('userReferrer') || 'direct',
  //       page_visited: localStorage.getItem('lastPageVisited') || window.location.href,
  //       pages_visited: JSON.parse(localStorage.getItem('pagesVisited') || '[]')
  //     };

  //     // Log the form data to the console
  //     console.log('Form Data:', formData);

  //     this.commonService.submitContactForm(formData).subscribe((response:any)=>{
  //       this.submitted = false;
  //       if(response.success){
  //         this.toastr.success('Mail Sent Successfully!', 'Success!');
  //         this.contactmail.reset();
  //         this.trackingService.clearPageVisits();
  //       }
  //     },
  //     () =>{
  //       this.toastr.error('Mail Not Sent. Pls try again after some times!', 'Failure!');
  //     }
  //     )
  //   }
  // }
  // numericOnly(event:any) {
  //   let pattern = /^([0-9])$/;
  //   return pattern.test(event.key);
  // }
}
