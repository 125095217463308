<!-- Banner Section -->
<div class="banners-section">

  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>NFT Marketplace </strong> Development Company
                </span>
              </h1>
              <h4 class="common-sub-heading-alt">Redefining The World of
                Creatives</h4>

              <p>
                Encouraging craftsmanship and creativity our NFT marketplace
                development services ensure to benefit creators and artists to
                exhibit and monetize their creatives. As an NFT marketplace
                development company, we are committed to establishing your true
                market potential.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a
                    href="https://t.me/sparkouttechblockchainx"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                  </a>
                </div>
                <div class="content-link">
                  <a
                    href="https://nftmarketplace.blockchainx.tech/home/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span>Demo</span>
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="
                        assets-new/images/nft-marketplace-new/nft-marketplace.webp
                    " />

              <img loading="lazy"
                data-src="assets-new/images/nft-marketplace-new/nft-marketplace.png"
                class="img-fluid"
                alt="NFT Marketplace"
                width="500"
                height="500" />
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll"
              title="scroll" />
          </picture>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Banner Section ends -->
<section class="section-service-alt about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          Our<span>Clients</span>
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose DAO Platform Development start -->
<div class="section-service-main" #about>
  <div class="container">
    <div class="row align-items-center">
      <div
        class="col-lg-5 mx-auto col-md-12 col-sm-12 col-12 order-lg-first order-last">
        <div class="bep-work-section text-center">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/nft-marketplace-new/webp/nft-marketplace-development-solution.webp" />

            <img loading="lazy"
              data-src="assets-new/images/nft-marketplace-new/nft-marketplace-development-solution.png"
              class="img-fluid"
              alt="NFT Marketplace Development Solution"
              width="500"
              height="500" />
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <h3 class="main-sub-heading">NFT Marketplace Development Solution</h3>
          <p class="pb-3">
            A bridge connecting the creators and their creatives with curious
            enthusiasts with an eye for unique digital collectibles. Connecting
            the artistic vibe of the tribe, an NFT marketplace is a platform
            that seamlessly connects the buyer and seller over a creative. The
            main function of the NFT Marketplace platform is to efficiently
            enable transactions and eradicate the complexity of owning an NFT.
          </p>
          <p>
            An NFT marketplace development solution, developed with us, shall be
            the primary support to grow your business. Eventually helps you gain
            traction for the creatives hosted in the platform, and allows buyers
            to explore the niche in a very detailed manner. We help you
            customize the entire solution in a very intricate manner with our
            experts. Connect with us now!
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Why Choose DAO Platform Development end -->

<!-- Why Invest NFT -->
<section class="section-service-alt" #about>
  <div class="container">
    <div class="why-nft-content">
      <div class="why-for-nft">
        <div class="orange-vector"></div>
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="metaverse_heading">
              <h3>Purpose Of Investing In <span> NFT Marketplace</span> Platform
                Development</h3>
              <p>
                Realizing NFTs are more than cryptocurrencies with their ability
                to act on real-time happenings, it’s a choice of many modern
                artists to express themselves, providing them with their
                expressions hostage - Our NFT Marketplace development solution.
              </p>
            </div>
          </div>
        </div>
        <div class="row why-image-list">
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/nft-marketplace-new/webp/opensea.webp
                    " />

              <img loading="lazy"
                data-src="assets-new/images/nft-marketplace-new/opensea.png"
                class="img-fluid"
                alt="opensea"
                title="opensea"
                width="261"
                height="154" />
            </picture>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/nft-marketplace-new/webp/rarible.webp
                    " />

              <img loading="lazy"
                data-src="assets-new/images/nft-marketplace-new/rarible.png"
                class="img-fluid"
                alt="rarible"
                title="rarible"
                width="261"
                height="154" />
            </picture>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/nft-marketplace-new/webp/sorare.webp
                    " />

              <img loading="lazy"
                data-src="assets-new/images/nft-marketplace-new/sorare.png"
                class="img-fluid"
                alt="sorare"
                title="sorare"
                width="261"
                height="154" />
            </picture>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-6 text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/nft-marketplace-new/webp/superrare.webp
                    " />

              <img loading="lazy"
                data-src="assets-new/images/nft-marketplace-new/superrare.png"
                class="img-fluid"
                alt="superrare"
                title="superrare"
                width="261"
                height="154" />
            </picture>
          </div>
        </div>
      </div>
      <!-- <div class="">
            <div class="row why-detail">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 px-0">
                <p>
                    NFTs have earned true utility and they are explored in almost
                    every sector. With BlockchainX as your NFT marketplace development
                    company, you can render an effective and efficient trading
                    experience and establish on a global scale.
                </p>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-12 why-nft-read-more">
                <div class="content-link">
                    <a [routerLink]="['/contact']">
                    <span>Read More</span>
                    <img
                        src="assets-new/images/right-arrow.png"
                        class="img-fluid"
                        width="50"
                        height="15"
                        alt="Arrow"
                    />
                    </a>
                    <hr />
                </div>
                </div>
            </div>
            </div> -->
    </div>
  </div>
</section>
<!-- Why Invest NFT -->

<!-- Development Services starts -->
<div class="section-service-main">
  <div class="our-process-erc">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="metaverse_heading sm-pb-3 pb-2">
            <h3>What Are The External <span>NFT Marketplace</span> Development
              Services?</h3>
          </div>
        </div>
      </div>
      <div class="row justify-content-center erc-processing">
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Designing and Development</h4>
            <p>
              Our NFT marketplace development solution can be designed and
              developed to be basic and advanced based on the project
              requirement.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Smart Contracts Audit</h4>
            <p>
              The Smart contracts are thoroughly audited and ensured to be
              bug-free in the future as new transactions come by every time.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>DAO & NFT Governance</h4>
            <p>
              The NFT Governance through DAO provides users the ability to vote
              and take a decision over the governance tokens' actionables in the
              platform.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Platform Support and Maintenance</h4>
            <p>
              Post-launch support and maintenance in terms of updates are
              provided with our NFT marketplace development service.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>NFT Platform Infrastructure</h4>
            <p>
              We help you craft your NFT marketplace platform with utmost
              details that improve the scalability of the project.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>NFT Wallet Development</h4>
            <p>
              In-app NFT wallet development with utmost security can be
              integrated within the NFT marketplace.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Futuristics Features</h4>
            <p>
              Over time we help you integrate and better your NFT marketplace
              with futuristic features that help you stand out in the market.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Liquidity</h4>
            <p>
              Our services in NFT marketplace development ensure to maintenance
              of the liquidity range of the NFT and collectibles in the
              ecosystem.
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- Development Services ends -->

<!-- DeFi Staking Platforms starts -->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h3>Extensive Advantages Of<span> NFT Marketplace</span>
            Development</h3>
        </div>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/nft-marketplace-new/webp/intriguing-storefront.webp" />

            <img loading="lazy"
              data-src="assets-new/images/nft-marketplace-new/intriguing-storefront.png"
              class="img-fluid"
              alt="intriguing Storefront"
              width="130"
              height="130" />
          </picture>
          <h4 class="erc-20-head">Intriguing Storefront</h4>
          <p class="text-center">
            Easy to navigate storefront simplifying experience for the user
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/nft-marketplace-new/webp/advanced-search-option.webp" />

            <img loading="lazy"
              data-src="assets-new/images/nft-marketplace-new/advanced-search-option.png"
              class="img-fluid"
              alt="Advanced Search Option"
              width="130"
              height="130" />
          </picture>
          <h4 class="erc-20-head">Advanced Search Option</h4>
          <p class="text-center">
            Much improved search to precisely locate the result.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/nft-marketplace-new/webp/cross-chain-compatibility.webp" />

            <img loading="lazy"
              data-src="assets-new/images/nft-marketplace-new/cross-chain-compatibility.png"
              class="img-fluid"
              alt="Cross Chain Compatibility"
              width="130"
              height="130" />
          </picture>
          <h4 class="erc-20-head">Cross-chain Compatibility</h4>
          <p class="text-center">
            NFT platform that efficiently connects with multiple networks.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/nft-marketplace-new/webp/bulk-purchase.webp" />

            <img loading="lazy"
              data-src="assets-new/images/nft-marketplace-new/bulk-purchase.png"
              class="img-fluid"
              alt="Bulk Purchase"
              width="130"
              height="130" />
          </picture>
          <h4 class="erc-20-head">Bulk Purchase</h4>
          <p class="text-center">
            Making multiple purchases all at one time.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/nft-marketplace-new/webp/intricate-filters.webp" />

            <img loading="lazy"
              data-src="assets-new/images/nft-marketplace-new/intricate-filters.png"
              class="img-fluid"
              alt="Intricate Filters"
              width="130"
              height="130" />
          </picture>
          <h4 class="erc-20-head">Intricate Filters</h4>
          <p class="text-center">
            Classify the requirement very keenly through filters.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/nft-marketplace-new/webp/multi-chain-functionality.webp" />
            <img loading="lazy"
              data-src="assets-new/images/nft-marketplace-new/multi-chain-functionality.png"
              class="img-fluid"
              alt="Multichain Functionality"
              width="130"
              height="130" />
          </picture>
          <h4 class="erc-20-head">Multi-chain Functionality</h4>
          <p class="text-center">
            Seamless integration with multi-chain for cross-functionality
          </p>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
              <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/defi-stak/webp/earn-money-by-playing.webp"
              />

              <source
                  type="image/png"
                  srcset="assets-new/images/defi-stak/earn-money-by-playing.png"
              />

              <img loading="lazy"
                  data-src="assets-new/images/defi-stak/earn-money-by-playing.png"
                  class="img-fluid"
                  alt="Defi staking software"
                  width="75"
                  height="75"

              />
              </picture>
              <h4 class="erc-20-head">Bulk Purchase</h4>
              <p class="text-center">
              The NFT staking platform is available to the populace and this
              exposure boosts conviction and trust between the owner and users of
              the platform.
              </p>
          </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
              <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/defi-stak/webp/earn-money-by-playing.webp"
              />

              <source
                  type="image/png"
                  srcset="assets-new/images/defi-stak/earn-money-by-playing.png"
              />

              <img loading="lazy"
                  data-src="assets-new/images/defi-stak/earn-money-by-playing.png"
                  class="img-fluid"
                  alt="Defi staking software"
                  width="75"
                  height="75"

              />
              </picture>
              <h4 class="erc-20-head">Intricate Filters</h4>
              <p class="text-center">
              As long as the NFT is staked on the staking platform, the NFT
              staking user obtains constant bonuses.
              </p>
          </div>
      </div> -->
    </div>
  </div>
</div>
<!-- DeFi Staking Platforms ends -->

<!--nft metaverse-->
<section class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mb-lg-0 mb-4">
          <h3>Watch Our <span>NFT Marketplace</span> Demo</h3>
        </div>
      </div>
    </div>
    <div class="row metmb-3 align-items-start video-section-top">
      <div class="col-lg-5">
        <div class="metaverse_subheading">
          <h3 class="video-heading">Admin Feature</h3>
          <ul class="user-demo-list">
            <li>Ride to the Features supporting the universe of
              Possibilities!</li>
            <!-- <li>Bid NFTs</li>
            <li>Sell NFTs</li>
            <li>Buy Token</li>
            <li>Trending Collections</li> -->
          </ul>
          <div class="content-link">
            <a href="https://nftadmin.blockchainx.tech/" target="_blank"
              rel="noopener noreferrer">
              <span>View Demo</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png"
                  class="img-fluid"
                  width="50" height="15" alt="Arrow" title="Arrow"
                  *ngIf="!isDarkmode" />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy"
                  data-src="assets-new/images/right-arrow-dark.png"
                  class="img-fluid"
                  width="50" height="15" alt="Arrow" title="Arrow"
                  *ngIf="isDarkmode" />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-7 orders-1 video-meta-right">
        <div class="violet-vector"></div>
        <div class="video_right_side">
          <video
            src="assets-new/video/NFT Marketplace Admin Dashboard Demo Video _ White Label NFT Marketplace Solutions _ BlockchainX.mp4"
            controls [autoplay]="false" width="98%"
            poster="assets-new/video/admin-poster.jpeg"></video>
          <!-- <iframe class="nft-demo-videos" src="https://www.youtube.com/embed/9CPbRqqFgZA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
        </div>
      </div>
    </div>
    <div class="row align-items-start mt-0 pt-0 mt-lg-5 pt-lg-5">
      <div class="col-lg-7 orders-2 video-meta-left">
        <div class="green-vector"></div>
        <div class="video_left_side">

          <video
            src="assets-new/video/White Label NFT Marketplace User Demo Video _ Launch an NFT Marketplace Platform _ BlockchainX.mp4"
            controls [autoplay]="false" width="98%"
            poster="assets-new/video/nft-marketplaceoneday.jpeg"></video>
        </div>
      </div>
      <div class="col-lg-5 orders-1 video-left">
        <div class="metaverse_subheading">
          <h3 class="video-heading">User Demo</h3>
          <ul class="user-demo-list">
            <li>Explore the potential of Our NFT Marketplace, here In the
              demo</li>
          </ul>

          <div class="content-link">
            <a href="https://nftmarketplace.blockchainx.tech/" target="_blank"
              rel="noopener noreferrer">
              <span>View Demo</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png"
                  class="img-fluid"
                  width="50" height="15" alt="Arrow" title="Arrow"
                  *ngIf="!isDarkmode" />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy"
                  data-src="assets-new/images/right-arrow-dark.png"
                  class="img-fluid"
                  width="50" height="15" alt="Arrow" title="Arrow"
                  *ngIf="isDarkmode" />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!--nft metaverse-->

<!-- Our Products -->
<section class="section-service-alt marketplace-screens">
  <div class="our-products">
    <div class="container">
      <div class="row sm-pb-4 pb-2 justify-content-center">
        <div class="col-lg-10">
          <div class="metaverse_heading mx-auto">
            <h3>Check Out The Screens Of <span> NFT Marketplace</span>
              Development Solutions</h3>
          </div>
          <p class="py-3 text-center">
            NFTs can be purchased in one of these two convenient methods.
          </p>
        </div>

      </div>

      <div class="row gap-lg-0 gap-sm-5 gap-4">
        <div class="col-lg-6">
          <!-- <div class="metaverse_heading mx-auto text-center">
                  <h3 class="text-center pb-2">Black <span> Theme</span></h3>
                </div> -->
          <div class="mx-auto text-center dark-title">
            <h3 class="text-center pb-2">Black <span> Theme</span></h3>
          </div>
          <div class="product-category-list theme-arrow">

            <owl-carousel-o [options]="marketplaceOptions">
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-dark-screen-1.webp
                              " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-dark-screen-1.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-dark-screen-2.webp
                              " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-dark-screen-2.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-dark-screen-3.webp
                              " />
                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-dark-screen-3.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-dark-screen-4.webp
                              " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-dark-screen-4.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-dark-screen-5.webp
                              " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-dark-screen-5.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-dark-screen-6.webp
                              " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-dark-screen-6.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
        <div class="col-lg-6">
          <!-- <div class="metaverse_heading mx-auto text-center">
                  <h3 class="text-center pb-2">White <span> Theme</span></h3>
                </div> -->
          <div class="mx-auto text-center dark-title">
            <h3 class="text-center pb-2">White <span> Theme</span></h3>
          </div>
          <div class="product-category-list theme-arrow">

            <owl-carousel-o [options]="marketplaceOptions">
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-light-screen-1.webp
                                " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-light-screen-1.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-light-screen-2.webp
                                " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-light-screen-2.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-light-screen-3.webp
                                " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-light-screen-3.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-light-screen-4.webp
                                " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-light-screen-4.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-light-screen-5.webp
                                " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-light-screen-5.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="item-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/screens/webp/marketplace-light-screen-6.webp
                                " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/screens/marketplace-light-screen-6.jpg"
                        class="img-fluid"
                        alt="Marketplace Screen"
                        title="Marketplace Screen"
                        width="624"
                        height="390" />
                    </picture>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- Our Products -->

<!-- NFT marketplace workflow -->
<section class="section-service-main">
  <div class="container">
    <div class="orange-vector"></div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="metaverse_heading">
          <h3>Experience The Seamless Navigation Of An <span> NFT
              Marketplace</span> Platform</h3>
        </div>
      </div>
    </div>
    <div class="workflow-image">
      <picture>
        <source
          type="image/webp"
          srcset="assets-new/images/nft/webp/time-line.webp
              " />

        <img loading="lazy"
          data-src="assets-new/images/nft/time-line.png"
          height="402"
          width="1116"
          class="img-fluid"
          alt="Timeline"
          title="Timeline" />
      </picture>
    </div>
  </div>

</section>
<!-- NFT marketplace workflow -->

<!-- Ways to Reward Users starts -->
<div class="section-service-alt">
  <div class="types-of-erc">
    <div class="container">
      <div class="row sm-pb-4 pb-2">
        <div class="metaverse_heading mx-auto">
          <h3> Versatility In Utilizing An
            <span>NFT Marketplace </span>
          </h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/nft-marketplace-new/webp/crypto-collectibles.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/nft-marketplace-new/crypto-collectibles.png"
                  class="img-fluid"
                  alt="Crypto Collectibles"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Crypto Collectibles</h4>
            <p>
              Claim verified authentication and register ownership for animated
              digital collectibles like gifs, stickers, etc.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/nft-marketplace-new/webp/gaming.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/nft-marketplace-new/gaming.png"
                  class="img-fluid"
                  alt="gaming"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Gaming</h4>
            <p>
              Curating unique in-game collectibles, including armor, suits,
              powers, accessories, and others as individual assets.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/nft-marketplace-new/webp/art.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/nft-marketplace-new/art.png"
                  class="img-fluid"
                  alt="art"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Art</h4>
            <p>
              The foremost range of NFTs, either digital or any kind of unique
              artwork can be digitized to monetize in the crypto world.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/nft-marketplace-new/webp/trading-marketplace.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/nft-marketplace-new/trading-marketplace.png"
                  class="img-fluid"
                  alt="Trading Marketplace"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Trading Marketplace</h4>
            <p>
              Unlike other attributes, the NFT marketplace can be a unique space
              that rejuvenates trading with modern twists.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/nft-marketplace-new/webp/music.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/nft-marketplace-new/music.png"
                  class="img-fluid"
                  alt="music"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Music</h4>
            <p>
              Of course, music is taking a serious invasion into the NFT space
              giving artists a classic entry into a secured and transparent
              space.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/nft-marketplace-new/webp/real-estate.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/nft-marketplace-new/real-estate.png"
                  class="img-fluid"
                  alt="Real Estate"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Real Estate</h4>
            <p>
              Like in real time, Real estate is a shooting business in the
              metaverse bringing up new possibilities to deal as NFTs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Ways to Reward Users ends -->

<!-- DEMO -->
<section class="section-service-main demo-videos">
  <div class="container">
    <div class="metaverse_heading mx-auto">
      <h3>Our <span>NFT Marketplace </span> Demo Videos</h3>
      <p class="text-center py-3">
        Try out our demo for hands-on experience. Our platform is
        fully-customizable and we can tailor it to your project.
      </p>
    </div>
    <div
      class="row metmb-3 erc-about-content d-flex align-items-center gap-lg-0 gap-4">
      <div class="col-lg-4">
        <div class="video_right_side">
          <h3 class="video-heading">User NFT Buy/Sell demo</h3>
          <video poster="assets-new/images/nft/webp/user-buy-sell-demo.webp"
            src="assets-new/images/nft/screens/videos/user-buy-Sell-demo.mp4"
            controls
            [autoplay]="false"
            width="100%">
            <track
              kind="captions"
              src="assets-new/images/nft/screens/videos/user-buy-Sell-demo.mp4"
              srclang="en"
              label="English" />
          </video>
        </div>
        <!-- <div class="video_right_side">
          <h3> Admin Feature </h3>
          <a href="https://staking.blockchainx.tech/" target="_blank"
                rel="noopener noreferrer">
          <picture>
              <source
              type="image/webp"
              srcset="assets-new/images/about/webp/token-staking-dapp.webp"
              />

              <source
              type="image/png"
              srcset="assets-new/images/about/token-staking-dapp.png"
              />

              <img loading="lazy"
              data-src="assets-new/images/about/token-staking-dapp.png"
              class="img-fluid"
              height="100%"
              width="100%"
              alt="token-migration-demo"

              />
          </picture>
          </a>
      </div> -->
      </div>
      <div class="col-lg-4">
        <div class="video_right_side">
          <h3 class="video-heading">Partner Dashboard demo - Mint/Bulk Mint NFTs
          </h3>
          <video poster="assets-new/images/nft/webp/partner-dashboard-demo.webp"
            src="assets-new/images/nft/screens/videos/partner-dashboard-demo.mp4"
            controls
            [autoplay]="false"
            width="100%">
            <track
              kind="captions"
              src="assets-new/images/nft/screens/videos/partner-dashboard-demo.mp4"
              srclang="en"
              label="English" />
          </video>
        </div>
        <!-- <div class="video_right_side">
          <h3> User Demo </h3>
          <a href="https://staking.blockchainx.tech/" target="_blank"
                rel="noopener noreferrer">
          <picture>
              <source
              type="image/webp"
              srcset="assets-new/images/about/webp/token-staking-dapp.webp"
              />

              <source
              type="image/png"
              srcset="assets-new/images/about/token-staking-dapp.png"
              />

              <img loading="lazy"
              data-src="assets-new/images/about/token-staking-dapp.png"
              class="img-fluid"
              height="100%"
              width="100%"
              alt="token-migration-demo"

              />
          </picture>
          </a>
      </div> -->
      </div>
      <div class="col-lg-4">
        <div class="video_right_side">
          <h3 class="video-heading">Admin Dashboard - Manage collections/NFTs,
            Blogs, etc </h3>
          <video poster="assets-new/images/nft/webp/admin-dashboard.webp"
            src="assets-new/images/nft/screens/videos/admin-dashboard.mp4"
            controls
            [autoplay]="false"
            width="100%">
            <track
              kind="captions"
              src="assets-new/images/nft/screens/videos/admin-dashboard.mp4"
              srclang="en"
              label="English" />
          </video>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- DEMO -->

<!-- Our Process starts -->
<div class="section-service-alt">
  <div class="our-process-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h3>Our <span>NFT Marketplace </span> Deliverance</h3>
            <p class="text-center py-3">
              How our NFT staking platform development works
            </p>
          </div>
        </div>
      </div>
      <!-- Fast Transactions Starts -->
      <div class="row erc-processing justify-content-center">
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Captivating NFT Holders </h4>
            <p>
              Our consultant extensively collects your requirements keeping in
              mind the scale of your project and tokenomics.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Safeguarding Asset with Cryptography</h4>
            <p>
              We help you figure our where and how your staking platform could
              help users connect with your project easily.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Defi Investment avenues</h4>
            <p>
              We also help you choose the right blockchain network to deploy on,
              if your staking campaign is a standalone project.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Multichain versatility</h4>
            <p>
              In this stage, we create an overall prototype of your staking
              platform before moving on the core designs.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Futuristics Features</h4>
            <p>
              This is where the entire platform takes shape visually and we
              create a high-fidelity prototype for the platform.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Seamlessly integrating wallets and payment</h4>
            <p>
              Our solution is completely decentralized, and the backend is
              powered by a strong smart contract tailored to your project.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Liquidity </h4>
            <p>
              We conduct a thorough internal audit and testing process, with the
              smart contracts deployed on the tesnet for UAT.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Search Filter Exploration</h4>
            <p>
              Here, we deploy the smart contracts on the mainnet, connect the
              frontend with the smart contracts, and transfer the ownership to
              you.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Our Process ends  -->

<!-- Service Fields -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="common-sub-heading">
          <h4>NFT Marketplace Beyond NFTs<span class="dots">.</span></h4>
        </div>

        <h3 class="main-sub-heading pb-lg-5 pb-4">
          Varied Development Solutions In Varied Fields
        </h3>
        <div class="field-slider">
          <owl-carousel-o [options]="fieldOptions">
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="nft_field_market">
                  <div class="item-image">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft-marketplace-new/webp/phygital-nft-collectibles.webp
                        " />

                      <img loading="lazy"
                        data-src="assets-new/images/nft-marketplace-new/phygital-nft-collectibles.png"
                        width="130"
                        height="130"
                        class="img-fluid"
                        alt="Phygital NFT Collectibles"
                        title="phygital-nft-collectibles" />
                    </picture>
                  </div>
                  <h4 class="common-sub-heading">
                    Phygital NFT Collectibles
                  </h4>
                  <p>
                    Enjoy the perks of asset collection along with a digital
                    authority as an ownership of the NFT, that exists in both
                    the real and digital world.
                  </p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="nft_field_market">
                  <div class="item-image">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft-marketplace-new/webp/fractionalized-real-estate.webp
                        " />
                      <img loading="lazy"
                        data-src="assets-new/images/nft-marketplace-new/fractionalized-real-estate.png"
                        width="130"
                        height="130"
                        class="img-fluid"
                        alt="Fractionalized Real Estate"
                        title="Fractionalized Real Estate" />
                    </picture>
                  </div>
                  <h4 class="common-sub-heading">
                    Fractionalized Real Estate
                  </h4>
                  <p>
                    Unlike a whole area, now own real estate digital
                    collectibles in fractions. Divided ownership with similar
                    perks of owning a usual NFT.
                  </p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="nft_field_market">
                  <div class="item-image">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft-marketplace-new/webp/digital-marketplace.webp
                        " />
                      <img loading="lazy"
                        data-src="assets-new/images/nft-marketplace-new/digital-marketplace.png"
                        width="130"
                        height="130"
                        class="img-fluid"
                        alt="Digital Marketplace"
                        title="Digital Marketplace" />
                    </picture>
                  </div>
                  <h4 class="common-sub-heading">Digital Marketplace</h4>
                  <p>
                    Your show space to showcase your exuberant talent in the
                    digital world. They are more than a digital store connecting
                    all the dots for betterment.
                  </p>
                </div>
              </div>
            </ng-template>
            <!-- <ng-template carouselSlide>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="nft_field_market">
                  <div class="item-image">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/nft/webp/art.webp
                        "
                      />

                      <source
                        type="image/png"
                        srcset="assets-new/images/nft/art.png"
                      />

                      <img loading="lazy"
                        data-src="assets-new/images/nft/art.png"
                        width="130"
                        height="130"
                        class="img-fluid"
                        alt="Digital art"
                        title="Digital art"

                      />
                    </picture>
                  </div>
                  <h4 class="common-sub-heading">Digital ART Marketplace</h4>
                  <p>
                    Blockchain networks with the ability to digitize anything,
                    Digital NFTs add value to the ecosystem.
                  </p>
                </div>
              </div>
            </ng-template> -->
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Service Fields -->

<!-- Dream to Reality -->
<section class="section-service-alt">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="metaverse_heading">
          <h3>Additional Features Of<span> NFT Marketplace</span> Development
            Platform</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="dreamlist-top">
          <div class="marquee">
            <div class="marquee-main">
              <span>
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/webp/bid-and-buy-facility.webp
                    " />

                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/bid-and-buy-facility.png"
                    width="72"
                    height="72"
                    class="img-fluid"
                    alt="Bid and Buy Facility"
                    title="Bid and Buy Facility" *ngIf="!isDarkmode"/>
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/bid-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/bid-lite.png"
                    alt="Bid and Buy Facility" title="Bid and Buy Facility"
                    class="img-fluid mx-auto"
                    width="72" height="72" *ngIf="isDarkmode"/>
                </picture>
                <h4 class="common-sub-heading-alt">Bid And Buy facility</h4>
              </span>
            </div>
            <div class="marquee-main">
              <span>
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/webp/efficient-nft-listing.webp
                    " />

                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/efficient-nft-listing.png"
                    width="72"
                    height="72"
                    class="img-fluid"
                    alt="Efficient NFT Listing"
                    title="Efficient NFT Listing" *ngIf="!isDarkmode"/>
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/efficient-nft-liftin-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/efficient-nft-liftin-lite.png"
                    alt="Efficient NFT Listing" title="Efficient NFT Listing"
                    class="img-fluid mx-auto"
                    width="72" height="72" *ngIf="isDarkmode"/>
                </picture>
                <h4 class="common-sub-heading-alt">Efficient NFT Listing</h4>
              </span>
            </div>
            <div class="marquee-main">
              <span>
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/webp/built-in-wallets.webp
                    " />

                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/built-in-wallets.png"
                    width="72"
                    height="72"
                    class="img-fluid"
                    alt="Built in Wallets"
                    title="Built in Wallets" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/built-in-wallets-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/built-in-wallets-lite.png"
                    alt="Built in Wallets" title="Built in Wallets"
                    class="img-fluid mx-auto"
                    width="72" height="72" *ngIf="isDarkmode"/>
                </picture>
                <h4 class="common-sub-heading-alt">Built-in Wallets</h4>
              </span>
            </div>
            <div class="marquee-main">
              <span>
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/webp/royalties.webp
                    " />

                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/royalties.png"
                    width="72"
                    height="72"
                    class="img-fluid"
                    alt="royalties"
                    title="royalties" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/royalty-mechanism-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/royalty-mechanism-lite.png"
                    alt="Royalties" title="Royalties"
                    class="img-fluid mx-auto"
                    width="72" height="72" *ngIf="isDarkmode"/>
                </picture>
                <h4 class="common-sub-heading-alt">Royalty Mechanisms</h4>
              </span>
            </div>
            <div class="marquee-main">
              <span>
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/webp/airdrop.webp
                    " />

                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/airdrop.png"
                    width="72"
                    height="72"
                    class="img-fluid"
                    alt="airdrop"
                    title="airdrop" *ngIf="!isDarkmode"/>
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/airdrop-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/airdrop-lite.png"
                    alt="Airdrop" title="Airdrop"
                    class="img-fluid mx-auto"
                    width="72" height="72" *ngIf="isDarkmode"/>
                </picture>
                <h4 class="common-sub-heading-alt">
                  Airdrops & Rewards
                </h4>
              </span>
            </div>
            <div class="marquee-main">
              <span>
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/webp/rate.webp
                    " />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/rate.png"
                    width="72"
                    height="72"
                    class="img-fluid"
                    alt="rate"
                    title="rate" *ngIf="!isDarkmode"/>
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/rating-system-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/rating-system-lite.png"
                    alt="Rate" title="Rate"
                    class="img-fluid mx-auto"
                    width="72" height="72" *ngIf="isDarkmode"/>
                </picture>
                <h4 class="common-sub-heading-alt">Rating System</h4>
              </span>
            </div>
            <div class="marquee-main">
              <span>
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/webp/kyc-aml.webp
                    " />

                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/kyc-aml.png"
                    width="72"
                    height="72"
                    class="img-fluid"
                    alt="KYC/AML"
                    title="KYC/AML" *ngIf="!isDarkmode"/>
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/aml-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/aml-lite.png"
                    alt="KYC/AML" title="KYC/AML"
                    class="img-fluid mx-auto"
                    width="72" height="72" *ngIf="isDarkmode"/>
                </picture>
                <h4 class="common-sub-heading-alt">KYC/AML</h4>
              </span>
            </div>
            <div class="marquee-main">
              <span>
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/webp/secured-payment-gate.webp
                    " />

                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/secured-payment-gate.png"
                    width="72"
                    height="72"
                    class="img-fluid"
                    alt="Secured Payment Gate"
                    title="Secured Payment Gate" *ngIf="!isDarkmode"/>
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/secured-payment-gate-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/secured-payment-gate-lite.png"
                    alt="Secured Payment Gate" title="Secured Payment Gate"
                    class="img-fluid mx-auto"
                    width="72" height="72" *ngIf="isDarkmode"/>
                </picture>
                <h4 class="common-sub-heading-alt">Secured Payment <br>
                  Gateway</h4>
              </span>
            </div>
            <div class="marquee-main">
              <span>
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/webp/push-notification.webp
                    " />

                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/push-notification.png"
                    width="72"
                    height="72"
                    class="img-fluid"
                    alt="Push Notifications"
                    title="Push Notifications" *ngIf="!isDarkmode"/>
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/push-notifications-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/push-notifications-lite.png"
                    alt="Push Notifications" title="Push Notifications"
                    class="img-fluid mx-auto"
                    width="72" height="72" *ngIf="isDarkmode"/>
                </picture>
                <h4 class="common-sub-heading-alt">Push Notifications</h4>
              </span>
            </div>
            <div class="marquee-main">
              <span>
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/webp/market-analysis.webp
                    " />

                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/market-analysis.png"
                    width="72"
                    height="72"
                    class="img-fluid"
                    alt="Market Analysis"
                    title="Market Analysis" *ngIf="!isDarkmode" />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/nft-marketplace-new/sale-analysis-lite.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/nft-marketplace-new/sale-analysis-lite.png"
                    alt="Market Analysis" title="Market Analysis"
                    class="img-fluid mx-auto"
                    width="72" height="72" *ngIf="isDarkmode"/>
                </picture>
                <h4 class="common-sub-heading-alt">
                  Sale Analysis
                </h4>
              </span>
            </div>
          </div>
          <!-- <div class="marquee pt-5" direction="right">

          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Dream to Reality -->

<!-- How Nft Fields Work -->
<section class="section-service-main">
  <div class="violet-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-12 mx-auto">
        <div class="metaverse_heading">
          <h3>Different Revenue Streams For The Admin <br> In A<span> NFT
              Marketplace</span></h3>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 cmb-5">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/nft-marketplace-new/webp/royalty-fee.webp
            " />

          <img loading="lazy"
            data-src="assets-new/images/nft-marketplace-new/royalty-fee.png"
            width="115"
            height="115"
            class="img-fluid royalty-fee"
            alt="Royalty Fee"
            title="Royalty Fee" />
        </picture>
        <div class="our_process_col_grap">
          <h2>01</h2>
        </div>
        <div class="our_process_text_wrap">
          <h3>Royalty Fee</h3>
          <p>
            Ranging from 5% to 10% the platform can claim royalty charges for
            every secondary sale happening on the platform.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 cmb-5">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/nft-marketplace-new/webp/partner-onboarding-fee.webp
            " />
          <img loading="lazy"
            data-src="assets-new/images/nft-marketplace-new/partner-onboarding-fee.png"
            width="115"
            height="115"
            class="img-fluid royalty-fee"
            alt="Partner Onboarding Fee"
            title="Partner Onboarding Fee" />
        </picture>
        <div class="our_process_col_grap">
          <h2>02</h2>
        </div>
        <div class="our_process_text_wrap">
          <h3>Partner Onboarding Fee</h3>
          <p>
            As a new partner signs up in the NFT marketplace platform for any
            new projects the platform can collect a fee for rendering its
            services.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 cmb-5">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/nft-marketplace-new/webp/project-promotions.webp
            " />

          <img loading="lazy"
            data-src="assets-new/images/nft-marketplace-new/project-promotions.png"
            width="115"
            height="115"
            class="img-fluid royalty-fee"
            alt="Project Promotions"
            title="Project Promotions" />
        </picture>
        <div class="our_process_col_grap">
          <h2>03</h2>
        </div>
        <div class="our_process_text_wrap">
          <h3>Project Promotions</h3>
          <p>
            Admin can charge promotion fees for projects to get featured and
            highlighted in the platform that in turn boost their sales.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- How Nft Fields Work -->

<!--web3 expert-->
<app-expert></app-expert>
<!--end-->

<!-- FAQ Section -->
<section class="section-service-main">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  Where can I find a NFT Marketplace Development company?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    There are multiple opportunities to identify the NFT
                    Marketplace development company. It’s important to ensure
                    that the company themselves provides end-to-end development
                    services, from designing to ideation.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  What is the time and cost for NFT marketplace development?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The cost and time involved in developing an NFT Marketplace
                    is difficult to identify and fix. Based on the requirement,
                    customization, and advancements the time and cost involved
                    keep fluctuating.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  Do you provide customized NFT Marketplace Development
                  services?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Yes, of course, We provide complete customization for NFT
                    Marketplace Platform Development. We ensure to craft your
                    solution as you desire.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive">
                  How To Create an NFT Marketplace development platform like
                  Opensea?
                </button>
              </div>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The very first step involved in developing an NFT
                    marketplace like Open Sea is to get your checklist done for
                    the platform and do some groundwork to identify the best NFT
                    marketplace developer, set up a meeting and discuss your
                    requirements and constraints, and proceed to develop.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingSix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix">
                  What is Nft Marketplace Development?
                </button>
              </div>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    NFT Marketplace connects creators and their digital
                    collectibles with interested buyers with the perks of
                    enabling their utilities. Meanwhile, the process of curating
                    such a platform with the ability to sync with the
                    requirements of both creators and admin is the process of
                    NFT Marketplace Development.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ Section -->

<!-- Blog -->
<section class="section-service-alt">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/nft-marketplace-development-complete-guide/">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/blog/complete-guide-for-nft/developed-successful-nft-marketplace.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/complete-guide-for-nft/developed-successful-nft-marketplace.png"
                    width="404" height="246" class="img-fluid"
                    alt="Developed Successful NFT Marketplace"
                    title="developed-successful-nft-marketplace" />
                </picture>
                <h3>
                  Building a Successful NFT Marketplace: Key Factors and
                  Insights
                </h3>
                <p>
                  In the world of digital content, NFTs are quickly emerging as
                  a game changer. NFT marketplaces enable the authentication...
                </p>
              </a>
              <div class="content-link">
                <a
                  href="https://www.blockchainx.tech/nft-marketplace-development-complete-guide/">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/create-nft-marketplace-polygon/">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/blog/create-nft-marketplace-polygon.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/create-nft-marketplace-polygon.jpg"
                    width="404" height="246" class="img-fluid"
                    alt="Create NFT Marketplace Polygon"
                    title="Create NFT Marketplace Polygon" />
                </picture>
                <h3>
                  How to Create an NFT Marketplace on Polygon?
                </h3>
                <p>
                  Since the arrival of NFTs (non-fungible tokens), they have
                  gained a large user base, and people have begun...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/create-nft-marketplace-polygon/">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/create-your-nft-marketplace-on-avalanche/">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/blog/create-your-nft-marketplace-on-avalanche/nft-marketplace-on-avalanche.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/create-your-nft-marketplace-on-avalanche/nft-marketplace-on-avalanche.jpg"
                    width="404" height="246" class="img-fluid"
                    alt="NFT Business on Avalanche"
                    title="NFT Business on Avalanche" />
                </picture>
                <h3>
                  How to Create Your NFT Marketplace on Avalanche
                </h3>
                <p>
                  The most popular form of cryptocurrencies, NFT - the
                  non-fungible token has a massive market in the web3 space...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/create-your-nft-marketplace-on-avalanche/">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/nft-marketplace-development-solana/">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/blog/nft-marketplace-development-solana.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/nft-marketplace-development-solana.jpg"
                    width="404" height="246" class="img-fluid"
                    alt="NFT Marketplace Development Solana"
                    title="NFT Marketplace Development Solana" />
                </picture>
                <h3>
                  How to create NFT marketplace in Solana
                </h3>
                <p>
                  The market is superfluous with digital expansion. Every
                  business, out in the world, every nook and corner...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/nft-marketplace-development-solana/">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/how-to-create-an-nft-marketplace/">

                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/blog/how-to-create-an-nft-marketplace.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/how-to-create-an-nft-marketplace.jpg"
                    width="404" height="246" class="img-fluid"
                    alt="NFT Marketplace" title="NFT Marketplace" />
                </picture>
                <h3>
                  How to Create NFT Marketplace? - Complete Development Process
                </h3>
                <p>
                  The Non-fungible tokens, NFTs are the major reason for the
                  growing popularity and widespread attention...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/how-to-create-an-nft-marketplace/">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>

<section class="section-service-main">
  <app-get-in-touch></app-get-in-touch>
</section>

<!-- flow-chart -->
<!-- <section class="section-service-alt flow-chart-section">
      <div class="violet-vector"></div>
      <div class="green-vector"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading">
              <h4>Sell Flow <span class="dots">.</span></h4>
            </div>
            <h3 class="main-sub-heading">Interaction flow chart</h3>
          </div>
        </div>
        <div class="flow-chart">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/nft/webp/chart.webp
              "
            />

            <source
              type="image/png"
              srcset="assets-new/images/nft/chart.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/nft/chart.png"
              height="633"
              width="1117"
              class="img-fluid"
              alt="chart"
              title="chart"

            />
          </picture>
        </div>
      </div>
    </section> -->
<!-- flow-chart -->
