<section class="blogs">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="d-flex justify-content-between">
        <div class="common-sub-heading sub-title">
          Our Latest Blogs<span class="dots"></span>
        </div>
        <div class="deploy-link justify-content-end">
          <a [routerLink]="['/blog']">
            <!-- <span>Read More</span> -->
            <img
                  src="assets-new/images/right-arrow.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="!isDarkmode"
                />
                <img
                  src="assets-new/images/right-arrow-dark.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="isDarkmode"
                />
          </a>
        </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">

          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/asset-tokenization-on-various-industries/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/asset-tokenization-on-various-industries/asset-tokenization-on-various-industries-banner.webp">
                  <img loading="lazy" src="assets-new/images/blog/asset-tokenization-on-various-industries/asset-tokenization-on-various-industries-banner.jpg" alt="Asset tokenization on various industries : 9 Industries to watch" title="Asset tokenization on various industries : 9 Industries to watch" class="img-fluid" width="855" height="521"/>
                </picture>
                <p class="h-3">
                  Asset tokenization on various industries : 9 Industries to watch
                </p>
                <p>
                  Asset tokenization is shaking up businesses around the globe by converting tangible and intangible assets into blockchain-based tokens that provide remarkable liquidity...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/asset-tokenization-on-various-industries/">
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/create-centralized-crypto-exchange/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/create-centralized-crypto-exchange/create-centralized-crypto-exchange.webp">
                  <img loading="lazy" src="assets-new/images/blog/create-centralized-crypto-exchange/create-centralized-crypto-exchange.jpg" alt="Create Centralized cryptocurrency exchange" title="Create Centralized cryptocurrency exchange" class="img-fluid" width="855" height="521"/>
                </picture>
                <p class="h-3">
                  9 Simple Steps to Create Centralized Crypto Exchange
                </p>
                <p>
                  Are you prepared to explore the exciting world of trading centralized cryptocurrencies? If so, starting your own centralized...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/create-centralized-crypto-exchange/">
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/launch-a-meme-coin-on-solana/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/launch-a-meme-coin-on-solana/launch-a-meme-coin-on-solana.webp">
                  <img loading="lazy" src="assets-new/images/blog/launch-a-meme-coin-on-solana/launch-a-meme-coin-on-solana.jpg" alt="Launch a Meme coin on Solana - A step-by-step tutorial" title="Launch a Meme coin on Solana - A step-by-step tutorial" class="img-fluid" width="855" height="521"/>
                </picture>
                <p class="h-3">
                  6 Steps launch a meme coin on solana
                </p>
                <p>
                  Is the idea of transforming your favourite meme into a cryptocurrency something you have ever considered? On the other hand Solana has your back...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/launch-a-meme-coin-on-solana/">
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/how-to-create-crypto-exchange/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/how-to-create-crypto-exchange/how-to-create-crypto-exchange.webp">
                  <img loading="lazy" src="assets-new/images/blog/how-to-create-crypto-exchange/how-to-create-crypto-exchange.jpg" alt="how to create a crypto exchange" title="how to create a crypto exchange" class="img-fluid" width="855" height="521"/>
                </picture>
                <p class="h-3">
                  How to Create Crypto Exchange Platform: 7 Quick Steps
                </p>
                <p>
                  It might be challenging to create crypto exchange but if you plan your steps well—you can do it step by step...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/how-to-create-crypto-exchange/">
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/how-to-create-trc20-token/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/how-to-create-trc20-token/how-to-create-trc20-token.webp">
                  <img loading="lazy" src="assets-new/images/blog/how-to-create-trc20-token/how-to-create-trc20-token.jpg" alt="How to Create TRC20 Token" title="How to Create TRC20 Token" class="img-fluid" width="856" height="522"/>
                </picture>
                <p class="h-3">
                  How to Create TRC20 Token: 7 Step-by-Step Guide
                </p>
                <p>
                  Have you ever considered to creating a trc20 tokens on the TRON blockchain or looking for a fast and affordable transaction...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/how-to-create-trc20-token/">
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/types-of-meme-coins/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/types-of-meme-coin/types-of-meme-coins.webp">
                  <img loading="lazy" src="assets-new/images/blog/types-of-meme-coin/types-of-meme-coins.jpg" alt="Types of Meme Coins" title="Types of Meme Coins" class="img-fluid" width="856" height="522"/>
                </picture>
                <p class="h-3">
                  Types of Meme Coins to Watch in 2024
                </p>
                <p>
                  Meme coins began as humorous inventions frequently influenced by internet culture but they developed into multi-billion phenomena, demonstrating...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/types-of-meme-coins/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/how-to-create-erc20-token/">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/how-to-create-erc20-token/how-to-create-erc20-token.webp
                    "
                  />
                  <img
                    loading="lazy"
                    data-src="assets-new/images/blog/how-to-create-erc20-token/how-to-create-erc20-token.jpg"
                    width="404"
                    height="255"
                    class="img-fluid"
                    alt="How to Create ERC20 Token"
                    title="How to Create ERC20 Token"
                  />
                </picture>
                <p class="h-3">
                  How to Create an ERC20 Token in 7 Steps – A Comprehensive Guide
                </p>
                <p>
                  Have you ever thought about how to create your own cryptocurrency? As blockchain technology continues to growmore people are...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/how-to-create-erc20-token/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a href="https://www.blockchainx.tech/how-to-launch-crypto-token/">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/blog/how-to-launch-crypto-token/how-to-launch-crypto-token.webp">
                  <img loading="lazy" src="assets-new/images/blog/how-to-launch-crypto-token/how-to-launch-crypto-token.jpg" alt="Crypto token launch made easy-Key steps and tips" title="Crypto token launch made easy-Key steps and tips" class="img-fluid" width="856" height="522"/>
                </picture>
                <p class="h-3">
                  Learn How to Launch Crypto Token from Scratch
                </p>
                <p>
                  Are you considering developing your cryptocurrency or figuring out how to launch crypto token? For this—you must have an effective strategy...
                </p>
              </a>
              <div class="content-link">
                <a href="https://www.blockchainx.tech/how-to-launch-crypto-token/">
                  <!-- <span>Read More</span> -->
                  <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
