import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from 'src/app/shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-cryptocurrency-payment-card-development',
  templateUrl: './cryptocurrency-payment-card-development.component.html',
  styleUrls: ['./cryptocurrency-payment-card-development.component.css']
})
export class CryptocurrencyPaymentCardDevelopmentComponent implements OnInit {

  selectedLink:number;
  title = 'Cryptocurrency  Payment Card Development Services';
  about: any;
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Avail our Cryptocurrency payment card development services and make payments more secure, highly accessible, convenient, and compatible.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Cryptocurrency Payment Card Development,Cryptocurrency Payment Card Development, Cryptocurrency Payment Card Development services, Cryptocurrency Payment Card Development company, Cryptocurrency Payment Card Development solution, Cryptocurrency Payment Card Development Platform, Cryptocurrency Payment Card Development Process',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Cryptocurrency  Payment Card Development Services',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/cryptocurrency-payment-card-development-services/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Avail our Cryptocurrency payment card development services and make payments more secure, highly accessible, convenient, and compatible.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/crypto-payment.png',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/cryptocurrency-payment-card-development-services/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Cryptocurrency  Payment Card Development Services',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Avail our Cryptocurrency payment card development services and make payments more secure, highly accessible, convenient, and compatible.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/crypto-payment.png',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/cryptocurrency-payment-card-development-services/'
    );
  }

  addClass(id:number){
    this.selectedLink=id;
  }
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  }
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    navSpeed: 500,
    // margin: 30,
    dots: false,
    center: true,
    navText: [],
    responsive: {
      0: {
        items: 1.5,
      },
      640: {
      items: 2,
      },
      740: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    nav: false,
  };
}