<!-- Banner Section -->
<div class="banners-section">

    <div class="banner_wrapper">
      <div class="container" id="banner-page-vector-main">
        <div class="green-vector"></div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-12 col-sm-12 col-12">
            <div class="banner_contentWrapper">
              <div class="caption">
                <h1 class="pb-3">
                  <span><strong>Luxury Item </strong>Tokenization</span>
                </h1>
                <div class="common-sub-heading-alt pb-2">Maximize the Potential of Your Physical Assets </div>
  
                <p>
                    Broaden the capabilities and utility of real assets, including unique luxury items with the power of decentralization with our Luxury item tokenization development, unveiling the true potential.
                </p>
                <div class="erc-link">
                  <div class="content-link">
                    <a
                      href="https://t.me/sparkouttechblockchainx"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>Telegram</span>
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
                    </a>
                  </div>
                  <div class="content-link">
                    <a
                      href="https://www.blockchainx.tech/contact/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>Email Us</span>
                      <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12 text-center">
            <div class="banner-right-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                      assets-new/images/real-world-asset-tokenization/actual-assets-as-digital-securities.webp
                    " />
  
                <source
                  type="image/png"
                  srcset="
                      assets-new/images/real-world-asset-tokenization/actual-assets-as-digital-securities.png
                    " />
  
                <img loading="lazy"
                  data-src="assets-new/images/real-world-asset-tokenization/actual-assets-as-digital-securities.png"
                  class="img-fluid"
                  alt="actual-assets-as-digital-securities"
                  width="500"
                  height="500" 
                  />
              </picture>            
            </div>
          </div>
        </div>
  
        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center"
            (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png" />
              <img loading="lazy"
                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll-icon"
                title="scroll-icon" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner Section end -->
  <section class="section-service-alt about-section-clients">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>
            Our<span>Clients</span>
          </h3>
        </div>
      </div>
      <div class="row client-carousel">
        <div class="col-lg-12 mx-auto">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
                </picture>
              </div>
            </ng-template>
  
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
                </picture>
              </div>
            </ng-template>
  
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
                </picture>
              </div>
            </ng-template>
  
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- Why consider AI Meme start-->
<section class="section-service-main" #about>
    <div class="container">
      <div class="whys_section mb-0 text-center">
        <h2 class="pb-lg-5 pb-4"><span>Luxury Item Token </span>Development Services</h2>
      </div>
      <div class="row">
        <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
          <div class="bep-work-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/luxury-image-token.webp" />
              <source type="image/png"
                srcset="assets-new/images/real-world-asset-tokenization/luxury-image-token.png" />
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/luxury-image-token.png"
                width="596" height="596"
                alt="Gold Tokenization Development Service"
                title="Gold Tokenization Development Service"
                class="BSC-Tokens-image img-fluid" 
                />
            </picture>
          </div>
        </div>
        <div
          class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <p>
              As the evolution around luxury items and brands continues, decentralization through blockchain can take a leap effectively. The craze over technology into daily aspects is getting intriguing and validated. Luxury items including unique, antiques, special collectibles, rare automotives, and many other things can now be tokenized with our Luxury item tokenization development services.
            </p>
            <p>
              Bringing them under the shades of decentralization gets transparency, security of the asset, global witness and massive swift in the increase of interest therefore scaling. While our services under Luxury item tokenization includes.
            </p>
          </div>
  
          <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  Real Asset tokenization
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Asset Fractionalization
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Asset Management Systems
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Luxury Item Token Marketplace
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </section>
  <!-- End -->

  <!-- Related Services -->
<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Features Involved In <span>Luxury Item Tokenization</span></h3>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/asset-ownership.webp">
              <source type="image/png"
                srcset="assets-new/images/real-world-asset-tokenization/asset-ownership.png">
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/asset-ownership.png"
                width="74" height="74" alt="Fractional Asset Ownership"
                title="Fractional Asset Ownership" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Fractional Asset Ownership</h4>
          <p>
            Rather than selling or buying luxury assets as a whole in blockchain, with fractional ownership options we can tokenize a whole asset into fractions and enable ownership authority.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/asset-saving.webp">
              <source type="image/png"
                srcset="assets-new/images/real-world-asset-tokenization/asset-saving.png">
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/asset-saving.png"
                width="74" height="74" alt="Asset Transparency"
                title="Asset Transparency" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Asset Transparency
          </h4>
          <p>
            The asset that is tokenized can be monitored effectively from time to time and understand its growth for a long term through blockchain’s immutable disturbed ledger formats. 
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/selling-asset.webp">
              <source type="image/png"
                srcset="assets-new/images/real-world-asset-tokenization/selling-asset.png">
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/selling-asset.png"
                width="75" height="75" alt="Improved Token Liquidity"
                title="Improved Token Liquidity" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Improved Token Liquidity</h4>
          <p>
            With the growing popularity for fractionalised luxury item tokens the assets are in good frequency to bring better and much improved token liquidity with regular scaling.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/asset-management.webp">
              <source type="image/png"
                srcset="assets-new/images/real-world-asset-tokenization/asset-management.png">
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/asset-management.png"
                width="74" height="74" alt="Asset Management"
                title="Asset Management" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Asset Management</h4>
          <p>
            Tokenizing luxury items and real assets is a simple yet seamless process to manage, maintain and handle especially with highly valuable, fragile, or large assets.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/real-world-asset-tokenization/exploring-asset.webp">
              <source type="image/png"
                srcset="assets-new/images/real-world-asset-tokenization/exploring-asset.png">
              <img loading="lazy"
                data-src="assets-new/images/real-world-asset-tokenization/exploring-asset.png"
                width="75" height="75" alt="Global Brand Visibility"
                title="Global Brand Visibility" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Global Brand Visibility</h4>
          <p>
            With investments coming in and forth from different vicinity, the reach among audience and investor has attracted from all parts of world yielding better visibility globally. 
          </p>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/nft-markeplace.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/nft-markeplace.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/nft-markeplace.png"
                width="75" height="75" alt="Increasing investor Base"
                title="Increasing investor Base" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Increasing investor Base
          </h4>
          <p>
            For investors who are aiming to build a versatile portfolio, these
            Gold tokenizations give them the option to make wider or smaller
            investments therefore increasing traffic.
          </p>
        </div>
      </div> -->
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- Difference b/w Web2 and Web3 starts -->
<section class="section-service-main">
  <div class="types-of-erc text-center">
      <div class="container">
          <div class="build-web3-solution">
              <div class="row justify-content-center mb-3">
                  <div class="col-lg-10">
                      <h3 class="main-sub-heading text-center">Opening up multifacet opportunities for investments, <span> Luxury Item Tokenization Development</span>
                      </h3>
                  </div>
              </div>
              <p>
                A perfect choice giving your assets more value, utilized as a versatile asset with multiple utilities, gaining investor interest with ownership perks, providing opportunities with lesser investments, and bringing massive traction. Still mulling?
              </p>
              <div class="discuss-link">
                  <a href="/contact"><span>Connect with us today for Luxury item token development!</span>
                      <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow" class="img-fluid"
                        width="50" height="15" *ngIf="!isDarkmode" />
                        <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow" class="img-fluid"
                        width="50" height="15" *ngIf="isDarkmode" />
                  </a>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- Ends -->

  <!-- Principles Start -->
  <section class="section-service-alt">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-5">
          <div class="c-animation">
            <!-- <div class="common-sub-heading">
              <h4>Choose us<span class="dots">.</span></h4>
            </div> -->
            <h3 class="main-sub-heading">
              Working of Luxury Item Tokenization
            </h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="green-vector"></div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div class="col-12 service-detail">
              <p>
                The tokenizer chooses the asset to tokenize, and get it physically stored with a custodian
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div class="col-12 service-detail">
              <p>
                Custodian verifies the custody and permits the tokenizer to mint the token in their platform.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div class="col-12 service-detail">
              <p>
                The token is minted by the tokenizer or custodian, it can be listed in the marketplaces. The custody details are stored on a decentralized IPFS server and its hash is embedded on the token’s smart contract metadata.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row service-content">
            <div class="col-12 service-detail">
              <p>
                With that all on point, the token buyer can now make their purchase, it's validated and the asset from the custodian is transferred to the next custodian in charge. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
<!-- Principles End -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>BlockchainX Tokenize Your Luxury Items </span> with Maximizing Outcomes
          </h2>
          <p class="pb-3">
            We at BlockchainX as a professional Blockchain development company, over the course of 7 years we have expanded our niche and dynamics in a versatile manner around blockchain, web3 and decentralizations. From creative customization and result driven consultation to effective deployment, producing amplified results our dedicated team of professionals contribute with utmost care.
          </p>
          <p class="pb-3">
            Our services have touched, worked and successfully launched 50+ blockchain projects around the lines of blockchain development, smart contracts, tokenization, network building, community handling, etc. While we have good experience and brought in massie results with tokenization and its distinctions.
          </p>
          <p class="pb-2">
            Choosing BlockchainX for your luxury item tokenization brings you opportunity to 
          </p>
          <div class="whysection_text_wrap">
            <ul>
              <li>Doing proper market research</li>
              <li>Equity token development options</li>
              <li>Fractional ownership opportunities</li>
              <li>Gathering larger investment crowd</li>
              <li>Amplifying on better ROI</li>
            </ul>
          </div>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/real-world-asset-tokenization/block-chain-asset.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/real-world-asset-tokenization/block-chain-asset.png" />

            <img loading="lazy"
              data-src="assets-new/images/real-world-asset-tokenization/block-chain-asset.png"
              width="575"
              height="521"
              class="img-fluid"
              alt="Gold Tokenization development company"
              title="Gold Tokenization development company"
              />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->
<section class="section-service-alt">
  <div>
    <div class="violet-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="our_experts">
            <h2><span>Luxury brands and Blockchain enthusiasts </span>your call to venture into a new opportunity with us!</h2>
            <div class="text-center my-3 my-md-4">
              <a class="our_expert_talk" href="https://www.blockchainx.tech/contact/">Connect today <span>to step forward into Luxury Item Tokenization.</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>

  <!-- FAQ Start -->
  <section class="section-service-main">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              Frequently Asked Question <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    What luxury items can be tokenized?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      Blockchain decentralization can tokenize any assets, while being specific about luxury items from unique ornaments, metals, antiques, automotives, monuments, etc. 
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    What are the different blockchain used for Luxury item tokenizations?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      Almost every blockchain network is compatible to tokenize luxury items, which includes Ethereum, Binance, Tron, Solana, EOS, Hyperledger, and others. 
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    Can I tokenize any item in the real world?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      Yes, the opportunity to tokenize any physical asset is possible.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour">
                    Do Luxury item tokens have token equity?
                  </button>
                </div>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      Yes they do have equity, and the highlighted part is, this requires no big investment as we can fractionalize assets and distribute it in part digitally. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
  </section>
  <!-- FAQ End -->

  <section class="section-service-alt">
    <app-blog></app-blog>
</section>

<section class="section-service-alt">
    <app-get-in-touch></app-get-in-touch>
</section>