<div class="container">
  <div class="contact-main">
    <div class="main-heading text-center">
      <div class="d-flex align-items-center justify-content-center mb-3">
        <picture>
          <source
            type="image/webp"
            srcset="assets-new/images/contact/webp/hand.webp"
          />

          <source type="image/png" srcset="assets-new/images/contact/hand.png" />

          <img loading="lazy"
            data-src="assets-new/images/contact/hand.png"
            alt="waveing hand emoji"
            width="90"
            height="90"
            class="waveing-image img-fluid"
          />
        </picture>
        <h1>We love to hear from you</h1>
      </div>
      <p>Any question? Reach out to us and we’ll get back to you shortly.</p>
    </div>
  </div>

  <div class="contact-form contact-page-form">
    <div class="contact-arc">

    </div>
    <div class="contact-arc1">

    </div>
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <picture>
      <source
        type="image/webp"
        srcset="assets-new/images/contact/webp/3d.webp"
      />

      <source type="image/png" srcset="assets-new/images/contact/3d.png" />

      <img loading="lazy"
        data-src="assets-new/images/contact/3d.png"
        alt="avatar"
        width="340"
        height="340"
        class="contact-image img-fluid"
      />
    </picture>



    <div class="row">
      <div class="col-lg-7 col-md-12 contact-inputs contacts mb-4 mb-sm-5 mb-lg-0">
        <div class="getintouch getintochform bitrix-snippet-form">
          <!-- <div #crmForm></div> -->
          <app-getintouch-form></app-getintouch-form>
          <!-- <div [innerHTML]="bitrixSnippetScript"></div>
          <form id="quickcontactForm" [formGroup]="contactmail" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-12">
                <div class="form-floating mb-4">
                  <input type="text" class="form-control" id="floatingInput" formControlName="name"
                    placeholder="Name" [ngClass]="{
                      'is-invalid': submitted && f['name'].errors
                    }" />
                  <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                    <div *ngIf="f['name'].errors['required']"> Name is required </div>
                  </div>
                  <label for="floatingInput" class="px-0">Name <span class="red">*</span></label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating mb-4">
                  <input type="email" class="form-control" id="floatingemail" placeholder="Email"
                    formControlName="email" [ngClass]="{
                      'is-invalid': submitted && f['email'].errors
                    }" />
                  <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                    <div *ngIf="f['email'].errors['required']"> Email is required </div>
                    <div *ngIf="f['email'].errors['email']"> Email must be a valid email address </div>
                  </div>
                  <label for="floatingemail" class="px-0">Email <span class="red">*</span></label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating mb-4">
                  <input type="text" class="form-control" id="floatingWhatsapp" formControlName="whatsapp_telegram"
                    placeholder="Whatsapp"  [ngClass]="{
                      'is-invalid': submitted && f['whatsapp_telegram'].errors
                    }" />
                  <div *ngIf="submitted && f['whatsapp_telegram'].errors" class="invalid-feedback">
                    <div *ngIf="f['whatsapp_telegram'].errors['required']"> Whatsapp/Telegram Id is required </div>
                  </div>
                  <label for="floatingWhatsapp" class="px-0">Whatsapp/Telegram Id</label>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating mb-4">
                  <input type="text" class="form-control" id="floatingCountry" formControlName="country"
                    placeholder="Country"  [ngClass]="{
                      'is-invalid': submitted && f['country'].errors
                    }" />
                  <div *ngIf="submitted && f['country'].errors" class="invalid-feedback">
                    <div *ngIf="f['country'].errors['required']"> Country is required </div>
                  </div>
                  <label for="floatingCountry" class="px-0">Country</label>
                </div>
              </div>
              <div class="col-12">
                <label for="floatingTextarea2" class="px-0">Message <span class="red">*</span></label>
                <div class="form-floating mb-4">
                  <textarea class="form-control" placeholder="Message" id="floatingTextarea2"
                    formControlName="tell_us_about_your_project" [ngClass]="{
                      'is-invalid': submitted && f['tell_us_about_your_project'].errors
                    }"></textarea>
                  <div *ngIf="submitted && f['tell_us_about_your_project'].errors" class="invalid-feedback">
                    <div *ngIf="f['tell_us_about_your_project'].errors['required']"> Message is required </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="position-relative mb-4">
                  <select id="floatingEnquiry" name="enquiry_type" class="form-control enquiry-type" formControlName="enquiry_type"
                  [ngClass]="{
                    'is-invalid': submitted && f['enquiry_type'].errors
                  }" >
                    <option value="">Enquiry Type<span class="red">*</span></option>
                    <option value="Business Enquiry">Business Enquiry</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Career Opportunities">Career Opportunities</option>
                    <option value="Event/Media Enquiry">Event/Media Enquiry</option>
                    <option value="Investment">Investment</option>
                    <option value="I want to sell a service">I want to sell a service</option>
                  </select>
                  <div *ngIf="submitted && f['enquiry_type'].errors" class="invalid-feedback">
                    <div *ngIf="f['enquiry_type'].errors['required']">Please select Enquiry Type</div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button type="submit" class="b24-form-btn">Send</button>
              </div>
            </div>
          </form> -->
        </div>
      </div>
      <div class="col-lg-5 col-md-12 ">
        <!-- <h2>Get In Touch</h2>
        <p>Any question? Reach out to us and we’ll get back to you shortly.</p>
        <div class="contact-main-list">
          <div class="social-links">
            <a href="mailto:contact@blockchainx.tech"  target="_blank"
                rel="noopener noreferrer" class="contact-mail" >
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/contact/webp/mail.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/contact/mail.png"
                />

                <img
                  src="assets-new/images/contact/mail.png"
                  alt="linkedin"
                  width="32"
                  height="32"
                />
              </picture>
            </a>

            <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer" class="whatsapp">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/contact/whatsapp-icon.png"
                />

                <img
                  src="assets-new/images/contact/whatsapp-icon.png"
                  alt="contact-Whatsapp"
                  width="32"
                  height="32"
                />
              </picture>
            </a>

            <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer" class="twitter" >
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/contact/webp/telegram.webp"
                />

                <source
                  type="image/svg"
                  srcset="assets-new/images/contact/telegram.png"
                />

                <img
                  src="assets-new/images/contact/telegram.png"
                  alt="twitter"
                  width="40"
                  height="40"
                />
              </picture>
            </a>
          </div>
        </div>
        <div class="contact-links">
          <h4>Follow us on</h4>
          <div class="social-links">
            <a
              href="https://www.linkedin.com/company/blockchainxtech/"
              target="_blank"
                rel="noopener noreferrer"
              class="linkdin"
            >
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/contact/webp/linkdin.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/contact/linkdin.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/contact/linkdin.png"
                  alt="linkedin"
                  width="22"
                  height="22"
                />
              </picture>
            </a>

            <a
              href="https://www.instagram.com/blockchainxtech/"
              target="_blank"
                rel="noopener noreferrer"
              class="instagram"
            >
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/contact/webp/instagram.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/contact/instagram.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/contact/instagram.png"
                  alt="instagram"
                  width="22"
                  height="22"
                />
              </picture>
            </a>

            <a
              href="https://twitter.com/Blockchainxtech"
              target="_blank"
                rel="noopener noreferrer"
              class="twitter"
            >
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/contact/webp/twitterx.webp"
                />

                <source
                  type="image/svg"
                  srcset="assets-new/images/contact/twitterx.svg"
                />

                <img loading="lazy"
                  data-src="assets-new/images/contact/twitterx.svg"
                  alt="twitter"
                  width="25"
                  height="25"
                />
              </picture>
            </a>
          </div>
        </div> -->
        <!-- Calendly inline widget begin -->
        <div class="calendly-inline-widget" data-url="https://calendly.com/blockchainx-tech">
          <iframe src="https://calendly.com/blockchainx-tech?embed_domain=www.calendly-embed.com&amp;embed_type=Inline" width="100%" height="100%" frameborder="0" title="Select a Date &amp; Time - Calendly"></iframe>
        </div>

        <!-- Calendly inline widget end -->
      </div>
      <div class="row">
        <h3 class="main-sub-heading text-center mt-lg-4 mt-3" >
          Connect with on social media
        </h3>
      <div class="col-lg-12">
        <div class="social-links justify-content-center flex-wrap mt-lg-3 mt-2 mb-lg-2">
          <a href="mailto:contact@blockchainx.tech"  target="_blank"
              rel="noopener noreferrer" class="contact-mail" >
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/contact/webp/mail.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/contact/mail.png"
              />

              <img
                src="assets-new/images/contact/mail.png"
                alt="linkedin"
                width="32"
                height="32"
              />
            </picture>
          </a>

          <a href="https://wa.me/7708889555" target="_blank"
              rel="noopener noreferrer" class="whatsapp">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/contact/whatsapp-icon.png"
              />

              <img
                src="assets-new/images/contact/whatsapp-icon.png"
                alt="contact-Whatsapp"
                width="32"
                height="32"
              />
            </picture>
          </a>

          <a href="https://t.me/sparkouttechblockchainx" target="_blank"
              rel="noopener noreferrer" class="twitter" >
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/contact/webp/telegram.webp"
              />

              <source
                type="image/svg"
                srcset="assets-new/images/contact/telegram.png"
              />

              <img
                src="assets-new/images/contact/telegram.png"
                alt="twitter"
                width="40"
                height="40"
              />
            </picture>
          </a>
          <a
            href="https://www.linkedin.com/company/blockchainxtech/"
            target="_blank"
              rel="noopener noreferrer"
            class="linkdin"
          >
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/contact/webp/linkdin.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/contact/linkdin.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/contact/linkdin.png"
                alt="linkedin"
                width="22"
                height="22"
              />
            </picture>
          </a>

          <a
            href="https://www.instagram.com/blockchainxtech/"
            target="_blank"
              rel="noopener noreferrer"
            class="instagram"
          >
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/contact/webp/instagram.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/contact/instagram.png"
              />

              <img loading="lazy"
                data-src="assets-new/images/contact/instagram.png"
                alt="instagram"
                width="22"
                height="22"
              />
            </picture>
          </a>

          <a
            href="https://twitter.com/Blockchainxtech"
            target="_blank"
              rel="noopener noreferrer"
            class="twitter"
          >
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/contact/webp/twitterx.webp"
              />

              <source
                type="image/svg"
                srcset="assets-new/images/contact/twitterx.svg"
              />

              <img loading="lazy"
                data-src="assets-new/images/contact/twitterx.svg"
                alt="twitter"
                width="25"
                height="25"
              />
            </picture>
          </a>
        </div>
      </div>
    </div>

    </div>
  </div>
</div>
<section class="section-service-main">
  <div class="metaverse-fact-section">
    <div class="violet-vector"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="common-sub-heading sub-title">
            BlockchainX Overview<span class="dots">.</span>
          </div>
          <div class="commontext_wrap">
            <h2 class="text-start pb-3">
              <a href="https://www.sparkouttech.com/" target="_blank">Sparkout
                Tech Solutions Inc</a>
            </h2>
            <a href="https://www.sparkouttech.com/" target="_blank">
              <picture *ngIf="isDarkmode">
                <source
                  type="image/png"
                  srcset="assets-new/images/sparkout-logo.svg"
                />
                <img
                  loading="lazy"
                  src="assets-new/images/sparkout-logo.svg"
                  class="img-fluid"
                  alt="Sparkout logo"
                  width="220"
                  height="51"

                />
              </picture>
              <picture *ngIf="!isDarkmode">
                <source
                  type="image/webp"
                  srcset="assets-new/images/sparkout-inc-logo.webp"
                />
                <source
                  type="image/png"
                  srcset="assets-new/images/sparkout-inc-logo.svg"
                />
                <img
                  loading="lazy"
                  src="assets-new/images/sparkout-inc-logo.svg"
                  class="img-fluid"
                  alt="Sparkout logo"
                  width="220"
                  height="51"
                />
              </picture>
            </a>
          </div>
        </div>
        <!-- <div class="col-lg-8">
            <div class="trust-badge">
                <img loading="lazy" data-src="assets-new/images/home-v2/clutch.png" alt="clutch" class="img-fluid me-2" width="40" height="40">
                <h4>
                    Most trusted Mobile App Consulting Company on Clutch
                  </h4>
            </div>
          </div> -->
      </div>
    </div>
    <div class="container mt-3">
      <div class="row d-flex align-items-center justify-content-center">
        <div class="col-lg-5">
          <div>
            <p class="pb-lg-0 pb-2">
              BlockchainX is a leading Blockchain development company, the
              subsidiary company of Sparkout Tech curating upgrade business
              solutions since 2017. Meeting industry requirements in the global
              market, our advanced tech approaches revolutionize almost every
              sector.
            </p>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="nfts-fact text-center">
                <picture *ngIf="!isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/india.webp" />
                  <source
                    type="image/png"
                    srcset="assets-new/images/home-v2/india.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/india.png"
                    alt="india map"
                    title="india map"
                    class="img-fluid"
                    width="120"
                    height="120" />
                </picture>
                <picture *ngIf="isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/light-theme/india.webp" />
                  <source
                    type="image/png"
                    srcset="assets-new/images/home-v2/light-theme/india.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/light-theme/india.png"
                    alt="india map"
                    title="india map"
                    class="img-fluid"
                    width="120"
                    height="120" />
                </picture>
                <div class="nft-text-wrap">
                  <p>
                    <!-- <span>C.M.Nagar, Ramakrishnapuram,</span> -->
                    <span>No. 3 Gem Colony, </span>
                    <!-- <span>Coimbatore, Tamil Nadu,</span> -->
                    <span>Near Siva Hospital, Ganapathy, </span>
                    <span>Coimbatore, Tamil Nadu, </span>
                    <span>India - 641 006.</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="nfts-fact text-center">
                <picture *ngIf="!isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/asia.webp" />
                  <source
                    type="image/png"
                    srcset="assets-new/images/home-v2/asia.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/asia.png"
                    alt="asia"
                    title="asia map"
                    class="img-fluid"
                    width="120"
                    height="120" />
                </picture>
                <picture *ngIf="isDarkmode">
                  <source
                    type="image/webp"
                    srcset="assets-new/images/home-v2/light-theme/asia.webp" />
                  <source
                    type="image/png"
                    srcset="assets-new/images/home-v2/light-theme/asia.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/home-v2/light-theme/asia.png"
                    alt="asia"
                    title="asia map"
                    class="img-fluid"
                    width="120"
                    height="120" />
                </picture>
                <div class="nft-text-wrap">
                  <p>
                    <span>Branch Office - US</span><br>
                    <span>8 The Green, STE A</span><br>
                    <span>Dover, De, Kent</span><br>
                    <span>United States - 19901.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
