<form id="userForm1" class="getintochform" [formGroup]="contactmail1" (ngSubmit)="onSubmit(1)">
  <div class="form-floating mb-4">
    <input type="text" class="form-control" id="name1" formControlName="name"
      placeholder="First Name" [ngClass]="{
        'is-invalid': submittedForm1 && contactmail1.controls['name'].errors
      }" />
    <div *ngIf="submittedForm1 && contactmail1.controls['name'].errors" class="invalid-feedback">
      <div *ngIf="contactmail1.controls['name'].errors['required']"> Name is required </div>
    </div>
    <label for="name1" class="px-0">Name <span class="red">*</span></label>
  </div>
  <div class="form-floating mb-4">
    <input type="email" class="form-control" id="email1" placeholder="Email"
      formControlName="email" [ngClass]="{
        'is-invalid': submittedForm1 && contactmail1.controls['email'].errors
      }" />
    <div *ngIf="submittedForm1 && contactmail1.controls['email'].errors" class="invalid-feedback">
      <div *ngIf="contactmail1.controls['email'].errors['required']"> Email is required </div>
      <div *ngIf="contactmail1.controls['email'].errors['email']"> Email must be a valid email address </div>
    </div>
    <label for="email1" class="px-0">E-mail <span class="red">*</span></label>
  </div>
  <div class="form-floating mb-5">
    <input type="text" class="form-control" placeholder="Project" id="description1"
      formControlName="tell_us_about_your_project" [ngClass]="{
        'is-invalid': submittedForm1 && contactmail1.controls['tell_us_about_your_project'].errors
      }">
    <div *ngIf="submittedForm1 && contactmail1.controls['tell_us_about_your_project'].errors" class="invalid-feedback">
      <div *ngIf="contactmail1.controls['tell_us_about_your_project'].errors['required']"> Project is required </div>
    </div>
    <label for="description1" class="px-0">Tell us about your project <span class="red">*</span></label>
  </div>
  <button type="submit" class="b24-form-btn">Send</button>
</form>
