<section class="blog-main">
    <div class="green-vector"></div>
    <div class="Slider-Blog">
      <div class="orange-vector"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-12 col-12 mb-sm-4 mb-1">
            <div class="row">
                <div class="mx-auto">
                  <div class="commontext_subhead pb-0">
                    <h3>News<span class="dots">.</span></h3>
                  </div>
                  <div class="feature_wrap pb-sm-4 pb-3">
                    <div class="commontext_wrap">
                      <h2 class="text-start">Our Latest Compilation On The Market</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <ng-container
                  *ngFor="let data of blogDetails; let i = index"
                >
                  <div class="col-lg-12 col-md-6 col-sm-6 col-12 mb-3">
                    <div class="blog_wrap">
                      <a href="{{ data.link.href }}" class="news-wrap">
                        <img
                          src="{{ data.imgSrc }}"
                          alt="{{ data.imgAlt }}"
                          class="img-fluid list-blog-picture"
                          width="398"
                          height="215"
                        />
                        <div class="news-content">
                        <h3 class="pb-0">{{ data.title }}</h3>
                        <p>
                          {{ data.description }}
                        </p>
          
                        <div class="content-link">
                          <span>Read More</span>
                          <img loading="lazy"
                        src="assets-new/images/right-arrow.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="!isDarkmode" />
                      <img loading="lazy"
                        src="assets-new/images/right-arrow-dark.png"
                        alt="link-arrow"
                        class="img-fluid ms-2"
                        width="50"
                        height="15"
                        *ngIf="isDarkmode" />
          
                          <!-- <hr /> -->
                        </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- <div class="row blogs-list">
                <div class="col-lg-3 col-sm-5 col-8 mx-auto text-center">
                  <a class="blog_submit cursor-pointer" (click)="showMore()"
                    >Show more</a
                  >
                </div>
              </div> -->
          </div>
          <div class="col-lg-3 col-md-12 col-12">
            <app-newsletter-form></app-newsletter-form>
          </div>
        </div>
      </div>
    </div>
  </section>

<!-- <section class="section-service-alt-padd">
  <app-contactform></app-contactform>
</section> -->
<section class="section-service-alt-padd">
  <app-get-in-touch></app-get-in-touch>
</section>