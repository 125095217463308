import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from 'src/app/shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-peaq-blockchain-development',
  templateUrl: './peaq-blockchain-development.component.html',
  styleUrls: ['./peaq-blockchain-development.component.css']
})
export class PeaqBlockchainDevelopmentComponent implements OnInit {

  selectedLink:number;
  title = 'PEAQ Blockchain Development Company | BlockchainX';
  about: any;
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Wondering which robust blockchain supports DePINs accommodating real-world use cases? Bringing PEAQ blockchain development for you. Tap to get its nuances!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'PEAQ blockchain development, PEAQ blockchain development company, PEAQ blockchain development services, PEAQ blockchain development solutions, PEAQ blockchain development platform , PEAQ blockchain developer, Hire a PEAQ blockchain developer',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'PEAQ Blockchain Development Company | BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/peaq-blockchain-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Wondering which robust blockchain supports DePINs accommodating real-world use cases? Bringing PEAQ blockchain development for you. Tap to get its nuances!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/peaq-blockchain-development/peaq-blockchain-development-service.webp',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/peaq-blockchain-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'PEAQ Blockchain Development Company | BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Wondering which robust blockchain supports DePINs accommodating real-world use cases? Bringing PEAQ blockchain development for you. Tap to get its nuances!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/peaq-blockchain-development/peaq-blockchain-development-service.webp',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/peaq-blockchain-development/'
    );
  }

  fieldOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    slideBy: 1,
    dots: false,
    // autoHeight: true,
    margin: 20,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 2,
      },

      1024: {
        items: 3,
      },

      1366: {
        items: 3,
      },
    },
    nav: false,
  };

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    navSpeed: 500,
    // margin: 30,
    dots: false,
    center: true,
    navText: [],
    responsive: {
      0: {
        items: 1.5,
      },
      640: {
      items: 2,
      },
      740: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    nav: false,
  };
  addClass(id:number){
    this.selectedLink=id;
  }
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  }

}
