<!-- Banner Section -->
<div class="banners-section">
  
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span> <strong> Security Token Offering </strong>Services</span>
              </h1>
              <p>
                One-stop solution for developing and hosting your STO.
                BlockchainX as a Security token development company extends our
                full-fledged services, efficient, effective, and sure a supreme
                solution helping you scale up.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Arrow" title="Arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Arrow" title="Arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a href="https://www.blockchainx.tech/contact/">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Arrow" title="Arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Arrow" title="Arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/security-token-offering.webp">
              <source type="image/png"
                srcset="assets-new/images/erc/security-token-offering.png">
              <img loading="lazy"
                data-src="assets-new/images/erc/security-token-offering.png"
                width="450" height="450"
                alt="Security token offering development"
                title="Security token offering development" class="img-fluid">
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/light-theme/security-token-offering.webp">
              <source type="image/png"
                srcset="assets-new/images/erc/light-theme/security-token-offering.png">
              <img loading="lazy"
                data-src="assets-new/images/erc/light-theme/security-token-offering.png"
                width="450" height="450"
                alt="Security token offering development"
                title="Security token offering development" class="img-fluid">
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="Scroll"
              title="Scroll" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Banner Section ends -->

<!-- STO devlopment company -->
<section class="section-service-alt" #about>
  <div class="container">
    <div class="row align-items-center sto-service-develop">
      <div
        class="col-lg-5 col-md-12 col-sm-12 col-12 text-center about-content">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/erc/sto-development-company.webp">
          <source type="image/png"
            srcset="assets-new/images/erc/sto-development-company.png">
          <img loading="lazy"
            data-src="assets-new/images/erc/sto-development-company.png"
            width="350" height="280" alt="Sto Development Company"
            title="Sto Development Company" class="img-fluid">
        </picture>

      </div>
      <div class="col-lg-7 col-md-12 col-sm-12 col-12 about-content-sto">
        <h3 class="main-sub-heading">STO Development Company</h3>
        <p>
          BlockchainX helps crypto businesses grow through our services. STO
          development coming under the roof, we ensure to meet the market
          standards of the digital fundraising mechanism. From STO consulting we
          take you forward in the process with our development expertise.
          Connect with us now.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- STO devlopment company -->

<!-- Development Services starts -->
<div class="section-service-alt-main">
  <div class="our-process-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading">
            <h3>Wide Range of <span> STO development services</span></h3>
          </div>
        </div>
      </div>

      <div class="row erc-processing">
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Smart Contract Development</h4>
            <p>
              For your STO token, we help you integrate a smart contract binding
              its uniqueness, authority, and ownership.
            </p>
          </div>
        </div>

        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Debt STO Development</h4>
            <p>
              Debt tokens represent the debt instruments like real estate
              mortgages of the business.
            </p>
          </div>
        </div>

        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Equity STO Development</h4>
            <p>
              Equity tokens are more like traditional stocks responsible for
              resources in businesses.
            </p>
          </div>
        </div>

        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>STO White Paper Creation</h4>
            <p>
              We help you plan and process your project and also render our
              strategic inputs in STO whitepaper creation.
            </p>
          </div>
        </div>

        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Customizable STO Dashboardst</h4>
            <p>
              To manage, monitor, and maintain your crypto project and STO token
              tractions, we customize your dashboard.
            </p>
          </div>
        </div>

        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>STO Website Development</h4>
            <p>
              We help you design and develop an STO website for your Crypto
              projects, that's unique, creative, and traffic-pulling.
            </p>
          </div>
        </div>

        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>KYC/AML Registration</h4>
            <p>
              We ensure to build a two-step verification security system and
              only authorized users can enter in.
            </p>
          </div>
        </div>

        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Digital STO Launch</h4>
            <p>
              Since digitalization is at its peak and evolving from all
              perspectives, the Digital STO launch is inclusive.
            </p>
          </div>
        </div>

        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Reserve Assets Token</h4>
            <p>
              Real-world assets like gold, oil, real estate, etc are reserved
              assets and companies can tokenize these assets in STO backing
              their solidity.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Development Services ends -->

<!-- STO Launch Methodology starts -->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h3>Extensive <span>STO Development Service</span></h3>
        </div>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/sto-launch-methodology/groundwork.webp">
            <source type="image/png"
              srcset="assets-new/images/erc/sto-launch-methodology/groundwork.png">
            <img loading="lazy"
              data-src="assets-new/images/erc/sto-launch-methodology/groundwork.png"
              width="100" height="100" alt="The Groundwork"
              title="The Groundwork" class="img-fluid">
          </picture>
          <h4 class="erc-20-head">The Groundwork</h4>
          <p>
            With our prior knowledge in STO, we help you plan and execute your
            token better than streets high in the market.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/sto-launch-methodology/pre-STO-preparation.webp">
            <source type="image/png"
              srcset="assets-new/images/erc/sto-launch-methodology/pre-STO-preparation.png">
            <img loading="lazy"
              data-src="assets-new/images/erc/sto-launch-methodology/pre-STO-preparation.png"
              width="100" height="100" alt="Pre STO Preparation"
              title="Pre STO Preparation" class="img-fluid">
          </picture>
          <h4 class="erc-20-head">Pre STO Preparation</h4>
          <p>
            White Paper idea, STO marketing, choice of BlockchainX, landing page
            set up, and what else? You name it, we help you with it.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/sto-launch-methodology/approaching-of-accredited-investors.webp">
            <source type="image/png"
              srcset="assets-new/images/erc/sto-launch-methodology/approaching-of-accredited-investors.png">
            <img loading="lazy"
              data-src="assets-new/images/erc/sto-launch-methodology/approaching-of-accredited-investors.png"
              width="100" height="100" alt="Accredited Investors"
              title="Accredited Investors" class="img-fluid">
          </picture>
          <h4 class="erc-20-head">Approaching Investors</h4>
          <p>
            Before approaching them, we could help you make them aware of your
            STO. How? Hire our STO services now!
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/sto-launch-methodology/post-STO-activities.webp">
            <source type="image/png"
              srcset="assets-new/images/erc/sto-launch-methodology/post-STO-activities.png">
            <img loading="lazy"
              data-src="assets-new/images/erc/sto-launch-methodology/post-STO-activities.png"
              width="100" height="100" alt="Post STO Activities"
              title="Post STO Activities" class="img-fluid">
          </picture>
          <h4 class="erc-20-head">Post STO Activities</h4>
          <p>
            Done with the launch, and it's time to… Detox! We help you meet the
            pressure in the market, and glow in and out!
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- STO Launch Methodology ends -->

<!-- Product Services -->
<section>
  <div class="section-service-alt-main">
    <div class="product-services">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 nft-service-list">
            <div class="casetudies_common">
              <h3 class="main-sub-heading">Key Features of STO Development</h3>
            </div>
          </div>
          <div class="col-lg-7 scrolling-section" id="meta-case-study">
            <div class="row mx-0">
              <div class="col-lg-12">
                <div class="casestudy_wrap">
                  <div class="casestudy_head">
                    <h3>
                      <a>KYC automation/Accredited investor verification</a>
                    </h3>
                  </div>
                  <div class="casestudy_para">
                    <p>
                      To be secure and maintain privacy in the platform, our KYC
                      automation seals it well. Through technical authorizations
                      and high-end security system integrations, we help
                      businesses ensure their trust and reliability in the
                      market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-lg-12">
                <hr class="case_studies_hr" />
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-lg-12">
                <div class="casestudy_wrap">
                  <div class="casestudy_head">
                    <h3><a>Interoperable layers</a></h3>
                  </div>
                  <div class="casestudy_para">
                    <p>
                      With multifunctional capabilities, our STO platform
                      development service binds the layers of the network and
                      gives a clear vision of the operations with immediate
                      effects on the happenings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-lg-12">
                <hr class="case_studies_hr" />
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-lg-12">
                <div class="casestudy_wrap">
                  <div class="casestudy_head">
                    <h3><a>Multi-ledger security token development</a></h3>
                  </div>
                  <div class="casestudy_para">
                    <p>
                      Secured through blockchain, the security token development
                      is guarded, guided, and guaranteed to sustain in the
                      market, contributing to better growth in the market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-lg-12">
                <hr class="case_studies_hr" />
              </div>
            </div>
            <!--start-->
            <div class="row mx-0">
              <div class="col-lg-12">
                <div class="casestudy_wrap">
                  <div class="casestudy_head">
                    <h3>
                      <a>The facility of non-fungible security tokens</a>
                    </h3>
                  </div>
                  <div class="casestudy_para">
                    <p>
                      Carrying out the uniqueness of Non-fungible tokens to the
                      tokenomics of security token offerings, the platform adds
                      value and breeds its importance in the market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-lg-12">
                <hr class="case_studies_hr" />
              </div>
            </div>
            <!--end-->
            <!--start-->
            <div class="row mx-0">
              <div class="col-lg-12">
                <div class="casestudy_wrap">
                  <div class="casestudy_head">
                    <h3>
                      <a>Automated reporting to the concerned regulatory
                        body</a>
                    </h3>
                  </div>
                  <div class="casestudy_para">
                    <p>
                      Through the automated reporting feature, the information
                      and data are directly transcended to the respected
                      authority. Help them save and secure their needs in terms
                      of malfunctioning or vulnerabilities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-lg-12">
                <hr class="case_studies_hr" />
              </div>
            </div>
            <!--end-->
            <!--start-->
            <div class="row mx-0">
              <div class="col-lg-12">
                <div class="casestudy_wrap">
                  <div class="casestudy_head">
                    <h3>
                      <a>High-end security against money laundering
                        activities</a>
                    </h3>
                  </div>
                  <div class="casestudy_para">
                    <p>
                      We ensure to fetch the high-end security system into our
                      process, securing and confining the functionality of the
                      token and also protecting it from other fraudulent
                      happenings by the third party.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-lg-12">
                <hr class="case_studies_hr" />
              </div>
            </div>
            <!--end-->
            <!--start-->
            <div class="row mx-0">
              <div class="col-lg-12">
                <div class="casestudy_wrap">
                  <div class="casestudy_head">
                    <h3><a>Full customization for your industry</a></h3>
                  </div>
                  <div class="casestudy_para">
                    <p>
                      We customize the entire solution based on the market
                      requirements and your requirements. Helping you contribute
                      better to the market and breed your importance more in the
                      economy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-lg-12">
                <hr class="case_studies_hr" />
              </div>
            </div>
            <!--end-->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Services -->

<!-- Product Features -->
<div class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Security Token Offering </span>Development Process</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/our-execution-process/discovery-workshop.webp">
              <source type="image/png"
                srcset="assets-new/images/erc/our-execution-process/discovery-workshop.png">
              <img loading="lazy"
                data-src="assets-new/images/erc/our-execution-process/discovery-workshop.png"
                width="75" height="75" alt="Discovery Workshop"
                title="Discovery Workshop" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Discovery Workshop</h4>
          <p>
            Basic gathering of information, jotting requirements, and analyzing
            the time and professionals involved are made clear from both ends.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/our-execution-process/strategy-and-solution-design.webp">
              <source type="image/png"
                srcset="assets-new/images/erc/our-execution-process/strategy-and-solution-design.png">
              <img loading="lazy"
                data-src="assets-new/images/erc/our-execution-process/strategy-and-solution-design.png"
                width="75" height="75" alt="Strategy and Solution"
                title="Strategy and Solution" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Strategy and Solution Design
          </h4>
          <p>
            Rendering a test phase to check for requirements, a review process
            to analyze if the requirements are met and additionalities are
            addressed and planned.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/our-execution-process/development.webp">
              <source type="image/png"
                srcset="assets-new/images/erc/our-execution-process/development.png">
              <img loading="lazy"
                data-src="assets-new/images/erc/our-execution-process/development.png"
                width="75" height="75" alt="Development" title="Development"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Development</h4>
          <p>
            Tech-savvy professionals at BlockchainX involve themself in the STO
            development process. Coding and iterating smart contracts are done
            here.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/our-execution-process/testing-and-QA.webp">
              <source type="image/png"
                srcset="assets-new/images/erc/our-execution-process/testing-and-QA.png">
              <img loading="lazy"
                data-src="assets-new/images/erc/our-execution-process/testing-and-QA.png"
                width="75" height="75" alt="Testing and QA"
                title="Testing and QA" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Testing and QA</h4>
          <p>
            Sending the codes and structure for testing, and ensuring the
            contracts are all validated. The QA team goes through and generates
            a report for better usage.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/our-execution-process/launch-and-maintenance.webp">
              <source type="image/png"
                srcset="assets-new/images/erc/our-execution-process/launch-and-maintenance.png">
              <img loading="lazy"
                data-src="assets-new/images/erc/our-execution-process/launch-and-maintenance.png"
                width="75" height="75" alt="Launch and Maintenance"
                title="Launch and Maintenance" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Launch and Maintenance</h4>
          <p>
            Once the STO development is done and after the final approval, your
            STO token is all set to launch, and we also help with its
            maintenance.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/our-execution-process/support-and-customer-service.webp">
              <source type="image/png"
                srcset="assets-new/images/erc/our-execution-process/support-and-customer-service.png">
              <img loading="lazy"
                data-src="assets-new/images/erc/our-execution-process/support-and-customer-service.png"
                width="75" height="75" alt="Support and Customer"
                title="Support and Customer" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Support and Customer Service
          </h4>
          <p>
            In the future, we also render added support in terms of updating and
            grading up the STO that we developed for you.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Product Features -->

<!-- Tech Stak Update -->
<section class="section-service-main">
  <div class="container">
    <div class="metaverse_heading mx-auto">
      <h3>Tech Stacks Involved In<span> STO Development Process </span></h3>
    </div>
    <div class="business-stak-list-section">
      <div class="row business-tech-stak">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="list-one">

            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/frameworks-line-dark.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/frameworks-line-dark.png"
                alt="Frameworks" title="Frameworks"
                class="img-fluid mx-auto"
                width="278" height="35" *ngIf="!isDarkmode"/>
            </picture>
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/frameworks-lite.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/frameworks-lite.png"
                alt="Frameworks" title="Frameworks"
                class="img-fluid mx-auto"
                width="278" height="35" *ngIf="isDarkmode"/>
            </picture>
      </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="list-one">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-caliper.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-caliper.png"
                alt="caliber" title="caliber" class="img-fluid mx-auto"
                width="185" height="54"  *ngIf="!isDarkmode"/>
            </picture>
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-caliper-lite.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-caliper-lite.png"
                alt="caliber" title="caliber"
                class="img-fluid mx-auto"
                width="185" height="54" *ngIf="isDarkmode"/>
            </picture>
      </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="list-one">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledge-composer-dark.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledge-composer-dark.png"
                alt="Hyperledger Composer" title="Hyperledger Composer"
                class="img-fluid mx-auto"
                width="234" height="50" *ngIf="!isDarkmode" />
            </picture>
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-composer-lite.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-composer-lite.png"
                alt="Hyperledger Composer" title="Hyperledger Composer"
                class="img-fluid mx-auto"
                width="234" height="50" *ngIf="isDarkmode"/>
            </picture>
      </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="list-one">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledge-explorer-dark.webp">
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledge-explorer-dark.png"
                width="217" height="43" alt="Hyperledger explorer" title="Hyperledger explorer"
                class="img-fluid mx-auto" *ngIf="!isDarkmode">
            </picture>
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-explorer-lite.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-explorer-lite.png"
                alt="Hyperledger explorer" title="Hyperledger explorer"
                class="img-fluid mx-auto"
                width="217" height="43" *ngIf="isDarkmode"/>
            </picture>
          </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="list-one">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-main-dark.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-main-dark.png"
                alt="hyperledger" title="hyperledger"
                class="img-fluid mx-auto"
                width="253" height="49" *ngIf="!isDarkmode"/>
            </picture>
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-lite.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-lite.png"
                alt="hyperledger" title="hyperledger"
                class="img-fluid mx-auto"
                width="253" height="49" *ngIf="isDarkmode"/>
            </picture>
      </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="list-one">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-sawtooth.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-sawtooth.png"
                alt="sawtooth" title="sawtooth" class="img-fluid mx-auto"
                width="231" height="50" *ngIf="!isDarkmode"/>
            </picture>
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-sawtooth-lite.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-sawtooth-lite.png"
                alt="Sawtooth" title="Sawtooth"
                class="img-fluid mx-auto"
                width="231" height="50" *ngIf="isDarkmode"/>
            </picture>
      </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="list-one">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-iroha.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-iroha.png" alt="iroha"
                title="iroha" class="img-fluid mx-auto"
                width="175" height="48" *ngIf="!isDarkmode" />
            </picture>
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-iroha-lite.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-iroha-lite.png"
                alt="Iroha" title="Iroha"
                class="img-fluid mx-auto"
                width="175" height="48" *ngIf="isDarkmode"/>
            </picture>
      </div>
        </div>

        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="list-one">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-fabric-dark.webp">
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-fabric-dark.png" width="174"
                height="49" alt="Fabric" title="Fabric"
                class="img-fluid mx-auto" *ngIf="!isDarkmode">
            </picture>
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/home/stak/hyperledger-iroha-lite.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/stak/hyperledger-iroha-lite.png"
                alt="Iroha" title="Iroha"
                class="img-fluid mx-auto"
                width="175" height="48" *ngIf="isDarkmode"/>
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Tech Stak Update -->

<section class="why_choose_us section-service-alt">
  <div class="why-alt-section">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container choosing-section">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-2">
            <h2>Connect With BlockchainX</h2>
          </div>
          <div class="whysection_text_wrap">
            <p>
              As experienced professionals and team, our services have been
              astounding and made a real-time impact on the user's and the
              client's perspective. BlockchanX shall ensure to serve the
              best-decentralized solutions that are effective, efficient, and
              have a premium output that is worth your time and investment. Our
              STO development services are customized to your needs. Contact us
              to know better and avail of our services.
            </p>
            <div class="content-link my-3">
              <a href="https://www.blockchainx.tech/contact/">
                <span>Read More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Arrow" title="Arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Arrow" title="Arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/metaverse/why_blockchain.webp" />

              <img loading="lazy"
                data-src="assets-new/images/metaverse/why_blockchain.png"
                width="550"
                height="450"
                class="img-fluid"
                alt="Why Blockchainx"
                title="Why Blockchainx" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main">
  <app-blog></app-blog>
</section>
<!-- <section class="section-service-alt-padd">
  <app-contactform></app-contactform>
</section> -->
<section class="section-service-alt">
  <app-get-in-touch></app-get-in-touch>
</section>
