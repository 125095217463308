import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-solana-development',
  templateUrl: './solana-development.component.html',
  styleUrls: ['./solana-development.component.css'],
})
export class SolanaDevelopmentComponent implements OnInit {
  title = 'Hire Solana Blockchain Development Services From BlockchainX';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  
  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'We help you develop and launch exclusive business class solutions deployed on the Solana Network. BlockchainX with our extended Blockchain development services, we are also capable of rendering and satisfying your requirement in your way. And Solana Blockchain development services are very much customized here.  ',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Hire Solana Blockchain Developers, Hire expert programmer and solana developer from India',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Hire Solana Blockchain Development Services From BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://blockchainx.tech/hire-solana-developers/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'We help you develop and launch exclusive business class solutions deployed on the Solana Network. BlockchainX with our extended Blockchain development services, we are also capable of rendering and satisfying your requirement in your way. And Solana Blockchain development services are very much customized here.  ',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/solana-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://blockchainx.tech/hire-solana-developers/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Hire Solana Blockchain Development Services From BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'We help you develop and launch exclusive business class solutions deployed on the Solana Network. BlockchainX with our extended Blockchain development services, we are also capable of rendering and satisfying your requirement in your way. And Solana Blockchain development services are very much customized here.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/solana-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://blockchainx.tech/hire-solana-developers/'
    );
  }
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    navSpeed: 500,
    // margin: 30,
    dots: false,
    center: true,
    navText: [],
    responsive: {
      0: {
        items: 1.5,
      },
      640: {
      items: 2,
      },
      740: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    nav: false,
  };
}
