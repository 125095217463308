import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute,Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-create-centralized-crypto-exchange',
  templateUrl: './create-centralized-crypto-exchange.component.html',
  styleUrls: ['./create-centralized-crypto-exchange.component.css']
})
export class CreateCentralizedCryptoExchangeComponent implements OnInit {

  selectedLink:number;
  title ='9 Simple Steps to Create Centralized Crypto Exchange';

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  isDarkmode:boolean=false;

  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'Learn the 9 effective steps to create centralized crypto exchange from scratch. To find more about the development steps and insights—explore our website.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Create Centralized Crypto Exchange,Create Centralized cryptocurrency exchange,Build a Centralized Crypto Exchange,Start a Centralized Crypto Exchange,Build a Centralized Crypto Exchange Platform,How to Build Crypto Centralized Exchange,Develop Centralized Crypto Exchange',
    });


    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        '9 Simple Steps to Create Centralized Crypto Exchange',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/create-centralized-crypto-exchange/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Learn the 9 effective steps to create centralized crypto exchange from scratch. To find more about the development steps and insights—explore our website.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/create-centralized-crypto-exchange/create-centralized-crypto-exchange.webp',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/create-centralized-crypto-exchange/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        '9 Simple Steps to Create Centralized Crypto Exchange',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Learn the 9 effective steps to create centralized crypto exchange from scratch. To find more about the development steps and insights—explore our website.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/create-centralized-crypto-exchange/create-centralized-crypto-exchange.webp',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/create-centralized-crypto-exchange/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/create-centralized-crypto-exchange.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }

}
