import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-nft-staking-platform-demo',
  templateUrl: './nft-staking-platform-demo.component.html',
  styleUrls: ['./nft-staking-platform-demo.component.css']
})
export class NftStakingPlatformDemoComponent implements OnInit {
  title = 'NFT Staking Development Company';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'By developing an NFT Staking Platform you can be the bridge connecting creators and investors providing widened opportunities. Skim to know how we develop.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Nft staking development services, Nft staking development company, Nft staking platform development, Nft staking platform',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'NFT Staking Development Company',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/nft-staking-platform-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'By developing an NFT Staking Platform you can be the bridge connecting creators and investors providing widened opportunities. Skim to know how we develop.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/nft-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/nft-staking-platform-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'NFT Staking Development Company',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'By developing an NFT Staking Platform you can be the bridge connecting creators and investors providing widened opportunities. Skim to know how we develop.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/nft-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/nft-staking-platform-development/'
    );
  }
marketplaceOptions: OwlOptions = {
  loop: true,
  autoplay: true,
  slideBy: 1,
  dots: false,
  autoHeight: true,
  margin: 25,
  navText: [],
  responsive: {
    0: {
      items: 1,
    },

    767: {
      items: 1,
    },

    1024: {
      items: 2,
    },

    1366: {
      items: 2,
    },
  },
  nav: false,
};
customOptions: OwlOptions = {
  loop: true,
  autoplay: true,
  slideBy: 1,
  navSpeed: 500,
  // margin: 30,
  dots: false,
  center: true,
  navText: [],
  responsive: {
    0: {
      items: 1.5,
    },
    640: {
    items: 2,
    },
    740: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  },
  nav: false,
};
}
