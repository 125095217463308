import { Component, Inject, OnInit } from '@angular/core';
import {
  Title,
  Meta,
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-solana-blockchain-development',
  templateUrl: './solana-blockchain-development.component.html',
  styleUrls: ['./solana-blockchain-development.component.css']
})
export class SolanaBlockchainDevelopmentComponent implements OnInit {


  title = 'Solana Blockchain Development Solutions';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService
  ) {
    }


  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'With its extended features, the Solana network contributes towards safety, security and speed. And if that is something you are looking for in your ecosystem, tap to learn more on Solana Blockchain Development solutions.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Solana Blockchain Development Company, Solana Blockchain Development services, Solana Blockchain Development, Solana Blockchain Development solution',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Solana Blockchain Development Solutions',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://blockchainx.tech/solana-blockchain-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'With its extended features, the Solana network contributes towards safety, security and speed. And if that is something you are looking for in your ecosystem, tap to learn more on Solana Blockchain Development solutions.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/solana-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://blockchainx.tech/solana-blockchain-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Solana Blockchain Development Solutions',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'With its extended features, the Solana network contributes towards safety, security and speed. And if that is something you are looking for in your ecosystem, tap to learn more on Solana Blockchain Development solutions.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/solana-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://blockchainx.tech/solana-blockchain-development/'
    );
  }
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    navSpeed: 500,
    // margin: 30,
    dots: false,
    center: true,
    navText: [],
    responsive: {
      0: {
        items: 1.5,
      },
      640: {
      items: 2,
      },
      740: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    nav: false,
  };
}
