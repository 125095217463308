<section class="blog-detail">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 mb-4">
                <div class="blog-single-wrap">
                    <picture>
                        <source
                          srcset="
                          assets-new/images/blog/real-world-asset-tokenization/banner.webp
                          "
                          type="image/webp"
                        />
                        <img
                          loading="lazy"
                          data-src="assets-new/images/blog/real-world-asset-tokenization/banner.jpg"
                          alt="Real World Asset Tokenization"
                          title="Real World Asset Tokenization: Complete Guide"
                          width="840"
                          height="511"
                          class="img-fluid"
                        />
                      </picture>
                    <h1> Real World Asset Tokenization: Complete Guide</h1>

                    <!-- <h2 id="item-9"
                        [ngClass]="selectedLink===9?'scrollHeading':'scrollheadingone'">Introduction -  What is RWA Tokenization?</h2> -->
                    <p>
                      Real World Asset Tokenization is a revolutionary process that involves converting tangible assets like real estate, art, and others with physician existence into digital tokens. Depending on the Blockchain network and being created on them, they represent token ownerships, fractional asset sharing, improved traceability, and transparent secured transactions. The most significant part of tokenization Real World assets is its ability to democratize illiquid assets in the modern era overcoming the traditional customs.
                    </p>
                    <p>
                      With the ability to break down assets into small and tradeable tokens, the avenue for investment opportunities using <a href="https://www.blockchainx.tech/real-world-asset-tokenization/" target="blank">real world asset tokenization services</a> gets wider and broader for individuals in all sectors. Challenging the conventional model of token liquidity, Tokenizing Real World assets in the Web3 market has a profound impact, therefore increasing efficiency, inclusivity, etc.
                    </p>

                    <h2 id="item-1"
                        [ngClass]="selectedLink===1?'scrollHeading':'scrollheadingone'">How RWA Tokenization Works?</h2>
                    <div class="d-flex justify-content-center mb-3">
                    <video
                        poster="assets-new/images/real-world-asset-tokenization/rwa-platform-img.webp"
                        src="assets-new/images/real-world-asset-tokenization/videos/rwa-platform.mp4"
                        controls
                        [autoplay]="false"
                        width="70%"
                        preload="auto"
                         >
                        <track kind="captions"
                          src="assets-new/images/real-world-asset-tokenization/videos/rwa-platform.mp4"
                          srclang="en" label="English">
                    </video>
                    </div>
                    
                    <p>
                      <a href="https://www.youtube.com/watch?v=qu8JqZ1Vw8s&t=98s" target="_blank">Real-world Tokenization</a> working is similar to that of any other token on the market. But the only difference is RWA Tokenization is backed by a real asset in the real world, who have a physical existence while their digital representation is the RWA token. 
                    </p>
                    <p>
                      For instance, let us take your ancestral property which is traditional, built using classical engineering, and has a great deal and demand in the current market. You want to monetize it while you are also unsure if over accessibility on the real place would damage it.
                    </p>
                    <p>
                      In this case, through tokenization, you can tokenize the complete property in the blockchain of your choice. Create a virtual reality experience on blockchain that can also be monetized by implementing one such functionality. 
                    </p>
                    <p>
                      The tokenized property can now be purchased as tokens either as a whole single entity or fractionalized into smaller investment instruments that would encourage people to invest, without the burden or the thought of making huge investments. This also encourages investors globally and from different investment categories.
                    </p>

                    <!-- <p class="pb-2">
                        <i class="bi bi-check-lg"></i> Residential Properties 
                    </p>
                      <p class="pb-2">
                        <i class="bi bi-check-lg"></i> Commercial Properties 
                    </p>
                    <p class="pb-2">
                        <i class="bi bi-check-lg"></i> Landscapes
                    </p>
                    <p>
                        <i class="bi bi-check-lg"></i> Monuments and historical places 
                    </p> -->

                    <h2 id="item-2"
                    [ngClass]="selectedLink===2?'scrollHeading':'scrollheadingone'">What Assets in the Real World Are Tokenized?</h2>     
                    <picture>
                      <source
                        srcset="assets-new/images/blog/real-world-asset-tokenization/assest-in-real-world-tokenized.webp"
                        type="image/webp"
                      />
                      <img
                        loading="lazy"
                        data-src="assets-new/images/blog/real-world-asset-tokenization/assest-in-real-world-tokenized.jpg"
                        alt="Types of rwa tokenization"
                        title="Types of rwa tokenization"
                        width="840"
                        height="300"
                        class="img-fluid"
                      />
                    </picture>           

                    <h5 class="h4">Real Estate Tokenization</h5>
                    <p>
                      When it comes to tokenizing real world assets, real estate is a prominent application with a definite financial model that involves ownership creation, fragmental ownership, digital token creation, and securing its value on <a href="https://www.blockchainx.tech/real-estate-tokenization/" target="_blank">real estate tokenization development</a>. 
                    </p>
                    <p class="pb-2">
                        <i class="bi bi-check-lg"></i> Residential Properties
                    </p>
                      <p class="pb-2">
                        <i class="bi bi-check-lg"></i> Commercial Properties 
                    </p>
                    <p class="pb-2">
                        <i class="bi bi-check-lg"></i>  Landscapes
                    </p>
                    <p class="pb-2">
                        <i class="bi bi-check-lg"></i> Monuments and historical places
                    </p>
                    <p>
                      <i class="bi bi-check-lg"></i> Art Collectible Tokenization
                    </p>

                    <p>
                      Art and related creatives are potential assets that can be tokenized. Initially, this was the idea of NFTs while narrowing the concept. Different forms of artwork, such as individual collections or a set of collectibles curated around a theme, can be digitally tokenized. This concept already has a wider range of audiences.   
                    </p>
                    <p class="pb-2">
                        <i class="bi bi-check-lg"></i> Tokenizing famous artworks
                    </p>
                    <p class="pb-2">
                        <i class="bi bi-check-lg"></i> Digital artworks 
                    </p>
                    <p class="pb-2">
                      <i class="bi bi-check-lg"></i> Sculptures 
                    </p>
                    <p class="pb-2">
                      <i class="bi bi-check-lg"></i> Designs, patterns, and dimensions 
                    </p>
                    <p class="pb-2">
                      <i class="bi bi-check-lg"></i> Paintings 
                    </p>
                    <p>
                      <i class="bi bi-check-lg"></i> Culture depicting creatives 
                    </p>

                    <h5 class="h4">Intellectual Property Tokenization</h5>
                    <p class="pb-2">
                      This is a concept that is above physical existence. An intellectual property out of one's own creative space with the actual application and use care can be digitized.
                    </p>
                    <p class="pb-2">
                      <i class="bi bi-check-lg"></i> Patent
                    </p>
                    <p class="pb-2">
                      <i class="bi bi-check-lg"></i> Trademarks 
                    </p>
                    <p class="pb-2">
                      <i class="bi bi-check-lg"></i> Copyrights 
                    </p>
                    <p>
                      <i class="bi bi-check-lg"></i> Licenses 
                    </p>

                  <h5 class="h4">Investment Fund Tokens</h5>
                    <p>
                      The classic investment methodologies, such as investment funds, mutual funds, and schemes, can now be tokenized digitally. They are making the fund management process simple, easy, and more accessible.
                    </p>
                    <p class="pb-2">
                      <i class="bi bi-check-lg"></i> Traditional shares
                    </p>
                    <p >
                      <i class="bi bi-check-lg"></i> Investment fund schemes 
                    </p>                 

                    <h2 id="item-3"
                        [ngClass]="selectedLink===3?'scrollHeading':'scrollheadingone'">Role Of Blockchain In RWA Tokenization</h2>

                    <p>
                      Blockchain is the underlying technology empowering the tokenizing real world assets. The decentralized nature and its distributed ledger records transactions across the network of nodes. It also ensures it is secured, transparent, and tamper-proof. While from the concept of Tokenizing Real World Assets trust and audible records of ownership and transaction are ensured. This eliminates the need for middlemen and reduces the risk of miscellaneous and fraudulent activities.
                    </p>
                    <p>
                      Self-executing smart contracts in Blockchain technology is a default thing, that is coded to execute agreements over certain conditions. This is a highlighting feature that is adopted into RWA tokenization that automates and streamlines the ownership process, asset transfers, and distributing revenue with enhanced efficiency, reliability, and possibilities through Tokenizing real world assets.
                    </p>
                    <h2 id="item-4"
                        [ngClass]="selectedLink===4?'scrollHeading':'scrollheadingone'">RWA Tokenization Protocols That Are Commonly Used</h2>

                      <picture>
                          <source
                            srcset="
                            assets-new/images/blog/real-world-asset-tokenization/inside-image-2.webp
                            "
                            type="image/webp"
                          />
                          <img
                            loading="lazy"
                            data-src="assets-new/images/blog/real-world-asset-tokenization/inside-image-2.jpg"
                            alt="RWA Tokenization Protocols"
                            title="RWA Tokenization Protocols"
                            width="840"
                            height="511"
                            class="img-fluid"
                          />
                      </picture>

                    <div class="h4">Ethereum</div>
                    <p>
                      To create Real world asset tokens in common, being a highly secure and decentralized choice, Ethereum protocol is used. The token standard and its functions are ensured and leveled up.
                    </p>

                    <div class="h4">Hyperledger Besu</div>
                    <p>
                      To create your blockchain environment and create RWA tokens in there trying to build an ecosystem on your own, Hyperledger Besu would be the right choice for Enterprise solutions.
                    </p>

                    <div class="h4">Corda</div>
                    <p>
                      If you choose to develop a custom environment on the blockchain for enterprises, Corda protocol can help you in curating such effective solutions for your RWA Tokens in a more efficient manner, exclusively tailored for you. 
                    </p>

                    <h3 id="item-5"
                    [ngClass]="selectedLink===5?'scrollHeading':'scrollheadingone'" class="h-2">Smart Contracts In Tokenizing Real World Assets</h3>
                    <p>
                      These self-executing contracts with terms written on codes are smart contracts. When it comes to Asset tokenization, smart contracts are capable of executing various functionalities of ownership and management processes. Meanwhile, let us discuss the major roles of Smart contracts in the tokenization Real World assets.
                    </p>
                    
                    <h5 class="h4">Token Ownership</h5>
                    <p>
                      Smart contracts offer automatic ownership transfers when a token or asset is bought or sold in the marketplace. This eliminates the need for intermediaries.
                    </p>

                    <h5 class="h4">Distributing Revenue</h5>
                    <p>
                      From the assets with the capacity to generate income, smart contracts automatically distribute dividends and profits based on predefined rules to the token holders.
                    </p>

                    <div class="h4">Compliance</div>
                    <p>
                      Enforcing compliance with legal and regulatory requirements, Smart Contract enforces to ensure that the tokens adhere to the pre-applicable laws.
                    </p>
                    <p>
                      Smart contracts in asset tokenization streamline the process with automation, increase overall efficiency, reduce operational costs, and manage trading tokenized assets. Blockchain technology plays a fundamental part in ensuring the seamless functioning of real world asset tokenization.
                    </p>

                  <h3 id="item-6"
                  [ngClass]="selectedLink===6?'scrollHeading':'scrollheadingone'" class="h-2">Steps to Tokenize a Real-World Asset</h3>
                    <h5 class="h4">1. Choose the Asset to Tokenize</h5>
                    <p>
                      Choose the asset that you want to tokenize in the blockchain network. This can be any asset from a real estate property to machinery, documents, etc.
                    </p>
                    <h5 class="h4">2. Plan its Digital functionalities</h5>
                    <p>
                      Based on the asset you choose to digitalize, plan its functionality and utility options along with your tech advisory to ensure your decision doesn't affect the development part.
                    </p>
                    <h5 class="h4">3. Create RWA Token</h5>
                    <p>
                      As you get an idea of your Token functions, go ahead and Create your Real-world asset tokenization with your tech partner. 
                    </p>
                    <h5 class="h4">4. Get it integrated into the Blockchain</h5>
                    <p>
                      Integrate the token with the set of rules and regulations defined by smart contracts on the Blockchain and mint it for your global access.
                    </p>
                    <h5 class="h4">5. List it for Token sale</h5>
                    <p>
                      Once the RWA token is minted, it can be listed for sale in the marketplace, while it requires quite rigorous marketing to meet high standards of sales and investment.
                    </p>
                    <h5 class="h4">6. Open new avenues through DEX and Trading</h5>
                    <p>
                      The tokens purchased and others minted can also be listed in the Trading platforms and Decentralized exchanges for better movement and tractions.
                    </p>

                  <h3 id="item-7"
                  [ngClass]="selectedLink===7?'scrollHeading':'scrollheadingone'" class="h-2">Types of Tokenized Real-World Assets</h3>
                    <h5 class="h4">1. Real estate Tokenization</h5>
                    <p>
                      It involves tokenizing physical properties like residential, and commercial buildings, monuments, Land areas, etc. Investors can buy and sell these tokens, representing fractional ownership of the asset.
                    </p>
                    <h5 class="h4">2. Luxury item Tokenization</h5>
                    <p>
                      Items like luxury brand bags, accessories, watches, artworks, stones, etc can be tokenized on the blockchain which also has potential options to provide investment opportunities through fractional ownership.
                    </p>
                    <h5 class="h4">3. Precious Metal tokenization</h5>
                    <p>
                      Gold, Silver, Platinum, Nickel, and other precious metals can also be tokenized on the blockchain. Either as Bullion or Coins or in briquettes can be tokenized while the physical assets are with the custodian safe like tokens on the blockchain.
                    </p>
                    <h5 class="h4">4. Rare Collectible Tokenization</h5>
                    <p>
                      Rare and unique collectibles like vintage cars, sports mementos, memorabilia, limited-edition items, merchandise, antique sets, pottery, and many other things can be tokenized and open to trade in the networks.
                    </p>
                    <h5 class="h4">5. Rental Property Tokeniations </h5>
                    <p>
                      Real-world tokenization extends to tokenizing Rental properties that provide investors the opportunity to earn through rental incomes and investments made on the token therefore opening multifold opportunities through best investment strategy.
                    </p>
                    <h5 class="h4">6. Machinery and Automobile Tokenization</h5>
                    <p>
                      Real-world asset tokenization brings new avenues for almost everything, while machinery, vehicles, and other aspects of the automobile industry can also be tokenized here benefiting in a larger prospectus from gaining exposure to promoting investments. 
                    </p>

                  <h3 id="item-8"
                  [ngClass]="selectedLink===8?'scrollHeading':'scrollheadingone'" class="h-2">Varied Industries Indulging In Real World Asset Tokenization</h3>
                    <h5 class="h4">Finance</h5>
                    <p>
                      Financial institutions are at the forefront of asset tokenization who are welcoming transformative inventions through tokenization. Tokenizing stocks, bonds, commodities, etc as digital tokens opens up new investment realms to vitalize and breed with fractional ownerships, small investments, trading options, etc.
                    </p>
                    <h5 class="h4">Real Estate</h5>
                    <p>
                      Real estate has a huge fund of real-world asset tokenization market. They are a prominent adopter of the trend of trying to implement digital transactions through blockchain in all possible ways. That also eliminates the need for brokers and cuts down transaction costs, eventually providing fractional ownership for real estate tokens.
                    </p>
                    <h5 class="h4">Supply Chain</h5>
                    <p>
                      There have been proven records of efficiency and transparency in the supply chain through tokenization. Which eventually moved for good and material seamlessly, while tracking became more convenient, transparent, and very accessible.
                    </p>
                    <h5 class="h4">Healthcare</h5>
                    <p>
                      The healthcare industry is very much indulging in real-world asset tokenization, where it is open to represent medical records, patient data, research findings, machinery, etc as tokens. While ensuring data security, transparency and also providing interoperability.
                    </p>
                    <h5 class="h4">Sports</h5>
                    <p>
                      For fans of course, the sports industry brought its venture into digital assets, where collectibles are tokenized and listed for sale. This includes tokenizing merchandise, content, trophies, event tickets, etc. This ownership provides exclusive rights in the sports organization that fosters strong bonds and consideration.
                    </p>
                    <h5 class="h4">Gaming</h5>
                    <p>
                      Gamin is dominating the industry in asset tokenization giving an opportunity to effectively bring in returns. By tokenizing in-game assets developers can create and act on a secondary marketplace for players to buy such tokens and make the best use of them.  
                    </p>
                    <h5 class="h4">Art Collectibles</h5>
                    <p>
                      Art is the start of tokenization, where physical artworks are tokenized on the platform to mint NFTs which are open to trade on the platforms. This provides monetizing opportunities for traders, investors, and of course the creators.  
                    </p>
                    <h5 class="h4">Infrastructure</h5>
                    <p>
                      Breaking down large-scale projects into smaller units through tokenization attracts wider investment opportunities. This is more manageable for developers and an easy mechanism to raise funds. This contributes to essential infrastructure including transportation, telecommunication, energy, etc.  
                    </p>
                    <h5 class="h4">Natural Resources</h5>
                    <p>
                      Natural resources like minerals, stones, commodities, etc., through tokenization, provide ownership along with transparency, facilitating fair trade policy, comparatively reducing corruption and unwanted surging.  
                    </p>
                    <h5 class="h4">Intellectual Property</h5>
                    <p>
                      Intellectual property like a creator's works, patent rights, etc can be tokenized under RWA tokenization, and the particular creator or artist gains a good investor pool. 
                    </p>
                    <h5 class="h4">Commodities</h5>
                    <p>
                      Agriculture industry products, gold, oil, and other commodities are open to tokenization which has improved liquidity and accessibility promoting investor bandwidth to trade commodities that hedge against market risk.  
                    </p>

                  <h3 id="item-9"
                  [ngClass]="selectedLink===9?'scrollHeading':'scrollheadingone'" class="h-2">Benefits Real World Asset Tokenization Provides the Community</h3>
                  <picture>
                    <source
                      srcset="assets-new/images/blog/real-world-asset-tokenization/benefits-real-world-assest-tokenization.webp"
                      type="image/webp"
                    />
                    <img
                      loading="lazy"
                      data-src="assets-new/images/blog/real-world-asset-tokenization/benefits-real-world-assest-tokenization.jpg"
                      alt="Benefits Real World Asset Tokenization"
                      title="Benefits Real World Asset Tokenization"
                      width="840"
                      height="300"
                      class="img-fluid"
                    />
                  </picture>
                    <h5 class="h4">Improved Liquidity</h5>
                    <p>
                      Real world Asset Tokenization services signifies enhanced liquidity, which is the biggest challenge that's been faced by traditional assets from the perspective of investing in physical assets. Let it be buildings, real estate, artworks, etc. by breaking the assets into tokens, fractionalizing buying opportunities, and giving divided ownership. This encourages buyers and investors across different scales to grab fine investment opportunities. Here, the demand to buy the whole property is replaced by partitional ownership as the asset is fractionalized—a very straightforward, quick, and easy approach to transactions that reduces the time and effort involved in the selling and buying process.
                    </p>
                    <h5 class="h4">Fractional Ownership</h5>
                    <p>
                      Real world asset tokenization provides opportunities like fractional ownership. Where the investor is burdened less with giving options to buy high-value assets in fractions, unlike making a whole purchase. Rather involving large capital from one investor. This brings the opportunity to a wider range of investors and contributes to building a bigger community. This democratism opens opportunities on a broader scale for both small-scale and big-scale investors. This also helps investors diversify their purchase and investment portfolios to a wider range, entrusting them with the perks of holding fractional assets. Therefore promoting inclusivity through investments.
                    </p>
                    <h5 class="h4">Improves Accessibility</h5>
                    <p>
                      Eliminating the major traditional barrier in the investment sector, tokenization Real World assets is a futuristic approach that opens up a wider range of possibilities. The lower minimum investment requirement creates and opens up a diverse group of investors, like retail investors, who can participate but not on a larger scale. This is a democratic opening for all investors rather than being reserved for institutional players. Thereby, this improves accessibility in the financial ecosystem, allowing the participation of a wider audience with mutual benefits yielded through digital asset tokenization.
                    </p>
                    <h5 class="h4">Security and transparency</h5>
                    <p>
                      Blockchain technology, being the backbone of real world asset tokenization, ensures the network is transparent and, at the same time, secure in the process of recording ownership and leasing transactions. As all the transactions are recorded in the ledge that is immutable, there is a clear history of events that can be audited and legal proof for ownership transfer. Their openness and transparent nature build trust and faith among investors in making their investments. It also contributes to reducing the chances of risk and other miscellaneous activities. In addition, the smart contract automates transactions, enforces contractual obligations, and improves security by reducing the need for intermediaries.
                    </p>

                  <h3 id="item-10"
                  [ngClass]="selectedLink===10?'scrollHeading':'scrollheadingone'" class="h-2">Challenges in RWA Tokenization</h3>
                    <h5 class="h4">Regulatory Concerns</h5>
                    <p>
                      It's naturally complex to navigate through the regulatory landscape of tokenizing RWA. There are different jurisdictions and various regulations over security, ownership, finances, and transactions. Tokenization of real world assets either falls under existing norms or does need to take a step ahead with new regulations of the body that need to be fulfilled. It is crucial to be legit and acceptable as tokenized assets. Not paying attention to regulations can cause issues for both investors and issuers that impact their growth and adaptation of RWA tokenization.
                    </p>
                    <h5 class="h4">Security Issues</h5>
                    <p>
                      The broader ecosystem of tokenization of real world assets is not yet immune to the robust security features of blockchain technology. As smart contracts automate most of the real world tokenizing processes, there is a high chance of injecting vulnerabilities, and the risk of hacking is high, posing a significant threat. As investors demand trust in the security of their assets, these types of breaches erode existing trust.
                    </p>
                    <h5 class="h4">Challenges in Market Adoption</h5>
                    <p>
                      There are certain hurdles concerning the tokenization of real world assets, despite the advantages existing in the system. The market is aware, but the ratio or range of awareness needs to be on par, while there are versatile opinions over the acceptance of new technology and adoption of it.
                    </p>
                    <p class="pb-2">
                      <i class="bi bi-check-lg"></i> Lack of awareness
                    </p>
                    <p class="pb-2">
                      <i class="bi bi-check-lg"></i> Knowledge and understanding 
                    </p>
                    <p class="pb-2">
                      <i class="bi bi-check-lg"></i> Acquiring trust among varied perceptions 
                    </p>
                    <p>
                      <i class="bi bi-check-lg"></i> Legacy systems 
                    </p>

                   
                  <h3 id="item-11"
                  [ngClass]="selectedLink===11?'scrollHeading':'scrollheadingone'" class="h-2">Trends and Development Around Real World Assets Tokenization</h3>
                   <p>
                    As new technologies are emerging around the Web3 sector, exploring different industries like supply chains, banks and financial institutions, logistics, manufacturing, and more the efficiency that technology is aiming for is a good thing for a better future. Real world asset tokenization, now open to real estate, intellectual property, and personal items and assets, can expand with a broader perspective into the business sector in a prominent manner. The present innovations in tokenization are more involved in the technicalities that insist on tranquilizing and getting the basement right to act upon.
                   </p>
                   <p>
                    This includes considering consensus mechanisms, product scalability, DeFi development and applications, and so on. As these aim to address the present challenges and expand the capabilities of asset tokenization, the regulators around them will eventually evolve. And this will be a focus on expansion with a clear perspective of definitions, frameworks, international collaborations, and the rest.
                   </p>

                  <h3 id="item-12"
                  [ngClass]="selectedLink===12?'scrollHeading':'scrollheadingone'" class="h-2">The Future of RWA</h3>
                   <p>From the opinions shared by the market experts, they anticipate as follows:</p>                  
                   <p>Boston Consulting Group expects 10% growth in the GDP by 2030 with asset securities token speculation with a $16 trillion value.                   
                   </p>
                   <p> Traditional Securities and asset managers report that the market value of tokenizing real world assets could hit $10 trillion in the bull case and $3.5 trillion in the bear case by 2030.</p>
                   <p>This trillion-dollar business powerhouse has potential in banking and finance, making it a wireframe to explore with DeFi in the real world. Asset tokenization can shift towards being a financial instrument in institutional frameworks with its massive growth, recognition, and accessibility in the market.</p>
                   <p>With a broader perspective and expectation over tokenizing real world assets, the opportunity in future finance and investments is greater. And it seems to have a good market impact in the global corridors. Tokenizing Real World assets reshapes and revolutionizes the sector of managing financial assets, better understanding and realization of trends and techniques gives an extra edge in making better actions with Real world asset tokenization development.</p>      
                    
                    <h3 class="h-2">Conclusion</h3>
                    <p>
                      BlockchainX, with a trained team of experts, is a <a href="https://www.blockchainx.tech/" target="_blank">blockchain development company</a> that offers services in all aspects of tokenization, tokenomics, listing, Web3 marketplace, and more.,we also render tokenization platform development which implies maximizing your revenue streams.
                    </p>
                    <p>
                      Vitalize on the opportunities with more clarity on the technology and market dynamics are at constant change with tokenizing real world assets. 
                    </p>

                </div>

                <app-social></app-social>

            </div>
            <div class="col-lg-4 col-md-12">
                <!-- <app-blog-sidebar></app-blog-sidebar> -->
                <div class="blog-right-side table-content">
                    <div class="table-content-main">
                        <div class="metaverse_heading">
                            <h4 class="pb-0"><span>Table of Contents</span></h4>
                        </div>
                        <div class="categories_list_view table-contens">
                            <ul>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-1"
                                        (click)="addClass(1)">How RWA Tokenization Works?</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-2"
                                        (click)="addClass(2)">What Assets in the Real World Are Tokenized?</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-3"
                                        (click)="addClass(3)">Role Of Blockchain In RWA Tokenization</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-4"
                                        (click)="addClass(4)">RWA Tokenization Protocols That Are Commonly Used</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-5"
                                        (click)="addClass(5)">Smart Contracts In Tokenizing Real World Assets</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-6"
                                        (click)="addClass(6)">Steps to Tokenize a Real-World Asset</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-7"
                                        (click)="addClass(7)">Types of Tokenized Real-World Assets</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-8"
                                        (click)="addClass(8)">Varied Industries Indulging In Real World Asset Tokenization</a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-9"
                                        (click)="addClass(9)">Benefits Real World Asset Tokenization Provides the Community</a>
                                </li>
                                <li>
                                  <a
                                      href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-10"
                                      (click)="addClass(10)">Challenges in RWA Tokenization</a>
                                </li>
                                <li>
                                  <a
                                      href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-11"
                                      (click)="addClass(11)">Trends and Development Around Real World Assets Tokenization</a>
                                </li>
                                <li>
                                  <a
                                      href="https://www.blockchainx.tech/real-world-asset-tokenization-guide-2024/#item-12"
                                      (click)="addClass(12)">The Future of RWA</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="categories_list">
                        <div class="metaverse_heading">
                          <h4 class="pb-0"><span>Categories</span></h4>
                        </div>
                        <div class="categories_list_view">
                          <ul>
                            <li class="pt-0">
                              <a>NFT</a>
                            </li>
                    
                            <li>
                              <a>Metaverse</a>
                            </li>
                    
                            <li>
                              <a>NFT Marketplace</a>
                            </li>
                    
                            <li>
                              <a>DEFI</a>
                            </li>
                    
                            <li>
                              <a>DAO</a>
                            </li>
                    
                            <li>
                              <a>Cryptocurrency</a>
                            </li>
                    
                            <li>
                              <a>Wallet</a>
                            </li>
                            <li>
                              <a>Crypto Exchange</a>
                            </li>
                          </ul>
                        </div>
                      </div> -->
                      <div class="blog-author-wrap">
                        <div class="blog-author-detail">
                          <div>
                            <img
                              src="assets-new/images/blog/yokesh-shankar.svg"
                              alt="blog author"
                              width="150"
                              height="150"
                            />
                          </div>
                          <div>
                            <div class="metaverse_heading">
                              <h4><span>About Author</span></h4>
                            </div>
                            <h5>Yokesh Sankar</h5>
                            <div class="author-social-connect">
                              <ul>
                                <li>
                                  <a
                                    href="https://www.instagram.com/yokesh.sankar/"
                                    target="_blank"
                                    class="fs-5"
                                    rel="noopener noreferrer"
                                    alt="instagram"
                                    aria-label="Instagram"><i class="bi bi-instagram"></i></a>
                                </li>
                                <li>
                                  <a
                                    href="https://in.linkedin.com/in/yokeshsankar"
                                    target="_blank"
                                    class="fs-5"
                                    rel="noopener noreferrer"
                                    alt="Linkedin"
                                    aria-label="Linkedin"><i class="bi bi-linkedin"></i></a>
                                </li>
                                <li>
                                  <a
                                    href="https://www.facebook.com/yokesh.shankar"
                                    target="_blank"
                                    class="fs-5"
                                    rel="noopener noreferrer"
                                    alt="facebook"
                                    aria-label="facebook"><i class="bi bi-facebook"></i></a>
                                </li>
                                <li>
                                  <a
                                    href="https://twitter.com/yokesh_sankar_v"
                                    target="_blank"
                                    class="fs-5"
                                    rel="noopener noreferrer"
                                    alt="Twitter"
                                    aria-label="twitter">
                                    <i class="bi bi-twitter-x"></i>
                                  </a>
                                </li>
                  
                              </ul>
                            </div>
                          </div>
                        </div>
                        <p>
                          I am Yokesh Sankar. Co-Founder of BlockchainX, My vision and time to
                          offer the best products for our clients without apprehensions at
                          economic rates.
                        </p>
                      </div>
                      <div class="social-shares">
                        <div>
                          <a href="https://twitter.com/Blockchainxtech" target="_blank"
                                  rel="noopener noreferrer"
                            >Follow us on Twitter
                            <span class="mx-2 cyan-300"
                              ><i class="bi bi-twitter-x color cyan-300"></i></span
                          ></a>
                        </div>
                      </div>
                </div>

            </div>
        </div>
        <!-- <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                <app-comments></app-comments>
            </div>
        </div> -->
    </div>
</section>
<app-blog></app-blog>
<section class="section-service-alt-main">
  <app-get-in-touch></app-get-in-touch>
</section>