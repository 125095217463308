import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-cryptocurrency-exchange-development',
  templateUrl: './cryptocurrency-exchange-development.component.html',
  styleUrls: ['./cryptocurrency-exchange-development.component.css'],
})
export class CryptocurrencyExchangeDevelopmentComponent implements OnInit {

  selectedLink:number;

  title =
    'Cryptocurrency Exchange Software Development | BlockchainX';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {

     //darkmode services 
     this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });

    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Cryptocurrency exchange software development with advanced security, real-time transactions and multi-coin capability. Make your platform future proof right now!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'cryptocurrency exchange software development, cryptocurrency exchange software Development Company, crypto exchange development, White Label Cryptocurrency exchange software development, Cryptocurrency Exchange Development, Cryptocurrency Exchange Development Company',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Cryptocurrency Exchange Software Development | BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/cryptocurrency-exchange-software-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Cryptocurrency exchange software development with advanced security, real-time transactions and multi-coin capability. Make your platform future proof right now!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/cryptocurrency-development/cryptocurrency-exchange-software.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/cryptocurrency-exchange-software-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Cryptocurrency Exchange Software Development | BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Cryptocurrency exchange software development with advanced security, real-time transactions and multi-coin capability. Make your platform future proof right now!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/cryptocurrency-development/cryptocurrency-exchange-software.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/cryptocurrency-exchange-software-development/'
    );
  }
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  }
  addClass(id:number){
    this.selectedLink=id;
  }
}
