<section class="banners-section" #about>

  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Build Sovereign Chains</strong></span> on
                Substrate
              </h1>
              <p>
                Leverage the security and capabilities of Rust for your web3
                project with our Substrate blockchain development service. We
                help web3 startups launch their blockchains rapidly and
                efficiently.
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/launch-your-token.webp" />
              <source type="image/png"
                srcset="assets-new/images/token-images/launch-your-token.png" />
              <img loading="lazy"
                data-src="assets-new/images/token-images/launch-your-token.png"
                width="500" height="500" class="img-fluid"
                alt="Substrate Development Company"
                title="Substrate Development Company" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <!-- <div class="col-lg-10 metaverse_heading mx-auto">
            <h3 class="pb-lg-5 pb-4">Why should you consider<span> Substrate blockchain development?</span></h3>
        </div> -->
      <div class="whys_section mb-0 text-center">
        <h2 class="pb-lg-5 pb-4">Why should you consider<span> Substrate
            blockchain development?</span></h2>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <source type="image/png"
              srcset="assets-new/images/token-images/why-should-you-choose.png">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350" alt="Substrate Blockchain Development"
              title="Substrate Blockchain Development"
              class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            Building your chain on Substrate has many benefits. Firstly,
            blockchains built on Substrate are programmed in Rust which is a
            highly capable and secure programming language. Secondly, you have
            the opportunity to become a Polkadot or Kusama parachain. It also
            allows you to leverage Polkadot’s supportive community to grow your
            project to new heights.
          </p>
        </div>

        <!-- <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  Premium security
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Undeniable security
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Improved scalability
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Interoperable ability
                </li>
              </ul>
            </div>
          </div> -->
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <source type="image/png"
              srcset="assets-new/images/erc/trending-erc20-token-list.png" />
            <img loading="lazy"
              data-src="assets-new/images/erc/trending-erc20-token-list.png"
              width="405" height="405"
              alt="Popular Projects built using Substrate"
              title="Popular Projects built using Substrate"
              class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Popular Projects </span>
            built using Substrate</h3>
          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span><a
                    href="https://www.coingecko.com/en/coins/moonbeam"
                    target="_blank"
                    rel="noopener noreferrer">Moonbeam</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/acala"
                    target="_blank"
                    rel="noopener noreferrer">Acala</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/polkastarter"
                    target="_blank"
                    rel="noopener noreferrer">Polkastarter</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/litentry"
                    target="_blank"
                    rel="noopener noreferrer">Litentry</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/hydradx"
                    target="_blank"
                    rel="noopener noreferrer">HydraDX</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://www.coingecko.com/en/coins/polymesh"
                    target="_blank"
                    rel="noopener noreferrer">Polymesh</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Related Services </span></h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <source type="image/png"
                srcset="assets-new/images/token images/project-consulting.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="dApp Development"
                title="dApp Development" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">dApp Development</h4>
          <p>
            Build decentralized applications on your sovereign chain that allows
            users to access services inside your ecosystem.
          </p>
          <div class="content-link">
            <a [routerLink]="['/dapps-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/staking-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="Decentralized Exchange"
                title="Decentralized Exchange" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Decentralized Exchange
          </h4>
          <p>
            Launch a decentralized exchange for your chain’s ecosystem and allow
            your community to trade assets easily.
          </p>
          <div class="content-link">
            <a [routerLink]="['/cryptocurrency-exchange-software-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/farming-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="NFT Marketplace"
                title="NFT Marketplace" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">NFT Marketplace</h4>
          <p>
            Creating an NFT Marketplace for your Substrate blockchain allows you
            to attract the NFT crowd, increasing your visibility.
          </p>
          <div class="content-link">
            <a [routerLink]="['/nft-marketplace-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/token-bridge.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="Cross Chain Bridge"
                title="Cross Chain Bridge" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Cross-Chain Bridge</h4>
          <p>
            Easily connect assets from your Substrate blockchain to other chains
            by using our Cross-Chain Bridge platform.
          </p>
          <div class="content-link">
            <a href="https://bridge.blockchainx.tech/" target="_blank"
              rel="noopener noreferrer">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/layer-i-blockchain.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                width="75" height="75" alt="Launchpad" title="Launchpad"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Launchpad</h4>
          <p>
            A big part of growing your ecosystem is helping founders build their
            projects on-chain. Let them easily fundraise with a launchpad.
          </p>
          <div class="content-link">
            <a [routerLink]="['/white-label-launchpad-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/nft-markeplace.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/nft-markeplace.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/nft-markeplace.png"
                width="75" height="75" alt="Staking Platform"
                title="Staking Platform" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Staking Platform
          </h4>
          <p>
            Launching a staking campaign allows you to increase community
            interest in your project and grow your user base exponentially.
          </p>
          <div class="content-link">
            <a [routerLink]="['/nft-staking-platform-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>Why choose BlockchainX for Substrate development
              services</span>
          </h2>
          <p class="pb-3">
            We are a leading substrate blockchain development company with
            experienced and certified developers, architects, and consultants
            that can help you take your project from paper to production,
            rapidly. Leverage industry-leading professionals and technologies to
            launch your Web3 startups with our Substrate development services.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png"
                  class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="Substrate Development Services"
              title="Substrate Development Services" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What is Substrate blockchain development?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Substrate is a Rust framework launched by the makers of
                    Polkadot. It allows developers to rapidly build sovereign
                    chains and smart contracts that are highly secure and
                    scalable.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  How long does it take to build a solution on Substrate?
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Building a chain on Substrate as a Proof-of-Concept might
                    only take a few days. However, building a solution for your
                    business will take longer. It depends on what you have in
                    mind for your web3 project. You can get started by speaking
                    to one of our expert consultants.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  Why do I need Substrate for my project?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Substrate allows you to rapidly build a sovereign chain for
                    your project. If you are startup with a product that demands
                    high scalability, you can choose to build your product on
                    your own chain and make it scalable, secure, and fast.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  How much does Substrate development services cost?
                </button>
              </div>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Substrate blockchain development can cost anywhere between a
                    few thousand dollars and up. It's always better to find a
                    services agency and work with them until your product gains
                    some traction with users. After that, you can choose to hire
                    a dedicated team in-house or move with the agency you are
                    working with on a monthly billing plan. The former might be
                    significantly expensive depending on where you are.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive">
                  Do I need a coin for my Substrate blockchain?
                </button>
              </div>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    While you can create your Substrate blockchain ecosystem
                    around a native coin, it is not mandatory. If you’re
                    solution is strictly to solve a specific problem that
                    doesn’t require a token economy, you can choose to keep your
                    blockchain as the underlying piece of tech that your product
                    runs on.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Check out our Latest Blogs </h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                    width="1024"
                    height="624"
                    class="img-fluid"
                    alt="Web3 Gaming"
                    title="AI Transforming Web3 Gaming And Metaverse" />
                </picture>
                <h3>
                  AI Transforming Web3 Gaming And Metaverse
                </h3>
                <p>
                  In the 1990s, the introduction of the internet, and the
                  evolution of something beyond reality kick-started ...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/what-is-gamefi'
                  ]">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/what-is-gamefi.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/what-is-gamefi.jpg"
                    width="1024"
                    height="624"
                    class="img-fluid"
                    alt="What is GameFi?"
                    title="What is GameFi?" />
                </picture>
                <h3>
                  What is GameFi? Diving into the world of Blockchain Games
                </h3>
                <p>
                  From the earlier days of the video game industry, developers
                  have aimed to create worlds as powerful as real life....</p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/what-is-gamefi'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                  src="assets-new/images/right-arrow.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="!isDarkmode" />
                <img loading="lazy"
                  src="assets-new/images/right-arrow-dark.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/blockchain-game-development'
                  ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.webp
                      " />
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/industries/blockchain-in-gaming.jpg"
                    width="1024"
                    height="624"
                    class="img-fluid"
                    alt="Blockchain Technology"
                    title=" The Potential of Blockchain Technology to Enhance Industry Profits " />
                </picture>
                <h3>
                  The Potential of Blockchain Technology to Enhance Industry
                  Profits
                </h3>
                <p>
                  Mario video games in the year 1981 became very famous among
                  game lovers by the name of Jumpman. In today’s world,...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/blockchain-game-development'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                  src="assets-new/images/right-arrow.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="!isDarkmode" />
                <img loading="lazy"
                  src="assets-new/images/right-arrow-dark.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Launch your project on Substrate
                today!</span></h3>
            <p>Connect with one of our Substrate experts to start your Web3
              startup journey.</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/mail.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="Mail"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/whatsapp-icon.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/telegram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="Telegram"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/linkdin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/instagram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="Instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/twitterx.svg" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="Twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->
