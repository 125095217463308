<!-- Metaverse Section -->
<section class="banners-section">

  <div class="green-vector"></div>
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-8 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span> <strong> Metaverse </strong></span>
                <span>Development Service</span>
              </h1>
              <div class="common-sub-heading-alt pb-2">
                Crafting Creative Immersive Ecosystem
              </div>

              <p>
                Extensive virtual space, our Metaverse Development Services have
                spread to curate immersive environments with high efficiency
                through customized solutions.
              </p>
              <div class="content-link">
                <a [routerLink]="['/contact']">
                  <span>Get in touch</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source type="image/webp" srcset="assets-new/images/metaverse/metaverse-banner-image.webp" />
              <source type="image/png" srcset="assets-new/images/metaverse/metaverse-banner-image.png" />
              <img loading="lazy" data-src="assets-new/images/metaverse/metaverse-banner-image.png" alt="Right-vector" title="Right-vector" class="img-fluid" width="437" height="405"  />
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp"
            />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png"
            />
            <img loading="lazy"

              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon"
            />
          </picture>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- end -->
<section class="section-service-alt about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          Our<span>Clients</span>
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Facts Section -->
<section class="meta-facts" #about>
  <div class="metaverse-fact-section">
    <div class="violet-vector"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="common-sub-heading sub-title">
            Breaking The Myth<span class="dots">.</span>
          </div>
          <h3 class="main-sub-heading">
            Unfurling Facts & Highlighting Opportunities
          </h3>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row">
        <div class="col-lg-12">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="nft-fact">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/metaverse/meta-6.webp" />
                  <source type="image/png" srcset="assets-new/images/metaverse/meta-6.png" />
                  <img loading="lazy" data-src="assets-new/images/metaverse/meta-6.png" alt="nft market" title="nft market" class="img-fluid" width="143" height="142"  />
                </picture>
                <div class="nft-text-wrap">
                  <p>Metaverse Is The Future Of Digital Ecosystem, says Meta</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="nft-fact">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/metaverse/meta-4.webp" />
                  <source type="image/png" srcset="assets-new/images/metaverse/meta-4.png" />
                  <img loading="lazy" data-src="assets-new/images/metaverse/meta-4.png" alt="nft market" title="nft market" class="img-fluid" width="142" height="142"  />
                </picture>
                <div class="nft-text-wrap">
                  <p>
                    Smart contract certifications have higher value in the
                    market
                  </p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="nft-fact">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/metaverse/meta-5.webp" />
                  <source type="image/png" srcset="assets-new/images/metaverse/meta-5.png" />
                  <img loading="lazy" data-src="assets-new/images/metaverse/meta-5.png" alt="nft market" title="nft market" class="img-fluid" width="143" height="142"  />
                </picture>
                <div class="nft-text-wrap">
                  <p>
                    Top fashion brands have to seek a captivating audience here
                  </p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="nft-fact">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/metaverse/meta-3.webp" />
                  <source type="image/png" srcset="assets-new/images/metaverse/meta-3.png" />
                  <img loading="lazy" data-src="assets-new/images/metaverse/meta-3.png" alt="nft market" title="nft market" class="img-fluid" width="143" height="142"  />
                </picture>
                <div class="nft-text-wrap">
                  <p>
                    Big tech giants collectively indulge in exploring the
                    systems
                  </p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="nft-fact">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/metaverse/meta-2.webp" />
                  <source type="image/png" srcset="assets-new/images/metaverse/meta-2.png" />
                  <img loading="lazy" data-src="assets-new/images/metaverse/meta-2.png" alt="nft market" title="nft market" class="img-fluid" width="143" height="142"  />
                </picture>
                <div class="nft-text-wrap">
                  <p>Metaverse has great potential in transforming ecosystem</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="nft-fact">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/metaverse/meta-1.webp" />
                  <source type="image/png" srcset="assets-new/images/metaverse/meta-1.png" />
                  <img loading="lazy" data-src="assets-new/images/metaverse/meta-1.png" alt="nft market" title="nft market" class="img-fluid" width="143" height="142"  />
                </picture>
                <div class="nft-text-wrap">
                  <p>Higher investments in the sector yield higher outcomes</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="nft-fact">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/metaverse/meta-5.webp" />
                  <source type="image/png" srcset="assets-new/images/metaverse/meta-5.png" />
                  <img loading="lazy" data-src="assets-new/images/metaverse/meta-5.png" alt="nft market" title="nft market" class="img-fluid" width="120" height="120"  />
                </picture>
                <div class="nft-text-wrap">
                  <p>
                    Digital adaptations are going to be the future lifestyle
                  </p>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
<!--factsection-2-->
<!--nft metaverse-->
<section class="nft_metaverse">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading">
          <h3><span>Metaverse</span> NFT Marketplace Demo</h3>
        </div>
      </div>
    </div>
    <div class="row metmb-3">
      <div class="col-lg-5">
        <div class="metaverse_subheading">
          <h3>Unleash Unbounded Opportunities For Your Creatives</h3>

          <div class="content-link">
            <a
              href="https://seaswap.trade/eYS8odL/discrete-majestic-sphere"
              target="_blank"
                rel="noopener noreferrer"
            >
              <span>Explore</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-7" id="play">
        <div class="video_right_side">
          <div class="violet-vector"></div>
          <div class="demo-video-link">
            <a
              href="https://seaswap.trade/eYS8odL/discrete-majestic-sphere"
              target="_blank"
                rel="noopener noreferrer"
              >Explore
            </a>
          </div>
          <video
            width="100%"
            height="100%"
            muted
            poster="assets-new/images/metaverse/nft-video-1.webp"
            kind="NFT Marketplace Demo"
            aria-label="NFT Marketplace Demo video"
            class="img-fluid"
            id="video1"
          >
        </video>
        </div>
      </div>
    </div>
    <div class="row align-items-end video-mt-1">
      <div class="col-lg-7 orders-2" id="play">
        <div class="video_left_side">
          <div class="green-vector"></div>
          <div class="demo-video-link">
            <a
              href="https://seaswap.trade/ETuKCKL/writhing-gorgeous-park"
              target="_blank"
                rel="noopener noreferrer"
            >
              Explore
            </a>
          </div>
          <video
            width="100%"
            height="100%"
            muted
            poster="assets-new/images/metaverse/nft-video2.webp"
            kind="NFT Marketplace Demo Video"
            aria-label="Marketplace Demo video"
            class="img-fluid"
            id="video2"
          ></video>
        </div>
      </div>
      <div class="col-lg-5 orders-1">
        <div class="metaverse_subheading video-left">
          <h3>Incredible Solutions Beyond Expectations</h3>
          <div class="content-link">
            <a
              href="https://seaswap.trade/ETuKCKL/writhing-gorgeous-park"
              target="_blank"
                rel="noopener noreferrer"
            >
              <span>Explore</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--nft metaverse end-->
<section class="nft_market_place">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="nft_market_aligns">
          <div>
            <div class="common-sub-heading sub-title">
              NFT Marketplace <span class="dots">.</span>
            </div>
            <h3 class="main-sub-heading">
              Multifacet Platforms With Vast Scopes
            </h3>
          </div>
          <div class="content-link">
            <a [routerLink]="['/nft-marketplace-development']">
              <span>Explore</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nft_market_place_video" id="play">
    <div class="demo-video-link">
      <a
        href="https://hubs.mozilla.com/scenes/D7JMhFB"
        target="_blank"
        rel="noopener noreferrer"
      >
        View Demo
      </a>
    </div>
    <video
      width="100%"
      height="100%"
      muted
      poster="assets-new/images/metaverse/nft_market_video.webp"
      class="img-fluid"
      id="video3"
    ></video>
  </div>
</section>
<section class="casestudies">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="casetudies_common">
          <div class="violet-vector"></div>
          <div class="common-sub-heading sub-title">
            Metaverse<span class="dots">.</span>
          </div>
          <h3 class="main-sub-heading">Rendering Endless Opportunities</h3>
          <div class="casestudies_para">
            <p>
              In an open market with no limitations, we shall help you curate
              creative platforms with our expertise, and knowledge in Metaverse
              development services. Derive business-class solutions from top
              market experts in the field, facilitating an end-to-end solution.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-7 scrolling-section" id="meta-case-study">
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Metaverse Application Development</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  With the shift to decentralized solutions, we are bringing
                  on-demand facilitators with transparency and higher efficiency
                  through our Metaverse application development. This custom
                  solution takes your business to a new phase with betterment
                  and progress.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Metaverse NFT Development</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  With abilities to monetize with digital assets, Metaverse NFT
                  Development is now a pinch of life added to those
                  collectibles. Given functionalities and abilities, their use
                  cases extend and so do their values and demand in the market,
                  and there raises your reputation too.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Metaverse Store Development</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  With everything hitting the online to comprehend the physical
                  difficulties, our Metaverse Store Development, we help you
                  curate a storefront for your brand injecting metaverse
                  abilities, putting you in the spotlight for the globe to
                  notice your uniqueness.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <!--start-->
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Metaverse Social Media Platform</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  The social media platforms today are the connecting point of
                  the world, and by bringing in metaverse abilities into the
                  structures we help you in connecting the technology with real
                  life, more like a link between two parallel worlds, the real
                  and reel together in one.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <!--end-->
        <!--start-->
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Metaverse Platform Development</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  Our Metaverse Platform Development insists on curating
                  creative platforms for our clients in the digitally empowered
                  era and stands as an enduring example to the world,
                  symbolizing endless possibilities in different sectors from
                  art, real estate, and many others.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <!--end-->
        <!--start-->
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Metaverse Integration Services</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  For your dream, to make your business completely digitized, we
                  facilitate our Metaverse Integration services, which can
                  transform your current operations into a highly secured, safe,
                  and advanced ecosystem with our expertise. Go completely
                  decentralized with us.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <!--end-->
        <!--start-->
        <div class="row">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Metaverse Marketing Services</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  To grab global attention and seek the interest of the digital
                  market, we render customized business-driven Metaverse
                  Marketing services, that are result-oriented, supportive for
                  your business, and contributes is reaching the corners in the
                  digital era.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <!--end-->
      </div>
    </div>
  </div>
</section>
<!-- Why Choose Us -->
<app-why-choose></app-why-choose>
<!-- Why Choose Us -->
<!-- Our Development Process -->
<section class="our_development_process">
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-12">
        <div class="commontext_wrap">
          <h2>
            Our Metaverse NFT Marketplace<span> Elements Brought In</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-4 metmb-3">
        <div class="virtual_process cmb-5">
          <h2>01</h2>
          <div class="h4">Custom Avatar Creation</div>
          <p>
            The users can create a virtual version of themselves, a replica that
            can be creative and customized, however.
          </p>
        </div>
        <div class="virtual_process">
          <h2>02</h2>
          <div class="h4">Digital Asset Tokens</div>
          <p>
            The platform supports tokenizing unique assets from art collectibles
            to real estate for users to act & monetize.
          </p>
        </div>
      </div>
      <div class="col-lg-4 metmb-3">
        <div class="our_dev_process" id="development-picture">
          <picture>
            <source type="image/webp" srcset="assets-new/images/metaverse/metaverse-demo.webp" />
            <source type="image/png" srcset="assets-new/images/metaverse/metaverse-demo.png" />
            <img loading="lazy" data-src="assets-new/images/metaverse/metaverse-demo.png" alt="development" title="development" class="img-fluid" width="275" height="275"  />
          </picture>
        </div>
      </div>
      <div class="col-lg-4 virual-list">
        <div class="virtual_process cmb-5">
          <h2>03</h2>
          <div class="h4">Wallet Integration</div>
          <p>
            Connecting NFT wallets in platforms support quick, safe, and secured
            transactions and safe storage.
          </p>
        </div>
        <div class="virtual_process">
          <h2>04</h2>
          <div class="h4">Game Asset Utilization</div>
          <p>
            Players can build and sell in-game assets in metaverse marketplaces
            bringing name, fame, and returns.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Our Development Process -->
<div class="meta-discuss">
  <div class="container py-0">
    <app-let-discuss></app-let-discuss>
  </div>
  <app-business-tech-stack></app-business-tech-stack>
</div>
<app-expert></app-expert>
<section class="section-service-main">
<app-blog></app-blog>
</section>
<!-- <section class="section-service-alt-padd">
<app-contactform></app-contactform>
</section> -->
<section class="section-service-main">
  <app-get-in-touch></app-get-in-touch>
</section>
