<!-- Banner Section -->
<div class="banners-section">
  
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span>ERC20 Token<strong> Development</strong></span>
              </h1>
              <div class="common-sub-heading-alt h-4">
                To Empower Your Business Finances
              </div>

              <p>
                Enhance your business model with efficient ERC20 token development services, with the advent of technological potentials. BlockchainX will prominently guide you through the process of security backlogs, standby trust, transparency, and efficiency.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx"
                    target="_blank"
                rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']" title="contact">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/erc20-token-development.webp">
              <source type="image/png"
                srcset="assets-new/images/erc/erc20-token-development.png">
              <img loading="lazy"
                data-src="assets-new/images/erc/erc20-token-development.png"
                width="409" height="409" alt="Best erc20 token generator"
                title="Best erc20 token generator" class="img-fluid">
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/light-theme/erc20-token-development.webp">
              <source type="image/png"
                srcset="assets-new/images/erc/light-theme/erc20-token-development.png">
              <img loading="lazy"
                data-src="assets-new/images/erc/light-theme/erc20-token-development.png"
                width="409" height="409" alt="Best erc20 token generator"
                title="Best erc20 token generator" class="img-fluid">
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp"
            />
            <img loading="lazy"

              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon"
            />
          </picture>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Banner Section ends -->

<!-- What is ERC20 start-->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="metaverse_heading">
      <h2 class="pb-lg-5 pb-4"><span>What Is ERC20 </span>Token Development?</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture *ngIf="!isDarkmode">
            <source type="image/webp"
              srcset="assets-new/images/erc/what-is-erc20-token.webp">
            <source type="image/png"
              srcset="assets-new/images/erc/what-is-erc20-token.png">
            <img loading="lazy"
              data-src="assets-new/images/erc/what-is-erc20-token.png"
              width="396" height="396" alt="erc20 token create"
              title="erc20 token create" class="BSC-Tokens-image img-fluid">
          </picture>
          <picture *ngIf="isDarkmode">
            <source type="image/webp"
              srcset="assets-new/images/erc/light-theme/what-is-erc20-token.webp">
            <source type="image/png"
              srcset="assets-new/images/erc/light-theme/what-is-erc20-token.png">
            <img loading="lazy"
              data-src="assets-new/images/erc/light-theme/what-is-erc20-token.png"
              width="396" height="396" alt="erc20 token create"
              title="erc20 token create" class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div
        class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            A potential crypto investment that helps businesses in multiple verses is a crypto token. Ethereum, among the other blockchain networks, has established its capabilities in the market and continues to soar high. By opting to create ERC20 tokens for your business that may represent any asset, share value, etc. While on purchase and in terms of monetary requirement ERC20 tokens can be circulated to source the derided investment. By customizing tokens for your requirements and usability, it is convenient to integrate with DAO, DEX, DeFi, Launchpads, ICO, IDO, DApps, and more.
          </p>
        </div>

        <div class="row token d-flex">
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span>
                Customized Tokens
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Empower Business values
              </li>
            </ul>
          </div>
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Easy to trace and track
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> 100% Qualified Codes
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row card-main-row">
        <div class="col-lg-2"></div>
        <div class="col-lg-10 col-md-12">
            <div class="row justify-content-end country-list">
              <div
              class="col-lg-4 col-md-4 col-sm-4 col-12 project-inner text-center px-0"
            >
              <p class="count-mumber">50 <sup>+</sup> <span class="inner-span">Country</span></p>
            </div>
            <div
              class="col-lg-4 col-md-4 col-sm-4 col-12 project-inner text-center px-0"
            >
              <p class="count-mumber">300 <sup>+</sup> <span class="inner-span">Projects Delivered</span></p>
            </div>
            <div
              class="col-lg-4 col-md-4 col-sm-4 col-12 project-inner-alt text-center px-0"
            >
              <p class="count-mumber">350 <sup>+</sup> <span class="inner-span">Total Projects</span></p>
            </div>
            </div>
        </div>
      </div>
      <div class="common-socialbar">
        <div class="row social-icon-row align-items-center justify-content-center">
            <div class="col-lg-10 col-md-12 col-sm-12">
              <div class="row social-inner-row align-items-center">
                  <div class="col-lg-3 col-md-3 col-sm-6 text-center icon-col">
                      <div class="icon-main">
                          <a href="#"><i class="bi bi-telegram"></i>
                            Twitterofficial</a>
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 text-center icon-col">
                      <div class="icon-main">
                          <a href="#"><i class="bi bi-whatsapp"></i>
                            +91-9564732783</a>
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 text-center icon-col">
                      <div class="icon-main">
                          <a href="#"><i class="bi bi-skype"></i>
                            Exports</a>
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 text-center icon-col">
                      <div class="icon-main">
                          <a href="#"><i class="bi bi-clipboard2-data"></i>
                            Get Quots</a>
                      </div>
                  </div>
              </div>
            </div>
        </div>
      </div> -->
  </div>
</section>
<!-- End -->

<div class="section-service-main">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 mx-auto col-md-12 col-sm-12 col-12 order-lg-first order-last">
        <div class="bep-work-section pb-0">
          <picture *ngIf="!isDarkmode">
            <source type="image/webp"
              srcset="assets-new/images/erc/erc-token-development.webp">
            <img loading="lazy"
              data-src="assets-new/images/erc/erc-token-development.png"
              width="400" height="400" alt="ERC20 Token Development Company"
              title="ERC20 Token Development Company" class="BSC-Tokens-image img-fluid">
          </picture>
          <picture *ngIf="isDarkmode">
            <source type="image/webp"
              srcset="assets-new/images/erc/light-theme/erc-token-development-white.webp">
            <img loading="lazy"
              data-src="assets-new/images/erc/light-theme/erc-token-development-white.png"
              width="400" height="400" alt="ERC20 Token Development Company"
              title="ERC20 Token Development Company" class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
        <div class="whys_section mb-0">
          <h2><span>ERC20 Token </span> Development Company</h2>
          <p>
            Ethereum with a great flow in traction and advancement, ERC20 Token development companies are gaining opportunities to explore. While we at BlockchainX as an ERC20 token development company tailor your solutions with customization. Our primary motive is entitled to serve your requirement in ERC20 token development with ultimate features and functionalities. Our expertise as an ERC20 Token Development Company includes ideating, curating, and providing web3 consultation for your project, we also design your tokenomics, and smart contracts, and ensure to provide rightful error-free codes that execute the operation with precision and clarity. Connect with our Expert team of developers now to customize your exclusive, sustainable, and elegant ERC20 token developed peculiarly for your project and its growth.
          </p>
          <div class="mt-4 mb-4 mb-lg-0 mt-lg-4 col-lg-12">
            <a class="our_expert_talk" href="erc20-token-development/#form">Book a Free cost Consultation Here!</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Product Features -->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>ERC20 Token <span>Development Services</span></h3>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center gy-5">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/tokenomics-planning.webp" />
              <img loading="lazy"
                data-src="assets-new/images/erc/tokenomics-planning.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Tokenomics Planning"
                title="Tokenomics Planning" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Tokenomics Planning</h4>
          <p>
            We also provide tokenomics planning services for your tokens. The primary aspect of any ERC20 token is Tokenomics which is a set of rules and incentives that define issuance, distribution, and circulation of the token in the market.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ethereum-token-creation.webp" />
              <img loading="lazy"
                data-src="assets-new/images/erc/ethereum-token-creation.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Ethereum Token Creation "
                title="Ethereum Token Creation " />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Ethereum Token Creation 
          </h4>
          <p>
            We help you develop customized Ethereum tokens for your projects, games, and operations. Based on the requirement we strategize the functionalities of the token and develop tokens on the desired standard.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/smart-contract-creation.webp" />
              <img loading="lazy"
                data-src="assets-new/images/erc/smart-contract-creation.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Smart Contract Creation"
                title="Smart Contract Creation" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Smart Contract Creation</h4>
          <p>
            Smart contracts are the backbone of any token. Our ERC20 token development service also extends to curate well-defined Smart contracts that are tailored for your token and ensure its functions are executed as designed.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/exchange-listing-assistance.webp" />
              <img loading="lazy"
                data-src="assets-new/images/erc/exchange-listing-assistance.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Exchange Listing Assistance"
                title="Exchange Listing Assistance" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Exchange Listing Assistance</h4>
          <p>
            As an ERC20 token development company, we provide Exchange listing assistance for your projects and contribute to the growth of the token seamlessly. Our experts analyze the market situations and help you make wise choices.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/listing-token-market-tracker-websites.webp" />
              <img loading="lazy"
                data-src="assets-new/images/erc/listing-token-market-tracker-websites.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Listing on Token Market Tracker Websites"
                title="Listing on Token Market Tracker Websites" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Listing on Token Market Tracker Websites</h4>
          <p>
            Our ERC20 token development includes listing your developed Token on Market Tracker Websites that will help you gain traction and eventually improve your exposure in the market.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/erc20-token-audits.webp" />
              <img loading="lazy"
                data-src="assets-new/images/erc/erc20-token-audits.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="ERC20 Token Audits"
                title="ERC20 Token Audits" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">ERC20 Token Audits</h4>
          <p>
            We extend our service with internal Token audit for the tokens we curate for you, while for your already developed token or platform, we extend our Token Auditing services ensuring the credibility of the codes. 
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/token-migration-services.webp" />
              <img loading="lazy"
                data-src="assets-new/images/erc/token-migration-services.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Token Migration Services"
                title="Token Migration Services" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Token Migration Services</h4>
          <p>
            For your Version 1 ERC20 tokens, we help you with requirement-based upgradation and migration so that the functions of the V1 token are migrated into a better-constructed V2 ERC20 token.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/cold-storage-facilities.webp" />
              <img loading="lazy"
                data-src="assets-new/images/erc/cold-storage-facilities.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Cold Storage Facilities"
                title="Cold Storage Facilities" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Cold Storage Facilities</h4>
          <p>
            We also extend our service to design and develop highly secured cold storage solutions that vest on advanced hardware security modules that protect the Erc20 token from potential threats and breaches.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Product Features -->




<!-- ERC20 Token Development Process Start-->
<section class="section-service-main">
  <div class="container">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="common-sub-heading">
        <p class="h-4">Our Process</p>
      </div>
      <h2 class="main-sub-heading text-start">
        ERC20 Token Development Process
      </h2>
    </div>
    <div class="process-development-image text-center">
      <picture *ngIf="!isDarkmode">
        <source type="image/webp"
          srcset="assets-new/images/erc/process-development.webp">
        <source type="image/png"
          srcset="assets-new/images/erc/process-development.png">
        <img loading="lazy"
          data-src="assets-new/images/erc/process-development.png" width="1109"
          height="405" alt="ERC20 Token Development Process"
          title="ERC20 Token Development Process" class="img-fluid">
      </picture>
      <picture *ngIf="isDarkmode">
        <source type="image/webp"
          srcset="assets-new/images/erc/light-theme/process-development.webp">
        <source type="image/png"
          srcset="assets-new/images/erc/light-theme/process-development.png">
        <img loading="lazy"
          data-src="assets-new/images/erc/light-theme/process-development.png" width="1109"
          height="406" alt="ERC20 Token Development Process"
          title="ERC20 Token Development Process" class="img-fluid">
      </picture>
    </div>
  </div>
</section>
<!-- End-->

<!-- Different Standard -->
<div class="section-service-alt-padd">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading">
          <h2>
            <span>Ethereum Token Development</span> on Different Standard
          </h2>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
                width="75" height="75" alt="ERC20" title="ERC20"
                class="img-fluid">
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">ERC20</div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="ERC721" title="ERC721"
                class="img-fluid">
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">ERC721</div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="ERC725" title="ERC725"
                class="img-fluid">
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">
              ERC725
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="ERC1400" title="ERC1400"
                class="img-fluid">
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">
              ERC1400
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="ERC777" title="ERC777"
                class="img-fluid">
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">ERC777</div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="ERC1155" title="ERC1155"
                class="img-fluid">
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">ERC1155</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Different Standard -->
<div class="section-service-alt-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading">
          <h3> Functions of our
            <span>ERC20 Token </span> Creation
          </h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="Multi Wallet Compatibility" title="Multi Wallet Compatibility"
                class="img-fluid">
            </picture>
            <div class="erc-20-head-alt h-4">Multi Wallet Compatibility​</div>
            <p class="text-center">Most of the crypto wallets and crypto exchanges accept ERC-20 tokens thereby easy to access. </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="Token Minting" title="Token Minting"
                class="img-fluid">
            </picture>
            <div class="erc-20-head-alt h-4">Token Minting​</div>
            <p class="text-center">Without any consensus-related activities, ERC-20 tokens can be created on the Ethereum blockchain.</p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="Token Burning" title="Token Burning"
                class="img-fluid">
            </picture>
            <div class="erc-20-head-alt h-4">
              ​Token Burning
            </div>
            <p class="text-center">Control inflation of your ERC20 tokens by sending them to zero wallets with burn functionality.</p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="Advanced Access Control" title="Advanced Access Control"
                class="img-fluid">
            </picture>
            <div class="erc-20-head-alt h-4">
              ​Advanced Access Control
            </div>
            <p class="text-center">Create ERC 20 token that provides complete ownership and access control to the creator, making them very flexible.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="Verified Source Code" title="Verified Source Code"
                class="img-fluid">
            </picture>
            <div class="erc-20-head-alt h-4">
              Verified Source Code
            </div>
            <p class="text-center">We verify your smart contract code on Ethereum to maintain maximum transparency and decentralization.</p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail align-items-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/eth-1.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/eth-1.png"
              width="75" height="75" alt="Ether Recovery" title="Ether Recovery"
                class="img-fluid">
            </picture>
            <div class="erc-20-head-alt  h-4">
              Ether Recovery
            </div>
            <p class="text-center">Our ERC20 token smart contracts are designed to be built in with Ether recovery functionality, to prevent someone from sending it to the contract address.
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Features of Our ERC20 Token Development -->
<section class="section-service-alt">
  <div class="container">
    <div class="metaverse_heading mx-auto">
      <h3>Features of <span> ERC20 Token</span> Development</h3>
    </div>
    <div class="row acc-sec-list align-items-center">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
        <picture *ngIf="!isDarkmode">
          <source type="image/webp"
            srcset="assets-new/images/erc/our-unique-features.webp" />
          <source type="image/png"
            srcset="assets-new/images/erc/our-unique-features.png" />
          <img loading="lazy"
            data-src="assets-new/images/erc/our-unique-features.png"
            width="428" height="428" alt="erc20 token development"
            title="erc20 token development" class="img-fluid unique-image">
        </picture>
        <picture *ngIf="isDarkmode">
          <source type="image/webp"
            srcset="assets-new/images/erc/light-theme/our-unique-features.webp" />
          <source type="image/png"
            srcset="assets-new/images/erc/light-theme/our-unique-features.png" />
          <img loading="lazy"
            data-src="assets-new/images/erc/light-theme/our-unique-features.png"
            width="402" height="428" alt="erc20 token development"
            title="erc20 token development" class="img-fluid unique-image">
        </picture>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-list">
        <div class="accordion" id="accordionMain">
          <div class="accordion-item">
            <div class="accordion-header" id="headingOne">
              <h5
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
               Solidity Nature
              </h5>
            </div>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionMain">
              <div class="accordion-body">
                <p>
                  The ERC20 token generator here maintains a high-level smart contracts programming language.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingTwo">
              <h5
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo">
                Creating ERC20 Token
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionMain">
              <div class="accordion-body">
                <p>
                  We curate unique ERC20 tokens for unique Use cases customized in their name and symbols that stand out.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingThree">
              <h5
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree">
                Security Mechanism​
              </h5>
            </div>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionMain">
              <div class="accordion-body">
                <p>
                  We ensure security on user identification, intrusion detection, Virus protection, data, and information backup with the power of forefront technologies.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFour">
              <h5
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour">
                ​Smart Contract​
              </h5>
            </div>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionMain">
              <div class="accordion-body">
                <p>
                  ERC20 token development Services include curating mintable tokens with an algorithmic contract that compiles complicated code.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFive">
              <h5
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive">
                Atomic Swap
              </h5>
            </div>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionMain">
              <div class="accordion-body">
                <p>
                  Our Ethereum tokens are created with mintable qualities that can be swapped from one another.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingSix">
              <h5
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix">
                Supply Control
              </h5>
            </div>
            <div
              id="collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionMain">
              <div class="accordion-body">
                <p>
                  Having complete control over your ERC tokens, owners are allowed to burn and mint at once here.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<section class="section-service-main">
  <div class="container">
    <div class="metaverse_heading mx-auto">
      <h3>How to <span> Create and Deploy</span> an ERC20 Token?</h3>
    </div>
    <div class="row acc-sec-list align-items-center">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/erc/how-to-create-deploy-erc20-token.webp" />
          <img loading="lazy"
            data-src="assets-new/images/erc/how-to-create-deploy-erc20-token.png"
            width="428" height="428" alt="erc20 token development"
            title="erc20 token development" class="img-fluid unique-image">
        </picture>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-list">
        <div class="accordion" id="accordionMain">
          <div class="accordion-item">
            <div class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
                1. Set token development environment 
              </button>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo">
                2. Understanding the project structures
              </button>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree">
                3. Writing ERC20 token smart contract
              </button>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour">
                4. Test thoroughly on the testnet
              </button>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive">
                5. Deploy ERC20 token on mainnet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Types Of DAOs starts -->
<div class="section-service-alt">
  <div class="types-of-erc">
    <div class="container">
      <div class="row mb-3">
        <div class="metaverse_heading mx-auto">
          <h3><span>Benefits of ERC20 Token </span> Development Service</h3>
        </div>
      </div>
      <div class="row types-of-token-detail justify-content-center gy-5">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/cryptocurrency/crypto-consulting-services.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/cryptocurrency/crypto-consulting-services.png"
                   class="img-fluid"
                  alt="Crypto-consulting" title="Crypto-consulting" width="75"
                  height="75">
              </picture>

            </div>
            <div class="erc-20-head-alt text-left h-4">
              Fundraising
            </div>
            <p>
              Contributes to bringing new investment meeting project requirements.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/cryptocurrency/crypto-coin-development.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/cryptocurrency/crypto-coin-development.png"
                   class="img-fluid"
                  alt="Crypto-coin" title="Crypto-coin" width="75" height="75">
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">Staking</div>
            <p>
              A module that helps in breeding business by creating a demand for tokens.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/cryptocurrency/crypto-wallet-development.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/cryptocurrency/crypto-wallet-development.png"
                   class="img-fluid" alt="Crypto-wallet"
                  title="Crypto-wallet" width="75" height="75">
              </picture>

            </div>
            <div class="erc-20-head-alt text-left h-4">Utility</div>
            <p>
              Unlike tokens, injecting wider utility increases its value and need in the market.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/cryptocurrency/crypto-exchange-development.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/cryptocurrency/crypto-exchange-development.png"
                   class="img-fluid" alt="Crypto-exchange"
                  title="Crypto-exchange" width="75" height="75">
              </picture>
            </div>

            <div class="erc-20-head-alt text-left h-4">
              Cost-effective​​
            </div>
            <p>
              ERC20 token generator is an effective way to meet your requirements.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/cryptocurrency/altcoin-development-service.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/cryptocurrency/altcoin-development-service.png"
                  class="img-fluid" alt="Altcoin-development"
                  title="Altcoin-development"  width="75"
                  height="75">
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">
              ​Simple coding
            </div>
            <p>
              Bid bye to complicated coding that complicates functions, We opt for simplicity.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/cryptocurrency/ico-development.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/cryptocurrency/ico-development.png"
                   class="img-fluid" alt="ICO-development"
                  title="ICO-development" width="75" height="75">
              </picture>
            </div>
            <div class="erc-20-head-alt text-left h-4">​Less time consumption</div>
            <p>
              Simplified solutions, predefined targets, and strategies, cutting down labor time.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/cryptocurrency/defi-development.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/cryptocurrency/defi-development.png"
                   class="img-fluid" alt="Crypto-mining"
                  title="Crypto-mining" width="75" height="75">
              </picture>
            </div>

            <div class="erc-20-head-alt text-left h-4">​Secured with smart contract​​</div>
            <p>
              Secured smart contracts to create your ERC 20 token, we induce power and efficiency.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/cryptocurrency/crypto-listing-services.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/cryptocurrency/crypto-listing-services.png"
                   class="img-fluid" alt="Crypto-listing"
                  title="Crypto-listing" width="75" height="75">
              </picture>
            </div>

            <div class="erc-20-head-alt text-left h-4">Automatic transaction​</div>
            <p>
              Human human interventions, Transaction happens automatically on validator’s approval.
            </p>
        </div> 
      </div>
    </div>
     </div>
  </div>  
</div>  
<!-- Types Of DAOs ends -->

<!-- Trending ERC20 Token List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center mb-lg-0 mb-md-4 mb-0">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <img loading="lazy"
              data-src="assets-new/images/erc/trending-erc20-token-list.png"
              width="405" height="405" alt="Trending ERC20 Token List"
              title="Trending ERC20 Token List" class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6">
          <h3 class="main-sub-heading text-start"><span>Create ERC20 Token</span> Like These Trending Tokens </h3>

          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
              <ul>

                <li class="d-flex">
                  <span><i class="bi bi-hexagon-fill"></i></span><div class="pb-0 h-5">Chainlink​ (LINK)</div> 
                </li>
              
                <li class="d-flex">
                  <span><i class="bi bi-hexagon-fill"></i></span><div class="pb-0 h-5">Shibu inu (SHIB)</div> 
                </li>
               <li class="d-flex">
                  <span><i class="bi bi-hexagon-fill"></i></span><div class="pb-0 h-5">​USD Coin (USDC)</div> 
                </li>
                <li class="d-flex">
                  <span><i class="bi bi-hexagon-fill"></i></span><div class="pb-0 h-5">​Uniswap​ (UNI)</div> 
                </li>
                <li class="d-flex">
                  <span><i class="bi bi-hexagon-fill"></i></span><div class="pb-0 h-5">​BNB​</div> 
                </li>

              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
              <ul>
                <li class="d-flex">
                  <span><i class="bi bi-hexagon-fill"></i></span><div class="pb-0 h-5">Tether​ USD (USDT)</div>
                </li>
                <li class="d-flex">
                  <span><i class="bi bi-hexagon-fill"></i></span><div class="pb-0 h-5">​Tron</div>
                </li> 
                <li class="d-flex">
                  <span><i class="bi bi-hexagon-fill"></i></span><div class="pb-0 h-5">​Eternity</div> 
                </li>

                <li class="d-flex">
                  <span><i class="bi bi-hexagon-fill"></i></span><div class="pb-0 h-5">Wrapped Ethereum (WETH)</div> 
                </li>
            </ul>
          </div>
        </div>  
        </div>
      </div>
    </div>
  </div>
</div>        
<!-- End -->

<!-- New-Token-Generator iframe start-->
<section class="section-service-alt" #about>
  <div class="container">
    <div class="row text-center h-100 new-token-generator">
      <iframe src="https://devuser.blockchainx.finance/new-token-generator"
        frameborder="0" width="100%" height="1220" title="Create Your Token"></iframe>
    </div>
  </div>
</section>
<!-- End -->

<section class="section-service-main">
  <div class="container">
    <div class="metaverse_heading">
      <h2>Create <span> ERC20 Token </span> Generator Along With These Services</h2>
    </div>

    <div class="nft-development-slider">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">ERC20 Token Minting and Burning</h4>
            <p>
              We assist and guide you through the token listing process in other Apps that contribute to scaling.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">ICO Development</h4>
            <p>
              Avant-grade ERC20 token development that ensures it meets its full purpose on DeFi and Decentralised Exchanges.
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">Crypto Wallet Development</h4>
            <p>
              Convenient, easy-to-use, and highly standardized, secured token wallet integration services are also rendered here.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Ethereum Token Development Products Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="metaverse_heading">
      <h3>Products With <span>ERC20 Token </span>Generator Development</h3>
    </div>

    <div class="nft-development-slider">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">ERC20 Token Migrations</h4>
            <p>
              Convert tokens effortlessly from one blockchain network to another. Check our working demo!
            </p>
            <div class="item-header">
              <a
                href="https://www.blockchainx.tech/token-migration"
                target="_blank"
                rel="noopener noreferrer">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/erc/erc20-token-migration.webp">
                  <img loading="lazy"
                    data-src="assets-new/images/erc/erc20-token-migration.png"
                    width="579" height="341" alt="erc20-token-migration"
                    title="erc20-token-migration" class="img-fluid">
                </picture>
              </a>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">ERC20 ICO Token Sale</h4>
            <p>
              Take your token to a wider audience and grab the attention for sales and investment. Watch our ICO Token sale demo here.
            </p>
            <div class="item-header">
              <a
                href="https://www.blockchainx.tech/ready-made-ico-software"
                target="_blank"
                rel="noopener noreferrer">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/erc/erc20-ico-token-sale.webp" />
                  <img loading="lazy"
                    data-src="assets-new/images/erc/erc20-ico-token-sale.png"
                    width="579" height="341" alt="erc20-ico-token-sale"
                    title="erc20-ico-token-sale" class="img-fluid">
                </picture>
              </a>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">
              ERC20 Token Wallet Development
            </h4>
            <p>
              A secured, safe, and easily accessible wallet to store and transact with your token. Check if it's working here.
            </p>
            <div class="item-header">
              <a
                href=" https://www.blockchainx.tech/cryptocurrency-wallet-development"
                target="_blank"
                rel="noopener noreferrer">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/erc/erc20-token-wallet.webp">
                  <img loading="lazy"
                    data-src="assets-new/images/erc/erc20-token-wallet.png"
                    width="579" height="341" alt="erc20-token-wallet"
                    title="erc20-token-wallet" class="img-fluid">
                </picture>
              </a>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-12 nft-sl-list">
          <div class="nft_devlopment_market">
            <h4 class="common-sub-heading-alt">ERC20 Bridge Development</h4>
            <p>
              Connect seamlessly between two or more networks with ERC20 Bridge Development. Watch it working!
            </p>
            <div class="item-header">
              <a
                href="https://www.blockchainx.tech/bridge-smart-contract-development"
                target="_blank"
                rel="noopener noreferrer">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/erc/erc20-bridge-development.webp">
                  <img loading="lazy"
                    data-src="assets-new/images/erc/erc20-bridge-development.png"
                    width="579" height="341" alt="erc20-bridge-development"
                    title="erc20-bridge-development" class="img-fluid">
                </picture>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<section class="section-service-main erc-teck-stack">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 metaverse_heading mx-auto">
        <h3><span>Ethereum Tech Stacks</span> To Integrate While Creating ERC20 Token</h3>
      </div>
    </div>
    <ul
      class="nav nav-tabs align-items-center justify-content-evenly"
      id="myTab">
      <li class="nav-item">
        <a href="#languages" class="nav-link active"
          data-bs-toggle="tab">Programming Languages</a>
      </li>
      <li class="nav-item">
        <a href="#wallets" class="nav-link" data-bs-toggle="tab">Wallets</a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane fade show active" id="languages">
        <div class="row align-items-center justify-content-center technology-stock">
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/webp/truffle.webp" />
              <source type="image/svg"
                srcset="assets-new/images/erc/truffle.svg" />
              <img loading="lazy"  data-src="assets-new/images/erc/truffle.svg"
                width="256" height="253" alt="truffle" title="truffle"
                class="img-fluid">
            </picture>

          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/webp/java.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/java.jpg"
                width="80" height="80" alt="java" title="java"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/nodejs.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/nodejs.png"
                width="202" height="60" alt="Node JS" title="Node JS"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/angular.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/angular.png"
              width="202" height="60" alt="angular" title="angular"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/reactjs.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/reactjs.png"
                width="201" height="75" alt="React JS" title="angular"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/mongodb.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/mongodb.png"
                width="238" height="71" alt="Mongodb" title="Mongodb"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/webp/flutter.webp" />
              <img loading="lazy"  data-src="assets-new/images/erc/flutter.svg"
                width="82" height="101" alt="flutter" title="flutter"
                class="img-fluid">
            </picture>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="wallets">
        <div class="row align-items-center justify-content-center technology-stock">
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/metamask.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/metamask.png" />
              <img loading="lazy"  data-src="assets-new/images/erc/metamask.png"
                width="216" height="64" alt="metamask" title="metamask"
                class="img-fluid">
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/light-theme/metamask.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/light-theme/metamask.png" />
              <img loading="lazy"  data-src="assets-new/images/erc/light-theme/metamask.png"
                width="216" height="64" alt="metamask" title="metamask"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/wallet-connect.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/wallet-connect.png" />
              <img loading="lazy"
                data-src="assets-new/images/erc/wallet-connect.png" width="238"
                height="71" alt="wallet connect" title="wallet connect"
                class="img-fluid">
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/light-theme/wallet-connect.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/light-theme/wallet-connect.png" />
              <img loading="lazy"
                data-src="assets-new/images/erc/light-theme/wallet-connect.png" width="238"
                height="71" alt="wallet connect" title="wallet connect"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/ledger.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/ledger.png" />
              <img loading="lazy"  data-src="assets-new/images/erc/ledger.png"
                width="218" height="65" alt="ledger" title="ledger"
                class="img-fluid">
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/light-theme/ledger.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/light-theme/ledger.png" />
              <img loading="lazy"  data-src="assets-new/images/erc/light-theme/ledger.png"
                width="218" height="65" alt="ledger" title="ledger"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/trust-wallet.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/trust-wallet.png" />
              <img loading="lazy"
                data-src="assets-new/images/erc/trust-wallet.png" width="238"
                height="77" alt="trust wallet" title="trust wallet"
                class="img-fluid">
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/light-theme/trust-wallet.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/light-theme/trust-wallet.png" />
              <img loading="lazy"
                data-src="assets-new/images/erc/light-theme/trust-wallet.png" width="238"
                height="77" alt="trust wallet" title="trust wallet"
                class="img-fluid">
            </picture>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-6 text-center process-image">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/webp/coinbase.webp" />
              <source type="image/svg"
                srcset="assets-new/images/erc/coinbase.svg" />
              <img loading="lazy"  data-src="assets-new/images/erc/coinbase.svg"
                width="150" height="75" alt="coinbase" title="coinbase"
                class="img-fluid">
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/erc/light-theme/coinbase.webp" />
              <source type="image/svg"
                srcset="assets-new/images/erc/light-theme/coinbase.svg" />
              <img loading="lazy"  data-src="assets-new/images/erc/light-theme/coinbase.svg"
                width="150" height="75" alt="coinbase" title="coinbase"
                class="img-fluid">
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- why-choose-section -->
<section class="why_choose_us">
  <div class="why-alt-section light-background">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container choosing-section">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-3">
            <h2>
              Why Choose BlockchainX for <span>ERC20 Token Development?</span>
            </h2>
            <p>
              BlockchainX is a pioneer in web3 development, we have hands-on experience on different projects and made successful token development launches for the versatile sector. Our highlighting ability that stands out with developing ERC20 Token generator is,
            </p>
          </div>
          <div class="whysection_text_wrap">
            <ul>
              <li>Experts in creating crypto tokens in various blockchains</li>
              <li>6 Years of experience in token development service</li>
              <li>Our developers are skilled, equipped, and at the same time open to learning</li>
              <li>Provide crypto exchange provide ERC20 tokens with the highest quality standard</li>
            </ul>

            <div class="content-link my-3">
              <a [routerLink]="['/contact']">
                <span>Get In Touch</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/metaverse/why_blockchain.webp" />

              <img loading="lazy"
                data-src="assets-new/images/metaverse/why_blockchain.png"
                width="550"
                height="450"
                class="img-fluid"
                alt="why-blockchainX"
                title="why-blockchainX"
                 />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Section -->
<section class="section-service-main">
  <div class="container">
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingFirst">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFirst"
                  aria-expanded="true"
                  aria-controls="collapseFirst">
                  What is the erc20 token?
                </button>
              </div>
              <div
                id="collapseFirst"
                class="accordion-collapse collapse show"
                aria-labelledby="headingFirst"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    ERC20 is a token standard on the Ethereum network that allows creation  and sell in the network. They have pre-defined use cases and values. They provide ownership rights to token holders.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingSecond">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSecond"
                  aria-expanded="false"
                  aria-controls="collapseSecond">
                  How do you create an Ethereum ERC20 Token?
                </button>
              </div>
              <div
                id="collapseSecond"
                class="accordion-collapse collapse"
                aria-labelledby="headingSecond"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Determine your requirement and token functionalities, either a token generator or seek the help of experienced token developers like BlockchainX to get into the process and live your ERC20 tokens in the market.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThird">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThird"
                  aria-expanded="false"
                  aria-controls="collapseThird">
                  What is the time frame to develop an ERC20 token?
                </button>
              </div>
              <div
                id="collapseThird"
                class="accordion-collapse collapse"
                aria-labelledby="headingThird"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The time of ERC20 token development generally fluctuates from days to months based on your requirements, functionalities, and advanced use case that it has to carry for itself.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
          <div class="accordion-header" id="headingFourth">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFourth"
              aria-expanded="false"
              aria-controls="collapseFourth"
            >
            What are some use cases of Ethereum tokens?
            </button>
          </div>
          <div
            id="collapseFourth"
            class="accordion-collapse collapse"
            aria-labelledby="headingFourth"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                The Ethereum tokens are used in financial transactions between contracts, there are utility tokens with voting opportunities, participate in DeFi, create NFTs, used for fundraising through Initial coin offering (ICO) or Standard Token Offering (STO).
              </p>
            </div>
          </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFifth">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFifth"
                  aria-expanded="false"
                  aria-controls="collapseFifth">
                  What Are The Popular Six Fundamental Functions In Our ERC20 Token Development?
                </button>
              </div>
              <div
                id="collapseFifth"
                class="accordion-collapse collapse"
                aria-labelledby="headingFifth"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The fundamental functions of ERC20 token development is denoting total supply, balance of stock, transferring tokens from one account to another, exchanging for equal value tokens, and more.
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="showFaq" class="faq-section">
            <div class="accordion-item">
              <div class="accordion-header" id="headingSixth">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSixth"
                  aria-expanded="false"
                  aria-controls="collapseSixth">
                  How much does it cost to create an ERC20 Token?
                </button>
              </div>
              <div
                id="collapseSixth"
                class="accordion-collapse collapse"
                aria-labelledby="headingSixth"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The cost to create ERC20 Tokens is subjective, your requirements, advancements, and objectives define and determine the same.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingSeven">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven">
                  Where To Create Your ERC20 Token?
                </button>
              </div>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    You can create it with us at BlockchainX. We provide exclusively customized solutions that are efficient and contribute towards the progress of your project.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingEight">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight">
                  Where to get the best ERC20 Token Development Services?
                </button>
              </div>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    To Get the best ERC20 token development service, it's important to first have clarity around what your requirements are and the outcome you want out of it. From there you can analyze the ERC20 token development service providers to understand whose services sync with your requirements and choose your partner. At BlockchainX we provide customized solutions tailored for your Business, and to know more connect with us now. 
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingNine">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine">
                  What are the most popular ERC-20 tokens?
                </button>
              </div>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingNine"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Ethereum (ETH), Wrapped Bitcoin (WBTC), Chainlink (LINK), Tether (USDT), Shiba Inu (SHIB), Maker (MKR), OmiseGO (OMG), Uniswap (UNI), 0x (ZRX). 
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTen">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen">
                  Why should I create an ERC20 token?
                </button>
              </div>
              <div
                id="collapseTen"
                class="accordion-collapse collapse"
                aria-labelledby="headingTen"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    As the buzz over Ethereum is continuing to explore as there is betterment in technology, usability, and operations, choosing to develop an ERC20 token can enhance and explore your participation in the market, and eventually contribute towards growth and scalability. 
                  </p>
                </div>
              </div>
            </div>
           </div>
          </div>
          <div class="d-flex justify-content-center">
            <div (click)="toggleFaq()" class="faq-button">
              <span *ngIf="!showFaq" class="fs-3">
                <i class="bi bi-mouse"></i>
              </span>
              <span *ngIf="showFaq" class="fs-4">
                <i class="bi bi-caret-up-fill"></i> 
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- FAQ Section -->

<!-- Blog Start -->
<section class="section-service-alt">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <p class="h-3">Blog<span class="dots">.</span></p>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <p class="h-2 text-start">Our Latest Compilation On The Market</p>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
                src="assets-new/images/right-arrow.png"
                alt="link-arrow"
                class="img-fluid"
                width="50"
                height="15"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a [routerLink]="['/what-is-erc20']">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/blog/what-is-erc20/erc20-token-standard.webp
                    "
                  />
                  <img loading="lazy"

                    data-src="assets-new/images/blog/what-is-erc20/erc20-token-standard.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="erc20-token-standard"
                    title="erc20-token-standard"
                  />
                </picture>
                <div class="h-3">
                  What is ERC20? A Guide to the Pinnacles of Ethereum Token
                  Standard
                </div>
                <p>
                  While this crypto revolution continues to change the virtual
                  and the real world, the ERC20 token standards...
                </p>
              </a>
              <div class="content-link">
                <a [routerLink]="['/what-is-erc20']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                  '/erc1155-token-standard'
                ]"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/erc-1155.webp
                    "
                  />
                  <img loading="lazy"

                    data-src="assets-new/images/blog/erc-1155.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="erc-1155"
                    title="erc-1155"
                  />
                </picture>
                <div class="h-3">
                  ERC1155 – Explore the ERC-1155 Token Standard
                </div>
                <p>
                  If you understand what an non-fungible-token-development NFT is and that the abbreviation of "Non-fungible token" no longer confuses...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                    '/erc1155-token-standard'
                  ]"
                >
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                  '/how-to-create-a-private-blockchain'
                ]"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.webp
                    "
                  />
                  <img loading="lazy"

                    data-src="assets-new/images/blog/how-to-create-a-private-blockchain/private-blockchain-development.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="private-blockchain-development"
                    title="private-blockchain-development"
                  />
                </picture>
                <div class="h-3">
                  How to Create a Private Blockchain For Intensify Business Security?
                </div>
                <p>
                  With blockchain dominating the entire digital ecosystem, and finance and technology taking over the world, digital investments...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                    '/how-to-create-a-private-blockchain'
                  ]"
                >
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                  src="assets-new/images/right-arrow.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="!isDarkmode" />
                <img loading="lazy"
                  src="assets-new/images/right-arrow-dark.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                  '/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad'
                ]"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                    assets-new/images/blog/crypto-launchpad-development-steps.webp
                    "
                  />
                  <img loading="lazy"

                    data-src="assets-new/images/blog/crypto-launchpad-development-steps.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="crypto-launchpad-development-steps"
                    title="crypto-launchpad-development-steps"
                  />
                </picture>
                <div class="h-3">
                  Crypto Launchpad Development: Steps to Take Ahead of Developing a Launchpad
                </div>
                <p>
                  Crypto launchpad development is a great opportunity to keep up and stay one step ahead in this booming crypto world...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                    '/crypto-launchpad-development-steps-to-take-ahead-of-developing-a-launchpad'
                  ]"
                >
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<section class="section-service-main" id="form">
<app-get-in-touch></app-get-in-touch>
</section>