import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.css'],
})
export class ContactformComponent implements OnInit {
  isDarkmode: Boolean=false;

  constructor(
    public router: Router,
    private footerservice: FooterServiceService,
  ) {
  }
  ngOnInit(): void {
    this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
  }
}


