<section class="section-service-main">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-10 mx-auto">
              <div class="whys_section mb-0 text-center">
                <h2 class="text-center pb-0 mb-3">
                  <span>BlockchainX Testimonials </span>
                </h2>
                <p>
                  Bringing our clients feedback to the world. It has always been our clients who have talked about our indigenous journey of cracking imposters, and brightening up with a lot of new possibilities through our services. Similar Hear us out from our clients themself sharing their experience working with us at BlockchainX for their versatile requirements.
                </p>
              </div>
            </div>
          </div>

          <div class="sec-about" #about>
            <div class="violet-vector"></div>
            <div class="container">
              <div class="row mb-5">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-first order-last">
                  <div>
                    <video
                      src="assets-new/video/blockchainx-sunwave.mp4"
                      controls
                      [autoplay]="false"
                      width="95%">
                      <track
                        kind="captions"
                        src="assets-new/video/blockchainx-sunwave.mp4"
                        srclang="en"
                        label="English" />
                    </video>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
                  <div class="whys_section mb-0">
                    <p class="pb-3">
                      I came to blockchain to take a whole new perspective to the Project business. I realized the real world had to explore with the blooming blockchain, where businesses get new opportunities to engage with their customers better and approach fundraising in a much more efficient manner. BlockchainX team guided me with the perfect solution from the start. Despite blockchain technology being  a whole new concept for me, my team BlockchainX has guided me throughout the process from the start. They explained transformative ways to accomplish in the efficient way forward, what we should expect from the eb3 project in terms of how the technology works.
                    </p>
                    <!-- <p class="name fst-italic fw-bold pb-1">Jasikaran</p>
                    <p class="designation pb-1">CEO</p>
                    <p class="company-name">SunWave Coin - Maximilian</p> -->
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
                  <div class="whys_section mb-0">
                    <p class="pb-3">
                      They delivered good service and great products, giving us the opportunity to use tokenization and engage with our community, building our Business and Brand. I'm very happy with their service, and would recommend them to anyone out there who wants to explore web3 as a business, while we have been also planning for another project with BlockchainX as our Technology Partner.
                    </p>
                    <p class="name fst-italic fw-bold pb-1">Maximilian</p>
                    <p class="designation pb-1">CEO</p>
                    <p class="company-name">SunWave Coin</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div>
                      <video
                        src="assets-new/video/blockchain-development-company.mp4"
                        controls
                        [autoplay]="false"
                        width="95%">
                        <track
                          kind="captions"
                          src="assets-new/video/blockchain-development-company.mp4"
                          srclang="en"
                          label="English" />
                      </video>
                    </div>
                  </div>
              </div>
              <!-- <div class="row mb-5">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-first order-last">
                  <div>
                    <video
                      src="assets-new/video/blockchain-development-company.mp4"
                      controls
                      [autoplay]="false"
                      width="95%">
                      <track
                        kind="captions"
                        src="assets-new/video/blockchain-development-company.mp4"
                        srclang="en"
                        label="English" />
                    </video>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
                  <div class="whys_section mb-0">
                    <p class="pb-3">
                      Providing end-to-end business-grade solutions, that help you level your game in the market. As a Blockchain development company, our services circle around,  developing Blockchain solutions, from scratch, ideating and market analysis of your product grades and building sustainable self-automated decentralized solutions based on blockchain.
                    </p>
                    <p class="name fst-italic fw-bold pb-1">Jasikaran</p>
                    <p class="designation pb-1">CEO</p>
                    <p class="company-name">DSFR</p>
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
                  <div class="whys_section mb-0">
                    <p class="pb-3">
                      Providing end-to-end business-grade solutions, that help you level your game in the market. As a Blockchain development company, our services circle around,  developing Blockchain solutions, from scratch, ideating and market analysis of your product grades and building sustainable self-automated decentralized solutions based on blockchain.
                    </p>
                    <p class="name fst-italic fw-bold pb-1">Jasikaran</p>
                    <p class="designation pb-1">CEO</p>
                    <p class="company-name">DSFR</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div>
                      <video
                        src="assets-new/video/blockchain-development-company.mp4"
                        controls
                        [autoplay]="false"
                        width="95%">
                        <track
                          kind="captions"
                          src="assets-new/video/blockchain-development-company.mp4"
                          srclang="en"
                          label="English" />
                      </video>
                    </div>
                  </div>
              </div> -->
              <div class="row">
                <div class="col-12">
                    <div class="token-vs-btn text-center">
                        <a href="https://wa.me/7708889555" class="our_expert_talk d-inline-block"
                          target="_blank"
                          rel="noopener noreferrer">TASK WITH OUR EXPERTS</a>
                      </div>
                </div>  
              </div>
            </div>
          </div>
    </div>
</section>