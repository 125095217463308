import {
  Component,
  OnInit
} from '@angular/core';
import {
  Title,
  Meta
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-automated-market-maker',
  templateUrl: './automated-market-maker.component.html',
  styleUrls: ['./automated-market-maker.component.css']
})
export class AutomatedMarketMakerComponent implements OnInit {

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService
  ) {}


  isDarkmode:boolean=false;
  title = 'Automated Market Maker Development Company - BlockchainX';

  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'We help startups build custom automated market maker solutions rapidly. Hire an expert automated market maker development company.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'automated market maker,automated market maker solutions,automated market maker services,amm development,amm development company',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Automated Market Maker Development Company - BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/automated-market-maker-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'We help startups build custom automated market maker solutions rapidly. Hire an expert automated market maker development company.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/automated-market-maker.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/automated-market-maker-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Automated Market Maker Development Company - BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'We help startups build custom automated market maker solutions rapidly. Hire an expert automated market maker development company.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/automated-market-maker.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/automated-market-maker-development/'
    );
  }

  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };

}
