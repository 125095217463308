<section class="blog-detail">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 mb-4">
          <div class="blog-single-wrap">
            <picture>
              <source type="image/webp" srcset="assets-new/images/blog/create-your-own-crypto-token/create-your-own-crypto-token-banner.webp">
              <img loading="lazy" src="assets-new/images/blog/create-your-own-crypto-token/create-your-own-crypto-token-banner.jpg" alt="8 Steps to Create Your Own Crypto Token: A Comprehensive Guide" title="8 Steps to Create Your Own Crypto Token: A Comprehensive Guide" class="img-fluid" width="856" height="522"/>
            </picture>
            <h1>8 Steps to Create Your Own Crypto Token: A Comprehensive Guide</h1>

            <div class="h4">
              Listen to the Audio :
            </div>
            <div class="w-100 py-2">
              <audio controls >
                  <source src="assets-new/images/blog/create-your-own-crypto-token/create-your-own-crypto-token.mp3" type="audio/mpeg">
              </audio>
            </div>

            <p>Over the decade, the popularity and strength of cryptocurrencies have increased a lot, challenging the conventional ways of making payments. It is changing the way we make financial transactions. Due to this growing interest in cryptocurrencies, many companies and individuals want to create their own crypto tokens. Yes, there are various platforms available, like Ethereum and Binance Smart Chain, which help you create your own crypto token.</p>
            <p>This crypto token development guide will teach you to learn the essential steps to create your own token. It also helps you understand everything; hence, whether you want to hire expert <a href="https://www.blockchainx.tech/token-development-company/" target="_blank">crypto token development services</a> or create your own crypto token, this is the place to be. Be it fundraising for a new project, rewarding the users of a digital platform, or representing real-world assets on the blockchain, then you can create your own token. From choosing the right blockchain to promoting your token, we will help you create your own crypto token.</p> 
 
            <h2 id="item-1" [ngClass]="selectedLink===1?'scrollHeading':'scrollheadingone'">What are Crypto Tokens?</h2>
            <p>A crypto token is a digital asset that works on an already established cryptocurrency blockchain network, like Ethereum, Binance Smart Chain, or Solana. While tokens and cryptocurrencies share many things, native cryptocurrencies like Ether or Bitcoin function as virtual currencies and are an inherent digital asset of the blockchain. Tokens can be used even for representing ownership in tangible items, such as real estate or art. By creating your crypto token, you are working on the implementation of a smart contract on blockchain, specifying everything that can be done with the token: how it is transferred, managed, and interacts in general. Such tokens would be used for rewarding players in-game, or giving rights to vote in case of some community decision, among others, even being traded as digital assets. One of the best ways of interacting within the digital and decentralized space is to create your own crypto token, be it for a personal project, a business, or a DApp.</p>

            <h2 id="item-2" [ngClass]="selectedLink===2?'scrollHeading':'scrollheadingone'">How does a Crypto Token work? </h2>
            <p>The word "crypto" is the term for cryptography; it defines various cryptographic techniques that secure cash and digital money by means of elliptic curve encryption, public-private key pairs, and hashing algorithms. The underlying theory of cryptocurrency tokens can be explained as follows:</p>

            <h5 class="h4">Origin :</h5>
            <p>The different cryptocurrencies on a blockchain are the result of a token developed based on the existing structure of that blockchain. For instance, developers on Ethereum use ERC-20 protocols to create tokens. The rules describe the way tokens work on the network, including the process by which owners of tokens send them to other users.</p>

            <h5 class="h4">Smart Contracts :</h5>
            <p>The backbone behind crypto tokens is smart contracts. Issuance of a token means executing a smart contract, which defines the nature of a token in terms of total supply, transfer rules, and extra functionality. Once the smart contract goes on-chain, it starts living its life: it will start swapping tokens by itself according to the logic set inside.</p>

            <h5 class="h4">Transactions :</h5>
            <p>Every time a user sends or receives tokens, his transaction immediately goes to the blockchain. A smart contract updates the ledger to let tokens move directly among the users without any intermediary. Every transaction is guaranteed safe, open, and unalterable once documented.</p>

            <h5 class="h4">Utility :</h5>
            <p>With regard to their creation, tokens can serve various functions. For instance, tokens may serve as a means of payment on a network, a right to vote in a decentralized organization, and even as a store of particular assets such as property or digital art.</p>

            <h5 class="h4">Safety and authentication :</h5>
            <p>Tokens are secure through public-private key encryption. Therefore, users can manage and transfer tokens with safety, which doesn't allow any illegal access to the tokens. The consensus mechanism of the blockchain verifies each transaction to make the ledger correct and secure.</p>  
  
            <h2 id="item-3" [ngClass]="selectedLink===3?'scrollHeading':'scrollheadingone'">Difference between Cryptocurrency and a Token</h2>
            <p>While cryptocurrency and tokens exist on blockchains, their purposes are different. Cryptocurrencies, such as Bitcoin and Ethereum, are a form of digital money that is used to make purchases or store value. They run on independent blockchains but are designed primarily for transactional purposes and value storage. Tokens come in versions created on the blockchain. The tokens themselves do not have a blockchain but use the structure of another blockchain. They can represent access, ownership, membership, participation, or other forms of contribution to a particular project. For example, tokens may serve to vote on some decentralized platform or to unlock certain features in an application. In other words, though cryptocurrencies are some form of digital money, tokens can show one's interaction with various services and assets on the blockchain.</p>
  
            <h2 id="item-4" [ngClass]="selectedLink===4?'scrollHeading':'scrollheadingone'">Types of Crypto Token</h2>
            <p>If you are planning to create your own crypto token, make sure you understand the available types of tokens out there. Each serves a different purpose, and knowing those differences will help you choose the right one for your project. For simplicity, here's segmentation into various types:</p>
             <picture>
              <source type="image/webp" srcset="assets-new/images/blog/create-your-own-crypto-token/types-of-crypto-token.webp">
              <source type="image/jpg" srcset="assets-new/images/blog/create-your-own-crypto-token/types-of-crypto-token.jpg">
              <img loading="lazy" src="assets-new/images/blog/create-your-own-crypto-token/types-of-crypto-token.jpg" alt="Types of Crypto Token" title="Types of Crypto Token" class="img-fluid" width="856" height="743"/>
            </picture>

            <h4>Utility Tokens</h4>
            <p>They represent a particular type of utility token, meaning they are a means whereby users are granted access to some particular service or feature on some particular platform. Generally speaking, such tokens are going to be utilized on various DApps for the purpose of making a payment by means of given services or taking part in certain forms of activities of the platform. In this respect, tokens of some sort provide rights to use particular premium features, to vote for some important changes on this or that platform, etc.</p>

            <h4>Security Tokens</h4>
            <p>Security tokens are representative of the ownership of some tangible asset: the stock of a company, real estate, or any other form of physical property. Like all other securities, they are controlled under the regulations of a governing body, but they also provide avenues of investment capability into physical, tangible assets on the blockchain. Some of the benefits that one would derive from them are dividends or voting rights associated with the given asset.</p>

            <h4>Payment Tokens</h4>
            <p>Payment tokens are digital currencies that are applied to make transactions. Otherwise, they work just like any other form of money, with the only addition being that their operations are on a blockchain. This allows one to pay for goods, conduct payments, and even store value safely and efficiently.</p>

            <h4>Governance Tokens</h4>
            <p>Governance tokens provide the holders with voting powers for proposals over any decentralized platform. It is a system whereby users are allowed to contribute to the direction in which something moves a project or service-having one's say in the future. That may mean voting over new features coming onto the platform.</p>

            <h4>Stablecoins</h4>
            <p>Stablecoins, by design, are pegged to an asset, normally the US dollar, to keep its value stable. Compared to other cryptocurrencies, these tend to be more stable; hence, they are useful in trading and other transactions where stability is a concern. These help avoid the price swings common with other digital currencies.</p>

            <h4>Non-Fungible Tokens</h4>
            <p>By definition, NFTs cannot be interchanged for another token on a one-on-one basis since they are unique digital tokens. Each one of them is distinct, and that is why they are perfectly fitting for things such as digital art or collectibles. They represent the ownership of some sort of one-of-a-kind digital asset that can be traded.</p>

            <h4>Asset-Backed Tokens</h4>
            <p>Asset-backed tokens: These tokens are pegged to a particular asset, like real estate or commodities. They enable the trading of tangible assets or investing in them across blockchains. They digitalize ownership and the management of physical goods.</p>

            <h4>Reward Tokens</h4>
            <p>In short, reward tokens are a form of incentive for users' participation or contribution to a particular platform and, for the most part, can be earned through activities like staking and providing liquidity. Overall, they have been put in place for incentives that create engagement and loyalty in projects or services.</p>

            <h4>Exchange Tokens</h4>
            <p>The exchange tokens are those issued by the cryptocurrency exchanges themselves and entail innumerable benefits for the users of exchanges. The benefits could be in many forms from reduced trading fees, access to exclusive features, and attending events amongst many others. In fact, they enrich the trading experience on an exchange platform.</p>
 

            <h2 id="item-5" [ngClass]="selectedLink===5?'scrollHeading':'scrollheadingone'">8 Steps to create your own crypto token</h2>
            <p>Create your own crypto token that can help you raise funds, incentivize users, virtualize assets such as real estate, and more. The following is a step-by-step guide to teaching you how to easily create your own crypto token.</p>

            <picture>
              <source type="image/webp" srcset="assets-new/images/blog/create-your-own-crypto-token/steps-to-create-token.webp">
              <img loading="lazy" src="assets-new/images/blog/create-your-own-crypto-token/steps-to-create-token.jpg" alt="Steps to create your own crypto token" title="Steps to create your own crypto token" class="img-fluid" width="840" height="613"/>
          </picture>
           
            <h4>1. Identify Your Token's Use Case</h4>
            <p>The very first thing to do when creating one's own crypto token is to understand the reason behind the crypto token development. This is important because this is going to guide many of the decisions downstream. For example, if it aims at creating one's own token for fundraising, then it would need to focus on things like making the token appealing to investors. If the token is about incentivizing users of a certain platform, then its distribution and utility within that platform take precedence over anything else. Defining the purpose of your token will basically tell you how you are going to conceptualize, build, and promote it.</p>

            <h4>2. Choose the Correct Blockchain Platform</h4>
            <p>After identifying your purpose, the next thing you need to know is which blockchain your token is going to utilize. Top names include Ethereum, Binance Smart Chain, and Solana. Ethereum will probably always be the favorable choice, given its strong ecosystem, while Binance Smart Chain will allow going with relatively cheaper fees, suitable for projects with moderate budgets. Meanwhile, Solana offers speed and an affordable cost-a good option to consider by one's self. The kind of blockchain that is to be employed will, in turn, have both functionality and cost implications, so you have to carefully choose the one to suit your needs.</p>
            
            <h4>3. Token Creation</h4>
            <p>Crypto token development is absolutely not a complex process. There are quite a number of user-friendly utilities to create your own crypto token without writing code. For instance, templates on Ethereum will enable you to specify key attributes of your token, such as but not limited to name, symbol, and total supply. Besides, if you are going to do something for the first time and are not sure if you are capable of doing it, then you can always address crypto token development services. These services will handle the technical side for you and ensure that your token is appropriately and securely created.</p>

            <h4>4. Test Your Token Before Launch</h4>
            <p>Testing is one thing that is of paramount importance prior to your token going live. Testing your token in a test network dummy blockchain provides you with an opportunity to see how things work without real assets being at risk. This will help you identify bugs or any other unexpected behavior and fix them before your token goes live. As a matter of fact, deep testing of your token will ensure that the expected performance, whether through transactions or rewards among others, is achieved.</p>

            <h4>5. Launch Your Token</h4>
            <p>Once the testing is complete, you can go ahead and deploy your token on the blockchain. Once again, deployment means paying a fee, also known as a gas fee, the moment your token becomes part of that particular blockchain network depending on your transactions, reward purposes, or any such intended functions. At that particular moment in time, your token has gone live and is ready to use milestone indeed.</p>

            <h4>6. Create and Distribute Your Tokens</h4>
            <p>When your token has gone live, distribution shall be the next step. The goals of your project will determine what kind of distribution model shall work for your tokens. If they are for fundraising, then sell them through an Initial Coin Offering. This means if they are rewards; it falls to you to choose the avenue and frequency of issuance to your users. Proper distribution makes it a guarantee that your token will be used effectively and reach the right people.</p>

            <h4>7. Market Your Token</h4>
            <p>If you want to enjoy the much-needed success, you have to market your token. You will need to ensure that people know about your token and the value it comes with. You can create a community on social media that follows you, helping spread the word about your project. You could also create partnerships with other projects or list your token on exchanges, thanks to which your token will be in a position to attract both users and investors to your project.</p>

            <h4>8. Maintenance and Updates</h4>
            <p>When it is launched, that is only just the very beginning. You have to take care of your token and release the versions while on the move. The digital world can be fast-changing; you might rewrite some of the features or bugs. That way, if you are engaged and your token is up to date, then it is a guarantee of long-term success.</p>

            <p>If you want to create your own crypto token and are sure about it, then here is a simple guide on token development you can follow. Be it managing the process yourself or looking for crypto token development services; these steps will take you through it all, from start to finish.</p>
  
            <h2 id="item-6" [ngClass]="selectedLink===6?'scrollHeading':'scrollheadingone'">Importance of Crypto Token Development</h2>
            <p>The crypto token development has grown into an important milestone for the digital economy and is of great importance in several aspects. Besides creating new opportunities, knowing the value of crypto tokens will be an important way to engage with digital assets as an entrepreneur, developer, or investor.</p>

            <picture>
              <source type="image/webp" srcset="assets-new/images/blog/create-your-own-crypto-token/importance-of-crypto-token.webp">
              <img loading="lazy" src="assets-new/images/blog/create-your-own-crypto-token/importance-of-crypto-token.jpg" alt="Importance of Crypto Token Development" title="Importance of Crypto Token Development" class="img-fluid" width="856" height="743"/>
            </picture>

            <h4>1. Fundraising Opportunities</h4>
            <p>One of the major purposes for which crypto tokens were developed was to raise funds for some project. Like other, more traditional finances, tokens can be sold to investors in return for capital. This method of raising funds is more commonly referred to as an Initial Coin Offering or a token sale, where such projects need not depend on more traditional methods of investment. Creating your own token is a great method for startups and businesses to raise capital and rally early supporters.</p>

            <h4>2. Entrance to DeFi</h4>
            <p>Crypto tokens are at the heart of what is probably today's hottest-growing finance sector, generally referred to as decentralized finance. Most projects in the DeFi space use tokens for lending, providing liquidity, and taking loans without approaching a bank. You can participate in and have a share in this area of growing finance by developing your own token. For one, such a token can be used to finance some peer-to-peer transactions and build a decentralized exchange in which to have open, transparent access to a given financial service.</p>

            <h4>3. Incentivizing and rewarding of users</h4>
            <p>Generally speaking, the token can be issued for incentivizing reasons within a network or community. A token could be given as a reward for any activities participated in, projects contributed to, or even doing some sort of work. Instead, it develops an effective mechanism of engagement and serves as a way of keeping users active by stimulating them to act more. Having one's own token, one can make any form of reward model to encourage users' behavior relevant to the project and retain an active community of users.</p>

            <h4>4. Ownership and Governance</h4>
            <p>The tokens can represent ownership or a vote in something, anything from projects right down to organizations. A large portion of DAOs give the right to token holders to vote in changes or decisions on the platform. This is how it injects voice into the users and makes governance more democratic. So, creating your crypto token may be the best way to distribute ownership as well as decision-making power among your community and hence make it more community-driven and decentralized.</p>

            <h4>5. Tokenization of Real World Assets</h4>
            <p>Crypto tokens can represent any asset on a blockchain-from real estate and art to stocks and commodities. Great about this is that it allows dividing ownership into fractions, while there is a very smooth process of transferring ownership and liquidation of the assets. A business might create a token that represents a real-world asset in an attempt to tap into new markets to add value to investors and users.</p>

            <h4>6. Creating Unique Digital Economies</h4>
            <p>Crypto token development allows one to create his or her digital economy within a certain ecosystem. Be it some kind of gaming platform, a content-creating network, or DApp, the tokens can serve in that system for cash-buying goods or services from other people or even voting for further platform development. While you are creating your own token, you develop a mini economy in which one can govern both rules and incentives.</p>
  
            <h4>7. Increased Market Reach</h4>
            <p>Tokens create the avenue of reaching more global markets. Convention currencies are normally confined to geographic and political boundaries, but crypto tokens will be made readily available and will be traded by whoever accesses the internet. It opens up your project to a whole new market where you share your idea with users and investors from anywhere in the world. It might therefore be the case that the issue of a crypto token extends one's market reach, cutting into surging momentum from rising global interest in digital assets.</p>

            <h4>8. Transparency and Security</h4>
            <p>Crypto tokens make use of blockchain technology to record every transaction in a transparent and secure manner. Every transaction is recorded on the public ledger, with tampering or manipulation of the same inching towards impossibility. That, therefore, provides a level of trust and security for projects and businesses that are hard to achieve with conventional systems. You can apply transparency and security in building trust among users while creating your own token.</p>

            <h4>9. Innovation and Flexibility</h4>
            <p>Crypto tokens are all about flexibility and customization. Be it the development of some new financial product, a system of rewards, or a digital asset, the programming of the token is to be carried out in the way it should be for your specific needs and exact use cases. This will allow for innovation and experimentation in having the right set of tools for the creators to build out unique solutions that set themselves apart in the market. Crypto Token Development Services are all about the development and implementation of tokens in concert with your vision and goals.</p>

            <h4>10. Driving Blockchain Technology Adoption</h4>
            <p>Create your own crypto token that contributes to the wider adoption of blockchain technology. While you develop and use tokens, you are actually working out the expansion of the border of what is possible in decentralized systems. In this case, helping your project means supporting the blockchain ecosystem as a whole.</p>
            <p>The importance of crypto token development comes because it opens a completely new horizon for raising funds, engaging users, representing assets, and innovation generally within the digital sphere. A created token can open floodgates of opportunities and drive success in the rapid evolution of a personal project, business venture, or enterprise decentralized application.</p>

            <h2 id="item-7" [ngClass]="selectedLink===7?'scrollHeading':'scrollheadingone'">Our Crypto token development projects</h2>
            <p>Some of the crypto token development projects we have done include but are not limited to:</p>

            <h4>1. Real Estate Investment Token</h4>
            <h5 class="h4">Project :</h5>
            <p> We developed a token for one real estate investment platform that gave rights to people to buy shares in commercial properties.</p>
            <h5 class="h4">Result:</h5>
            <p>That made the process of owning and trading shares of real estate much easier for the investor, introducing them to a whole new way of investing in properties.</p>

            <h4>2. Gaming Rewards Token</h4>
            <h5 class="h4">Project:</h5>
            <p>We designed tokens for a gaming platform that would reward players for their achievements and purchases within the platform.</p>
            <h5 class="h4">Result:</h5>
            <p>These tokens would give access to special features within it and help players trade among others for more fun and interaction.</p>

            <h4>3. Voting Tokens for Decentralized Platforms</h4>
            <h5 class="h4">Project:</h5>
            <p>We have created tokens for a platform whose users can vote for all the important decisions concerning its development.</p>
            <h5 class="h4">Result:</h5>
            <p>In such a way, one empowers the community to take part in the fate of the platform and make the very process of decision-making more transparent.</p>

            <h4>4. NFT Marketplace Tokens</h4>
            <h5 class="h4">Project:</h5>
            <p>We have designed tokens for a digital art marketplace token corresponding to a unique work of art.</p>
            <h5 class="h4">Result:</h5>
            <p>It provides secure sales and trading of artworks for artists, with high marketplace activity.</p>

            <h4>5. Stablecoin Development</h4>
            <h5 class="h4">Project:</h5>
            <p>We developed a US dollar-pegged stablecoin for one financial platform.</p>
            <h5 class="h4">Result:</h5>
            <p>The users had a reliable digital currency for making transactions that were stable in price against the usual price fluctuations typical for other cryptocurrencies.</p>

            <p>This will show our capability in the crypto token development of various types for meeting different needs. If one of your ideas is to create your own crypto token, then we are able to bring it into life.</p>

            
            <h2 id="item-8" [ngClass]="selectedLink===8?'scrollHeading':'scrollheadingone'">Why choose Blockchainx Solutions for Crypto Token Development?</h2>

            <p>At Blockchainx, we help you create your own crypto token with precision and expertise. Whether you want to create your own cryptocurrency from scratch or start a bespoke token for your initiative, our <a href="https://www.blockchainx.tech/crypto-currency-development/" target="_blank">cryptocurrency token development services</a> are tailored specifically to support your vision. Our talented professionals assist you in developing your unique crypto tokens from scratch all the way through the launch. We achieve this by utilizing advanced technology and strategic foresight in order to derive robust solutions that fit your needs. Collaborate with us and have a personalized cryptocurrency token built for you. You concentrate on expanding your project while Blockchainx handles the specifics. Do not hesitate to reach out to us today if you desire more information regarding how our services lead one toward successful crypto token development.</p>
            
            

          </div>
          <app-social></app-social>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="blog-right-side table-content">
            <div class="table-content-main">             
              <div class="metaverse_heading">
                <h3 class="pb-0"><span>Table of Contents</span></h3>
              </div>
              <div class="categories_list_view table-contens">
                <ul>
                  <li>
                    <a href="https://www.blockchainx.tech/create-your-own-crypto-token/#item-1" (click)="addClass(1)">What are Crypto Tokens?</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-your-own-crypto-token/#item-2" (click)="addClass(2)">How does a Crypto Token work?</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-your-own-crypto-token/#item-3" (click)="addClass(3)">Difference between Cryptocurrency and a Token</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-your-own-crypto-token/#item-4" (click)="addClass(4)">Types of Crypto Token</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-your-own-crypto-token/#item-5" (click)="addClass(5)">Steps to create your own crypto token</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-your-own-crypto-token/#item-6" (click)="addClass(6)">Importance of Crypto Token Development</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-your-own-crypto-token/#item-7" (click)="addClass(7)">Our Crypto token development projects</a>
                  </li>                                   
                  <li>
                    <a href="https://www.blockchainx.tech/create-your-own-crypto-token/#item-8" (click)="addClass(8)">Why Choose Blockchainx for Crypto Token Development?</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="blog-author-wrap">
              <div class="blog-author-detail">
                <div>
                  <img src="assets-new/images/blog/yokesh-shankar.svg" alt="blog author" title="About Author" width="150" height="150"/>
                </div>
                <div>
                  <div class="metaverse_heading">
                    <h4>
                      <span>About Author</span>
                    </h4>
                  </div>
                  <h5>Yokesh Sankar</h5>
                  <div class="author-social-connect">
                    <ul>
                      <li>
                        <a href="https://www.instagram.com/yokesh.sankar/" target="_blank" class="fs-5" rel="noopener noreferrer" alt="instagram" aria-label="Instagram">
                          <i class="bi bi-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://in.linkedin.com/in/yokeshsankar" target="_blank" class="fs-5" rel="noopener noreferrer" alt="Linkedin" aria-label="Linkedin">
                          <i class="bi bi-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/yokesh.shankar" target="_blank" class="fs-5" rel="noopener noreferrer" alt="facebook" aria-label="facebook">
                          <i class="bi bi-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/yokesh_sankar_v" target="_blank" class="fs-5" rel="noopener noreferrer" alt="Twitter" aria-label="twitter">
                          <i class="bi bi-twitter-x"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>I am Yokesh Sankar. Co-Founder of BlockchainX, My vision and time tooffer the best products for our clients without apprehensions ateconomic rates.</p>
            </div>
            <div class="social-shares">
              <div>
                <a href="https://twitter.com/Blockchainxtech" target="_blank" rel="noopener noreferrer">Follow us on Twitter<span class="mx-2 cyan-300"><i class="bi bi-twitter-x color cyan-300"></i></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-lg-8 col-md-12 col-sm-12 col-12">
          <app-comments></app-comments>
        </div>
      </div> -->
    </div>
  </section>
  <app-blog></app-blog>
  <section class="section-service-alt-main">
    <app-get-in-touch></app-get-in-touch>
  </section>