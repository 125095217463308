<div class="case-inner-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12 col-12">
        <div class="case-main-banner">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/case/starcard/star-card-sports.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/case/starcard/star-card-sports.png"
            />

            <img loading="lazy"
              src="assets-new/images/case/starcard/star-card-sports.png"
              class="img-fluid"
              width="840"
              height="504"
              alt="starcard-sports-company"
            />
          </picture>
        </div>
        <div class="case-study-content">
          <h4>Gaming In Blockchain</h4>
          <div class="metaverse_subheading">
            <h3>Star Card Sports: Play-To-Earn Gaming Platform On Polygon</h3>
          </div>
          <div class="case-study-main-content">
            <p>
              Gaming is one of the enduring factors bringing in the spirit, one
              of the biggest stress busters, and in recent times, monetizing
              also becomes one of its primary attributes. From making money,
              blockchain-based gaming arenas are giving players the opportunity
              to sink in rewards, crypto earnings, and NFT ownership. Star Cards
              Sports is a play-to-earn Gaming arena built on the Polygon
              blockchain network. The layer 2 blockchain for the Ethereum
              network.Star Card Sports is based on the sport, Football. This is
              a kind of sports gaming platform integrated with metaverse
              functions, That the players can monetize on everything, and their
              avatars along with their exclusive abilities can be showcased for
              trading in the marketplace. In the initial stages, the SCS game is
              about to start with token distribution pre-launch of the game.
              That welcomes a wider set of audience in the first lot and
              increases liquidity for the SCSG token. With progress and
              attention from the audience, the gaming features and functions
              shall be kept updated yet not changed in their core value. The
              native token distribution is expected to contribute in multiple
              ways and staking is one purging choice. Polygon network support is
              best for the platform in terms of handling Transactions, its
              low-cost access, speed, security, and others are all the perks to
              operating on the same.
            </p>

            <h4>A visual outline of the project plan</h4>
            <div class="metaverse_subheading">
              <h3>Specific Focus Of Star Card Sports</h3>
            </div>
            <div class="case-inner-image first-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/starcard/specific-focusof-star-card-sports.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/starcard/specific-focusof-star-card-sports.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/case/starcard/specific-focusof-star-card-sports.png"
                  class="img-fluid"
                  width="840"
                  height="504"
                  alt="Focus Of StarCard-Sports"
                />
              </picture>
              <div class="green-vector"></div>
            </div>

            <p>
              The specific goal was to build a blockchain-based soccer game that
              connects real-world player data in the game. A team of amateur
              players. Apart from its gaming abilities, it also aimed at giving
              users the opportunity to earn through in-game assets, which are
              NFTs.
            </p>

            <h4>Challenges Faced In The Development Phase</h4>
            <div class="metaverse_subheading">
              <h3>The Pain Points</h3>
            </div>
            <div class="case-inner-image first-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/starcard/the-pain-points.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/starcard/the-pain-points.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/case/starcard/the-pain-points.png"
                  class="img-fluid"
                  width="840"
              height="504"
                  alt="Pain-Points of starcard-sports"
                />
              </picture>
              <div class="green-vector"></div>
            </div>

            <p>
              The fundraising goal for the project is high. This means that
              users could potentially sell tokens in bulk and tank the token
              price. We solved this by adding a vesting smart contract to the
              crowd sale that releases a specific percentage of tokens monthly.
            </p>

            <h4>The Solution</h4>
            <div class="metaverse_subheading">
              <h3>Milestones That We Achieved</h3>
            </div>
            <div class="solution-unlist">
              <ul>
                <li>Bulk Minting</li>
                <li>Collections sale</li>
                <li>Extempore database</li>
              </ul>
            </div>

            <div class="metaverse_subheading">
              <h3>The Working Of SCS Game</h3>
            </div>
            <p>
              The users/ players can create a profile in the SCS platform or
              TapIn as both communicate with others via an API through oracle
              chainlink. A wallet is established as the account is created for
              the player. They can now customize their avatars and NFT cards
              with the information. Players purchase their NFTs and define
              contract rules. The initial cost of the NFTis free for TapIn
              account holders. The cost on a basic scale is 250 SCSG. Players
              can challenge other players and their friends. They also can enter
              into a league to win real rewards. Further chances to upgrade
              their skills. The Role Play Gaming mode allows players to use
              avatars and apply in-game control to tackle their enemies. There
              are amazingly designed creative fantasy spaces for the players to
              drive in with 3D options and items. The players can challenge 1 on
              1, to advance the game.The Metaverse ability allows the players to
              design their own stadium to conduct these games and challenges. 
              Meanwhile, the fans can come together to interact.The in-game
              powers are open for purchase. Players can include identical
              physical products of partner football brands as NTFS and trade
              them for SCSG tokens. Players can also make in-house asset
              purchases, from the SCS house. They can be traded via partnered
              marketplaces and the earnings are partitioned accordingly.
            </p>

            <div class="metaverse_subheading">
              <h3>Design Screens</h3>
            </div>
            <div class="case-inner-image second-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/starcard/design-screens.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/starcard/design-screens.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/case/starcard/design-screens.png"
                  class="img-fluid"
                  width="840"
                  height="504"
                  alt="Design Screens"
                />
              </picture>
              <div class="orange-vector"></div>
            </div>

            <div class="common-unique">
              <div class="metaverse_subheading">
                <h3>Unique Features</h3>
              </div>
              <p>
                The Star Card Sports is embedded with various features that
                contribute to the better good and mainly concentrate on
                progressions.
              </p>
              <ul>
                <li>
                  <strong> Football Alliance</strong> - The Gameplay focuses on
                  two different styles of Colgate's New World Football Alliance.
                  The play data is shared and resided on the platform with NFT
                  available for both gaming styles.
                </li>
                <li>
                  <strong> Managerial Mode</strong> - This mode assembles teams
                  based on NFTs collections and each player on the TapIn
                  platform is represented via a different player card. Here real
                  athletes can be directly brought into the game.
                </li>
                <li>
                  <strong> RPG Mode</strong> - This is an adventure-style mode
                  with a 3D avatar representing real-life characters. The P2E
                  model is confined throughout the gaming space. The users can
                  earn through their NFTs and purchase more in the marketplace.
                </li>
                <li>
                  <strong> Redeemable Rewards </strong> - The SCSG token can
                  anytime be redeemed into fiat and buying SCSG is also made
                  easy with fiat, BTC, ETh, or Matic on any crypto exchange
                  platform. SCSG token is also open for staking and players earn
                  early rewards through direct yielding.
                </li>
                <li>
                  <strong> SCS NFT Marketplace </strong> - The SCS NFT
                  Marketplace is accessed through in-game navigation. The
                  players are allowed to trade, stake, rent, and earn digital
                  assets along with rewards. The player card is accessible in
                  the marketplace along with rental and purchase fees.
                </li>
                <li>
                  <strong> Metaverse</strong> - In the RPG mode of the SCS game,
                  the players engage in a dystopian world and earn revenue and
                  also contribute to world causes. By completing their tasks and
                  challenges, players level up and earn SCSG tokens. The real
                  estate in the space is also open to access by players.
                </li>
              </ul>
            </div>

            <div class="tech-stak">
              <h4>Tech Stacks</h4>
              <div class="metaverse_subheading">
                <h3>
                  A great app needs the right tools and technology to perform
                </h3>
              </div>
              <div class="list-heading">
                <h3>Front End</h3>
              </div>
              <div class="tech-list">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/case/html5.webp">
                  <source type="image/png" srcset="assets-new/images/case/html5.png">
                  <img loading="lazy" data-src="assets-new/images/case/html5.svg" class="img-fluid" width="70" height="70" alt="html5"
                  title="html5"/>
                </picture>
                <picture>
                  <source type="image/webp" srcset="assets-new/images/case/css.webp">
                  <source type="image/png" srcset="assets-new/images/case/css.png">
                  <img loading="lazy" data-src="assets-new/images/case/css.svg" class="img-fluid" width="70" height="70" alt="css"
                  title="css"/>
                </picture>
                <picture>
                  <source type="image/webp" srcset="assets-new/images/case/angular.webp">
                  <source type="image/svg" srcset="assets-new/images/case/angular.svg">
                  <img loading="lazy" data-src="assets-new/images/case/angular.svg" class="img-fluid" width="70" height="70" alt="css"
                  title="css"/>
                </picture>
              </div>
              <div class="list-heading">
                <h3>Blockchain</h3>
              </div>
              <div class="tech-list1">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/case/poly.webp">
                  <source type="image/png" srcset="assets-new/images/case/poly.png">
                  <img loading="lazy" data-src="assets-new/images/case/poly.png" class="img-fluid" width="70" height="70" alt="Polygon"
                   title="Polygon"/>
                </picture>
                <picture>
                  <source type="image/webp" srcset="assets-new/images/case/solidity-2.webp">
                  <source type="image/png" srcset="assets-new/images/case/solidity-2.png">
                  <img loading="lazy" data-src="assets-new/images/case/solidity-2.png" class="img-fluid" width="70" height="70" alt="Solidity"
                  title="Solidity"/>
                </picture>
              </div>
              <div class="list-heading">
                <h3>Back end</h3>
              </div>
              <div class="tech-list1">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/case/nodejs.webp">
                  <source type="image/png" srcset="assets-new/images/case/nodejs.png">
                  <img loading="lazy" data-src="assets-new/images/case/nodejs.png" class="img-fluid" width="70" height="70" alt="Node"
                  title="Node"/>
                </picture>
                <picture>
                  <source type="image/webp" srcset="assets-new/images/case/mongodb.webp">
                  <source type="image/png" srcset="assets-new/images/case/mongodb.png">
                  <img loading="lazy" data-src="assets-new/images/case/mongodb.png" class="img-fluid" width="70" height="70" alt="mongodb"
                  title="mongodb"/>
                </picture>
              </div>
            </div>

            <div class="metaverse_subheading">
              <h3>Results</h3>
            </div>
            <div class="case-inner-image first-image">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/case/starcard/results.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/case/starcard/results.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/case/starcard/results.png"
                  class="img-fluid"
                  width="840"
                  height="504"
                  alt="Results of starcard-sports"
                />
              </picture>
              <div class="green-vector"></div>
            </div>
            <p>
              The Starcard Sports team raised $120K in the first round of token
              sales. While it's anticipated to have a long life-time through
              progressive aspects on its way.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="company-detail">
          <div class="detail-section-one">
            <h4>Company</h4>
            <h3 class="pb-0">Starcard</h3>
          </div>
          <div class="detail-section-two">
            <h4>Founder & CEO</h4>
            <h3 class="pb-0">Steve Schechter</h3>
          </div>
          <div class="detail-section-two">
            <h4>Website</h4>
            <h3 class="pb-0">Starcard-Sports Games</h3>
          </div>
          <div class="detail-section-two">
            <h4>Country</h4>
            <h3 class="pb-0">USA</h3>
          </div>
          <div class="detail-section-two">
            <h4>Business Type</h4>
            <h3 class="pb-0">P2E Gaming</h3>
          </div>
          <div class="detail-section-two">
            <h4>Team</h4>
            <h3 class="pb-0">Steve Schechter</h3>
            <h3 class="pb-0">Alex Schechter</h3>
          </div>
          <div class="detail-section-three">
            <h4>Follow us on</h4>
            <h3>Gaming In Blockchain</h3>
            <ul class="d-flex align-items-center gap-4">
              <li>
                <a
                  href="https://www.ekta.io/"
                  target="_blank"
                  class="fs-4"
                  rel="noopener noreferrer"
                  alt="global"
                  aria-label="global"><i class="bi bi-globe"></i></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ektachain/"
                  target="_blank"
                  class="fs-4"
                  rel="noopener noreferrer"
                  alt="instagram"
                  aria-label="Instagram"><i class="bi bi-instagram"></i></a>
              </li>
              <li>
                <a
                  href="https://twitter.com/EktaChain"
                  target="_blank"
                  class="fs-4"
                  rel="noopener noreferrer"
                  alt="Twitter"
                  aria-label="twitter">
                  <i class="bi bi-twitter-x"></i>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <section class="section-service-alt-main">
  <app-contactform></app-contactform>
</section> -->
<section class="section-service-alt-main">
<app-get-in-touch></app-get-in-touch>
</section>