import {
  Component,
  OnInit,
} from '@angular/core';
import {
  Title,
  Meta,
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-politifi-token-development',
  templateUrl: './politifi-token-development.component.html',
  styleUrls: ['./politifi-token-development.component.css']
})
export class PolitifiTokenDevelopmentComponent implements OnInit {
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService
  ) {}


  isDarkmode:boolean=false;
  title = 'Politifi Tokenization  Service Embracing The Public Sector';

  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'PolitiFi Token Development is the next evolution taken around a new duo, technology and Politics leaping with new trails and possibility for the chairperson candidates. Truly an experimental space for investors and players, Tap to know more.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Politifi token development, Politifi platform development, Political tokenization, Politifi tokenization services',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Politifi Tokenization  Service Embracing The Public Sector',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/politifi-token-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'PolitiFi Token Development is the next evolution taken around a new duo, technology and Politics leaping with new trails and possibility for the chairperson candidates. Truly an experimental space for investors and players, Tap to know more.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/politifi-token.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/politifi-token-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Politifi Tokenization  Service Embracing The Public Sector',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'PolitiFi Token Development is the next evolution taken around a new duo, technology and Politics leaping with new trails and possibility for the chairperson candidates. Truly an experimental space for investors and players, Tap to know more.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/politifi-token.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/politifi-token-development/'
    );
  }

  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };

}
