  import { Component, OnInit,} from '@angular/core';
  import { Router } from '@angular/router';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { ToastrService } from 'ngx-toastr';
  import { TrackingService } from '../service/tracking.service';
  import { FooterServiceService } from '../service/footer-service.service';
  import { CommonNewsletterService } from '../service/common-newsletter.service';

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.css']
  })
  export class NewsletterFormComponent implements OnInit {
    public newslettermail!: FormGroup;
    submitted = false;
    countryCode: any;
    formdata:[];
      isDarkmode: boolean = false;

      constructor(
        private formBuilder:FormBuilder,
        private CommonNewsletterService:CommonNewsletterService,
        private toastr:ToastrService,
        private trackingService: TrackingService,

        public router: Router,
        private footerservice: FooterServiceService  ) {
      }
      ngOnInit(): void {

        this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
          this.isDarkmode = response;
        });
        this.newslettermail = this.formBuilder.group({
          name: [''],
          email: ['', [Validators.required, Validators.email]]
        });
      }
      get f(){
        return this.newslettermail.controls;
      }
    
      public onSubmit(){
        this.submitted=true;
        if(this.newslettermail.valid){
          let formData= {
            ... this.newslettermail.value,
            source: localStorage.getItem('userReferrer') || 'direct',
            page_visited: localStorage.getItem('lastPageVisited') || window.location.href,
            pages_visited: JSON.parse(localStorage.getItem('pagesVisited') || '[]')
          };
          console.log(formData);
    
          this.CommonNewsletterService.submitContactForm(formData).subscribe((response:any)=>{
            this.submitted = false;
            if(response.status=['SUCCESS']){
              this.toastr.success('Mail Sent Successfully!', 'Success!');
              this.newslettermail.reset();
              this.trackingService.clearPageVisits();
    
              // Redirect to the "Thank You" page after 2 seconds
              setTimeout(() => {
                this.router.navigate(['/thank-you']); // Replace '/thank-you' with your actual route
              }, 2000);
            }
          },
          (exception:any) =>{
            this.toastr.error('Mail Not Sent. Pls try again after some times!', 'Failure!');
          }
          )
        }
      }
        numericOnly(event:any) {
          let pattern = /^([0-9])$/;
          return pattern.test(event.key);
        }
    }


