<section class="banners-section" #about>

    <div class="banner_wrapper">
      <div class="container" id="common-relative">
        <div class="green-vector"></div>
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12 col-sm-12 col-12">
              <div class="banner_contentWrapper">
                <div class="caption">
                  <h1>
                    <span><strong>Exchange-based Tokens </strong></span> Development Service
                  </h1>
                  <p>
                    Increase your token stability and functionality, with an Exchange-based token development that aces your business's market, and shapes the future of Digital finances.
                  </p>

                  <div class="contact-link">
                    <a [routerLink]="['/contact']" class="home-link"
                      >Talk To Our Experts</a
                    >
                    <a [routerLink]="['/case-studies']" class="home-link-alt"
                      >View Case Study</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 text-center">
              <div class="banner-right-section">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/launch-your-token.webp" />
                  <source type="image/png" srcset="assets-new/images/token-images/launch-your-token.png" />
                  <img loading="lazy"  data-src="assets-new/images/token-images/launch-your-token.png" width="500" height="500" class="img-fluid" alt="Stablecoin Development Company" title="Stablecoin Development Company" />
                </picture>
              </div>
            </div>

        </div>
        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp"
              />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png"
              />
              <img loading="lazy"

                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll-icon"
                title="scroll-icon"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12 order-lg-first order-last">
          <div class="bep-work-section mt-4 mt-sm-5 mt-lg-0">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/why-should-you-choose.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/why-should-you-choose.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/why-should-you-choose.png"
                width="350" height="350" alt="Stablecoin Development Services"
                title="Stablecoin Development Services" class="BSC-Tokens-image img-fluid">
            </picture>
          </div>
        </div>
        <div
          class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
          <div class="whys_section mb-0">
            <h2 class="pb-lg-3 pb-3 text-start"><span>Exchange-Based Token </span>Development Services</h2>
          </div>
          <div class="common-sub-heading">
            <p>
                Exchange Tokens are used in the marketplace to alter tokens and bring more value to the native token by increasing its liquidity and demand pool. As an exchange token primarily represents token trading, other faces make Exchange tokens highly functional, efficient, and sustainable in the market for a business. Exchange-based token development platform is a space that initiates, hosts, and renders these services to the users.
            </p>
          </div>

          <!-- <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  No Volatile Risk
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> High Liquidity
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Widespread Integration
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Energy Efficient
                </li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>

    </div>
</section>
<!-- End -->

<!-- Trending ERC20 Token List Start -->
<div class="section-service-main">
    <div class="tending-bep-20">
      <div class="container">
        <div class="row trending-token-list">
          <div class="col-lg-6 mb-lg-0 mb-4">
            <h3 class="main-sub-heading text-start"><span>Exchange Token </span> Development Projects</h3>

            <div class="row trending-bep">
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                <ul class="ps-3 ps-lg-2">
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://coinmarketcap.com/exchanges/apollox-v2-bsc/">APX V2</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://coinmarketcap.com/exchanges/thorchain-erc20/">Thor Chain</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://coinmarketcap.com/exchanges/thorchain-erc20/">Pancakeswap V2</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://coinmarketcap.com/exchanges/uniswap-v2-base/">Uniswap</a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                <ul>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://coinmarketcap.com/exchanges/dodo/">DoDo Ethereum</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://coinmarketcap.com/exchanges/thruster-v3/">Thruster V3</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://coinmarketcap.com/exchanges/apollox/">APX</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://coinmarketcap.com/exchanges/tokenlon/">TokenIon</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-flex pt-3">
              <a [routerLink]="['/contact']" class="home-link-alt">Connect With
                Us</a>
            </div>
          </div>
          <div class="col-lg-6 text-center">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/trending-erc20-token-list.png" />
              <img loading="lazy"
                data-src="assets-new/images/erc/trending-erc20-token-list.png"
                width="405" height="405" alt="Stablecoin Development Solution"
                title="Stablecoin Development Solution" class="img-fluid mx-auto">
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3><span>Exchange Tokens Platform </span>Development Services</h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/project-consulting.webp">
                <source type="image/png" srcset="assets-new/images/token images/project-consulting.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/project-consulting.png" width="75" height="75" alt="Exchange Token Development" title="Exchange Token Development" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Exchange Token Development</h4>
            <p>
                Upskill your crypto project and business with Exchange Token development that ensures stabilization and root of your liquidity pool in any case.
            </p>
            <div class="content-link">
                <a [routerLink]="['/token-development-company']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/staking-platform.webp">
                <source type="image/png" srcset="assets-new/images/token-images/staking-platform.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/staking-platform.png" width="75" height="75" alt="Exchange Development" title="Exchange Development" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                Exchange Development
            </h4>
            <p>
                We curate a highly sustainable, efficient, and easy-to-use centralized trading platform to ease the exchange transaction and support many other crypto projects.
            </p>
            <div class="content-link">
                <a [routerLink]="['/cryptocurrency-exchange-software-development']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/farming-platform.webp">
                <source type="image/png" srcset="assets-new/images/token-images/farming-platform.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/farming-platform.png" width="75" height="75" alt="Multi Blockchain Integration" title="Multi Blockchain Integration" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Multi Blockchain Integration</h4>
            <p>
                Our advanced and leveled-up integrations into your Exchange Token development platform ensure the support of multiple network tokens and projects that benefit at no stake.
            </p>
            <div class="content-link">
                <a [routerLink]="['/']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/token-bridge.webp">
                <source type="image/png" srcset="assets-new/images/token-images/token-bridge.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/token-bridge.png" width="75" height="75" alt="Decentralized Exchange " title="Decentralized Exchange " class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Decentralized Exchange </h4>
            <p>
                Our entire token development process is ensured to record every transaction seamlessly and continuously in the decentralized ledgers, while our exchange platform also acts on the same.
            </p>
            <div class="content-link">
                <a [routerLink]="['/cryptocurrency-exchange-software-development']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                <source type="image/png" srcset="assets-new/images/token-images/layer-i-blockchain.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/layer-i-blockchain.png" width="75" height="75" alt="DEX Consultation" title="DEX Consultation" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">DEX Consultation</h4>
            <p>
                We consult services, for Decentralized exchanges or any other services, we ensure to suggest and drive you most authentically, highlighting the spectrum as a whole and never in grey.
            </p>
            <div class="content-link">
                <a [routerLink]="['/blockchain-consulting-services']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/nft-markeplace.webp">
                <source type="image/png" srcset="assets-new/images/token-images/nft-markeplace.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/nft-markeplace.png" width="75" height="75" alt="Audited Smart Contract" title="Audited Smart Contract" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                Audited Smart Contract
            </h4>
            <p>
                Being a decentralized, blockchain-based smart contract, we ensure our services are keen, well-documented, and ensure to be trusted, secure, and free from any malicious attacks.
            </p>
            <div class="content-link">
                <a [routerLink]="['/smart-contract-development']">
                  <span>View More</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
          </div>
        </div>
      </div>

    </div>
</section>
<!-- Related Services -->

  <!-- why-choose-section -->
  <section class="section-service-main">
      <div class="green-vector"></div>
      <div class="orange-vector"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 metmb-3">
            <div class="whys_section mb-3">
              <h2>
                <span>BlockchainX Your Exchange  </span>Tokens Development Company
              </h2>
              <p>
                BlockchainX is a pioneer in the development and engagement with decentralized development, very keen on curating digital infrastructures with 7 years of experience. We ensure to drive your core goal and idea into possible innovations in blockchain space with our expertise. Our Exchange token development services also ensure to serve the best cause of yours and help in scaling your business to new horizons.
              </p>
              <div class="whysection_text_wrap pt-4">
                <ul>
                    <li>Expert and Experienced Guidance in development</li>
                    <li>Customized Exchange token development</li>
                    <li>Transforming technological support</li>
                    <li>Cross network integrations </li>
                    <li>Support throughout the process</li>
                    <li>Gain Global attention</li>
                  </ul>
              </div>
              <div class="content-link my-3">
                <a [routerLink]="['/contact']">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <!-- <hr /> -->
              </div>
            </div>
            <div class="whysection_text_wrap">

              <!-- <div class="content-link my-3">
                <a [routerLink]="['/contact']">
                  <span>Contact Us</span>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow"
                       />
                  </picture>
                </a>
                <hr />
              </div> -->
            </div>
          </div>
          <div class="col-lg-6 metmb-3 Why-section">
            <div class="why_right_side">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

                <img loading="lazy"
                  data-src="assets-new/images/token-images/why-choose-blockchainx.png"
                  width="624"
                  height="521"
                  class="img-fluid"
                  alt="Stablecoin Development Company"
                  title="Stablecoin Development Company"
                   />
              </picture>
            </div>
          </div>
        </div>
      </div>
  </section>
  <!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              FAQ <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    What is an Exchange-based token?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        An exchange token like any other crypto token is a digital asset that is open to exchange with or for other crypto tokens in the networks and DEX platforms.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    Can an Exchange token be used to trade for another token?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        Yes, of course, the main purpose of an Exchange token is to trade it for another token in the network. Exchange tokens also serve the purpose of customer incentives via staking, cost considerations, capital generation, governance, and more.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    What is the time and cost for the Exchange token development platform?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        The time and cost of the Exchange token development service truly depend on what your outcome is. There are pre-defined DEX platforms while for branding and other functionalities, the timing and cost fluctuate on the same.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
  </section>
  <!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Our Latest Compilation On The Market</h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
              <div class="blog_wrap">
                <a [routerLink]="['/comparison-of-solana-polygon-ethereum']">
                  <picture>
                    <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/depin/depin-extravaganza.webp
                      "
                    /> -->
                    <source
                      type="image/jpg"
                      srcset="
                      assets-new/images/blog/comparision-of-sol-poly-eth.jpg
                      "
                    />
                    <img loading="lazy"

                      data-src="assets-new/images/blog/comparision-of-sol-poly-eth.jpg"
                      width="404"
                      height="209"
                      alt="Solana Vs Polygon Vs Ethereum"
                      title="Explore the detailed comparison of Solana Vs Polygon Vs Ethereum"
                    />
                  </picture>
                  <h3>
                      Explore the detailed comparison of Solana Vs Polygon Vs Ethereum
                  </h3>
                  <p>
                      A few years ago, no one thought about how blockchain and cryptocurrency would change the ...
                  </p>
                </a>
                <div class="content-link">
                  <a [routerLink]="['/what-is-depin']">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode"
                    />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode"
                    />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a [routerLink]="['/nft-marketplace-development-solana']">
                  <picture>
                    <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/all-about-ai-token/all-about-ai-token.webp
                      "
                    /> -->
                    <source
                      type="image/jpg"
                      srcset="
                      assets-new/images/blog/nft-marketplace-development-solana.jpg
                      "
                    />
                    <img loading="lazy"

                      data-src="assets-new/images/blog/nft-marketplace-development-solana.jpg"
                      width="404"
                      height="246"
                      class="img-fluid"
                      alt="NFT marketplace in Solana"
                      title="How to create NFT marketplace in Solana"
                    />
                  </picture>
                  <h3>
                      How to create NFT marketplace in Solana
                  </h3>
                  <p>
                      The market is superfluous with digital expansion. Every business, out in the world, every nook and corner...
                  </p>
                </a>
                <div class="content-link">
                  <a [routerLink]="['/ai-token-definition']">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode"
                    />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode"
                    />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a [routerLink]="['/solana-proof-of-history']">
                  <picture>
                    <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/real-estate-tokenization/real-estate-tokenization-guide.webp
                      "
                    /> -->
                    <source
                      type="image/jpg"
                      srcset="
                      assets-new/images/blog/solana/banner.jpg
                      "
                    />
                    <img loading="lazy"

                      data-src="assets-new/images/blog/solana/banner.jpg"
                      width="404"
                      height="247"
                      class="img-fluid"
                      alt="blockchain to use proof of history"
                      title="The world's first blockchain to use proof of history"
                    />
                  </picture>
                  <h3>
                      Solana: The world's first blockchain to use proof of history
                  </h3>
                  <p>
                      When comparing the prominent Ethereum blockchain, Salona is very fast and is the most high-throughput ....
                  </p>
                </a>
                <div class="content-link">
                  <a [routerLink]="['/real-estate-tokenization-guide']">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode"
                    />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode"
                    />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

  <!-- Contact Start -->
  <section class="section-service-alt">
    <div class="app-get-in-touch-form">
      <div class="container">
          <div class="contact-form">
              <div class="row reverse-colum">
                <div class="col-lg-5 col-md-12 contact-social-links">
                  <h3 class="main-sub-heading"><span>Engrave Exchange Token Development Into Your Business</span></h3>
                  <p>To establish better liquidity, screaming success and support!</p>
                  <div class="contact-main-list">
                    <div class="social-links">
                      <a href="mailto:contact@blockchainx.tech"  target="_blank"
                rel="noopener noreferrer" class="contact-mail" >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"
                            alt="linkedin"
                            width="32"
                            height="32"
                          />
                        </picture>
                      </a>

                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer" class="whatsapp">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"
                            alt="contact-Whatsapp"
                            width="32"
                            height="32"
                          />
                        </picture>
                      </a>

                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer" class="twitter" >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/svg"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"
                            alt="twitter"
                            width="40"
                            height="40"
                          />
                        </picture>
                      </a>
                    </div>
                    <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            src="assets-new/images/contact/mail.png"

                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>

                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            src="assets-new/images/contact/whatsapp-icon.png"

                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div>

                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            src="assets-new/images/contact/telegram.png"

                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
                  </div>
                  <div class="contact-links">
                    <h4>Follow us on</h4>
                    <div class="social-links">
                      <a
                        href="https://www.linkedin.com/company/blockchainxtech/"
                        target="_blank"
                rel="noopener noreferrer"
                        class="linkdin"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/linkdin.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/linkdin.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/linkdin.png"
                            alt="linkedin"
                            width="22"
                            height="22"
                          />
                        </picture>
                      </a>

                      <a
                        href="https://www.instagram.com/blockchainxtech/"
                        target="_blank"
                rel="noopener noreferrer"
                        class="instagram"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/instagram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/instagram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/instagram.png"
                            alt="instagram"
                            width="22"
                            height="22"
                          />
                        </picture>
                      </a>

                      <a
                        href="https://twitter.com/Blockchainxtech"
                        target="_blank"
                rel="noopener noreferrer"
                        class="twitter"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/twitterx.webp"
                          />

                          <source
                            type="image/svg"
                            srcset="assets-new/images/contact/twitterx.svg"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/twitterx.svg"
                            alt="twitter"
                            width="25"
                            height="25"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 col-md-12 contact-inputs">
                  <div class="getintouch getintochform bitrix-snippet-form">
                    <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
  </section>
  <!-- Contact End -->
