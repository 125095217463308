import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private apiUrl = 'https://crmapi.sparkouttech.com/api/v1/leads/onboard-lead/6732f9e1103649bd1933b0e3/67357cd43e1e13749fc04b99';
  constructor(private http: HttpClient) { }

  submitContactForm(formData: any): Observable<any> {
    return this.http.post(this.apiUrl, formData);
  }
}
