<section class="banners-section" #about>

    <div class="banner_wrapper">
      <div class="container" id="common-relative">
        <div class="green-vector"></div>
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12 col-sm-12 col-12">
              <div class="banner_contentWrapper">
                <div class="caption">
                  <h1>
                    <span><strong>Asset-Backed Crypto</strong></span> Token Development
                  </h1>
                  <p>
                    May your unique real-world assets make their way to the top bay, and back with the power of immutable ledgers, secured smart contracts, and more with Asset Backed token development.
                  </p>

                  <div class="contact-link">
                    <a [routerLink]="['/contact']" class="home-link"
                      >Talk To Our Experts</a
                    >
                    <a [routerLink]="['/case-studies']" class="home-link-alt"
                      >View Case Study</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 text-center">
              <div class="banner-right-section">
                <picture *ngIf="!isDarkmode">
                  <source type="image/webp" srcset="assets-new/images/asset-backed-token/asset-backed.webp" />
                  <source type="image/png" srcset="assets-new/images/asset-backed-token/asset-backed.png" />
                  <img loading="lazy"  data-src="assets-new/images/asset-backed-token/asset-backed.png" width="500" height="500" class="img-fluid" alt="Asset-Backed Crypto" title="Asset-Backed Crypto Token" />
                </picture>
                <picture *ngIf="isDarkmode">
                  <source type="image/webp" srcset="assets-new/images/asset-backed-token/light-theme/asset-backed.webp" />
                  <source type="image/png" srcset="assets-new/images/asset-backed-token/light-theme/asset-backed.png" />
                  <img loading="lazy"  data-src="assets-new/images/asset-backed-token/light-theme/asset-backed.png" width="500" height="500" class="img-fluid" alt="Asset-Backed Crypto" title="Asset-Backed Crypto Token" />
                </picture>
              </div>
            </div>

        </div>
        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp"
              />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png"
              />
              <img loading="lazy"

                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll-icon"
                title="scroll-icon"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
    <div class="container">
      <div class="whys_section mb-0 text-center">
        <h2 class="pb-lg-5 pb-4"><span>Asset-backed Tokenization</span> Development Service</h2>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
          <div class="bep-work-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/why-should-you-choose.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/why-should-you-choose.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/why-should-you-choose.png"
                width="350" height="350" alt="Asset-backed Tokenization Development Service"
                title="Asset-backed Tokenization Development Service" class="BSC-Tokens-image img-fluid">
            </picture>
          </div>
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <p>
                The process of converting real-world assets in terms of metals like gold, silver, and diamond to make tokens is called asset-backed tokenization development. Unlike traditional stock, here the stakeholders can easily trade and extend high liquidity. Usually, asset-backed tokens are technically equipped and secure to use. Take a swift from traditional investments and surge with digital tokens, an opportunity overheating with the profit from asset-based tokens.
            </p>
          </div>

          <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  Asset-based tokens have high liquidity
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Secured to amplify in the marriage.
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Decentralized solution with immutable ledger
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Maximized returns
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<div class="section-service-main">
    <div class="tending-bep-20">
      <div class="container">
        <div class="row trending-token-list">
          <div class="col-lg-6 text-center order-lg-first order-last">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/trending-erc20-token-list.png" />
              <img loading="lazy"
                data-src="assets-new/images/erc/trending-erc20-token-list.png"
                width="405" height="405" alt="Asset Backed Token"
                title="Asset Backed Token" class="img-fluid mx-auto">
            </picture>
          </div>
          <div class="col-lg-6 mb-lg-0 mb-4">
            <h3 class="main-sub-heading text-start"><span>Famous Asset-backed </span> Crypto Tokens</h3>
            <div class="row trending-bep">
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                <ul>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span><a href="https://www.coingecko.com/en/coins/gala" target="_blank"
                rel="noopener noreferrer">GALA</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://www.coingecko.com/en/coins/hooked-protocol" target="_blank"
                rel="noopener noreferrer">Hooked Protocol</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://www.coingecko.com/en/coins/myria" target="_blank"
                rel="noopener noreferrer">Myria</a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                <ul>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://www.coingecko.com/en/coins/atlas-navi" target="_blank"
                rel="noopener noreferrer">Atlas Navi</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://www.coingecko.com/en/coins/commune-ai" target="_blank"
                rel="noopener noreferrer">Commune AI</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="https://www.coingecko.com/en/coins/propbase" target="_blank"
                rel="noopener noreferrer">Propbase</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-flex pt-3">
              <a [routerLink]="['/contact']" class="home-link-alt">Connect With
                Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3><span>Different asset-backed </span>Development Services We Offer</h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/project-consulting.webp">
                <source type="image/png" srcset="assets-new/images/token images/project-consulting.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/project-consulting.png" width="75" height="75" alt="Tokenization Consultation" title="Tokenization Consultation" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Tokenization Consultation</h4>
            <p>
                We provide you with prompt solutions and consultation for your tokenization journey in the web3 spaces. From market trends to better returns we help you understand every nuance.
            </p>
            <div class="content-link">
              <a [routerLink]="['/blockchain-consulting-services']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/staking-platform.webp">
                <source type="image/png" srcset="assets-new/images/token-images/staking-platform.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/staking-platform.png" width="75" height="75" alt="Asset tokenization Development" title="Asset tokenization Development" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                Asset tokenization Development
            </h4>
            <p>
                For your desired assets, we help you gain their original value and safely store them in the digital space with our Asset-backed tokenization development.
            </p>
            <div class="content-link">
              <a [routerLink]="['/real-world-asset-tokenization']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/farming-platform.webp">
                <source type="image/png" srcset="assets-new/images/token-images/farming-platform.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/farming-platform.png" width="75" height="75" alt="NFT marketplace for Tokenized assets" title="NFT marketplace for Tokenized assets" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">NFT marketplace for Tokenized assets</h4>
            <p>
                List your valuable assets in the worthy space, our NFT marketplace development for Asset-backed tokens will be the right choice to best showcase your valuables.
            </p>
            <div class="content-link">
              <a [routerLink]="['/nft-marketplace-development']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/token-bridge.webp">
                <source type="image/png" srcset="assets-new/images/token-images/token-bridge.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/token-bridge.png" width="75" height="75" alt="Asset-backed token Wallet" title="Asset-backed token Wallet" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Asset-backed token Wallet</h4>
            <p>
                Ensure your assets are stored and secured in the right place, a safe and guarded Wallet will help you keep your asset-backed tokens in the right place.
            </p>
            <div class="content-link">
              <a [routerLink]="['/cryptocurrency-wallet-development']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                <source type="image/png" srcset="assets-new/images/token-images/layer-i-blockchain.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/layer-i-blockchain.png" width="75" height="75" alt="Customized Tokenization Platform" title="Customized Tokenization Platform" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Customized Tokenization Platform</h4>
            <p>
                For more similar asset-backed token development, you can curate a Tokenization platform exclusively customized for you in your desired way.
            </p>
            <div class="content-link">
              <a [routerLink]="['/real-estate-tokenization']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/nft-markeplace.webp">
                <source type="image/png" srcset="assets-new/images/token-images/nft-markeplace.png">
                <img loading="lazy"  data-src="assets-new/images/token-images/nft-markeplace.png" width="75" height="75" alt="Asset Tokenization Staking Platform" title="Asset Tokenization Staking Platform" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                Asset Tokenization Staking Platform
            </h4>
            <p>
                Expand your opportunities with blockchain, our asset tokenization staking platform is not only about staking but also rewards users for holding their assets.
            </p>
            <div class="content-link">
              <a [routerLink]="['/nft-staking-platform-development']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
      </div>

    </div>
</section>
<!-- Related Services -->

  <!-- why-choose-section -->
  <section class="section-service-main">
      <div class="green-vector"></div>
      <div class="orange-vector"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 metmb-3">
            <div class="whys_section mb-0">
              <h2>
                <span>Create Asset Backed Token </span>Development With BlockchainX
              </h2>
              <p class="pb-3">
                BlockchainX is a leading pioneer in dealing with and developing decentralized, smart contract-based solutions, tokenizations, and much more. We are open to developing and creating backed token development solutions in your desired network with the desired functionalities and features. Without any denial, trust us we shall deliver sustainable solutions curated with care and concern.
              </p>
              <div class="whysection_text_wrap">
              <ul>
                <li>7plus years of experience in Web3 development </li>
                <li>50+ successful project </li>
                <li>Providing 1005 customizing </li>
                <li>Experienced developer with sheer knowledge.</li>
              </ul>
            </div>
              <div class="content-link">
                <a [routerLink]="['/contact']">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </div>
          <div class="col-lg-6 metmb-3 Why-section">
            <div class="why_right_side">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

                <img loading="lazy"
                  data-src="assets-new/images/token-images/why-choose-blockchainx.png"
                  width="624"
                  height="521"
                  class="img-fluid"
                  alt="Create Asset Backed Token"
                  title="Create Asset Backed Token"
                   />
              </picture>
            </div>
          </div>
        </div>
      </div>
  </section>
  <!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              FAQ <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    What is an Asset-Backed token?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        From its natural being, digitally transforming a real-world asset into the blockchain space as a digital token with its realistic characteristics, ensures its real value is served.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    What assets can be backed as tokens?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        Assets include real estate assets, buildings, paintings, creatives, exclusive supplies, structures, educational courses, games, sports assets, merchandise, and much more.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                What are the capabilities of asset-backed tokens?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        These asset-backed tokens are interoperable, secured, and safe, they can be owned as fractional assets, backed by smart contracts they are easy to trade and buy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
  </section>
  <!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Check out our Latest Blogs </h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Web3 Gaming"
                    title="AI Transforming Web3 Gaming And Metaverse" />
                </picture>
                <h3>
                  AI Transforming Web3 Gaming And Metaverse
                </h3>
                <p>
                  In the 1990s, the introduction of the internet, and the
                  evolution of something beyond reality kick-started ...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/what-is-gamefi'
                  ]">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/what-is-gamefi.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/what-is-gamefi.jpg"
                    width="404"
                    height="246"
                    alt="What is GameFi?"
                    title="What is GameFi?" />
                </picture>
                <h3>
                  What is GameFi? Diving into the world of Blockchain Games
                </h3>
                <p>
                  From the earlier days of the video game industry, developers
                  have aimed to create worlds as powerful as real life....</p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/what-is-gamefi'
                    ]">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/blockchain-game-development'
                  ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.webp
                      " />
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/industries/blockchain-in-gaming.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Blockchain Technology"
                    title=" The Potential of Blockchain Technology to Enhance Industry Profits " />
                </picture>
                <h3>
                  The Potential of Blockchain Technology to Enhance Industry
                  Profits
                </h3>
                <p>
                  Mario video games in the year 1981 became very famous among
                  game lovers by the name of Jumpman. In today’s world,...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/blockchain-game-development'
                    ]">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

  <!-- Contact Start -->
  <section class="section-service-alt">
    <div class="app-get-in-touch-form">
      <div class="container">
          <div class="contact-form">
              <div class="row reverse-colum">
                <div class="col-lg-5 col-md-12 contact-social-links">
                  <h3 class="main-sub-heading"><span>Unleash the real worth of your assets with Us</span></h3>
                  <p>Connect now to avail our asset-backed token development service!</p>
                  <div class="contact-main-list">
                    <div class="social-links">
                      <a href="mailto:contact@blockchainx.tech"  target="_blank"
                rel="noopener noreferrer" class="contact-mail" >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"
                            alt="linkedin"
                            width="32"
                            height="32"
                          />
                        </picture>
                      </a>

                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer" class="whatsapp">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"
                            alt="contact-Whatsapp"
                            width="32"
                            height="32"
                          />
                        </picture>
                      </a>

                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer" class="twitter" >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/svg"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"
                            alt="twitter"
                            width="40"
                            height="40"
                          />
                        </picture>
                      </a>
                    </div>
                    <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"

                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>

                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"

                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div>

                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"

                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
                  </div>
                  <div class="contact-links">
                    <h4>Follow us on</h4>
                    <div class="social-links">
                      <a
                        href="https://www.linkedin.com/company/blockchainxtech/"
                        target="_blank"
                rel="noopener noreferrer"
                        class="linkdin"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/linkdin.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/linkdin.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/linkdin.png"
                            alt="linkedin"
                            width="22"
                            height="22"
                          />
                        </picture>
                      </a>

                      <a
                        href="https://www.instagram.com/blockchainxtech/"
                        target="_blank"
                rel="noopener noreferrer"
                        class="instagram"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/instagram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/instagram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/instagram.png"
                            alt="instagram"
                            width="22"
                            height="22"
                          />
                        </picture>
                      </a>

                      <a
                        href="https://twitter.com/Blockchainxtech"
                        target="_blank"
                rel="noopener noreferrer"
                        class="twitter"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/twitterx.webp"
                          />

                          <source
                            type="image/svg"
                            srcset="assets-new/images/contact/twitterx.svg"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/twitterx.svg"
                            alt="twitter"
                            width="25"
                            height="25"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 col-md-12 contact-inputs">
                  <div class="getintouch getintochform bitrix-snippet-form">
                    <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
  </section>
  <!-- Contact End -->
