import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute,Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-launch-a-meme-coin-on-solana',
  templateUrl: './launch-a-meme-coin-on-solana.component.html',
  styleUrls: ['./launch-a-meme-coin-on-solana.component.css']
})
export class LaunchAMemeCoinOnSolanaComponent implements OnInit {

    selectedLink:number;
    title ='6 Easy Steps to Launch a Meme coin on Solana';

    constructor(
      public router: Router,
      private titleService: Title,
      private meta: Meta,
      private canonicalService: CanonicalService,
      private footservice:FooterServiceService,
      @Inject(DOCUMENT) private doc: Document
    ) { }

    isDarkmode:boolean=false;

    ngOnInit(): void {
      //darkmode services
      this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
        if (response) {
          this.isDarkmode = response;
        }
        else {
          this.isDarkmode = response;
        }
      });
      this.titleService.setTitle(this.title);

      this.meta.updateTag({
        name: 'description',
        content:
          'Here is 6 Easy steps to launch a meme coin on Solana? To find out more about the launching process—head to our website and follow our simple instructions.',
      });
      this.meta.updateTag({
        name: 'keywords',
        content:
          'Launch a meme coin on solana, How  to launch a meme coin solana, Create solana meme coin, Launch your meme coin on solana, Launch your own meme coin on solana, Create your own meme coin solana, solana meme coin launch',
      });


      this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
      this.meta.updateTag({ name: 'robots', content: 'index, follow' });

      this.meta.updateTag({
        property: 'og:title',
        content:
          '6 Easy Steps to Launch a Meme coin on Solana',
      });
      this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
      this.meta.updateTag({
        property: 'og:url',
        content:
          'https://www.blockchainx.tech/launch-a-meme-coin-on-solana/',
      });
      this.meta.updateTag({
        property: 'og:description',
        content:
          'Here is 6 Easy steps to launch a meme coin on Solana? To find out more about the launching process—head to our website and follow our simple instructions.',
      });
      this.meta.updateTag({ property: 'og:type', content: 'Website' });
      this.meta.updateTag({
        property: 'og:image',
        content:
          'https://www.blockchainx.tech/assets-new/images/blog/launch-a-meme-coin-on-solana/launch-a-meme-coin-on-solana.webp',
      });

      this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
      this.meta.updateTag({
        name: 'twitter:site',
        content:
          'https://www.blockchainx.tech/launch-a-meme-coin-on-solana/',
      });
      this.meta.updateTag({
        name: 'twitter:title',
        content:
          '6 Easy Steps to Launch a Meme coin on Solana',
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content:
          'Here is 6 Easy steps to launch a meme coin on Solana? To find out more about the launching process—head to our website and follow our simple instructions.',
      });
      this.meta.updateTag({
        name: 'twitter:image',
        content:
          'https://www.blockchainx.tech/assets-new/images/blog/launch-a-meme-coin-on-solana/launch-a-meme-coin-on-solana.webp',
      });

      // Add Canonical tag
      this.canonicalService.setCanonicalURL(
        'https://www.blockchainx.tech/launch-a-meme-coin-on-solana/'
      );
      this.createLink();
    }
    createLink() {
      this.canonicalService.destroyLinkForAmpURL();
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'amphtml');
      link.setAttribute(
        'href',
        'https://www.blockchainx.tech/amp/launch-a-meme-coin-on-solana.html'
      );
      this.doc.head.appendChild(link);
    }
    addClass(id:number){
      this.selectedLink=id;
    }
  }

