<section class="banners-section" #about>

    <div class="banner_wrapper">
        <div class="container" id="common-relative">
            <div class="green-vector"></div>
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                    <div class="banner_contentWrapper">
                        <div class="caption">
                            <h1>
                                <span><strong>Automated Market </strong> Maker
                                    Solutions</span>
                            </h1>
                            <p>
                                BlockchainX helps Web3 startups build customized
                                automated market makers using the latest
                                technologies. We are an automated market maker
                                development company that can take your trading
                                platform project from start to paper to change
                                quickly. Access world-leading Web3 developers
                                and build your Web3 platform at scale.
                            </p>

                            <div class="contact-link">
                                <a [routerLink]="['/contact']"
                                    class="home-link">Talk To Our Experts</a>
                                <a [routerLink]="['/case-studies']"
                                    class="home-link-alt">View Case Study</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12 col-sm-12 text-center">
                    <div class="banner-right-section">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/launch-your-token.webp" />
                            <source type="image/png"
                                srcset="assets-new/images/token-images/launch-your-token.png" />
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/launch-your-token.png"
                                width="500" height="500" class="img-fluid"
                                alt="Automated Market Maker Solutions"
                                title="Automated Market Maker Solutions" />
                        </picture>
                    </div>
                </div>

            </div>
            <div class="row d-lg-block d-none">
                <div class="scroll-section text-center"
                    (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="assets-new/images/home/scroll.webp" />
                        <source
                            type="image/png"
                            srcset="assets-new/images/home/scroll.png" />
                        <img loading="lazy"
                            data-src="assets-new/images/home/scroll.png"
                            width="25"
                            height="48"
                            class="img-fluid"
                            alt="scroll-icon"
                            title="scroll-icon" />
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
    <div class="container">
        <div class="whys_section mb-0 text-center">
            <h2 class="pb-lg-5 pb-4"><span>Why Choose AMM</span>
                Development?</h2>
        </div>
        <div class="row align-items-center">
            <div
                class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
                <div class="bep-work-section">
                    <picture>
                        <source type="image/webp"
                            srcset="assets-new/images/token-images/why-should-you-choose.webp">
                        <source type="image/png"
                            srcset="assets-new/images/token-images/why-should-you-choose.png">
                        <img loading="lazy"
                            data-src="assets-new/images/token-images/why-should-you-choose.png"
                            width="350" height="350"
                            alt="Why Choose AMM Development"
                            title="Why Choose AMM Development"
                            class="BSC-Tokens-image img-fluid">
                    </picture>
                </div>
            </div>
            <div
                class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
                <div class="common-sub-heading">
                    <p>
                        Automated Market Makers or AMMs are the brick and mortar
                        of decentralized exchanges. It allows traders to buy and
                        sell ERC20 tokens without having to rely on centralized
                        market makers or order books. However, since their
                        inception, not many protocols have emerged with new and
                        efficient protocols apart from Uniswap. This gives new
                        DEX startups the perfect market opportunity to launch
                        their own AMM with upgraded features and market making
                        logic.
                    </p>
                </div>

                <!-- <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  Globalized access and popularity
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Trade volume at the peak
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Multiple trading options
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Futuristic Approach
                </li>
              </ul>
            </div>
          </div> -->
            </div>
        </div>

    </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<section class="section-service-main">
    <div class="tending-bep-20">
        <div class="container">
            <div class="row trending-token-list">
                <div class="col-lg-6 text-center order-lg-first order-last">
                    <picture>
                        <source type="image/webp"
                            srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
                        <source type="image/png"
                            srcset="assets-new/images/erc/trending-erc20-token-list.png" />
                        <img loading="lazy"
                            data-src="assets-new/images/erc/trending-erc20-token-list.png"
                            width="405" height="405"
                            alt="Automated Market Marker Solutions"
                            title="Automated Market Marker Solutions"
                            class="img-fluid mx-auto">
                    </picture>
                </div>
                <div class="col-lg-6 mb-lg-0 mb-4">
                    <h3 class="main-sub-heading text-start"><span>Popular
                            Project in Automated Market
                        </span>Marker Solutions</h3>
                    <div class="row trending-bep">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                            <ul>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span><a
                                        href="https://www.coingecko.com/en/coins/balancer"
                                        target="_blank"
                                        rel="noopener noreferrer">Balancer</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://www.coingecko.com/en/coins/uniswap"
                                        target="_blank"
                                        rel="noopener noreferrer">Uniswap</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://www.coingecko.com/en/coins/pancakeswap"
                                        target="_blank"
                                        rel="noopener noreferrer">PancakeSwap</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                            <ul>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://www.coingecko.com/en/coins/raydium"
                                        target="_blank"
                                        rel="noopener noreferrer">Raydium</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://www.coingecko.com/en/coins/quickswap"
                                        target="_blank"
                                        rel="noopener noreferrer">QuickSwap</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://www.coingecko.com/en/coins/orca"
                                        target="_blank"
                                        rel="noopener noreferrer">Orca</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex pt-3">
                        <a [routerLink]="['/contact']"
                            class="home-link-alt">Connect With
                            Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 metaverse_heading mx-auto">
                <h3><span>Our Other Automated Market </span>Maker Services</h3>
            </div>
        </div>
        <div class="row types-of-token-detail">
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/project-consulting.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token images/project-consulting.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/project-consulting.png"
                                width="75" height="75"
                                alt="Custom AMM Design"
                                title="Custom AMM Design"
                                class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Custom AMM Design</h4>
                    <p>
                        Build a customized Automated Market Maker protocol by
                        implementing your unique AMM logic with our AMM
                        Development Company.
                    </p>
                    <!-- <div class="content-link">
              <a [routerLink]="['/']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                     />
                </picture>
              </a>
              <hr />
            </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/staking-platform.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/staking-platform.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/staking-platform.png"
                                width="75" height="75"
                                alt="Decentralized Exchange"
                                title="Decentralized Exchange"
                                class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">
                        Decentralized Exchange
                    </h4>
                    <p>
                        Kick-off your DEX startup quickly by launching an
                        Automated Market Maker protocol using Uniswap’s
                        technology and scale from there.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/farming-platform.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/farming-platform.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/farming-platform.png"
                                width="75" height="75" alt="Token Development"
                                title="Token Development" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Token Development</h4>
                    <p>
                        Create a dedicated token for your Automated Market Maker
                        protocol and allow users to avail perks for using your
                        platform and your token.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/token-bridge.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/token-bridge.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/token-bridge.png"
                                width="75" height="75" alt="Layer 1 Blockchain"
                                title="Layer 1 Blockchain" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Layer 1
                        Blockchain</h4>
                    <p>
                        Build your own blockchain network and launch your own
                        custom Automated Market Maker DEX to build interest with
                        potential users.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/layer-i-blockchain.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                                width="75" height="75" alt="Staking Platform"
                                title="Staking Platform" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Staking Platform</h4>
                    <p>
                        Launch a staking campaign and incentivize users to use
                        your token and AMM. This is a great way to build a
                        community quickly.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/nft-markeplace.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/nft-markeplace.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/nft-markeplace.png"
                                width="75" height="75"
                                alt="Cross-Chain Bridge"
                                title="Cross-Chain Bridge" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">
                        Cross-Chain Bridge
                    </h4>
                    <p>
                        Launch a token bridging system right inside your
                        Automated Market Maker platform and allow users to
                        easily trade on multiple networks.
                    </p>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 metmb-3">
                <div class="whys_section mb-0">
                    <h2>
                        <span>Why Choose BlockchainX for </span>AMM Development?
                    </h2>
                    <p class="pb-3">
                        BlockchainX is a leading Automated Market Maker
                        Development Company with years of experience building
                        customized DeFi solutions for our clients. Our strong
                        team of developers, architects, and consultants can help
                        you turn your DeFi startup dreams into a reality. Launch
                        your Web3 products at scale with us.
                    </p>
                    <!-- <div class="whysection_text_wrap">
              <ul>
                <li>50plus successful live project</li>
                <li>Open to customizing your solution </li>
                <li>On-time delivery and support</li>
                <li>Open to learn and implement</li>
              </ul>
            </div> -->
                    <div class="content-link">
                        <a [routerLink]="['/contact']">
                            <!-- <span>Read More</span> -->
                            <picture>
                                <source type="image/webp"
                                  srcset="assets-new/images/right-arrow.webp">
                                <source type="image/png"
                                  srcset="assets-new/images/right-arrow.png">
                                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                                   />
                              </picture>
                              <picture>
                                <source type="image/webp"
                                  srcset="assets-new/images/right-arrow-dark.webp">
                                <source type="image/png"
                                  srcset="assets-new/images/right-arrow-dark.png">
                                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                                   />
                              </picture>
                        </a>
                        <hr />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 metmb-3 Why-section">
                <div class="why_right_side">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

                        <source
                            type="image/png"
                            srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

                        <img loading="lazy"
                            data-src="assets-new/images/token-images/why-choose-blockchainx.png"
                            width="624"
                            height="521"
                            class="img-fluid"
                            alt="Why Choose BlockchainX for AMM Development"
                            title="Why Choose BlockchainX for AMM Development" />
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
        <!-- Accordion Section -->
        <div class="nft-acc">
            <div class="green-vector"></div>
            <div class="violet-vector"></div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12">
                    <div class="common-sub-heading sub-title">
                        FAQ <span class="dots">.</span>
                    </div>
                    <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
                </div>
            </div>
            <div
                class="row align-items-center justify-content-center pt-md-5 pt-4">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingOne">
                                <button
                                    class="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne">
                                    How long does Automated Market Maker
                                    Development take?
                                </button>
                            </div>
                            <div
                                id="collapseOne"
                                class="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        The development timeline for AMMs
                                        depends on how complex your idea is.
                                        Typically, it can take anywhere from 2-4
                                        months to complete and deploy on the
                                        mainnet.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingTwo">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo">
                                    Are there any pre-built solutions for AMM
                                    Development?
                                </button>
                            </div>
                            <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        We have a pre-built DEX solution that
                                        uses Uniswap’s Automated Market Maker
                                        mechanism. You can launch your DEX
                                        quickly within 7 Days and then work on
                                        upgrading the protocol with your custom
                                        mechanism.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingThree">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree">
                                    How much does it cost to build an AMM?
                                </button>
                            </div>
                            <div
                                id="collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        The cost for building an AMM will depend
                                        on how complex your protocol’s logic is.
                                        You can connect with one of our expert
                                        consultants and get a free quote today.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingFour">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour">
                                    Who provides liquidity in an Automated
                                    Market Maker protocol?
                                </button>
                            </div>
                            <div
                                id="collapseFour"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Anyone can create a liquidity pool on an
                                        Automated Market Maker protocol.
                                        Initially, you as the platform owner
                                        will need to provide liquidity for basic
                                        pairs. Your liquidity depth will
                                        increase as more projects launch on your
                                        DEX.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingFive">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive">
                                    What wallet will the AMM protool support?
                                </button>
                            </div>
                            <div
                                id="collapseFive"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingFive"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        We can integrate compatibility for any
                                        Web3 wallet depending on your
                                        requirements. We usually integrate
                                        Wallet Connect which will give users
                                        access to all major Web3 wallets.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Accordion Section -->
    </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
    <div class="green-vector"></div>
    <div class="container">
      <div class="row blog-list">
        <div class="col-lg-12">
          <div class="commontext_subhead">
            <h3>Blog<span class="dots">.</span></h3>
          </div>
          <div class="feature_wrap">
            <div class="commontext_wrap">
              <h2>Check out our Latest Blogs </h2>
            </div>
            <div class="deploy-link">
              <a [routerLink]="['/blog']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 left-vector">
          <owl-carousel-o [options]="blogs_details">
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                  <picture>
                    <!-- <source
                        type="image/webp"
                        srcset="
                          assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                        "
                      /> -->
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                        " />
                    <img loading="lazy"
                      data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                      width="404"
                      height="246"
                      class="img-fluid"
                      alt="Web3 Gaming"
                      title="AI Transforming Web3 Gaming And Metaverse" />
                  </picture>
                  <h3>
                    AI Transforming Web3 Gaming And Metaverse
                  </h3>
                  <p>
                    In the 1990s, the introduction of the internet, and the
                    evolution of something beyond reality kick-started ...
                  </p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="[
                      '/what-is-gamefi'
                    ]">
                  <picture>
                    <!-- <source
                        type="image/webp"
                        srcset="
                        assets-new/images/blog/what-is-gamefi.webp
                        "
                      /> -->
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/blog/what-is-gamefi.jpg
                        " />
                    <img loading="lazy"
                      data-src="assets-new/images/blog/what-is-gamefi.jpg"
                      width="404"
                      height="246"
                      alt="What is GameFi?"
                      title="What is GameFi?" />
                  </picture>
                  <h3>
                    What is GameFi? Diving into the world of Blockchain Games
                  </h3>
                  <p>
                    From the earlier days of the video game industry, developers
                    have aimed to create worlds as powerful as real life....</p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="[
                        '/what-is-gamefi'
                      ]">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="[
                      '/blockchain-game-development'
                    ]">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/industries/blockchain-in-gaming.webp
                        " />
                    <source
                      type="image/jpg"
                      srcset="
                        assets-new/images/industries/blockchain-in-gaming.jpg
                        " />
                    <img loading="lazy"
                      data-src="assets-new/images/industries/blockchain-in-gaming.jpg"
                      width="404"
                      height="246"
                      class="img-fluid"
                      alt="Blockchain Technology"
                      title=" The Potential of Blockchain Technology to Enhance Industry Profits " />
                  </picture>
                  <h3>
                    The Potential of Blockchain Technology to Enhance Industry
                    Profits
                  </h3>
                  <p>
                    Mario video games in the year 1981 became very famous among
                    game lovers by the name of Jumpman. In today’s world,...
                  </p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="[
                        '/blockchain-game-development'
                      ]">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
    <div class="app-get-in-touch-form">
        <div class="container">
            <div class="contact-form">
                <div class="row reverse-colum">
                    <div class="col-lg-5 col-md-12 contact-social-links">
                        <h3 class="main-sub-heading"><span>Connect with us
                                today!</span></h3>
                        <p>Schedule a call with our expert consultant and get a
                            free quote for your AMM project.</p>
                        <div class="contact-main-list">
                            <div class="social-links">
                                <a href="mailto:contact@blockchainx.tech"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="contact-mail">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/mail.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/mail.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/mail.png"
                                            alt="linkedin"
                                            width="32"
                                            height="32" />
                                    </picture>
                                </a>

                                <a href="https://wa.me/7708889555"
                                    target="_blank"
                                    rel="noopener noreferrer" class="whatsapp">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/whatsapp-icon.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/whatsapp-icon.png"
                                            alt="contact-Whatsapp"
                                            width="32"
                                            height="32" />
                                    </picture>
                                </a>

                                <a href="https://t.me/sparkouttechblockchainx"
                                    target="_blank"
                                    rel="noopener noreferrer" class="twitter">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/telegram.webp" />

                                        <source
                                            type="image/svg"
                                            srcset="assets-new/images/contact/telegram.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/telegram.png"
                                            alt="twitter"
                                            width="40"
                                            height="40" />
                                    </picture>
                                </a>
                            </div>
                            <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"

                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>

                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"

                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div>

                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"

                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
                        </div>
                        <div class="contact-links">
                            <h4>Follow us on</h4>
                            <div class="social-links">
                                <a
                                    href="https://www.linkedin.com/company/blockchainxtech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="linkdin">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/linkdin.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/linkdin.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/linkdin.png"
                                            alt="linkedin"
                                            width="22"
                                            height="22" />
                                    </picture>
                                </a>

                                <a
                                    href="https://www.instagram.com/blockchainxtech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="instagram">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/instagram.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/instagram.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/instagram.png"
                                            alt="instagram"
                                            width="22"
                                            height="22" />
                                    </picture>
                                </a>

                                <a
                                    href="https://twitter.com/Blockchainxtech"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="twitter">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/twitterx.webp" />

                                        <source
                                            type="image/svg"
                                            srcset="assets-new/images/contact/twitterx.svg" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/twitterx.svg"
                                            alt="twitter"
                                            width="25"
                                            height="25" />
                                    </picture>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 contact-inputs">
                        <div
                            class="getintouch getintochform bitrix-snippet-form">
                            <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact End -->
