<div class="blog-contact news-right-side mb-4">
  <div class="metaverse_heading">
    <h4><span>Newsletter</span></h4>
    <h5>
      Get all the latest posts delivered straight to your inbox.
    </h5>
  </div>
  <!-- <div class="row">
    <div
      class="col-lg-12 col-md-10 col-sm-10 col-12 mx-auto text-center"
    >
      <input
        type="text"
        class="form-control"
        placeholder="Your email address"
      />
      <a class="blog_submit">Submit</a>
    </div>
  </div> -->
  
  <form id="quickcontactForm" [formGroup]="newslettermail" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-lg-12 col-md-10 col-sm-10 col-12 mx-auto text-center position-relative">
          <input type="email" class="form-control" id="fEmail" placeholder="Your email address"
            formControlName="email" [ngClass]="{
              'is-invalid': submitted && f['email'].errors
            }" />
          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback position-absolute">
            <div *ngIf="f['email'].errors['required']"> Email is required </div>
            <div *ngIf="f['email'].errors['email']"> Email must be a valid email address </div>
          </div>
      </div>
      <div class="col-12">
        <button type="submit" class="b24-form-btn blog_submit">Submit</button>
      </div>
    </div>
  </form>
</div>


