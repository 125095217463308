<!-- Banner Section -->
<div class="banners-section">
    <div class="banner_wrapper">
      <div class="container" id="banner-page-vector-main">
        <div class="green-vector"></div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-12 col-sm-12 col-12">
            <div class="banner_contentWrapper">
              <div class="caption">
                <h1 class="pb-3">
                    <span> <strong>ERC3643 Token </strong> <br>Development Company</span>
                </h1>
                <p>
                  Aligning your asset as compiled permissioned tokens and meeting the regulatory norms with our ERC3643 token development services, that are catered to your needs.
                </p>

                <div class="contact-link">
                  <a href="https://www.blockchainx.tech/contact/" target="_blank" class="home-link-alt w-100 text-center">Talk with Our Experts</a>
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank" rel="noopener noreferrer" class="home-link w-100 text-center">Telegram</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12 text-center">
            <div class="banner-right-section">
              <picture *ngIf="isDarkmode">
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc3643-token-development-services/erc3643-token-development-services.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/erc3643-token-development-services/erc3643-token-development-services.png"
                  class="img-fluid"
                  alt="erc3643 token development services"
                  title="erc3643 token development services"
                  width="450"
                  height="450"/>
              </picture>
              <picture *ngIf="!isDarkmode">
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc3643-token-development-services/erc3643-token-development-services-light.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/erc3643-token-development-services/erc3643-token-development-services-light.png"
                  class="img-fluid"
                  alt="erc3643 token development services"
                  title="erc3643 token development services"
                  width="450"
                  height="450" />
              </picture>
          </div>
        </div>

        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center"
            (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll"
                title="Scroll" />
            </picture>
          </div>
        </div>
      </div>
    </div>
</div>
<!-- Banner Section end -->
<section class="section-service-alt about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          Our<span>Clients</span>
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<div class="section-service-main" #about>
    <div class="container">
      <div class="row reverse align-items-center">
        <div class="col-lg-5 mx-auto col-md-12">
          <div class="bep-work-section">
            <picture *ngIf="isDarkmode">
              <source
                type="image/webp"
                srcset="assets-new/images/erc3643-token-development-services/what-is-erc3643-token-standard.webp" />

              <img loading="lazy"
                data-src="assets-new/images/erc3643-token-development-services/what-is-erc3643-token-standard.png"
                class="img-fluid"
                alt="what is erc3643 token standard"
                title="what is erc3643 token standard"
                width="450"
                height="450" />
            </picture>
            <picture *ngIf="!isDarkmode">
              <source
                type="image/webp"
                srcset="assets-new/images/erc3643-token-development-services/what-is-erc3643-token-standard-light.webp" />

              <img loading="lazy"
                data-src="assets-new/images/erc3643-token-development-services/what-is-erc3643-token-standard-light.png"
                class="img-fluid"
                alt="what is erc3643 token standard"
                title="what is erc3643 token standard"
                width="450"
                height="450" />
            </picture >
          </div>
        </div>
        <div class="col-lg-6 col-md-12 about-content">
          <div class="common-sub-heading ">
            <h2 class="main-sub-heading text-center text-lg-start">What Is <span> ERC3643 Token Standard?</span></h2>
            <p class="pb-3">
              The ERC3643 token is an Ethereum-specialized token standard designed for compiling permissioned tokens meeting the regulatory framework. ERC3643 token is a quite different standard unlike Erc20, ERc721, or ERC1155 all of it focus on Fungibile tokens. This is a non-fungible token that is tailored to meet the security needs of the token. Meanwhile, it's built to provide built-in compliance features for those assets that are required, making it the perfect fit for tokenized securities, and real estate assets and utilized in other decentralized finance spaces. ERC3643 is widely used in the Finance, real estate, legal sectors, and others where compliance requirements with regulations are important. It also makes it possible for tokenizing assets like shares, bonds, real estate lands, etc who require permission during trading. Bridging the gap between traditional and decentralized finances.
            </p>
          </div>
        </div>
      </div>
    </div>
</div>

<section class="section-service-alt">
    <div class="container">
      <div class="row reverse dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 order-1  col-12 about-content">
          <div class="common-sub-heading">
            <h2 class="main-sub-heading text-center text-lg-start"><span>ERC3643 RWA Token</span> Development Company</h2>
            <p class="mb-4">
                We at BlockchainX as your RWA Token Development Company provide end-to-end development services for compliant and permissioned tokens that align with regulatory frameworks. We help you customize the token functionality as desired, while also suggesting peculiar technical, experience, and performance interventions. We develop your ERC3643 token with the utmost top tech integrations easing your operations, by its automated functions. And Securing from the regulations laid out. Our expert team of developers is skilled and possesses hands-on experience while they are also open to learning and exploring the new verse.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-2 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc3643-token-development-services/erc3643-rwa-token-development-company.webp" />

              <img loading="lazy"
                data-src="assets-new/images/erc3643-token-development-services/erc3643-rwa-token-development-company.png"
                class="img-fluid"
                alt="erc3643 rwa token development company"
                title="erc3643 rwa token development company"
                width="450"
                height="450" *ngIf="isDarkmode"/>
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc3643-token-development-services/erc3643-rwa-token-development-company-light.webp" />

              <img loading="lazy"
                data-src="assets-new/images/erc3643-token-development-services/erc3643-rwa-token-development-company-light.png"
                class="img-fluid"
                alt="erc3643 rwa token development company"
                title="erc3643 rwa token development company"
                width="450"
                height="450" *ngIf="!isDarkmode"/>
            </picture>
          </div>
        </div>
      </div>
    </div>
</section>

<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading text-center">
          <h2 class="mb-2">
            Remarkable Features of <span>ERC3643 Token Development</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center gy-5">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc3643-token-development-services/icons/permissioned-transfers.webp" />

              <img loading="lazy"
                data-src="assets-new/images/erc3643-token-development-services/icons/permissioned-transfers.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="permissioned transfers"
                title="permissioned transfers" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Permissioned Transfers</h3>
          <p>
            ERC3643 token development allows permissioned transfers meaning only verified authorized users could participate in transactions. It's important, the industry meets regulation norms such as KYC, AML, etc. The provider controls the token authority, and transfers, and ensures legal compliance.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc3643-token-development-services/icons/revocable-permissions.webp" />

              <img loading="lazy"
                data-src="assets-new/images/erc3643-token-development-services/icons/revocable-permissions.png"
                width="75"
                height="76"
                class="img-fluid"
                alt="revocable permissions"
                title="revocable permissions" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Revocable Permissions</h3>
          <p>
            The ERC3643 standard allows the issuer or the authority to revoke permissions. This refers to the users no longer having compliance standards, where their KYC expires. Their ability to trade the token can be temporarily or permanently revoked with these tokens
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc3643-token-development-services/icons/modular-and-extensible.webp" />

              <img loading="lazy"
                data-src="assets-new/images/erc3643-token-development-services/icons/modular-and-extensible.png"
                width="75"
                height="76"
                class="img-fluid"
                alt="modular and extensible"
                title="modular and extensible" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Modular and Extensible</h3>
          <p>
            ERC-3643 is a highly modular token, referring to developers who build and extend the token’s functionalities. This caters to the specific needs of their project and makes it more adaptable to different regulatory frameworks in the global network.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc3643-token-development-services/icons/interoperability.webp" />

              <img loading="lazy"
                data-src="assets-new/images/erc3643-token-development-services/icons/interoperability.png"
                width="75"
                height="76"
                class="img-fluid"
                alt="interoperability"
                title="interoperability" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Interoperability</h3>
          <p>
            ERC3643 tokens can interact with the DeFi applications and infrastructure. While maintaining regulatory compliance, it can enable access to liquidity pools, exchanges, and staking mechanisms.
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="text-center mt-4 mt-lg-5 col-lg-12">
      <a class="our_expert_talk" href="https://www.blockchainx.tech/erc3643-token-development-services/#item-1" (click)="addClass(1)">Schedule a Meeting</a>
    </div> -->
  </div>
</div>

<div class="section-service-alt">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="metaverse_heading text-center">
            <h2 class="mb-2">
                How Does the <span>ERC3643 Token Standard Work?</span>
            </h2>
          </div>
        </div>
      </div>
      <div class="row types-of-token-detail justify-content-center gy-5">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/ondo-finance-clone-script/icons/blockchain-consultations.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/ondo-finance-clone-script/icons/blockchain-consultations.png"
                  width="75"
                  height="75"
                  class="img-fluid"
                  alt="identity management and permissioned access"
                  title="identity management and permissioned access" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">Identity Management and Permissioned Access</h3>
            <p>
                The ERC3643 token needs the users to whitelist for the verification process before holding, selling, and receiving tokens. This involves meeting KYC, and AML compliance standards. The third-party compliance provider or issuers ensure that only authorized users interact with tokens. This makes asset management easy and meets legal and regulatory frameworks.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/ondo-finance-clone-script/icons/platform-infrastructure.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/ondo-finance-clone-script/icons/platform-infrastructure.png"
                  width="75"
                  height="76"
                  class="img-fluid"
                  alt="revocable token permissions"
                  title="revocable token permissions" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">Revocable Token Permissions</h3>
            <p>
                The core feature of ERC3643 token development is its revoke permission ability, meaning in case of the user’s credential expiry, or in terms of being no longer compliant with regulations, its ability to transfer and receive tokens temporarily or permanently can be revoked. Therefore allowing compliant users to interact.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/ondo-finance-clone-script/icons/tokenization-planning-and-deployment.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/ondo-finance-clone-script/icons/tokenization-planning-and-deployment.png"
                  width="75"
                  height="76"
                  class="img-fluid"
                  alt="modular architecture"
                  title="modular architecture" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">Modular Architecture</h3>
            <p>
                ERC-3643 is designed to be modular and extensible - meaning the token can be integrated with other regulatory frameworks across different networks. This allows the Developer to customize the compliance features based on the requirements of the assets like securities, bonds, real estate, and others; that are being tokenized.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/ondo-finance-clone-script/icons/asset-tokenization-options.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/ondo-finance-clone-script/icons/asset-tokenization-options.png"
                  width="75"
                  height="76"
                  class="img-fluid"
                  alt="defi interoperability"
                  title="defi interoperability" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">DeFi Interoperability</h3>
            <p>
                Despite its permissioned abilities, this ERC3643 token can be integrated with Ethereum’s Defi ecosystem as it extends opportunities for participating in liquidity pools, exchanges, and yield farming. However, these opportunities were originally restricted to other permissioned tokens.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/ondo-finance-clone-script/icons/smart-contract-auditing-and-upgrading.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/ondo-finance-clone-script/icons/smart-contract-auditing-and-upgrading.png"
                  width="75"
                  height="76"
                  class="img-fluid"
                  alt="on-chain compliance"
                  title="on-chain compliance" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">On-Chain Compliance</h3>
            <p>
                Unlike other traditional tokens, ER3643 is embedded with compliance in the token smart contract whole ownership restrictions, transfer permission and regulatory requirements are all directly enforced in the contract rules on the blockchain network, excluding manual interventions as everything is automated.
            </p>
          </div>
        </div>
      </div>
      <div class="text-center mt-4 mt-lg-5 col-lg-12">
        <a class="our_expert_talk" href="https://www.blockchainx.tech/erc3643-token-development-services/#item-1" (click)="addClass(1)">Schedule a Meeting</a>
      </div>
    </div>
</div>

<div class="section-service-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="metaverse_heading text-center">
            <h2 class="mb-2">
                 <span>ERC3643 RWA Token </span>Development Services
            </h2>
          </div>
        </div>
      </div>
      <div class="row types-of-token-detail justify-content-center gy-5">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc3643-token-development-services/icons/erc3643-rwa-token-development.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/erc3643-token-development-services/icons/erc3643-rwa-token-development.png"
                  width="75"
                  height="75"
                  class="img-fluid"
                  alt="erc3643 rwa token development"
                  title="erc3643 rwa token development" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">ERC3643 RWA Token Development</h3>
            <p>
                We customize your ERC3643 token based on your needs and requirements to cater to the best possible for you.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc3643-token-development-services/icons/token-consultation-services.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/erc3643-token-development-services/icons/token-consultation-services.png"
                  width="75"
                  height="76"
                  class="img-fluid"
                  alt="token consultation services"
                  title="token consultation services" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">Token Consultation Services</h3>
            <p>
                We provide end-to-end token consultation services that will help you understand clearly the market values and token performances.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc3643-token-development-services/icons/token-generator-development.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/erc3643-token-development-services/icons/token-generator-development.png"
                  width="75"
                  height="76"
                  class="img-fluid"
                  alt="token generator development"
                  title="token generator development" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">Token Generator Development</h3>
            <p>
                We help you create a whole new custom-built Token generator, a platform to create new tokens for crypto projects with other functions.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc3643-token-development-services/icons/deFi-platforms.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/erc3643-token-development-services/icons/deFi-platforms.png"
                  width="75"
                  height="76"
                  class="img-fluid"
                  alt="defi platforms"
                  title="defi platforms" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">DeFi Platforms</h3>
            <p>
                We develop a versatile Decentralized finance platform to record and make crypto transactions happen with ease.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc3643-token-development-services/icons/token-launchpad-marketplace.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/erc3643-token-development-services/icons/token-launchpad-marketplace.png"
                  width="75"
                  height="76"
                  class="img-fluid"
                  alt="token launchpad / marketplace"
                  title="token launchpad / marketplace" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">Token Launchpad / Marketplace</h3>
            <p>
                We create a well-equipped token launchpad and marketplaces for an efficient display of crypto assets enabled to trade, buy, sell, and more.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc3643-token-development-services/icons/crypto-wallet-development.webp" />

                <img loading="lazy"
                  data-src="assets-new/images/erc3643-token-development-services/icons/crypto-wallet-development.png"
                  width="76"
                  height="76"
                  class="img-fluid"
                  alt="crypto wallet development"
                  title="crypto wallet development" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">Crypto Wallet Development</h3>
            <p>
                We also develop crypto wallets that are safe, secure, and can be integrated with multiple networks at ease.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/erc3643-token-development-services/icons/smart-contract-audits.webp" />

                  <img loading="lazy"
                    data-src="assets-new/images/erc3643-token-development-services/icons/smart-contract-audits.png"
                    width="76"
                    height="76"
                    class="img-fluid"
                    alt="smart contract audits"
                    title="smart contract audits" />
                </picture>
              </div>
              <h3 class="erc-20-head-alt text-left h-4">Smart Contract Audits</h3>
              <p>
                We code first-hand, high-quality contracts that are audited and approved to have precision and accuracy.
              </p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/erc3643-token-development-services/icons/token-migrations.webp" />

                  <img loading="lazy"
                    data-src="assets-new/images/erc3643-token-development-services/icons/token-migrations.png"
                    width="76"
                    height="76"
                    class="img-fluid"
                    alt="token migrations"
                    title="token migrations" />
                </picture>
              </div>
              <h3 class="erc-20-head-alt text-left h-4">Token Migrations</h3>
              <p>
                We also with our Token migration service help you upgrade your tokens to a powered-up version competing with the market standards.
              </p>
            </div>
        </div>
      </div>
      <div class="text-center mt-4 mt-lg-5 col-lg-12">
        <a class="our_expert_talk" href="https://www.blockchainx.tech/spl404-token-development-company/#item-1" (click)="addClass(1)">Connect With Our Experts</a>
      </div>
    </div>
</div>

<section class="section-service-alt">
    <div class="container">
      <div class="row mb-lg-5 mb-4">
        <div class="col-lg-10 mx-auto">
          <div class="whys_section mb-0 text-center">
            <h2 class="text-center pb-0 mb-3 h-2"><span>ERC3643 RWA Token</span> Development Process</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 d-lg-block d-none">
          <div class="casetudies_common">
            <picture>
              <source type="image/webp" srcset="assets-new/images/erc3643-token-development-services/erc3643-rwa-token-development-process.webp" />
              <img loading="lazy" data-src="assets-new/images/erc3643-token-development-services/erc3643-rwa-token-development-process.png" width="500" height="500" class="img-fluid pb-md-0 pb-3" alt="erc3643 rwa token development process" title="erc3643 rwa token development process" *ngIf="isDarkmode" />
            </picture>
            <picture>
              <source type="image/webp" srcset="assets-new/images/erc3643-token-development-services/erc3643-rwa-token-development-process-light.webp" />
              <img loading="lazy" data-src="assets-new/images/erc3643-token-development-services/erc3643-rwa-token-development-process-light.png" width="500" height="500" class="img-fluid pb-md-0 pb-3" alt="erc3643 rwa token development process" title="erc3643 rwa token development process" *ngIf="!isDarkmode" />
            </picture>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Background Analysis</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    We start by understanding your requirements for token development, and do certain market research on the ERC3643 token’s current situation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Asset Choice</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    We gather information on the asset that is chosen to be digitalized on the blockchain network. This includes metadata information, token values, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3" >Blockchain and Infrastructure</h3>
                  </div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                    Then we step ahead to choose the blockchain and infrastructure of the token we develop. Token supply, its functions, and abilities are all jotted.
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Token Distributions Norms</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    The token distribution norms and its automated attributes are all coded along with a few tech integrations to support the wallet, understand commands, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Smart Contract Integration</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    Token smart contracts are coded and integrated. They are precisely audited ensuring the credibility of the token to perform the delegated automation rightly.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Testing and Main Launch</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    Getting into the main zone, we put your ERC3643 RWA token for a test to ensure the quality of the token. Once approved it moved to the main launch.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3" >DeFi Listing Services</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    Then the token is eligible for listing in different Decentralized platforms for trade, purchasing, and improved liquidity. It can be staked and yielded based on need.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Further Token Upgradation</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    We are open to also customizing your token anymore further based on your needs and the feedback from the launch.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>

<section class="section-service-main">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-0">
            <h2>
              Why Choose BlockchainX<span>  For ERC3643 RWA Token Development?</span>
            </h2>
            <p class="pb-3">
                BlockchainX intends to transform and contribute toward global digitalization. We power you with solid, secured solutions developed out of our 7 years of experience. We cater customized solutions for business operations and projects. Our major focus relies on making technology more accessible and relevant. With that said, as an ERC3643 token development company we provide structured, audited, and secured tokens representing the digital version of the actual real-world asset in the blockchain networks. We care about concern and security, therefore protecting data while being transparent on the network. We extend
            </p>
            <div class="whysection_text_wrap">
              <ul>
                <li>24-hour support.</li>
                <li>Post-launch services.</li>
                <li>Customized Solutions.</li>
                <li>A-grade Business Solutions.</li>
                <li>Efficient and Impressive.</li>
              </ul>
            </div>
            <div class="content-link my-3">
              <a href="https://blockchainx.tech/contact">
                <span>Get In Touch</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="arrow" title="arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="arrow" title="arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side d-flex justify-content-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc3643-token-development-services/why-choose-blockchainx.webp" />

              <img loading="lazy"
                data-src="assets-new/images/erc3643-token-development-services/why-choose-blockchainx.png"
                width="500"
                height="500"
                class="img-fluid"
                alt="why choose blockchainX for erc3643 rwa token development"
                title="why choose blockchainX for erc3643 rwa token development" *ngIf="isDarkmode"/>
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc3643-token-development-services/why-choose-blockchainx-light.webp" />

              <img loading="lazy"
                data-src="assets-new/images/erc3643-token-development-services/why-choose-blockchainx-light.png"
                width="500"
                height="500"
                class="img-fluid"
                alt="why choose blockchainX for erc3643 rwa token development"
                title="why choose blockchainX for erc3643 rwa token development" *ngIf="!isDarkmode"/>
            </picture>
          </div>
        </div>
      </div>
    </div>
</section>

<!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              Frequently Asked Question <span class="dots"></span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    What does ERC3643 Token mean?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        The straightforward meaning of the ERC3634 token is that this is a token standard used to deploy RWA asset representation on the blockchain. Which is possible to fractionalise, trade easily, and be compatible to interoperate.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    How does it benefit token owners?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        The token owners benefit from the entry barrier. Any small investment can provide an opportunity to gather crypto digital tokens representing a portion of an asset in the real world. ERC3643 tokens can be used in various methods like staking, vesting, yielding and lending, borrowing, and more.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    What are the different use cases of ERC3643 RWA tokens?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        ERC3643 RWA token development is a great fit for various sectors utilized for its versatility in utility. They can be used in healthcare, digitized machinery, the Educational sector, logistics, luxury items, Art and creative collectibles, and more.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour">
                    Is ERC3643 token development profitable?
                  </button>
                </div>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        Yes, of course, the ERC3643 token standard is completely a profitable option. Especially its ability for fractionalising is major proof to assure the opportunity it brings and contributes towards the growth aspects.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive">
                    How long does it take to develop ERC3643 tokens?
                  </button>
                </div>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        Developing ERC3643 tokens which is the standard for Real-world asset tokenization, takes roughly 2 to 4 months with the required customization.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
</section>
<!-- FAQ End -->

<section class="section-service-main">
    <app-blog></app-blog>
</section>

<section class="section-service-main" id="item-1" [ngClass]="selectedLink === 1 ? 'scrollHeading' : 'scrollheadingone'">
  <app-get-in-touch></app-get-in-touch>
</section>
