import {
  Component,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Title,
  Meta
} from '@angular/platform-browser';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-blockchain-consulting',
  templateUrl: './blockchain-consulting.component.html',
  styleUrls: ['./blockchain-consulting.component.css'],
})
export class BlockchainConsultingComponent implements OnInit {

  isDarkmode:boolean=false;
  title =
    'Blockchain Consulting Services to Unleash Your Potential';

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService
  ) {}

  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Reach out to Blockchain for on-point blockchain consultation services, we provide the utmost detailed and nuantic guidance and support for your crypto project.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Blockchain consultant, Blockchain Consulting services, Blockchain consulting firm, Blockchain development consulting, Blockchain business consultants, Blockchain advisory services, Blockchain advisory services',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Blockchain Consulting Services to Unleash Your Potential',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/blockchain-consulting-services/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Reach out to Blockchain for on-point blockchain consultation services, we provide the utmost detailed and nuantic guidance and support for your crypto project.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/blockchain-consulting.png',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/blockchain-consulting-services/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Blockchain Consulting Services to Unleash Your Potential',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Reach out to Blockchain for on-point blockchain consultation services, we provide the utmost detailed and nuantic guidance and support for your crypto project.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/blockchain-consulting.png',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/blockchain-consulting-services/'
    );
  }

}
