import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-bep-new',
  templateUrl: './bep-new.component.html',
  styleUrls: ['./bep-new.component.css'],
})
export class BepNewComponent implements OnInit {
  isDarkmode:boolean=false;
  title =
    'Race With BEP20 Token Development Facilitating Customized Functional';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });

    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'From fundraising to issuance, we help businesses and projects with BEP20 token development at BlockchainX. Our end-to-end BEP20 Token development services cover all the requirements and act on them. Connect with us, your BEP20 Token development company, and get to set trade marks in your business functions. ',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Bep20 token Development Company, Bep20 token Development Services, create bep20 token',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Race With BEP20 Token Development Facilitating Customized Functional',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/bep20-token-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'From fundraising to issuance, we help businesses and projects with BEP20 token development at BlockchainX. Our end-to-end BEP20 Token development services cover all the requirements and act on them. Connect with us, your BEP20 Token development company, and get to set trade marks in your business functions. ',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/bep20-tokens.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/bep20-token-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Race With BEP20 Token Development Facilitating Customized Functional',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'From fundraising to issuance, we help businesses and projects with BEP20 token development at BlockchainX. Our end-to-end BEP20 Token development services cover all the requirements and act on them. Connect with us, your BEP20 Token development company, and get to set trade marks in your business functions. ',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/bep20-tokens.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/bep20-token-development/'
    );
  }
  tokenOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    dots: false,
    autoHeight: true,
    margin: 25,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 2,
      },

      1024: {
        items: 3,
      },

      1366: {
        items: 3,
      },
    },
    nav: false,
  };
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    navSpeed: 500,
    margin: 30,
    dots: false,
    center: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      640: {
      items: 3,
      },
      740: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    nav: false,
  };
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  }
}
