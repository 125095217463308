  import { Component, OnInit, Inject } from '@angular/core';
  import { DOCUMENT, ViewportScroller } from '@angular/common';
  import { Title, Meta } from '@angular/platform-browser';
  import { ActivatedRoute,Router } from '@angular/router';
  import { CanonicalService } from '../../shared/canonical.service';
  import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

  @Component({
    selector: 'app-asset-tokenization-on-various-industries',
    templateUrl: './asset-tokenization-on-various-industries.component.html',
    styleUrls: ['./asset-tokenization-on-various-industries.component.css']
  })
  export class AssetTokenizationOnVariousIndustriesComponent implements OnInit {

      selectedLink:number;
      title ='Asset tokenization on various industries : 9 Industries to watch';

      constructor(
        public router: Router,
        private titleService: Title,
        private meta: Meta,
        private canonicalService: CanonicalService,
        private footservice:FooterServiceService,
        @Inject(DOCUMENT) private doc: Document
      ) { }

      isDarkmode:boolean=false;

      ngOnInit(): void {
        //darkmode services
        this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
          if (response) {
            this.isDarkmode = response;
          }
          else {
            this.isDarkmode = response;
          }
        });
        this.titleService.setTitle(this.title);

        this.meta.updateTag({
          name: 'description',
          content:
            'Explore asset tokenization on various industries! Discover how blockchain is transforming asset ownership in 9 sectors. Learn more about this digital shift!',
        });
        this.meta.updateTag({
          name: 'keywords',
          content:
            'Asset tokenization, asset tokenization on various industries, asset tokenization  industries, tokenized asset, tokenize physical assets, tokenization of financial assets, tokenization investments, tokenization of real world assets',
        });


        this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
        this.meta.updateTag({ name: 'robots', content: 'index, follow' });

        this.meta.updateTag({
          property: 'og:title',
          content:
            'Asset tokenization on various industries : 9 Industries to watch',
        });
        this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
        this.meta.updateTag({
          property: 'og:url',
          content:
            'https://www.blockchainx.tech/asset-tokenization-on-various-industries/',
        });
        this.meta.updateTag({
          property: 'og:description',
          content:
            'Explore asset tokenization on various industries! Discover how blockchain is transforming asset ownership in 9 sectors. Learn more about this digital shift!',
        });
        this.meta.updateTag({ property: 'og:type', content: 'Website' });
        this.meta.updateTag({
          property: 'og:image',
          content:
            'https://www.blockchainx.tech/assets-new/images/blog/asset-tokenization-on-various-industries/asset-tokenization-on-various-industries.webp',
        });

        this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
        this.meta.updateTag({
          name: 'twitter:site',
          content:
            'https://www.blockchainx.tech/asset-tokenization-on-various-industries/',
        });
        this.meta.updateTag({
          name: 'twitter:title',
          content:
            'Asset tokenization on various industries : 9 Industries to watch',
        });
        this.meta.updateTag({
          name: 'twitter:description',
          content:
            'Explore asset tokenization on various industries! Discover how blockchain is transforming asset ownership in 9 sectors. Learn more about this digital shift!',
        });
        this.meta.updateTag({
          name: 'twitter:image',
          content:
            'https://www.blockchainx.tech/assets-new/images/blog/asset-tokenization-on-various-industries/asset-tokenization-on-various-industries.webp',
        });

        // Add Canonical tag
        this.canonicalService.setCanonicalURL(
          'https://www.blockchainx.tech/asset-tokenization-on-various-industries/'
        );
        this.createLink();
      }
      createLink() {
        this.canonicalService.destroyLinkForAmpURL();
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'amphtml');
        link.setAttribute(
          'href',
          'https://www.blockchainx.tech/amp/asset-tokenization-on-various-industries.html'
        );
        this.doc.head.appendChild(link);
      }
      addClass(id:number){
        this.selectedLink=id;
      }
    }


