<!-- Banner Section starts -->
<div class="banners-section">
  
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span> <strong> R3 Corda Blockchain</strong> </span>
              </h1>
              <p>
                R3 Corda is an enterprise-grade multilateral ledger solution for
                businesses. Apart from others, Corda blockchain development
                enables even smaller firms to build blockchain solutions without
                having to start from scratch. Corda is specifically designed for
                enterprises where time is money and efficiency is paramount.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/corda/webp/r3-corda.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/corda/r3-corda.png" />

              <img loading="lazy"
                data-src="assets-new/images/corda/r3-corda.png"
                alt="R3 corda blockchain development company"
                title="R3 corda blockchain development company"
                width="400"
                height="400"
                class="img-fluid" />
            </picture>
          </div>
        </div>
      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Banner Section ends -->

<!-- Building Corda Blockchain -->
<div class="section-service-alt" #about>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/corda/webp/blockchain-apps.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/corda/blockchain-apps.png" />

            <img loading="lazy"
              data-src="assets-new/images/corda/blockchain-apps.png"
              alt="R3 corda blockchain development company"
              title="R3 corda blockchain development company"
              width="400"
              height="400"
              class="img-fluid" />
          </picture>
        </div>
      </div>
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
        <div class="metaverse_heading">
          <h3>
            Building blockchain apps on <span> Corda Blockchain Platform</span>
          </h3>
          <p>
            Custom Corda development services automate business processes and
            facilitate reliable peer-to-peer transactions. Several exciting
            features of CorDapps help real-world corporates by simplifying
            integration between organizations, reducing downtime, no global
            sharing of private data, lower compliance costs, and reducing the
            risk of fraud. And Corda cut off your record-keeping costs.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Building Corda Blockchain -->

<!-- Development Services starts -->
<div class="section-service-alt-main">
  <div class="our-process-erc">
    <div class="row">
      <div class="metaverse_heading">
        <h3><span>Corda Blockchain Development</span> Services</h3>
      </div>
    </div>
    <div class="container">
      <div class="row erc-processing">
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5 process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/corda/webp/consulting.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/corda/consulting.png" />

              <img loading="lazy"
                data-src="assets-new/images/corda/consulting.png"
                alt="Corda App Consulting"
                title="Corda App Consulting"
                width="100"
                height="100"
                class="img-fluid pb-2" />
            </picture>
            <h4>Corda App Consulting</h4>
            <p>
              Looking for a Corda Development Company? We have an enterprise
              blockchain expert team who will help you find complete solutions
              for your business.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/corda/webp/development.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/corda/development.png" />

              <img loading="lazy"
                data-src="assets-new/images/corda/development.png"
                alt="Corda App Consulting"
                title="Corda App Consulting"
                width="100"
                height="100"
                class="img-fluid pb-2" />
            </picture>
            <h4>CorDapp Development</h4>
            <p>
              Build enterprise-grade business applications with the highest
              security and privacy with our CordApp development service.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process cmb-5 process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/corda/webp/user-interfaces.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/corda/user-interfaces.png" />

              <img loading="lazy"
                data-src="assets-new/images/corda/user-interfaces.png"
                alt="Corda App Consulting"
                title="Corda App Consulting"
                width="100"
                height="100"
                class="img-fluid pb-2" />
            </picture>
            <h4>User Interfaces</h4>
            <p>
              We build highly user-friendly and industry-standard design for
              your business application through our expert design team.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/corda/webp/smart-contract.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/corda/smart-contract.png" />

              <img loading="lazy"
                data-src="assets-new/images/corda/smart-contract.png"
                alt="Corda App Consulting"
                title="Corda App Consulting"
                width="100"
                height="100"
                class="img-fluid pb-2" />
            </picture>
            <h4>Smart Contracts</h4>
            <p>
              Deploy high-precision and bug-free smart contracts for your
              business application with our Cordapp development service.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/corda/webp/regulated-tokens.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/corda/regulated-tokens.png" />

              <img loading="lazy"
                data-src="assets-new/images/corda/regulated-tokens.png"
                alt="Corda App Consulting"
                title="Corda App Consulting"
                width="100"
                height="100"
                class="img-fluid pb-2" />
            </picture>
            <h4>Regulated Tokens</h4>
            <p>
              Corda blockchain development allows you to regulate specialized
              tokens that can handle enterprise-scale financial transactions.
            </p>
          </div>
        </div>
        <div class="col-lg-6 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/corda/webp/create-custom-cordap.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/corda/create-custom-cordap.png" />

              <img loading="lazy"
                data-src="assets-new/images/corda/create-custom-cordap.png"
                alt="Corda App Consulting"
                title="Corda App Consulting"
                width="100"
                height="100"
                class="img-fluid pb-2" />
            </picture>
            <h4>Create Custom CorDap</h4>
            <p>
              CordApp Development helps you create blockchain applications that
              emphasize privacy, security, and scalability for your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Development Services ends -->

<!-- Development Demand starts -->
<div class="section-service-alt-padd">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mb-4">
          <h3>
            Future-Oriented
            <span> R3 Corda Development Solutions </span> Meeting 360-Degree
            Demands
          </h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/corda/webp/r3-consulting.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/corda/r3-consulting.png" />

                <img loading="lazy"
                  data-src="assets-new/images/corda/r3-consulting.png"
                  alt="Corda App Consulting"
                  title="Corda App Consulting"
                  width="76"
                  height="76"
                  class="img-fluid" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">R3 Corda App Consulting</h4>
            <p>
              Corda blockchain development allows you to create private
              blockchain solutions that can better handle confidential data.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/corda/webp/smart-contracts.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/corda/smart-contracts.png" />

                <img loading="lazy"
                  data-src="assets-new/images/corda/smart-contracts.png"
                  alt="Corda App Consulting"
                  title="Corda App Consulting"
                  class="img-fluid"
                  width="76"
                  height="76" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Smart Contracts</h4>
            <p>
              Corda blockchain development allows you to create private
              blockchain solutions that can better handle confidential data.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/corda/webp/ui-design.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/corda/ui-design.png" />

                <img loading="lazy"
                  data-src="assets-new/images/corda/ui-design.png"
                  alt="Corda App Consulting"
                  title="Corda App Consulting"
                  width="76"
                  height="76"
                  class="img-fluid" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">UI Design</h4>
            <p>
              Corda blockchain development allows you to create private
              blockchain solutions that can better handle confidential data.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/corda/webp/custom-cord-app.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/corda/custom-cord-app.png" />

                <img loading="lazy"
                  data-src="assets-new/images/corda/custom-cord-app.png"
                  alt="Corda App Consulting"
                  title="Corda App Consulting"
                  width="76"
                  height="76"
                  class="img-fluid" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Custom Cord App</h4>
            <p>
              Cordapp development helps you create blockchain applications for
              your business that emphasize privacy, security, and scalability
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/corda/webp/regulated-tokens-development.webp
                  " />

                <source
                  type="image/png"
                  srcset="
                    assets-new/images/corda/regulated-tokens-development.png
                  " />

                <img loading="lazy"
                  data-src="assets-new/images/corda/regulated-tokens-development.png"
                  alt="Corda App Consulting"
                  title="Corda App Consulting"
                  width="76"
                  height="76"
                  class="img-fluid" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
              Regulated Tokens Development
            </h4>
            <p>
              Corda blockchain development allows you to create specialized
              tokens that can handle enterprise-scale financial transactions.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 token-detail">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/corda/webp/cordapp-development.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/corda/cordapp-development.png" />

                <img loading="lazy"
                  data-src="assets-new/images/corda/cordapp-development.png"
                  alt="Corda App Consulting"
                  title="Corda App Consulting"
                  width="76"
                  height="76"
                  class="img-fluid" />
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">CorDapp Development</h4>
            <p>
              Corda blockchain development allows you to create private
              blockchain solutions that can better handle confidential data.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Development Demand ends -->

<!-- development Tools starts -->
<div class="section-service-main">
  <div class="develop-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading">
            <h3>Why <span> Corda?</span></h3>
          </div>
        </div>
      </div>
      <div class="row justify-content-center erc-about-content">
        <div class="col-lg-4 col-md-12 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/corda/webp/point-to-point-architecture.webp
                " />

              <source
                type="image/png"
                srcset="assets-new/images/corda/point-to-point-architecture.png" />

              <img loading="lazy"
                data-src="assets-new/images/corda/point-to-point-architecture.png"
                alt="Corda App Consulting"
                title="Corda App Consulting"
                class="img-fluid"
                width="150"
                height="150" />
            </picture>
            <h4 class="erc-20-head">Point-to-point Architecture</h4>
            <p class="text-center">
              The Corda's point-to-point architecture provides high-end security
              by allowing permission only to those who participate in
              transactions.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/corda/webp/multilateral-ledger.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/corda/multilateral-ledger.png" />

              <img loading="lazy"
                data-src="assets-new/images/corda/multilateral-ledger.png"
                alt="Corda App Consulting"
                title="Corda App Consulting"
                class="img-fluid"
                width="150"
                height="150" />
            </picture>
            <h4 class="erc-20-head">Multilateral Ledger</h4>
            <p class="text-center">
              Corda differs from other blockchains by allowing copies of
              transactions to be issued only to observers among participants.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12 text-center">
          <div class="erc-about-picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/corda/webp/pluggable-consensus.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/corda/pluggable-consensus.png" />

              <img loading="lazy"
                data-src="assets-new/images/corda/pluggable-consensus.png"
                alt="Corda App Consulting"
                title="Corda App Consulting"
                class="img-fluid"
                width="150"
                height="150" />
            </picture>
            <h4 class="erc-20-head">Pluggable Consensus</h4>
            <p class="text-center">
              The pluggable consensus mechanism of the Corda blockchain allows
              you to make changes to your platform without any downtime.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- development Tools ends -->

<!-- Advantages -->
<div class="section-service-alt">
  <div class="container">
    <div class="metaverse_heading">
      <h3>
        Advantages That You Obtain With <span> Corda Development Services</span>
      </h3>
    </div>
    <div class="row acc-sec-list">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-image text-center">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/corda/webp/advantages-that-you-obtain-with-corda-development-services.webp" />
          <source type="image/png"
            srcset="assets-new/images/corda/advantages-that-you-obtain-with-corda-development-services.png" />
          <img loading="lazy"
            data-src="assets-new/images/corda/advantages-that-you-obtain-with-corda-development-services.png"
            width="350" height="500" class="img-fluid" alt="Corda development"
            title="Corda development" />
        </picture>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 acc-list">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <div class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne">
                Unique
              </button>
            </div>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  Enhance your user privacy and security with our unique R3
                  Corda development service.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo">
                Secure
              </button>
            </div>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  R3 Corda is the go-to solution if you need to make your
                  financial product secure with blockchain.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree">
                Transparent
              </button>
            </div>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  The right consensus time stamping of R3 Corda ensures the
                  transparency of your transactions.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour">
                High Potential
              </button>
            </div>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  Our high-potential Corda consensus model guarantee assets have
                  deterministic settlement finality.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive">
                Pleasant Experience
              </button>
            </div>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  R3 Corda caters to all capabilities grounded in legal
                  construct and is compatible with emerging standards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Advantages -->

<!-- Why Corda Chain starts -->
<div class="section-service-main">
  <div class="container">
    <div class="metaverse_heading">
      <h3>
        Why <span>Corda Blockchain Platform</span> is NextGen digital trust
        solution?
      </h3>
    </div>

    <div class="row why-nft-content">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12">
        <p>
          A fast-paced business environment demands accuracy, efficiency, and
          scalability as the business grows. Enterprises need a blockchain
          solution to handle highly confidential information without worrying
          about security. Corda development services are the best solution for
          that.
        </p>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 col-12 why-nft-read-more">
        <div class="content-link">
          <a [routerLink]="['/contact']">
            <span>View Demo</span>
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/right-arrow.webp">
              <source type="image/png"
                srcset="assets-new/images/right-arrow.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                 />
            </picture>
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/right-arrow-dark.webp">
              <source type="image/png"
                srcset="assets-new/images/right-arrow-dark.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                 />
            </picture>
          </a>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Why Corda Chain  ends-->

<!-- Our Execution Process -->
<div class="section-service-main-last">
  <div class="container">
    <div class="row">
      <div class="col-lg-5 nft-service-list">
        <div class="casetudies_common">
          <h3 class="main-sub-heading">Our Execution Process</h3>

          <div class="casestudies_para">
            <p>
              We provide a wide range of custom Corda development services that
              automate business processes and facilitate reliable peer-to-peer
              transactions. Our agile execution process gives you a clear
              picture of an unbeatable R3 Corda development service.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-7 case-list">
        <div class="row">
          <div class="col-lg-12 case-study-list-main">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>PoC Development</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  An extensive demo of your project that reflects the real-world
                  scenario of design sketches and information architecture as
                  interactive prototypes. It ensures that your idea or service
                  is viable in the market.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 case-study-list-main">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Visual & Technical Design</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  The success of a product lies in the hands of users, so this
                  part is crucial. Our design team experts work together to
                  create UI/UX designs that positively impact the user
                  experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 case-study-list-main">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Development</h3>
              </div>

              <div class="casestudy_para">
                <p>
                  Core blockchain development creates a private blockchain that
                  provides reliable solutions to users. This can better handle
                  private data before making the application available to the
                  end-users deployment.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 case-study-list-main">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Deployment</h3>
              </div>
              <div class="casestudy_para">
                <p>
                  Make ready the product for testing and then deploy it into the
                  market for the result. We ensures that the applications,
                  prototypes, updates, and patches are delivered from developers
                  to users.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 case-study-list-main">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <h3>Maintenance & Upgrades</h3>
              </div>

              <div class="casestudy_para">
                <p>
                  Not limited to the deployment of a product, we provide
                  post-implementation support and maintenance. We work with our
                  clients from scratch by leveraging industry best practices for
                  updates or maintenance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr class="case_studies_hr" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Our Execution Process -->
<section class="why_choose_us">
  <div class="why-alt-section light-background">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container choosing-section">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-2">
            <h2>
              Why Choose BlockchainX for <span> R3 Corda Development? </span>
            </h2>
          </div>
          <div class="whysection_text_wrap">
            <p>
              Our blockchain experts in Corda development with our hands-on
              experience in field knowledge keep us going further. Our
              one-step-ahead teamwork always ensures cost-effectiveness and
              timely delivery of your products. We believe highly in being
              transparent with the way we work.
            </p>
            <div class="content-link my-3">
              <a [routerLink]="['/contact']">
                <!-- <span>Read More</span> -->
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/metaverse/why_blockchain.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/metaverse/why_blockchain.png" />

              <img loading="lazy"
                data-src="assets-new/images/metaverse/why_blockchain.png"
                width="550"
                height="450"
                class="img-fluid"
                alt="why-blockchainX"
                title="why-blockchainX" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main">
  <app-blog></app-blog>
</section>
<!-- <section class="section-service-alt-padd">
  <app-contactform></app-contactform>
</section> -->
<section class="section-service-main">
  <app-get-in-touch></app-get-in-touch>
</section>