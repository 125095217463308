import {
  Component,
  OnInit
} from '@angular/core';
import {
  Title,
  Meta
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-derivatives',
  templateUrl: './derivatives.component.html',
  styleUrls: ['./derivatives.component.css']
})
export class DerivativesComponent implements OnInit {
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService
  ) {}


  isDarkmode:boolean=false;
  title = 'Derivatives Development Securing Assets Tradability And Safety';

  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        "Derivatives development refers to creating and deploying financial product derivatives and platforms using blockchain technology. Let's indulge in detail here.",
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'derivatives,derivatives development ,derivatives exchange development,derivatives development company',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Derivatives Development Securing Assets Tradability And Safety',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/derivatives-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        "Derivatives development refers to creating and deploying financial product derivatives and platforms using blockchain technology. Let's indulge in detail here.",
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/derivatives-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/derivatives-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Derivatives Development Securing Assets Tradability And Safety',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        "Derivatives development refers to creating and deploying financial product derivatives and platforms using blockchain technology. Let's indulge in detail here.",
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/derivatives-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/derivatives-development/'
    );
  }

  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };

}
