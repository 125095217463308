// tracking.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  constructor() {}

  // Track the initial referrer
  trackReferrer(): void {
    if (!localStorage.getItem('userReferrer')) {
      const referrer = document.referrer;
      console.log(referrer);

      const source = referrer ? new URL(referrer).hostname : 'direct';
      localStorage.setItem('userReferrer', source);
    }
  }

  // Track every page visited
  trackPageVisit(): void {
    const pageVisited = window.location.href;
    const pagesVisited = JSON.parse(localStorage.getItem('pagesVisited') || '[]');
    // if (!pagesVisited.includes(pageVisited)) {
      pagesVisited.push(pageVisited);
      localStorage.setItem('pagesVisited', JSON.stringify(pagesVisited));
      localStorage.setItem('lastPageVisited', pageVisited); // Track last visited page
    // }
  }

  // Clear the pagesVisited array (after form submission)
  clearPageVisits(): void {
    localStorage.removeItem('userReferrer');
    localStorage.removeItem('lastPageVisited');
    localStorage.removeItem('pagesVisited');
  }
}
