<!-- Banner Section -->
<section class="banners-section">
    <div class="banner_wrapper">
        <div class="container" id="banner-page-vector-main">
            <div class="green-vector"></div>
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                    <div class="banner_contentWrapper">
                        <div class="caption">
                            <h1 class="pb-3">
                                <span><strong>Web3 Development </strong> Company
                                </span>
                            </h1>
                            <div class="common-sub-heading-alt pb-2">Unleash the True Potential Of Web3 Apps</div>
                            <p>
                                Build Web3 apps and scalable decentralized platforms with the help of our Web3 development company. Hire industry leading developers who can understand your business use cases and build user-centric platforms quickly.
                            </p>
                            <div class="erc-link">
                                <div class="content-link">
                                    <a
                                        href="https://t.me/sparkouttechblockchainx"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <span>Telegram</span>
                                        <img loading="lazy"
                                        src="assets-new/images/right-arrow.png"
                                        alt="link-arrow"
                                        class="img-fluid"
                                        width="50"
                                        height="15"
                                        *ngIf="!isDarkmode" />
                                      <img loading="lazy"
                                        src="assets-new/images/right-arrow-dark.png"
                                        alt="link-arrow"
                                        class="img-fluid"
                                        width="50"
                                        height="15"
                                        *ngIf="isDarkmode" />
                                    </a>
                                </div>
                                <div class="content-link">
                                    <a
                                        href="https://www.blockchainx.tech/contact/">
                                       <span>Demo</span>
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="!isDarkmode" />
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow-dark.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="isDarkmode" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12 col-sm-12 text-center">
                    <div class="banner-right-section">
                        <picture>
                            <source
                                type="image/webp"
                                srcset="
                        assets-new/images/web3-development/web3-development-company.webp
                    " />

                            <source
                                type="image/png"
                                srcset="
                    assets-new/images/web3-development/web3-development-company.png
                    " />

                            <img loading="lazy"
                                data-src="assets-new/images/web3-development/web3-development-company.png"
                                class="img-fluid"
                                alt="web3 development company"
                                width="479"
                                height="474" />
                        </picture>
                    </div>
                </div>
            </div>

            <div class="row d-lg-block d-none">
                <div class="scroll-section text-center"
                    (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="assets-new/images/home/scroll.webp" />
                        <source
                            type="image/png"
                            srcset="assets-new/images/home/scroll.png" />
                        <img loading="lazy"
                            data-src="assets-new/images/home/scroll.png"
                            width="25"
                            height="48"
                            class="img-fluid"
                            alt="scroll-icon"
                            title="scroll-icon" />
                    </picture>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- Banner Section ends -->
<section class="section-service-alt about-section-clients">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>
            Our<span>Clients</span>
          </h3>
        </div>
      </div>
      <div class="row client-carousel">
        <div class="col-lg-12 mx-auto">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
                </picture>
              </div>
            </ng-template>
  
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
                </picture>
              </div>
            </ng-template>
  
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
                </picture>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="ourclients">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                  <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
                </picture>
              </div>
            </ng-template>
  
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
<!-- Why Choose DAO Platform Development start -->
<section class="section-service-main" #about>
    <div class="container">
        <div class="row align-items-center">
            <div
                class="col-lg-5 mx-auto col-md-6 col-sm-12 col-12 order-md-first order-last">
                <div class="bep-work-section text-center">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="assets-new/images/web3-development/what-is-web3-development.webp" />

                        <source
                            type="image/png"
                            srcset="assets-new/images/web3-development/what-is-web3-development.png" />

                        <img loading="lazy"
                            data-src="assets-new/images/web3-development/what-is-web3-development.png"
                            class="img-fluid"
                            alt="What Is Web3 Development?"
                            width="500"
                            height="500" />
                    </picture>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 about-content">
                <div class="whys_section mb-0">
                    <h2>Why choose <span>Web3 for your business?</span></h2>
                    <p class="pb-3">
                        As a tech entrepreneur, breaking into Web3 can be your opportunity to quickly position yourself as a leading business in the market and provide future proof offerings for your users. In a nutshell, Web3 is the decentralized web where user interactions and monetary transactions work on the blockchain, using self-executing programs called smart contracts. This brings you as the entrepreneur the ability to offer decentralized versions of traditional tech such as Financial Applications, Gaming, Enterprise Applications, and much more.
                    </p>

                    <p><i class="bi bi-check-lg"></i> Get custom web3 development for your projects</p>
                    <p><i class="bi bi-check-lg"></i> Explore new revenue opportunities through web3</p>
                    <p><i class="bi bi-check-lg"></i> Streamline your business processes on the blockchain</p>
                    <p class="pb-sm-0 pb-2"><i class="bi bi-check-lg"></i>
                        Ensure data security while protecting transparency</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Why Choose DAO Platform Development end -->

<!-- Difference b/w Web2 and Web3 starts -->
<section class="section-service-alt">
    <div class="types-of-erc text-center">
        <div class="container">
            <div class="build-web3-solution">
                <div class="row justify-content-center mb-3">
                    <div class="col-lg-10">
                        <h3 class="main-sub-heading text-center">Empower your business with 
                            <span>Web3 solutions</span>
                        </h3>
                    </div>
                </div>
                <p>
                    Web2 is the present situation of the internet used every day - a centralized system controlled by dominant tech facilitators.The major drawback in Web2 is control and limited user access, while that is addressed in Web3. It represents the new era of the internet, built on decentralized technology; provides transparency, security, and user control. As a leading service provider among web3 development companies, the team at BlockchainX can help you build customized Web3 solutions with expert guidance to help you deliver the best user experience for your target audience.
                </p>
                <div class="discuss-link">
                    <a href="/contact"><span>Start Building Your Web3 Platform now</span>
                        <img loading="lazy"
                                            src="assets-new/images/right-arrow.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="!isDarkmode" />
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow-dark.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="isDarkmode" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Ends -->

<!-- Our Process start -->
<section class="section-service-main">
    <div class="our-process-erc">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h3 class="main-sub-heading">Our <span>Web3
                            Development</span> Services At BlockchainX</h3>
                    <!-- <p class="text-center pb-3">
                    How our NFT staking platform development works
                </p> -->
                </div>
            </div>
            <!-- Fast Transactions Starts -->
            <div class="row erc-processing justify-content-center">
                <div class="col-lg-4 metmb-3">
                    <div class="virtual_process">
                        <h4>Web3 Platform Development</h4>
                        <p>
                            Build full scale on chain software solutions that cater to multiple use cases such as finance, insurance, productivity, and much more.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 metmb-3">
                    <div class="virtual_process">
                        <h4>Web3 Integration Services</h4>
                        <p>
                            We integrate Web3 services into your pre-existing platform that is double-guarded, backed, and secured with Decentralization.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 metmb-3">
                    <div class="virtual_process">
                        <h4>Web3 Application Development</h4>
                        <p>
                            With blockchain integration, your day-to-day application levels up its standard to a new horizon with the perks of decentralization. 
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 metmb-3">
                    <div class="virtual_process">
                        <h4>Web3 Real Estate Platform Development</h4>
                        <p>
                            Real estate in Web3 is a kick-off to bring a mutual connection with the physical and digital world that is safer and sound.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 metmb-3">
                    <div class="virtual_process">
                        <h4>Web3 Games Development</h4>
                        <p>
                            Games are redefined and highly capable of monetizing with the power of blockchain that provides Play to earn opportunities.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 metmb-3">
                    <div class="virtual_process">
                        <h4>Web3 E-commerce Services</h4>
                        <p>
                            Now track the complete logistic chain from production until reaching your customer integrating Web3 service in E-commerce.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 metmb-3">
                    <div class="virtual_process">
                        <h4>Web3 NFTs</h4>
                        <p>
                            Monetize your creative side with potential Web3 NFTs that are backed by blockchain and ensure ownership and authority.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 metmb-3">
                    <div class="virtual_process">
                        <h4>Web3 Social Media development</h4>
                        <p>
                            A decentralized communication and content-sharing platform with the ability to be open, secure, and safe.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 metmb-3">
                    <div class="virtual_process">
                        <h4>Web3 Enterprise Solutions</h4>
                        <p>
                            These solutions are built for businesses, integrating key aspects such as on chain identity, automated task execution, secure data storage, and much more.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Our Process ends  -->
<!-- Technologies section start -->
<section class="section-service-alt">
    <div class="container">
        <h3 class="main-sub-heading text-center pb-5"> Technologies used in 
            <span> Web3 App Development Services</span>
        </h3>
        <div class="row technologies-row">
           <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="technologies-card position-relative h-100">
                <h4 class="erc-20-head-alt fw-bolder">Interoperability Solutions</h4>
                <p>
                    We believe that the future is cross chain and building effective solutions that can work with other web3 ecosystems is essential for sustainability of your project. We can build interoperable web3 solutions such as dApps that are both scalable and gas efficient.
                </p>
            </div>
           </div>
           <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="technologies-card position-relative h-100">
                <h4 class="erc-20-head-alt fw-bolder">Smart Contracts</h4>
                <p>
                    These are self-executing agreements on blockchain with the capability to automate functions based on predefined parameters and the trigger happens when the pre-set conditions are met. 
                </p>
            </div>
           </div>
           <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="technologies-card position-relative h-100">
                <h4 class="erc-20-head-alt fw-bolder">Web3 Identity</h4>
                <p>
                    The concept of on-chain identity is not just a theory anymore. We can build a highly connected identity solution for your business use cases where you can allow users to interact securely within your ecosystem, enforcing trust while protecting privacy.
                </p>
            </div>
           </div>
           <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="technologies-card position-relative h-100">
                <h4 class="erc-20-head-alt fw-bolder">File systems</h4>
                <p>
                    IPFS or Interplanetary File System is a widely used distributed file system we use in web3 applications to store various files. They are most prominently used to store the media files of NFTs. 
                </p>
            </div>
           </div>
           <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="technologies-card position-relative h-100">
                <h4 class="erc-20-head-alt fw-bolder">Blockchain</h4>
                <p>
                    The ultimatum of Web3 development, Blockchain is a distributed ledger that records transactions in the most transparent manner and securely share across networks for proof and maintenance purpose with utmost security. 
                </p>
            </div>
           </div>
           <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="technologies-card position-relative h-100">
                <h4 class="erc-20-head-alt fw-bolder">Metaverse</h4>
                <p>
                    The metaverse has gained a lot of popularity with brands around the world and for good reason. We can help you create free to rome virtual spaces to host your events, community engagements, and even virtual showrooms for your products.
                </p>
            </div>
           </div>
        </div>
    </div>
</section>
<!-- Technologies section end -->

<!-- Ways to Reward Users starts -->
<section class="section-service-main">
    <div class="types-of-erc">
        <div class="container">
            <div class="row">
                <h3 class="main-sub-heading text-center pb-0">Our extensive 
                    <span> Web3 Development Solutions</span>
                </h3>
            </div>
            <div class="row types-of-token-detail justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/enterprise-blockchain.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/enterprise-blockchain.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/enterprise-blockchain.png"
                                    class="img-fluid"
                                    alt="Enterprise Blockchain Development"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">Enterprise
                            Blockchain Development</h4>
                        <p>
                            Build blockchain-powered applications for business use cases. We specialize in understanding industry pain points and building scalable enterprise blockchain solutions that can help you optimize your business processes, protect data from breaches, and automate task.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/nfts-development.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/nfts-development.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/nfts-development.png"
                                    class="img-fluid"
                                    alt="NFTs Development"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">NFTs
                            Development</h4>
                        <p>
                            NFTs have a main focus with significant attention. They represent ownership and authority to users. It has exponential potential advances in blockchain technology. There are immense opportunities for blockchain development to boom with NFT.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/dapp-development.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/dapp-development.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/dapp-development.png"
                                    class="img-fluid"
                                    alt="Dapp Development"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">Dapp
                            Development</h4>
                        <p>
                            Decentralized Applications are the key area of focus in Web3 development services. Not just trading and commerce, DApps have the potential to disrupt any sector and make any set of operations seamlessly simple and easy.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/token-development.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/token-development.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/token-development.png"
                                    class="img-fluid"
                                    alt="Token Development"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">Token
                            Development</h4>
                        <p>
                            One of the crucial aspects of the Blockchain ecosystem is that it contributes to funding, governance, and operations. Token development gives you an entry into the market and the opportunity to create your own economies. 
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/blockchain-infrastructure.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/blockchain-infrastructure.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/blockchain-infrastructure.png"
                                    class="img-fluid"
                                    alt="Blockchain Infrastructure Development"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">Blockchain
                            Infrastructure Development</h4>
                        <p>
                            We can help you build full-scale layer 1 and layer 2 blockchain solutions to power your Web3 applications. Building you own blockchain allows you to curate the user experience to suit your audience and make applications highly scalable.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Ways to Reward Users ends -->

<!-- Our Real World Asset Tokenization Offerings Start -->
<div class="section-service-alt">
    <div class="types-of-erc">
        <div class="container">
            <div class="row">
                <h3 class="main-sub-heading text-center pb-0"> Industries we serve as a leading 
                    <span> Web3 development company</span>
                </h3>
                <!-- <p>We Provide Tailored Web3 Development for Any</p> -->
            </div>
            <div
                class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 types-of-token-detail justify-content-center industry-web">
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/field.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/field.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/field.png"
                                    class="img-fluid"
                                    alt="Field"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Field</h4>
                        <p>
                            Web3 has the biggest potential to work on fields, from tracking and tracing production it can give you data of end customer price.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/real-estate.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/real-estate.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/real-estate.png"
                                    class="img-fluid"
                                    alt="Real Estate"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Real Estate</h4>
                        <p>
                            Web3 in real estate is a new take to expand the investment ratio to all investors with the concept of fractional ownership.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/education.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/education.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/education.png"
                                    class="img-fluid"
                                    alt="Education"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Education</h4>
                        <p>
                            Know in depth about anything in and around the world easing from identifying the right potential tutor to testing knowledge.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/fashion.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/fashion.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/fashion.png"
                                    class="img-fluid"
                                    alt="Fashion"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Fashion</h4>
                        <p>
                            Fashion is the most quick and frequently evolving sector, where traceability and transparency of Web3 can be a daring addition.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/marketing.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/marketing.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/marketing.png"
                                    class="img-fluid"
                                    alt="Marketing"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Marketing</h4>
                        <p>
                            For better analytics to yield better results in marketing, Web3 innovations with decentralization can be a hit combo.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/game-development.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/game-development.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/game-development.png"
                                    class="img-fluid"
                                    alt="Game Development"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Game Development</h4>
                        <p>
                            Create Play-to-Earn games where user can earn utility tokens with real value by engaging in gaming activities and on chain assets that can be traded on the open marketplace.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/e-commerce.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/e-commerce.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/e-commerce.png"
                                    class="img-fluid"
                                    alt="E-commerce"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">E-commerce</h4>
                        <p>
                            Web3 in e-commerce brings in and acts upon accuracy and transparency and eases the operational flow of your business. 
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/fintech.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/fintech.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/fintech.png"
                                    class="img-fluid"
                                    alt="FinTech"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">FinTech</h4>
                        <p>
                            With the growing technological demand in the sector and with the need to handle hefty data, Fintech is stirring into blockchain and web3 for efficient solutions.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/event.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/event.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/event.png"
                                    class="img-fluid"
                                    alt="Event"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Event</h4>
                        <p>
                            A great use case for web3 is in issuing on chain event passes in the form of NFTs. You can easily RSVP users and provide them with benefits based on their NFT holdings.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/travel-tourism.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/travel-tourism.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/travel-tourism.png"
                                    class="img-fluid"
                                    alt="Travel & Tourism"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Travel & Tourism</h4>
                        <p>
                            Web3 in the travel industry helps you create gamified token economies that use tokens are an incentive to book flights, show loyalty to your brand, and engage with your business.
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="types-erc-detail text-center">
                        <div class>
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/manufacturing.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/manufacturing.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/manufacturing.png"
                                    class="img-fluid"
                                    alt="Manufacturing"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt">Manufacturing</h4>
                        <p>
                            From sourcing raw materials, to precisely locating the production process, blockchain transparency and accuracy will help in the efficient management of the manufacturing process.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Our Real World Asset Tokenization Offerings End -->

<!-- Ways to Reward Users starts -->
<section class="section-service-main">
    <div class="types-of-erc">
        <div class="container">
            <div class="row">
                <h3 class="main-sub-heading text-center pb-md-2 pb-0">
                    <span>Web3 Development </span> Process - A Full Package
                </h3>
            </div>
            <div class="row types-of-token-detail">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/project-mapping.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/project-mapping.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/project-mapping.png"
                                    class="img-fluid"
                                    alt="Project Mapping"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">Project
                            Mapping</h4>
                        <p>
                            We get to understand your requirements in developing the web3 platform. And with mandatory market research and analysis, we kick start the web3 development journey.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/technical-design.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/technical-design.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/technical-design.png"
                                    class="img-fluid"
                                    alt="Technical Design"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">Technical
                            Design</h4>
                        <p>
                            From the outline, we collaborate with our tech enthusiasts and experts to provide your web3 platform with a technically aided structure. 
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/development.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/development.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/development.png"
                                    class="img-fluid"
                                    alt="Development"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">Development</h4>
                        <p>
                            After the approval, we head forward to the development phase of your Web3 solutions. From coding to integrating and ensuring it's user-friendly. 
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/testing-and-optimization.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/testing-and-optimization.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/testing-and-optimization.png"
                                    class="img-fluid"
                                    alt="Testing and Optimization"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">Testing and
                            Optimization</h4>
                        <p>
                            Later we take it to the testing process. The web3 platform is thoroughly tested and optimized based on the results.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/deployment.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/deployment.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/deployment.png"
                                    class="img-fluid"
                                    alt="Deployment"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">Deployment</h4>
                        <p>
                            After the testing and optimization the web3 platform is all set to deploy for users to use and explore your business effectively.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                    <div class="types-erc-detail">
                        <div class="top-icon">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="assets-new/images/web3-development/maintenance-and-support.webp" />

                                <source
                                    type="image/png"
                                    srcset="assets-new/images/web3-development/maintenance-and-support.png" />

                                <img loading="lazy"
                                    data-src="assets-new/images/web3-development/maintenance-and-support.png"
                                    class="img-fluid"
                                    alt="Maintenance and Support"
                                    width="75"
                                    height="75" />
                            </picture>
                        </div>
                        <h4 class="erc-20-head-alt text-left">Maintenance and
                            Support</h4>
                        <p>
                            From there we head to the maintenance and support based on your requirements and the necessity to do so. 
                        </p>
                    </div>
                </div>
            </div>
            <div class="discuss-link text-center pt-0">
                <a href="/contact"><span>Get Started Now</span>
                    <img loading="lazy" src="assets-new/images/right-arrow.png" alt="link-arrow" class="img-fluid" width="50" height="15" *ngIf="!isDarkmode" />
                    <img loading="lazy" src="assets-new/images/right-arrow-dark.png" alt="link-arrow" class="img-fluid" width="50" height="15" *ngIf="isDarkmode" />
                </a>
            </div>
        </div>
    </div>
</section>
<!-- Ways to Reward Users ends -->

<!-- Product Services -->
<section class="section-service-alt">
    <div class="product-services">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 nft-service-list">
                    <div class="casetudies_common">
                        <h3 class="main-sub-heading">Our Engagement Models In Web3 Development</h3>
                        <p>
                            We extend our support and contribution based on your preference and requirement as per the projects. From strategizing to development we facilitate complete services as well as, partial contributions.
                        </p>
                    </div>
                </div>
                <div class="col-lg-7 scrolling-section" id="meta-case-study">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="casestudy_wrap">
                                <div class="casestudy_head">
                                    <h3>
                                        Dedicated Development
                                    </h3>
                                </div>
                                <div class="casestudy_para">
                                    <p>
                                        We provide a dedicated team to help you develop your entire Web3 ecosystem with a dedicated team of developers, and specialists.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <hr class="case_studies_hr" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="casestudy_wrap">
                                <div class="casestudy_head">
                                    <h3>Research and Development</h3>
                                </div>
                                <div class="casestudy_para">
                                    <p>
                                        We interact in your project for research and development and help you make perfect analysis on the market and situations. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <hr class="case_studies_hr" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="casestudy_wrap">
                                <div class="casestudy_head">
                                    <h3>Project maintenance and upgradation</h3>
                                </div>
                                <div class="casestudy_para">
                                    <p>
                                        We facilitate web3 app development project maintenance, and upgradation that help you level up your game in the web3 space more authentically.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <hr class="case_studies_hr" />
                        </div>
                    </div>
                    <!--start-->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="casestudy_wrap">
                                <div class="casestudy_head">
                                    <h3>
                                        Integration and Advancement
                                    </h3>
                                </div>
                                <div class="casestudy_para">
                                    <p>
                                        To integrate new technology, or any part of transforming from web2 to web3, yes we extend exclusive support and help you progress. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <hr class="case_studies_hr" />
                        </div>
                    </div>
                    <!--end-->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Product Services -->

<!-- Tech Stak Update -->
<section class="section-service-main">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="metaverse_heading mb-4">
                    <h3>
                        The <span>Technologies</span>  we use
                    </h3>
                </div>
            </div>
        </div>
        <nav class="tech-web">
            <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
              <button class="nav-link active" id="nav-blockchain-platforms-tab" data-bs-toggle="tab" data-bs-target="#nav-blockchain-platforms" type="button" role="tab" aria-controls="nav-blockchain-platforms" aria-selected="true">Blockchain Platforms</button>

              <button class="nav-link" id="nav-decentralized-storage-tab" data-bs-toggle="tab" data-bs-target="#nav-decentralized-storage" type="button" role="tab" aria-controls="nav-decentralized-storage" aria-selected="false">Decentralized Storage</button>

              <button class="nav-link" id="nav-decentralized-identity-tab" data-bs-toggle="tab" data-bs-target="#nav-decentralized-identity" type="button" role="tab" aria-controls="nav-decentralized-identity" aria-selected="false">Decentralized Identity</button>

              <button class="nav-link" id="nav-decentralized-finance-tab" data-bs-toggle="tab" data-bs-target="#nav-decentralized-finance" type="button" role="tab" aria-controls="nav-decentralized-finance" aria-selected="false">Decentralized Finance</button>

              <button class="nav-link" id="nav-oracles-tab" data-bs-toggle="tab" data-bs-target="#nav-oracles" type="button" role="tab" aria-controls="nav-oracles" aria-selected="false">Oracles</button>

              <button class="nav-link" id="nav-wallets-tab" data-bs-toggle="tab" data-bs-target="#nav-wallets" type="button" role="tab" aria-controls="nav-wallets" aria-selected="false">Wallets</button>

              <button class="nav-link" id="nav-development-frameworks-tab" data-bs-toggle="tab" data-bs-target="#nav-development-frameworks" type="button" role="tab" aria-controls="nav-development-frameworks" aria-selected="false">Development Frameworks</button>

            <button class="nav-link" id="nav-consensus-mechanisms-tab" data-bs-toggle="tab" data-bs-target="#nav-consensus-mechanisms" type="button" role="tab" aria-controls="nav-consensus-mechanisms" aria-selected="false">Consensus Mechanisms</button>

            <button class="nav-link" id="nav-governance-tab" data-bs-toggle="tab" data-bs-target="#nav-governance" type="button" role="tab" aria-controls="nav-governance" aria-selected="false">Governance</button>
            </div>
          </nav>
          <!-- tab-content -->
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-blockchain-platforms" role="tabpanel" aria-labelledby="nav-blockchain-platforms-tab" tabindex="0">
                <div class="row business-tech-stak">
                    <div class="col-md-3 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/frameworks.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/frameworks.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/frameworks.png"
                                    width="204" height="94"
                                    alt="Support-and-Customer" title="Rust"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Ethereum 11</p>
                        </div>
                    </div>
    
                    <div class="col-md-3 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/caliber.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/caliber.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/caliber.png"
                                    width="204" height="94"
                                    alt="Solana-tool-suite"
                                    title="Solana-tool-suite"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Polkadot 11</p>
                        </div>
                    </div>
    
                    <div class="col-md-3 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/hyperledger-composer.png"
                                    width="204" height="94" alt="Anchor-framework"
                                    title="Anchor-framework"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Solidity 11</p>
                        </div>
                    </div>
    
                    <div class="col-md-3 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/hyperledger-explorer.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/hyperledger-explorer.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/hyperledger-explorer.png"
                                    width="204" height="94" alt="MongoDB"
                                    title="MongoDB" class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Rust</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-decentralized-storage" role="tabpanel" aria-labelledby="nav-decentralized-storage-tab" tabindex="0">
                <div class="row business-tech-stak d-flex justify-content-center">
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/caliber.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/caliber.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/caliber.png"
                                    width="204" height="94"
                                    alt="Solana-tool-suite"
                                    title="Solana-tool-suite"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">IPFS (InterPlanetary File System)</p>
                        </div>
                    </div>
    
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/hyperledger-composer.png"
                                    width="204" height="94" alt="Anchor-framework"
                                    title="Anchor-framework"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Filecoin 11</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-decentralized-identity" role="tabpanel" aria-labelledby="nav-decentralized-identity-tab" tabindex="0">
                <div class="row business-tech-stak d-flex justify-content-center">
                    <div class="col-md-6 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/caliber.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/caliber.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/caliber.png"
                                    width="204" height="94"
                                    alt="Solana-tool-suite"
                                    title="Solana-tool-suite"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Decentralized Identity Foundation (DIF) standards</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-decentralized-finance" role="tabpanel" aria-labelledby="nav-decentralized-finance-tab" tabindex="0">
                <div class="row business-tech-stak d-flex justify-content-center">
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/caliber.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/caliber.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/caliber.png"
                                    width="204" height="94"
                                    alt="Solana-tool-suite"
                                    title="Solana-tool-suite"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">MakerDAO 11</p>
                        </div>
                    </div>
    
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/hyperledger-composer.png"
                                    width="204" height="94" alt="Anchor-framework"
                                    title="Anchor-framework"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Uniswap 11</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-oracles" role="tabpanel" aria-labelledby="nav-oracles-tab" tabindex="0">
                <div class="row business-tech-stak d-flex justify-content-center">
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/caliber.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/caliber.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/caliber.png"
                                    width="204" height="94"
                                    alt="Solana-tool-suite"
                                    title="Solana-tool-suite"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Chain Link 11 webp -not</p>
                        </div>
                    </div>
    
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/hyperledger-composer.png"
                                    width="204" height="94" alt="Anchor-framework"
                                    title="Anchor-framework"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Band Protocol</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-wallets" role="tabpanel" aria-labelledby="nav-wallets-tab" tabindex="0">
                <div class="row business-tech-stak d-flex justify-content-center">
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/caliber.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/caliber.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/caliber.png"
                                    width="204" height="94"
                                    alt="Solana-tool-suite"
                                    title="Solana-tool-suite"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">MetaMask 11</p>
                        </div>
                    </div>
    
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/hyperledger-composer.png"
                                    width="204" height="94" alt="Anchor-framework"
                                    title="Anchor-framework"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Trust Wallet: 11</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-development-frameworks" role="tabpanel" aria-labelledby="nav-development-frameworks-tab" tabindex="0">
                <div class="row business-tech-stak d-flex justify-content-center">
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/caliber.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/caliber.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/caliber.png"
                                    width="204" height="94"
                                    alt="Solana-tool-suite"
                                    title="Solana-tool-suite"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Truffle 11</p>
                        </div>
                    </div>
    
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/hyperledger-composer.png"
                                    width="204" height="94" alt="Anchor-framework"
                                    title="Anchor-framework"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Hardhat</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-consensus-mechanisms" role="tabpanel" aria-labelledby="nav-consensus-mechanisms-tab" tabindex="0">
                <div class="row business-tech-stak">
                    <div class="col-md-4 col-sm-12 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/caliber.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/caliber.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/caliber.png"
                                    width="204" height="94"
                                    alt="Solana-tool-suite"
                                    title="Solana-tool-suite"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Proof of Work (used by Ethereum, Bitcoin)</p>
                        </div>
                    </div>
    
                    <div class="col-md-4 col-sm-12 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/hyperledger-composer.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/hyperledger-composer.png"
                                    width="204" height="94" alt="Anchor-framework"
                                    title="Anchor-framework"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Proof of Stake (used by Ethereum 2.0, Binance Smart Chain)</p>
                        </div>
                    </div>
    
                    <div class="col-md-4 col-sm-12 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/hyperledger-explorer.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/hyperledger-explorer.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/hyperledger-explorer.png"
                                    width="204" height="94" alt="MongoDB"
                                    title="MongoDB" class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">Nominated Proof-of-Stake (used by Polkadot)</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-governance" role="tabpanel" aria-labelledby="nav-governance-tab" tabindex="0">
                <div class="row business-tech-stak d-flex justify-content-center">
                    <div class="col-md-6 col-sm-6 col-12">
                        <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/home/stak/caliber.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/home/stak/caliber.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/home/stak/caliber.png"
                                    width="204" height="94"
                                    alt="Solana-tool-suite"
                                    title="Solana-tool-suite"
                                    class="img-fluid mx-auto">
                            </picture>
                            <p class="fw-bolder">DAOs (Decentralized Autonomous Organizations)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        <!-- <div class="business-stak-list-section">
            <div class="row business-tech-stak">
                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/home/stak/frameworks.webp">
                            <source type="image/png"
                                srcset="assets-new/images/home/stak/frameworks.png">
                            <img loading="lazy"
                                data-src="assets-new/images/home/stak/frameworks.png"
                                width="204" height="94"
                                alt="Support-and-Customer" title="Rust"
                                class="img-fluid mx-auto">
                        </picture>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/home/stak/caliber.webp">
                            <source type="image/png"
                                srcset="assets-new/images/home/stak/caliber.png">
                            <img loading="lazy"
                                data-src="assets-new/images/home/stak/caliber.png"
                                width="204" height="94"
                                alt="Solana-tool-suite"
                                title="Solana-tool-suite"
                                class="img-fluid mx-auto">
                        </picture>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/home/stak/hyperledger-composer.webp">
                            <source type="image/png"
                                srcset="assets-new/images/home/stak/hyperledger-composer.png">
                            <img loading="lazy"
                                data-src="assets-new/images/home/stak/hyperledger-composer.png"
                                width="204" height="94" alt="Anchor-framework"
                                title="Anchor-framework"
                                class="img-fluid mx-auto">
                        </picture>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/home/stak/hyperledger-explorer.webp">
                            <source type="image/png"
                                srcset="assets-new/images/home/stak/hyperledger-explorer.png">
                            <img loading="lazy"
                                data-src="assets-new/images/home/stak/hyperledger-explorer.png"
                                width="204" height="94" alt="MongoDB"
                                title="MongoDB" class="img-fluid mx-auto">
                        </picture>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/home/stak/hyperledger.webp">
                            <source type="image/png"
                                srcset="assets-new/images/home/stak/hyperledger.png">
                            <img loading="lazy"
                                data-src="assets-new/images/home/stak/hyperledger.png"
                                width="204" height="94" alt="Next JS"
                                title="Next JS" class="img-fluid mx-auto">
                        </picture>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/home/stak/sawtooth.webp">
                            <source type="image/png"
                                srcset="assets-new/images/home/stak/sawtooth.png">
                            <img loading="lazy"
                                data-src="assets-new/images/home/stak/sawtooth.png"
                                width="204" height="94" alt="Node JS"
                                title="Node JS" class="img-fluid mx-auto">
                        </picture>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/home/stak/iroha.webp">
                            <source type="image/png"
                                srcset="assets-new/images/home/stak/iroha.png">
                            <img loading="lazy"
                                data-src="assets-new/images/home/stak/iroha.png"
                                width="204" height="94" alt="React.JS"
                                title="React.JS" class="img-fluid mx-auto">
                        </picture>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/home/stak/fabric.webp">
                            <source type="image/png"
                                srcset="assets-new/images/home/stak/fabric.png">
                            <img loading="lazy"
                                data-src="assets-new/images/home/stak/fabric.png"
                                width="204" height="94" alt="Phantom"
                                title="Phantom" class="img-fluid mx-auto">
                        </picture>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div class="list-one d-flex flex-column align-items-center justify-content-between h-100 gap-4 text-center">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/home/stak/sawtooth.webp">
                            <source type="image/png"
                                srcset="assets-new/images/home/stak/sawtooth.png">
                            <img loading="lazy"
                                data-src="assets-new/images/home/stak/sawtooth.png"
                                width="204" height="94" alt="Web3"
                                title="Web3" class="img-fluid mx-auto">
                        </picture>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>
<!-- Tech Stak Update -->

<!-- How We Engage Start -->
<div class="section-service-alt">
    <div class="types-of-erc">
        <div class="container">
            <div class="row">
                <div class="metaverse_heading mx-auto">
                    <h3>
                        How Are Our <span>Web3 Services</span> Classified?
                    </h3>
                </div>
            </div>
            <div
                class="row types-of-token-detail justify-content-center how-we-engage">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="types-erc-detail">
                        <picture>
                            <source
                                type="image/webp"
                                srcset="assets-new/images/web3-development/time-and-materials.webp" />

                            <source
                                type="image/png"
                                srcset="assets-new/images/web3-development/time-and-materials.png" />

                            <img loading="lazy"
                                data-src="assets-new/images/web3-development/time-and-materials.png"
                                class="img-fluid"
                                alt="Time and Materials"
                                width="75"
                                height="75" />
                        </picture>
                        <h4 class="erc-20-head-alt pb-0">Time and Materials</h4>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="types-erc-detail">
                        <picture>
                            <source
                                type="image/webp"
                                srcset="assets-new/images/web3-development/monthly-hiring.webp" />

                            <source
                                type="image/png"
                                srcset="assets-new/images/web3-development/monthly-hiring.png" />

                            <img loading="lazy"
                                data-src="assets-new/images/web3-development/monthly-hiring.png"
                                class="img-fluid"
                                alt="Monthly Hiring"
                                width="75"
                                height="75" />
                        </picture>
                        <h4 class="erc-20-head-alt pb-0">Monthly Hiring</h4>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="types-erc-detail">
                        <picture>
                            <source
                                type="image/webp"
                                srcset="assets-new/images/web3-development/fixed-cost.webp" />

                            <source
                                type="image/png"
                                srcset="assets-new/images/web3-development/fixed-cost.png" />

                            <img loading="lazy"
                                data-src="assets-new/images/web3-development/fixed-cost.png"
                                class="img-fluid"
                                alt="Fixed Cost"
                                width="75"
                                height="75" />
                        </picture>
                        <h4 class="erc-20-head-alt pb-0">Fixed Cost</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- How We Engage End -->

<section class="section-service-main">
    <div class="why-alt-section light-background">
        <div class="green-vector"></div>
        <div class="orange-vector"></div>
        <div class="container choosing-section">
            <div class="row align-items-center">
                <div class="col-lg-6 metmb-3">
                    <div class="whys_section mb-2">
                        <h2>Why Choose BlockchainX As Your <span>Web3
                                Development</span>?</h2>
                    </div>
                    <div class="whysection_text_wrap">
                        <p class="pb-3">
                            BlockchainX stands out as a reliable web 3.0 development partner with the competencies to turbocharge your business operations with top-notch security while meeting your unique requirements and improving efficiency along with business scalability.
                        </p>
                        <ul class="why-section-list">
                            <li>Transparency</li>
                            <li>On-Time Delivery</li>
                            <li>Deliver Exactly What You Need</li>
                            <li>Direct Contact With Your Team</li>
                            <li>Uplifted User Experience</li>
                            <li>Flexible Engagement Models</li>
                        </ul>

                        <div class="pt-lg-3 pt-0">
                            <a [routerLink]="['/contact']">
                                <!-- <span>Read More</span> -->
                                <picture *ngIf="!isDarkmode">
                                    <source type="image/webp"
                                        srcset="assets-new/images/right-arrow.webp">
                                    <source type="image/png"
                                        srcset="assets-new/images/right-arrow.png">
                                    <img loading="lazy"
                                        data-src="assets-new/images/right-arrow.png"
                                        class="img-fluid" width="50" height="15"
                                        alt="Link-arrow" title="Link-arrow" />
                                </picture>
                                <picture *ngIf="isDarkmode">
                                    <source type="image/webp"
                                        srcset="assets-new/images/right-arrow-dark.webp">
                                    <source type="image/png"
                                        srcset="assets-new/images/right-arrow-dark.png">
                                    <img loading="lazy"
                                        data-src="assets-new/images/right-arrow-dark.png"
                                        class="img-fluid" width="50" height="15"
                                        alt="Link-arrow" title="Link-arrow" />
                                </picture>
                            </a>
                            <!-- <hr /> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 metmb-3 Why-section">
                    <div class="why_right_side">
                        <picture>
                            <source
                                type="image/webp"
                                srcset="assets-new/images/metaverse/why_blockchain.webp" />

                            <source
                                type="image/png"
                                srcset="assets-new/images/metaverse/why_blockchain.png" />

                            <img loading="lazy"
                                data-src="assets-new/images/metaverse/why_blockchain.png"
                                width="550"
                                height="450"
                                class="img-fluid"
                                alt="why-blockchainX"
                                title="why-blockchainX" />
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- FAQ Section -->
<section class="section-service-alt">
    <div class="container">
        <!-- Accordion Section -->
        <div class="nft-acc">
            <div class="green-vector"></div>
            <div class="violet-vector"></div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12">
                    <div class="common-sub-heading sub-title">
                        FAQ <span class="dots">.</span>
                    </div>
                    <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
                </div>
            </div>
            <div
                class="row align-items-center justify-content-center pt-md-5 pt-4">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingOne">
                                <button
                                    class="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne">
                                   1. How can businesses benefit from web3 development?
                                </button>
                            </div>
                            <div
                                id="collapseOne"
                                class="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Web3 solutions are more transparent, secured, interoperable, and immutable making it efficient for businesses to host and operate business effectively and securely.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingTwo">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo">
                                    2. What are the benefits of outsourcing the Web 3.0 app development project?
                                </button>
                            </div>
                            <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        There is a lot of time, energy, and labor saved. With experience comes the ability to foresee how things alter when implemented and this testing phase is more when involved without experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingThree">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree">
                                    3. Why choose BlockchainX for your Web3 app development project?
                                </button>
                            </div>
                            <div
                                id="collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        BlockchainX with experts and experience in the field of blockchain and decentralization, helps you curate customized web3 solutions, with advanced technology and intricate features. 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingFour">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour">
                                    4. How much does it cost to develop a web3 solution?
                                </button>
                            </div>
                            <div
                                id="collapseFour"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        The cost of developing a Web3 solution completely depends on the requirements, advancement, and integrations you require and that would best fit your business. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Accordion Section -->
    </div>
</section>
<!-- FAQ Section -->

<!-- Case studies Start -->
<section class="section-service-main">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="casetudies_common">
                    <div class="common-sub-heading sub-title">
                        Case Studies<span class="dots">.</span>
                    </div>
                    <div class="casestudies_para">
                        <p>
                            This part holds the legacy of BlockchainX Tech.
                            Establishing all
                            that we have worked out for our clients as a
                            Blockchain
                            development company is brought down here. This gives
                            an ideology
                            of our expertise and could bring your idea to life
                            and hold the
                            top priority here.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 case-list">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="casestudy_wrap">
                            <div class="casestudy_head">
                                <div class="case-sub-head">
                                    <h3>EKTA</h3>
                                    <div class="case-picture">
                                        <picture>
                                            <source type="image/webp"
                                                srcset="assets-new/images/home/case/ekta-1.webp">
                                            <source type="image/png"
                                                srcset="assets-new/images/home/case/ekta-1.png">
                                            <img loading="lazy"
                                                data-src="assets-new/images/home/case/ekta-1.png"
                                                alt="Ekta" class="img-fluid"
                                                width="122" height="85" />
                                        </picture>
                                    </div>
                                </div>
                                <div class="deploy-link">
                                    <a href="ektachain" target="_blank"
                                        rel="noopener noreferrer">
                                        <!-- <span>Read More</span> -->
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="!isDarkmode" />
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow-dark.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="isDarkmode" />
                                    </a>
                                </div>
                            </div>
                            <div class="casestudy_para">
                                <p>
                                    A decentralized ecosystem built from
                                    scratch, along with the
                                    ability to perform everything and anything.
                                    Being a blockchain
                                    itself, it renders and supports DAO, and has
                                    its own
                                    marketplace and token to interact with. It
                                    explores endless
                                    possibilities and bridges the physical world
                                    and the
                                    blockchain era.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <hr class="case_studies_hr" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="casestudy_wrap">
                            <div class="casestudy_head">
                                <div class="case-sub-head">
                                    <h3>Minosis</h3>
                                    <div class="case-picture">
                                        <picture>
                                            <source type="image/webp"
                                                srcset="assets-new/images/home/case/minosis.webp">
                                            <source type="image/png"
                                                srcset="assets-new/images/home/case/minosis.png">
                                            <img loading="lazy"
                                                data-src="assets-new/images/home/case/minosis.png"
                                                alt="Minosis" class="img-fluid"
                                                width="122" height="85" />
                                        </picture>
                                    </div>
                                </div>

                                <div class="deploy-link">
                                    <a href="minosis" target="_blank"
                                        rel="noopener noreferrer">
                                        <!-- <span>Read More</span> -->
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="!isDarkmode" />
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow-dark.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="isDarkmode" />
                                    </a>
                                </div>
                            </div>
                            <div class="casestudy_para">
                                <p>
                                    Minosis is a crypto mining startup that
                                    allows users to
                                    participate in pools to earn virtual
                                    currency. In a unique
                                    way, Minosis miners can earn Bitcoin
                                    regardless of their mine
                                    and even achieve block rewards. Despite
                                    simplifying
                                    transaction's cost, the payments are
                                    accepted as native tokens
                                    or Bitcoins.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <hr class="case_studies_hr" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="casestudy_wrap">
                            <div class="casestudy_head">
                                <div class="case-sub-head">
                                    <h3>Zuki</h3>
                                    <div class="case-picture">
                                        <picture>
                                            <source type="image/webp"
                                                srcset="assets-new/images/home/case/zuki.webp">
                                            <source type="image/png"
                                                srcset="assets-new/images/home/case/zuki.png">
                                            <img loading="lazy"
                                                data-src="assets-new/images/home/case/zuki.png"
                                                alt="Zuki" class="img-fluid"
                                                width="122" height="85" />
                                        </picture>
                                    </div>
                                </div>

                                <div class="deploy-link">
                                    <a href="zuki" target="_blank"
                                        rel="noopener noreferrer">
                                        <!-- <span>Read More</span> -->
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="!isDarkmode" />
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow-dark.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="isDarkmode" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="casestudy_para">
                            <p>
                                Zuki is a P2E gaming platform built on a
                                decentralized economy
                                application. A community-oriented space that
                                performs building
                                characters, virtual goods, and metaverse
                                structures all in one.
                                Furthermore, the P2E mechanism is utilized to
                                generate financial
                                benefits for the players drawing a wider crowd.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <hr class="case_studies_hr" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="casestudy_wrap">
                            <div class="casestudy_head">
                                <div class="case-sub-head">
                                    <h3>Starcard Sports</h3>
                                    <div class="case-picture">
                                        <picture>
                                            <source type="image/webp"
                                                srcset="assets-new/images/home/case/star-card-sports.webp">
                                            <source type="image/png"
                                                srcset="assets-new/images/home/case/star-card-sports.png">
                                            <img loading="lazy"
                                                data-src="assets-new/images/home/case/star-card-sports.png"
                                                alt="Starcard Sports"
                                                class="img-fluid" width="122"
                                                height="85" />
                                        </picture>
                                    </div>
                                </div>

                                <div class="deploy-link">
                                    <a href="starcard-sports-games"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <!-- <span>Read More</span> -->
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="!isDarkmode" />
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow-dark.png"
                                            alt="link-arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="isDarkmode" />
                                    </a>
                                </div>
                            </div>
                            <div class="casestudy_para">
                                <p>
                                    This is a play-to-earn decentralized gaming
                                    arena developed to
                                    connect real-world players through digital
                                    gaming. This game
                                    is based on football along with metaverse
                                    abilities drawing
                                    income. With the ability to monetize, it
                                    inherits an immersive
                                    experience that challenges traditional
                                    gaming platforms.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <hr class="case_studies_hr" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Case studies End -->

<section class="section-service-alt">
    <app-blog></app-blog>
</section>

<section class="section-service-alt">
    <app-get-in-touch></app-get-in-touch>
</section>
