<!-- Banner Section -->
<div class="banners-section">
    <div class="banner_wrapper">
      <div class="container" id="banner-page-vector-main">
        <div class="green-vector"></div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-12 col-sm-12 col-12">
            <div class="banner_contentWrapper">
              <div class="caption">
                <h1 class="pb-3">
                  <span><strong>Jupiter Clone Script </strong> <br></span>
                </h1>
                <p>
                  Simplify crypto exchanges by choosing our easy-to-use Jupiter Clone script development services, a customized yet potentially built defi aggregator to meet your requirements.
                </p>           
                <div class="contact-link">
                  <a href="https://www.blockchainx.tech/contact/" target="_blank" class="home-link-alt">Talk With Our Experts</a>
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank" rel="noopener noreferrer" class="home-link">Telegram</a>
                </div> 
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12 text-center">
            <div class="banner-right-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/jupiter-clone-script/jupiter-clone-script-development.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/jupiter-clone-script/jupiter-clone-script-development.png"
                  class="img-fluid"
                  alt="Jupiter Clone Script Development"
                  title="Jupiter Clone Script Development"
                  width="450"
                  height="450" *ngIf="isDarkmode"/>         
              </picture>
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/jupiter-clone-script/jupiter-clone-script-development-light.webp" />
                <img loading="lazy"
                  data-src="assets-new/images/jupiter-clone-script/jupiter-clone-script-development-light.png"
                  class="img-fluid"
                  alt="Jupiter Clone Script Development"
                  title="Jupiter Clone Script Development"
                  width="450"
                  height="450" *ngIf="!isDarkmode"/>
              </picture>
          </div>
        </div>
        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center"
            (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp" />
              <img loading="lazy"
                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="Scroll"
                title="Scroll" />
            </picture>
          </div>
        </div>
      </div>
    </div>
</div>
<!-- Banner Section end -->
<section class="section-service-alt about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          Our<span>Clients</span>
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<div class="section-service-main" #about>
    <div class="container">
      <div class="row reverse align-items-center">
        <div class="col-lg-5 mx-auto col-md-12">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/what-is-jupiter-aggregator-clone.webp" />
  
              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/what-is-jupiter-aggregator-clone.png"
                class="img-fluid"
                alt="What Is Jupiter Aggregator Clone"
                title="What Is Jupiter Aggregator Clone"
                width="450"
                height="450" *ngIf="isDarkmode"/>
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/what-is-jupiter-aggregator-clone-light.webp" />
              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/what-is-jupiter-aggregator-clone-light.png"
                class="img-fluid"
                alt="What Is Jupiter Aggregator Clone"
                title="What Is Jupiter Aggregator Clone"
                width="450"
                height="450" *ngIf="!isDarkmode"/>
            </picture>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 about-content">
          <div class="common-sub-heading ">
            <h2 class="main-sub-heading text-center text-lg-start"><span>What Is  </span>Jupiter Aggregator Clone?</h2>
            <p class="pb-3">
              Jupiter Aggregator clone is a replicated similar version of the existing Jupiter Aggregator built on the Solana blockchain, which is designed to optimize and streamline token swaps. Its primary work mechanism focuses on helping users find the best rate for the tokens that are exchanged through aggregating liquidity from different Solana DEX ecosystems.
            </p>
            <p class="pb-3">
              When we refer to an Jupiter Exchange Clone Script, this is a typical decentralized exchanged aggregator on the blockchain that inherits the key features of the actual platform which is specialized aggregating liquidity while it is much more open to incorporating new features in a user-friendly environment with more simplified process to swap tokens.
            </p>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading text-center">
          <h2 class="mb-2">
            How Does The <span>Jupiter Clone App Development Work? </span>
          </h2>
          <p>
            The working of the Jupiter Clone is going to have a similar mechanism to the actual platform hence being a DEC on the Solana blockchain it operates on the Automated Market Maker record model where the liquidity for the pool is provided.
          </p>
        </div>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center gy-5">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/dex-aggregator-platform.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/dex-aggregator-platform.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="liquidity pools"
                title="liquidity pools" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Liquidity Pools</h3>
          <p>
            The users contribute liquidity to the tokens either SL/USDC, SOL/ETH, etc., through depositing equal value of both tokens in the pool. The Liquidity provider in return provides token representation of the shares.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/price-determinations.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/price-determinations.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Price Determinations"
                title="Price Determinations" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Price Determinations</h3>
          <p>
            The token price in the liquidity pool is ascertained by an algorithm that is based on the ratio of the two tokens in the pool. This determines the value of the token presented in the pool.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/third-party-integration.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/third-party-integration.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Trading"
                title="Trading" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Trading</h3>
          <p>
            By interacting with the liquidity pool, the user trades the tokens, and in the case of buying or selling a token, they do it by exchanging the tokens in the pool. This affects the prices of tokens based on supply and demand.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/trading-fee.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/trading-fee.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Trading fee"
                title="Trading fee" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Trading fee</h3>
          <p>
            The trading fee is charged on each transaction that happens on the Jupiter aggregator clone and is split in a usual manner based on the liquidity provided by the pool in the ecosystem.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/arbitrage-opportunities.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/arbitrage-opportunities.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Arbitrage Opportunities"
                title="Arbitrage Opportunities" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Arbitrage Opportunities</h3>
          <p>
            Through the Automated Market Maker model which allows efficient arbitrage, the traders can exploit the price discrepancies between the different markets.
          </p>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading text-center">
          <h2 class="mb-2 mt-4">
            The Perspective  <span>Of Jupiter Clone</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center real-world-check pb-5">
        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail">
            <div class="erc-20-head-alt h-4 user-admin">User</div>
            <p>
              1. Create an account and set up the Solana wallet to interact
            </p>
            <p>
              2. The user deposits their fund into the wallet address
            </p>
            <p>
              3. Uses the exchange interface to check through the available best options.
            </p>
            <p>
              4. Using AMM with no central order book, the prices are determined based on liquidity.
            </p>
            <p>
              5. The exchange happens, adding to the versatile investment portfolio of the user. 
            </p>
          </div>
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12 col-12">
          <div class="types-erc-detail gap-3">
            <div class="erc-20-head-alt h-4 user-admin">Admin</div>
            <p>
              1. Liquidity pools are managed including adding, removing, adjusting, & monitoring here.
            </p>
            <p>
              2. The admin decides on which token to list and also ensures it meets platform criteria.
            </p>
            <p>
              3. Security measures are implemented to protect the platform and user funds.
            </p>
            <p>
              4. The admin also oversees the governance including decision-making happens here. 
            </p>
            <p>
              5. Also provide user assistance, including resolving issues, and inquiries being addressed. 
            </p>
          </div>
        </div>
      </div>
  </div>
</div>

<div class="section-service-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="metaverse_heading text-center">
            <h2 class="mb-2">
                <span>White Label Jupiter Clone Script </span>Development Services
            </h2>
          </div>
        </div>
      </div>
      <div class="row types-of-token-detail justify-content-center gy-5">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/jupiter-clone-script/icons/dex-aggregator-platform.webp" />
  
                <img loading="lazy"
                  data-src="assets-new/images/jupiter-clone-script/icons/dex-aggregator-platform.png"
                  width="75"
                  height="75"
                  class="img-fluid"
                  alt="DEX Aggregator Platform"
                  title="DEX Aggregator Platform" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">DEX Aggregator Platform</h3>
            <p>The DEX aggregator platforms development services is where we help users to fetch the right choice for their tokens based on the liquidity and market demands.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/jupiter-clone-script/icons/dedicated-dex-platform.webp" />
  
                <img loading="lazy"
                  data-src="assets-new/images/jupiter-clone-script/icons/dedicated-dex-platform.png"
                  width="75"
                  height="75"
                  class="img-fluid"
                  alt="Dedicated DEX Platform"
                  title="Dedicated DEX Platform" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">Dedicated DEX Platform</h3>
            <p>
              We help you develop a dedicated decentralized exchange platform for crypto exchange ensuring good liquidity.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/jupiter-clone-script/icons/third-party-integration.webp" />
  
                <img loading="lazy"
                  data-src="assets-new/images/jupiter-clone-script/icons/third-party-integration.png"
                  width="75"
                  height="75"
                  class="img-fluid"
                  alt="Third-Party Integration"
                  title="Third-Party Integration" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">Third-Party Integration</h3>
            <p>
              In terms of immediate buy and sell options, wallet integrations, and security systems, we also provide third-party integrations with a smooth flow.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/jupiter-clone-script/icons/user-experience-design.webp" />
  
                <img loading="lazy"
                  data-src="assets-new/images/jupiter-clone-script/icons/user-experience-design.png"
                  width="75"
                  height="75"
                  class="img-fluid"
                  alt="User Experience Design"
                  title="User Experience Design" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">User Experience Design</h3>
            <p>
              We curate explicit user-friendly interface designs that are seamless to slide into and navigate, contributing to an amazing experience for the user. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/jupiter-clone-script/icons/gas-optimization.webp" />
  
                <img loading="lazy"
                  data-src="assets-new/images/jupiter-clone-script/icons/gas-optimization.png"
                  width="75"
                  height="75"
                  class="img-fluid"
                  alt="GAS Optimization"
                  title="GAS Optimization" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">GAS Optimization</h3>
            <p>
               In the fluctuating market, we provide GAS fee optimization services that shall ensure stability in the ecosystem and protect from dropout rates.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/jupiter-clone-script/icons/smart-contract-development.webp" />
  
                <img loading="lazy"
                  data-src="assets-new/images/jupiter-clone-script/icons/smart-contract-development.png"
                  width="75"
                  height="75"
                  class="img-fluid"
                  alt="Smart Contract Development"
                  title="Smart Contract Development" />
              </picture>
            </div>
            <h3 class="erc-20-head-alt text-left h-4">Smart Contract Development</h3>
            <p>
               We develop high-quality smart contracts that are first-hand, well-audited, and optimized to enable your automation requests seamlessly.
            </p>
          </div>
        </div>
      </div>
      <div class="text-center mt-4 mt-lg-5 col-lg-12">
        <a class="our_expert_talk" href="https://www.blockchainx.tech/jupiter-clone-script/#item-1" (click)="addClass(1)">Connect With Our Experts</a>
      </div>
    </div>
</div>

<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading text-center">
          <h2 class="mb-2">
              <span>Key Features Of </span>Jupiter Clone Script
          </h2>
          <p class="my-4">
            Listed below are the core features of Jupiter Clone Script
          </p> 
        </div>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center gy-5">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/automated-market-maker.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/automated-market-maker.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Automated Market Maker (AMM)"
                title="Automated Market Maker (AMM)" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Automated Market Maker (AMM)</div>
          <p>
            One of the core aspects of Decentralised Exchange is the AMM mechanism which allows the creation of liquidity pools for users to trade tokens without central order books.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/token-listing.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/token-listing.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Token Listing"
                title="Token Listing" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Token Listing</div>
          <p>
            With the ability to list a variety of different tokens including popular cryptocurrencies, and emerging projects,  for exchange, and it clearly defines and navigates to the right one.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/trading-pairs.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/trading-pairs.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Trading Pairs"
                title="Trading Pairs" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Trading Pairs</div>
          <p>
            Through a variety of trading pairs, it caters to the needs of different users and their preferences, market demands, etc., to spot the right one.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/order-types.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/order-types.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Order Types"
                title="Order Types" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Order Types</div>
          <p>
            Supporting various order types including limit orders, market orders, spot limit orders, and many more.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/wallet-integrations.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/wallet-integrations.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Wallet Integrations"
                title="Wallet Integrations" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Wallet Integrations</div>
          <p>
            It can integrate popular wallets that allow users to make deposits and withdrawals very easily and conveniently.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/icons/charting-tools.webp" />

              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/icons/charting-tools.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Charting Tools"
                title="Charting Tools" />
            </picture>
          </div>
          <div class="erc-20-head-alt text-left h-4">Charting Tools</div>
          <p>
            It provides access to an advanced charting tool that helps users make precise analyses of market trends and make informed decisions on trading.
          </p>
        </div>
      </div>
    </div>
    <div class="text-center mt-4 mt-lg-5 col-lg-12">
      <a class="our_expert_talk" href="https://www.blockchainx.tech/jupiter-clone-script/#item-1" (click)="addClass(1)">Connect With Our Experts</a>
    </div>
  </div>
</div>

<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading text-center">
          <h2 class="mb-2">
            Added Features to enhance your<span> Jupiter like Crypto Aggregator</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center gy-5">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/bond-tokenization-services/icons/bond-tokenization.webp" />

              <img loading="lazy"
                data-src="assets-new/images/bond-tokenization-services/icons/bond-tokenization.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Staking"
                title="Staking" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Staking</h3>
          <p>They allow users to stake their tokens and earn rewards on the platform.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/bond-tokenization-services/icons/marketplace-development.webp" />

              <img loading="lazy"
                data-src="assets-new/images/bond-tokenization-services/icons/marketplace-development.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Governance"
                title="Governance" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Governance</h3>
          <p>
            Implementing this enables users to participate in the platform's decision-making.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/bond-tokenization-services/icons/tokenomics-planning.webp" />

              <img loading="lazy"
                data-src="assets-new/images/bond-tokenization-services/icons/tokenomics-planning.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Yield Farming"
                title="Yield Farming" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Yield Farming</h3>
          <p>
            This opportunity contributes to incentivizing liquidity providers and stream revenue.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/bond-tokenization-services/icons/smart-contract-development.webp" />

              <img loading="lazy"
                data-src="assets-new/images/bond-tokenization-services/icons/smart-contract-development.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Launchpad"
                title="Launchpad" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Launchpad</h3>
          <p>
            A platform for new projects to raise funds through token sales.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/bond-tokenization-services/icons/stacking-protocol.webp" />

              <img loading="lazy"
                data-src="assets-new/images/bond-tokenization-services/icons/stacking-protocol.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="NFT Marketplace"
                title="NFT Marketplace" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">NFT Marketplace</h3>
          <p>
            Facilitating NFT trading opportunities for the users through the platform.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token mb-0">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/bond-tokenization-services/icons/dapp-development.webp" />

              <img loading="lazy"
                data-src="assets-new/images/bond-tokenization-services/icons/dapp-development.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="Cross Chain Functionality"
                title="Cross Chain Functionality" />
            </picture>
          </div>
          <h3 class="erc-20-head-alt text-left h-4">Cross Chain Functionality</h3>
          <p>
            Connect seamlessly with other networks that promote interoperability and trading.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section-service-alt">
  <div class="container">
    <div class="row mb-lg-5 mb-4">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h2 class="text-center pb-0 mb-3 h-2"><span>Why Should I Create A </span>DEX Aggregator Like Jupiter?</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 d-lg-block d-none">
        <div class="casetudies_common">
          <picture>
            <source type="image/webp" srcset="assets-new/images/jupiter-clone-script/create-a-dex-aggregator-like-jupiter.webp" />
            <img loading="lazy" data-src="assets-new/images/jupiter-clone-script/create-a-dex-aggregator-like-jupiter.png" width="500" height="500" class="img-fluid pb-md-0 pb-3" alt=" Create A DEX Aggregator Like Jupiter" title=" Create A DEX Aggregator Like Jupiter" *ngIf="isDarkmode" />
          </picture>
          <picture>
            <source type="image/webp" srcset="assets-new/images/jupiter-clone-script/create-a-dex-aggregator-like-jupiter-light.webp" />
            <img loading="lazy" data-src="assets-new/images/jupiter-clone-script/create-a-dex-aggregator-like-jupiter-light.png" width="500" height="500" class="img-fluid pb-md-0 pb-3" alt=" Create A DEX Aggregator Like Jupiter" title=" Create A DEX Aggregator Like Jupiter" *ngIf="!isDarkmode" />
          </picture>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3 class="text-decoration-none h-3">Maximized Access To Liquidity</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  The DEX aggregator pools liquidity through different channels and decentralized exchanges. This ensures users get the best possible prices for their Trades. That simplifies major trading-related problems and automatically optimizes token swaps with minimal slippage.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3 class="text-decoration-none h-3">Growing DeFi Ecosystem</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  As decentralized finance booms with shifting user-based towards decentralized platforms, there is a lot of control over assets, and the aggregators tap into the trend providing a seamless and cost-effective experience that simplifies trading and optimizes returns.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3 class="text-decoration-none h-3" >Increased Revenue Generation</h3>
                </div>
              </div>
            </div>
            <div class="casestudy_para">
              <p>
                Building a DEX aggregator, you add up to the opportunity to stream revenue by charging fees on transactions, and commission, while implementing new revenue models will also be easy here. And bringing premium features you can advance the routing algorithm and DeFi service. 
              </p>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3 class="text-decoration-none h-3">Innovative Opportunities</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  A DEX aggregator has the chance to innovate through new technology integrations. Your Jupiter clone script with such innovations brought into action, there it is gaining more traction. You could also bring in new features to the platform as it is highly compatible.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3 class="text-decoration-none h-3">Growth and Scalability</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  With a DEX aggregator like Jupiter, the platform is a DeFI growth with scalability. This is not limited to one exchange but opens up the opportunity to connect with different platforms and networks that enhance the longevity of the platform and show its adaptability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div>
</section>

<section class="section-service-main">
    <div class="container">
      <div class="row mb-lg-5 mb-4">
        <div class="col-lg-10 mx-auto">
          <div class="whys_section mb-0 text-center">
            <h2 class="text-center pb-0 mb-3 h-2"><span>Jupiter Clone </span>Development Process</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 d-lg-block d-none">
          <div class="casetudies_common">
            <picture>
              <source type="image/webp" srcset="assets-new/images/jupiter-clone-script/jupiter-clone-development-process.webp" />
              <img loading="lazy" data-src="assets-new/images/jupiter-clone-script/jupiter-clone-development-process.png" width="500" height="500" class="img-fluid pb-md-0 pb-3" alt="Jupiter Clone Development Process" title="Jupiter Clone Development Process" *ngIf="isDarkmode" />
            </picture>
            <picture>
              <source type="image/webp" srcset="assets-new/images/jupiter-clone-script/jupiter-clone-development-process-light.webp" />
              <img loading="lazy" data-src="assets-new/images/jupiter-clone-script/jupiter-clone-development-process-light.png" width="500" height="500" class="img-fluid pb-md-0 pb-3" alt="Jupiter Clone Development Process" title="Jupiter Clone Development Process" *ngIf="!isDarkmode" />
            </picture>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Basic Requirement Gathering</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    We start by understanding the idea and moto behind creating Jupiter Clone Development for crypto exchange. We start by figuring this out through different analysis strategies and help with insights on similar vendettas.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Market Situation Analysis</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    Going deeper, we do keen end-to-end market research to understand the market's approach and terns on, the demand around the users, their expectations, and a lot about the financial function and opportunities are researched. 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3" >Customization Planning</h3>
                  </div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                   Then we plan your Jupiter clone’s customization with respect to the UI/UX, logo, name, brand identity, and registrations, and also ensure to plan on the features that you want to continue and inherit from the existing platform.
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Dex Infrastructure</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    Going further we help you customize your DEX infrastructure with the security features, advanced integrations, staking mechanisms, and more with absolute zeal for you to uncover the potential of cryptocurrency in your space. 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Additional Tech Inputs</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    We also help you gather and integrate supreme features like AI/ML integrations, bringing big data analysis, and much more that make everything automated regardless of compliance difficulties as we ensure the flow is nowhere interrupted and there is good speed.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Smart Contract Deployment</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    We then code your smart contracts to automate transactions and mark exceptional progress. Our contracts are audited and error-free ensuring trust, reliability, and durability. Eventually, these self executing codes are deployed.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3" >Test Launch</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    Once the required infrastructure and development are done, we launch it on the test net to check for errors, operational flow, and more. If there are any bugs or issues that have to be addressed, alterations are done here. And it's approved for launch on the mainnet.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Ready For The Main Launch</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    With all that set, the crypto cancer Jupiter clone is also set for the exchange to be launched in the mainnet for user access. With a proper marketing strategy, this could have a great response from the user's end.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Updates Based On Needs</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    Based on the main launch response, if needed we also provide upgrading services for the exchange platform you developed and launched.  This is done based on the sustainable norms of the platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
            <div class="col-lg-12">
              <div class="casestudy_wrap">
                <div class="casestudy_head">
                  <div class="case-sub-head">
                    <h3 class="text-decoration-none h-3">Further Maintenance</h3>
                  </div>
                </div>
                <div class="casestudy_para">
                  <p>
                    We also extend further maintenance services based on the requirements and needs of the platform we developed for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
</section>

<section class="section-service-alt">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-0">
            <h2>
              Why Choose BlockchainX<span>  For Jupiter Clone Script Development?</span>
            </h2>
            <p class="pb-3">
              BlockchainX is a profound web3 solution provider. Over the 7 years in the Sector, we have nurtured the skill of delivering on-time solutions with precise customizations. As a technology partner, we have advanced to provide efficient web3 solutions and our team of enthusiastic developers and tech architects are very eager to contribute toward the current digital transformation and very open to enhancing their skills in the best way possible. As a Jupiter Clone App Development Company, we shall create the best customization possible catering to your needs. We extend
            </p>
            <div class="whysection_text_wrap">
              <ul>
                <li>End-to-end development service.</li>
                <li>100% customization.</li>
                <li>Unique Business Solutions.</li> 
                <li>Technologically improved DEX.</li> 
                <li>24 x 7 support & maintenance.</li>
              </ul>
            </div>
            <div class="content-link">
              <a href="https://www.blockchainx.tech/contact/" >
                <!-- <span>Read More</span> -->
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Arrow" title="Arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Arrow" title="Arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side d-flex justify-content-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/why-choose-blockchainx.webp" />
  
              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/why-choose-blockchainx.png"
                width="500"
                height="500"
                class="img-fluid"
                alt="Why Choose BlockchainX For Jupiter Clone Script"
                title="Why Choose BlockchainX For Jupiter Clone Script" *ngIf="isDarkmode"/>
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/jupiter-clone-script/why-choose-blockchainx-light.webp" />
  
              <img loading="lazy"
                data-src="assets-new/images/jupiter-clone-script/why-choose-blockchainx-light.png"
                width="500"
                height="500"
                class="img-fluid"
                alt="Why Choose BlockchainX For Jupiter Clone Script"
                title="Why Choose BlockchainX For Jupiter Clone Script" *ngIf="!isDarkmode"/>
            </picture>
          </div>
        </div>
      </div>
    </div>
</section>

<!-- FAQ Start -->
<section class="section-service-main">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              Frequently Asked Question <span class="dots"></span>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    What is Jupiter?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                       Jupiter is a pre-existing Decentralised Liquidity Aggregator platform built on the Solana ecosystem.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    Why Choose a Jupiter Clone Script?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                       By choosing to deploy the Jupiter clone script, there is a lot of time and energy saved. The number of resources required can also be cut down. This affects the overall time and investment involved in the development. While a clone script gives more opportunity to customize and make things better. With a clean interface and simplified navigation, your DEX will have all the ability to shine like the build from scratch.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    How to launch a DEX like Jupiter using a clone script?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      To launch your DEX like Jupiter through clone script, you will have to choose your blockchain, frame the features of your DEX, get a copy of the clone script rewrite the customization in terms of features, and also rebrand that in your name, audit the smart contracts and test in the test for error and corrections. Once everything is done, the Jupiter clone is all set to launch on the mainnet.
                    </p>
                  </div>
                </div>
              </div> 
              <div class="accordion-item">
                <div class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour">
                    How long does it take to develop a Jupiter clone?
                  </button>
                </div>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      To develop a Jupiter Clone script it will take an average of two to four months with all the required customizations you would need in it. 
                    </p>
                  </div>
                </div>
              </div>          
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
</section>
<!-- FAQ End -->

<section class="section-service-alt">
    <app-blog></app-blog>
</section>
  
<section class="section-service-alt" id="item-1" [ngClass]="selectedLink === 1 ? 'scrollHeading' : 'scrollheadingone'">
  <app-get-in-touch></app-get-in-touch>
</section>