<section class="blog-detail">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 mb-4">
          <div class="blog-single-wrap">
            <picture>
              <source type="image/webp" srcset="assets-new/images/blog/token-development-services/token-development-services.webp">
              <img loading="lazy" src="assets-new/images/blog/token-development-services/token-development-services.jpg" alt=" Token Development Services List" title=" Token Development Services List" class="img-fluid" width="856" height="522"/>
            </picture>
            <h1>Token Development Services List: Customized For You</h1> 
            <div class="h4">
              Listen to the Audio :
            </div>
            <div class="w-100">
              <audio controls >
                  <source src="assets-new/images/blog/token-development-services/token-development-service-list.mp3" type="audio/mpeg">
              </audio>
            </div>
            <p>The crypto token economy along with its fandom is increasing with great evolution and yielding better results. As the market is hitting $2.32 Trillion and still counting, the massive adoption has been a great stand. Token development service providers have played a vital role.</p>
            <p>Here we have gone one step deeper to understand and analyze different <a href="https://www.blockchainx.tech/token-development-company/" target="_blank">Token development services</a> in the market. And pick the right choice for your business and project with our expertise. Each token development service is distinct from one other, and while having great potential, it's important to choose the right service for your requirements.</p>
            <p>Before distinguishing between different Token development service lists, let's understand Token development service, and then gather insight on different services. </p>
  
            <h2 id="item-1" [ngClass]="selectedLink===1?'scrollHeading':'scrollheadingone'">Token development services</h2>
            <p>Token development service in a straightforward manner can be defined and put up as the process of developing a cryptocurrency for a variety of needs, but it must ensure that one requirement is met specifically. The token development service includes different stages of curating a token, while a single service provider does all of it. The process involves the creation, deployment, and managing different tokens on the blockchain network and meeting various project requirements and objectives that are versatile. This includes bringing new cryptocurrency, developing decentralized finances, or creating non-fungible tokens. All this plays a crucial part in meeting the funding needs of the projects, leveraging decentralized applications, creating digital assets, etc. </p>

            <h2 id="item-2"
            [ngClass]="selectedLink===5?'scrollHeading':'scrollheadingone'">Why Is It Important To Have Token Development Services?</h2>
            <p class="pb-3">
                Token creation is a common aspect around any crypto project or web3 and Blockchain, the simpler it is portrayed to develop, the complexities and precision in creating a cryptocurrency or token on the blockchain are high, while other attributional compromises have to be made in the terms of precision, quality, token quantity, technical aspects of the token and much more.  
            </p>
            <p class="pb-3">
                Through the help of Token development service providers, these shortcomings have been cut down while also bringing in pointers such as professionals to assist in the process, and ensure all the regulations are met with perfect checklists, there are opportunities to innovate, understand new technologies, and collaborate based on need. While adhering to professional support in token development services, there is a lot of time, and investment saved, as not everyone in the crypto ventures is equipped with enough knowledge on the technical aspects, and there are obvious chances for information gaps in the market. 
            </p>
            <p class="pb-3">
                This details the importance of availing of the service while letting us slide and explore the different types of token development services provided. 
            </p>
<!-- 
            <div class="erc-link justify-content-center">
              <div class="d-flex py-4 py-4">
                  <a href="https://www.blockchainx.tech/contact/" target="_blank" rel="noopener noreferrer" class="home-link-alt d-flex justify-content-center">
                    <span>Connect With Our Experts Now!</span>
                  </a>
              </div> 
            </div>
 -->
            <h2 id="item-3" [ngClass]="selectedLink===3?'scrollHeading':'scrollheadingone'">Different Types of Token Development Services</h2>
            <picture>
                <source type="image/webp" srcset="assets-new/images/blog/token-development-services/types-of-token-development.webp">
                <img loading="lazy" src="assets-new/images/blog/token-development-services/types-of-token-development.jpg" alt="Different Types of Token Development Services" title="Different Types of Token Development Services" class="img-fluid" width="840" height="613"/>
            </picture>  
            <h2 class="h2 pb-lg-3 pb-2">ERC20 Token Development</h2>
            <p>
                Ethereum-based token development services, where we do <a href="https://www.blockchainx.tech/erc20-token-development/" target="_blank"> ERC20 Token development</a> services for your projects customized to cater to your needs. We also do token development on different standards like ERC1155, ERC721, etc.
            </p>
            <h3 class="h3 pb-lg-3 pb-2">Service Features of ERC20 Token Development</h3>
            <h4 class="h4 pb-lg-3 pb-2">1. Token Fungibility</h4>
            <p>
                The ERC20 Tokens we develop are fungible and ensure they are interchangeable while having the same value intact.
            </p>
            <h4 class="h4 pb-lg-3 pb-2">2. Token Divisibility</h4>
            <p>
                The tokens we develop are divisible into smaller fractions making fractional ownership possible.
            </p>
            <h4 class="h4 pb-lg-3 pb-2">3. Transferability </h4>
            <p>
                The ERC20 tokens we developed are easy to transfer among wallets easily. 
            </p>
            <h4 class="h4 pb-lg-3 pb-2">4. Smart contract Compatibility</h4>
            <p>
                The tokens are ensured and meet the standard to be compatible with Etheruem smart contracts and engage in various functionalities eventually.
            </p>

            <h3 class="h3 pb-lg-3 pb-2">Uses Cases Of ERC20 Token Includes</h3>
            <p>Tokens can be crafted as utility tokens adhering to some functions, as security tokens, for payments as a medium of exchange in the DApps for goods and services. </p>
  
            <h2 class="h2 pb-lg-3 pb-2">BEP20 Token Development</h2>
            <p>
                Similar to Ethereum token development, our Binance Smart chain-based token development service is also provided. Specifically, our <a href="https://www.blockchainx.tech/bep20-token-development/" target="_blank"> BEP20 token development services</a> ensure to meet Binance smart contract regulations while adhering to your requirements. 
            </p>
            <h3 class="h3 pb-lg-3 pb-2">Service Features of BEP20 Token Development</h3>
            <h4 class="h4 pb-lg-3 pb-2">1. Having similar compatibility as ERC20 </h4>
            <p>
                BEP20 tokens twin with a few qualities of ERC20 tokens, such as fungibility, divisibility, transferability, and more. 
            </p>
            <h4 class="h4 pb-lg-3 pb-2">2. Binance Smart Chain Compatibility</h4>
            <p>
                BEP20 tokens are uniquely curated to meet the Binance smart chain requirements offering fast transactions at low fees.
            </p>

            <h3 class="h3 pb-lg-3 pb-2">Use Cases of Bep20 Token Development</h3>
            <p>The BEP20 token is very similar to the Ethereum token, it inherits its qualities, with the possibility of being built as utility tokens, payment tokens, and security tokens. </p>
  
            <h2 class="h2 pb-lg-3 pb-2">TRON  Token Development</h2>
            <p>
                <a href="https://www.blockchainx.tech/tron-token-development/" target="_blank"> TRC20 token development</a> is a process of developing Tron Token on the Tron blockchain similar to ERC20 and BEP20 with the ability to stand equal to the USDT.  Tron tokens are prominently used to create fungible tokens on the network. 
            </p>
            <h3 class="h3 pb-lg-3 pb-2">Service Features of Tron Token Development</h3>
            <h4 class="h4 pb-lg-3 pb-2">1. Token Creation  </h4>
            <p>
                Custom-built TRC20 token development curated for specific needs and use cases. 
            </p>
            <h4 class="h4 pb-lg-3 pb-2">2. Smart contract Development</h4>
            <p>
                This contract governs the token behavior, inclusive of token minting, burning, and transferring.
            </p>
            <h4 class="h4 pb-lg-3 pb-2">3. Token Economics  </h4>
            <p>
                Defining the tokens model, supply, distribution, incentives, etc.  
            </p>
            <h4 class="h4 pb-lg-3 pb-2">4. Exchange Listing</h4>
            <p>
                Compatibility to be listed on Exchange platforms to increase liquidity and visibility.
            </p>

            <h3 class="h3 pb-lg-3 pb-2">Use Cases of Tron Token Development</h3>
            <p>TRC20 tokens are used as in-app currencies, governance tokens in various Dapps, and fundraising for projects. They are also used to tokenize assets on the Tron blockchain and as payment modes. </p>
  
            <h2 class="h2 pb-lg-3 pb-2">Defi Token Development</h2>
            <p>
                Decentralized finances are the economic tool that provides value and access in the blockchain space. We curate a variety of tokens for industries across the globe in the desired Blockchain network including Ethereum, Binance, Thetra, Solana, etc., as a part of our <a href="https://www.blockchainx.tech/defi-development-company/" target="_blank"> Defi Token Development Services</a>. Eventually, we also develop tokens based on functionality like utility, and governance, such as meme coins, reward tokens, etc.  
            </p>
            <h3 class="h3 pb-lg-3 pb-2">Service Features of Defi Token Development</h3>
            <h4 class="h4 pb-lg-3 pb-2">1. Decentralized finance integration </h4>
            <p>
                DeFi tokens are designed to be used within the decentralized finance ecosystem, meeting the regulations and norms. 
            </p>
            <h4 class="h4 pb-lg-3 pb-2">2. Governance</h4>
            <p>
                Most of the DeFi tokens provide holders with voting rights to influence the protocol's development, the network choices, and the project in particular as well. 
            </p>
            <h4 class="h4 pb-lg-3 pb-2">3. Yield generation</h4>
            <p>
                The tokens can be designed to meet the reward system requirements and help in gaining accumulated revenue for holders.  
            </p>

            <h3 class="h3 pb-lg-3 pb-2">Use Cases of Defi Token Development</h3>
            <p>The DeFi tokens can be used for meeting governance needs, developed as Stablecoins coordinating between fiat and digital currency, for yield farming as rewards, and providing liquidity in decentralized exchanges and other DeFi platforms. </p>
  
            <h2 class="h2 pb-lg-3 pb-2">BRC20 Token Development</h2>
            <p>
                Bitcoin being the first ever established network in Blockchain, we also extend our token development service to develop BRC20 tokens.  We ensure to meet the regulations of ordinals and taproot protocol while kickstarting with your <a href="https://www.blockchainx.tech/brc20-token-development/" target="_blank"> BRC20 token development</a> process.
            </p>
            <h3 class="h3 pb-lg-3 pb-2">Service Features of BRC20 Token Development</h3>
            <h4 class="h4 pb-lg-3 pb-2">1. Bitcoin-based Tokens</h4>
            <p>
                BRC20 tokens are built on the Bitcoin blockchain, which offers security, decentralization, and safety. 
            </p>
            <h4 class="h4 pb-lg-3 pb-2">2. Simple functionality</h4>
            <p>
                The token functionality is very simple and has limited functions compared to other Etheruem or Finance-based tokens.
            </p>

            <h3 class="h3 pb-lg-3 pb-2">Use Cases of BRC20 Token Development</h3>
            <p> BRC20 tokens are often used in meme coin creation, and for creating collectible digital assets. All of this focuses on community-driven focus. </p>
  
            <h2 class="h2 pb-lg-3 pb-2">Non-fungible Token Development</h2>
            <p>
                Non Fungible tokens being one of a kind in the blockchain platform, we provide our services to build non-fungible, unique tokens that are merely digital representations of actual art, music, painting, luxury items, rare collectibles, etc. with the ability to have additional functions apart from trading. We also extend our <a href="https://www.blockchainx.tech/non-fungible-token-development/" target="_blank">Non-Fungible token Development services</a> in fractionalising NFTs.
            </p>
            <h3 class="h3 pb-lg-3 pb-2">Service Features of Non-fungible Token Development</h3>
            <h4 class="h4 pb-lg-3 pb-2">1. Non-fungibility</h4>
            <p>
                The NFTs are unique digital assets that cannot be interchanged for others while having unique value independently for themself. 
            </p>
            <h4 class="h4 pb-lg-3 pb-2">2. Digital Ownership</h4>
            <p>
                NFTs represent digital ownership of assets, like art, music, or collectibles. The creator has complete control of it.
            </p>
            <h4 class="h4 pb-lg-3 pb-2">3. Smart contract integration</h4>
            <p>
                NFTs are created using smart contracts that define their properties and ownership. We help you integrate those contracts seamlessly.
            </p>

            <h3 class="h3 pb-lg-3 pb-2">Use Cases of Non-fungible Token Development</h3>
            <p> NFT use cases include tokenizing digital arts that represent ownership. Gaming assets can be tokenized and collected on the blockchain. Eventually, this also applies to real estate tokenization, promoting real-world asset tokenization. </p>
  
            <h2 class="h2 pb-lg-3 pb-2">Real-world asset tokenization</h2>
            <p>
                Real-world asset tokenization is a unique and more fond tokenization in recent times. With the aspect of tokenizing anything and everything, <a href="https://www.blockchainx.tech/real-world-asset-tokenization/" target="_blank"> real-world asset tokenization services</a> include tokenizing any real-world item, from real estate lands, buildings, and monuments, to various industry assets like machinery, data, records, intellectual assets, etc. We provide you with the required customization support in tokenizing your assets, with fractional ownership opportunities. 
            </p>
            <h3 class="h3 pb-lg-3 pb-2">Service Features of Real-world Asset Tokenization </h3>
            <h4 class="h4 pb-lg-3 pb-2">1. Tokenization of physical assets </h4>
            <p>
                These real-world tokens with the potential to convert real-world assets as digital tokens on the blockchain.
            </p>
            <h4 class="h4 pb-lg-3 pb-2">2. Fractional ownership</h4>
            <p>
                Allowing ownership with the fractional investment of the assets.
            </p>
            <h4 class="h4 pb-lg-3 pb-2">3. Increased liquidity</h4>
            <p>
                Real-world asset tokenization improves the liquidity of illiquid assets.
            </p>

            <h3 class="h3 pb-lg-3 pb-2">Use Cases:</h3>
            <p>Most commonly found used in Real estate for tokenizing properties, and in art tokenizing creatives, and the same goes with commodities like gold, silver, etc., as well.</p>
  
            <h2 class="h2 pb-lg-3 pb-2">Native Coin Development</h2>
            <p>
                The Native coin development is a service that provides you with a completely exclusive and unique token developed for your blockchain and used to operate in your network. For custom-built blockchain networks, our Native cin development is a great choice to adhere to.
            </p>
            <h3 class="h3 pb-lg-3 pb-2">Service Features of Native Coin Development </h3>
            <h4 class="h4 pb-lg-3 pb-2">1. New cryptocurrency development</h4>
            <p>
                We curate, design, and launch a new blockchain network, while the service extends to curate its native token.
            </p>
            <h4 class="h4 pb-lg-3 pb-2">2. Customized token </h4>
            <p>
                We tailor these tokens based on your requirements and needs.
            </p>

            <h3 class="h3 pb-lg-3 pb-2">Use Cases of Native Coin Development</h3>
            <p>Create a new decentralized Platform compatible with varied applications and needs. This allows companies to curate their network and token for both internal and external purposes. </p>
  
            <h2 class="h2 pb-lg-3 pb-2">Token Smart contract audit</h2>
            <p>
                As a part of our token development services, we extend to provide Token <a href="https://www.blockchainx.tech/smart-contract-development/" target="_blank"> smart contract Audit services</a> for crypto tokens we develop to ensure the coins we developed are authentic and rightly coded. This can also be provided as an external service for tokens already there as an evaluation process. 
            </p>
            <h3 class="h3 pb-lg-3 pb-2">Service Features of Token Smart Contract Audit</h3>
            <h4 class="h4 pb-lg-3 pb-2">1. Security assessments</h4>
            <p>
                Ensuring the code is secured and safe for the token to perform, we here identify and address the potential vulnerabilities in the contract. 
            </p>
            <h4 class="h4 pb-lg-3 pb-2">2. Code Reviewing </h4>
            <p>
                This process involves analyzing the smart contract code for errors, bugs, and malicious codes.
            </p>
            <h4 class="h4 pb-lg-3 pb-2">3. Risk Assessments </h4>
            <p>
                We ensure the risk associates are traced and the required steps for the token protection are done, while also guiding things from the infrastructure level. 
            </p>

            <h3 class="h3 pb-lg-3 pb-2">Use Cases of Token Smart Contract Audit</h3>
            <p>This service helps protect the token from loss, entrusting investors with financial risks, and safeguarding them from security breaches. Going ahead we also make sure the token adheres to relevant regulations and standards.</p>
  
            <h2 class="h2 pb-lg-3 pb-2">Token migrations services</h2>
            <p>
               <a href="https://www.blockchainx.tech/token-migration/" target="_blank">Token migration services</a> is a process of enhancing the preexisting tokens with the twist of betterment. Here in our service, we ensure to upgrade the token with all custom requirements, change blockchain, and functionality, update features, and more.
            </p>
            <h3 class="h3 pb-lg-3 pb-2">Service Features of Token Migration Services</h3>
            <h4 class="h4 pb-lg-3 pb-2">1. Token Transfers</h4>
            <p>
                We migrate tokens from one blockchain network to another based on your needs and requirements. 
            </p>
            <h4 class="h4 pb-lg-3 pb-2">2. Value Preservation</h4>
            <p>
                We also make sure the token value is maintained during this migration process while cultivating for better growth.
            </p>
            <h4 class="h4 pb-lg-3 pb-2">3. Technical expertise</h4>
            <p>
                With our expert and experienced team, we can help you meet your technical requirements more efficiently in terms of token migrations smoothly.
            </p>

            <h3 class="h3 pb-lg-3 pb-2">Use Cases of Token Migration Services</h3>
            <p>Leveling up your pre-existing token to a new shape and performance, open to vitalize for any sector. This also helps in meeting the regulatory norms for the token and improves in terms of functionalities.</p>

            <!-- <p>Take this blog with you, Download our <a href="assets-new/images/blog/token-development-services/token-development-service-list.pdf" target="_blank">Token development services list PDF</a> and read it on the go</p> -->


            <div class="discus-detail p-md-3 p-2 text-center mb-sm-4 mb-2">
              <p class="common-sub-heading-alt pb-0">Take this blog with you, Download our <a href="assets-new/images/blog/token-development-services/token-development-service-list.pdf" target="_blank">Token development services list PDF</a> and read it on the go</p>
            </div>
  
            <h2 id="item-4" [ngClass]="selectedLink===4?'scrollHeading':'scrollheadingone'">Future Prospects of Crypto Token Development</h2>
            <p class="pb-2 d-flex">
                <i class="bi bi-check-lg"></i><span>The future aspects of crypto token development are positive and revolutionary. The global cryptocurrency market growth can (ace to $ 5 billion by 203), the main reason for this explosive growth is the increased adaptation and technical advancement.</span>
            </p>
            <p class="pb-2 d-flex">
                <i class="bi bi-check-lg"></i><span>Allied Market Research has shared a few research analyses from their end on crypto token evolution which states a 12.8% increase in growth rate during 2021 and 2023</span>
            </p>
            <p class="pb-2 d-flex">
                <i class="bi bi-check-lg"></i><span>Increased demand among main core drivers, along with international remittance and transparency coming your way concerning payment methods. </span>
            </p>
            <p class="pb-2 d-flex">
                <i class="bi bi-check-lg"></i><span>The twin three industries during 2021 were soon attributing toward mining and blockchain, which the dominance shall continue to grow.</span>
            </p>
            <p class="pb-2 d-flex">
                <i class="bi bi-check-lg"></i><span>The transaction part of the service has a possibility for significant growth.</span>
            </p>
            <p class="pb-2 d-flex">
                <i class="bi bi-check-lg"></i><span>The Asia Pacific crypto industry is on top margins where the competition, adaptation, and acceptance are increasing. </span>
            </p>
            <p class="pb-2 d-flex">
                <i class="bi bi-check-lg"></i><span>The margin of a company’s crypto asset management market will scale up to $9.4 billion from $670 by the end of this decade. </span>
            </p>
            <p class="pb-2 d-flex">
                <i class="bi bi-check-lg"></i><span>Asia Pacific is the most significant part where the actual growth is witnessed. There are a large number of crypto mining enterprises entering the market, which creates demand and pulls the audience eventually.  </span>
            </p>

            <p>
                As the concept is still rooted in the ecosystem, where the regulations are refining, public sentiments, and technology innovation are still - and of course, the future that holds for cryptocurrency token development is not fully predictable.
            </p>

            <h2 id="item-5" [ngClass]="selectedLink===5?'scrollHeading':'scrollheadingone'">How BlockchainX helps you to create</h2>
            <picture>
                <source type="image/webp" srcset="assets-new/images/blog/token-development-services/blockchainx-helps.webp">
                <img loading="lazy" src="assets-new/images/blog/token-development-services/blockchainx-helps.jpg" alt="How BlockchainX helps you to create" title="How BlockchainX helps you to create" class="img-fluid" width="840" height="613"/>
            </picture>  
            <p>BlockchainX with our expertise in the sector has a great market reputation and development standards that we maintain. We are glad to extend our token development services that can be tailored and customized for your preference. We curate all the above services, at the meters and metrics you decide for your project while our expertise lies in</p>
            <p class="pb-2 ">
                1. Rendering technology advanced solutions.
            </p>
            <p class="pb-2 ">
                2. Highly secured token development services.
            </p>
            <p class="pb-2 ">
                3. Industry based Token solutions.
            </p>
            <p class="pb-2 ">
                4. Tokens are Customized for your needs.
            </p>
            <p class="pb-2 ">
                5. Engage in the end-to-end token development process.
            </p>
            <p class="pb-2 ">
                6. Planning tokenomics with complete transparency.
            </p>
            <p class="pb-2 ">
                7. Token upgradation services for existing token.
            </p>
            <p class="pb-2 ">
                8. Smart contract auditing and improving.
            </p>
            <p>
                9. On-time delivery with post-launch services.
            </p>
            <p>
                The process of token development service being itself a detailed one, we at <a href="https://www.blockchainx.tech/" target="_blank"> BlockchainX</a> strive to contribute from the very minute you enter into our verse. While with hands-on experience, our team of developers crafts your tokens with utmost precision and quality. Where security is doubled and the integration with respect to utility, functions, and others are neatly put together. Also, the team is curious and open to learning and upgrading, which is an invitation to you to get creative, and lets together build the revolutionizing crypto token that the world hasn’t explored yet. 
            </p>
            <p>
                Connect with our Tech Experts now, and launch your token Soon!
            </p>
          </div>
          <app-social></app-social>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="blog-right-side table-content">
            <div class="table-content-main">
              <div class="metaverse_heading">
                <p class="pb-0 h-3"><span>Table of Contents</span></p>
              </div>
              <div class="categories_list_view table-contens">
                <ul>
                  <li>
                    <a href="https://www.blockchainx.tech/token-development-services-list/#item-1" (click)="addClass(1)">Token development services</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/token-development-services-list/#item-2" (click)="addClass(2)">Why Is It Important To Have Token Development Services?</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/token-development-services-list/#item-3" (click)="addClass(3)">Different Types of Token Development Services</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/token-development-services-list/#item-4" (click)="addClass(4)">Future Prospects of Crypto Token Development</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/token-development-services-list/#item-5" (click)="addClass(5)">How BlockchainX helps you to create</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="blog-author-wrap">
              <div class="blog-author-detail">
                <div>
                  <img src="assets-new/images/blog/yokesh-shankar.svg" alt="blog author" title="About Author" width="150" height="150"/>
                </div>
                <div>
                  <div class="metaverse_heading">
                    <p class="h-4">
                      <span>About Author</span>
                    </p>
                  </div>
                  <p class="h-5">Yokesh Sankar</p>
                  <div class="author-social-connect">
                    <ul>
                      <li>
                        <a href="https://www.instagram.com/yokesh.sankar/" target="_blank" class="fs-5" rel="noopener noreferrer" alt="instagram" aria-label="Instagram">
                          <i class="bi bi-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://in.linkedin.com/in/yokeshsankar" target="_blank" class="fs-5" rel="noopener noreferrer" alt="Linkedin" aria-label="Linkedin">
                          <i class="bi bi-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/yokesh.shankar" target="_blank" class="fs-5" rel="noopener noreferrer" alt="facebook" aria-label="facebook">
                          <i class="bi bi-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/yokesh_sankar_v" target="_blank" class="fs-5" rel="noopener noreferrer" alt="Twitter" aria-label="twitter">
                          <i class="bi bi-twitter-x"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>I am Yokesh Sankar. Co-Founder of BlockchainX, My vision and time tooffer the best products for our clients without apprehensions ateconomic rates.</p>
            </div>
            <div class="social-shares">
              <div>
                <a href="https://twitter.com/Blockchainxtech" target="_blank" rel="noopener noreferrer">Follow us on Twitter<span class="mx-2 cyan-300"><i class="bi bi-twitter-x color cyan-300"></i></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-blog></app-blog>
  <section class="section-service-alt-main">
    <app-get-in-touch></app-get-in-touch>
  </section>