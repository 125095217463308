import { Component, HostListener, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd, Event, RoutesRecognized  } from '@angular/router';

import { TITLE_PROVIDER } from './title-provider';
import { filter } from 'rxjs/operators';
import { TrackingService } from './shared/service/tracking.service';

// import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  isHomeNewPage: boolean = false;
  doc: any;

  title(title: any) {
    throw new Error('Method not implemented.');
  }
  public defaultTitle: string = 'BlockchainX';

  constructor(
    private router: Router,
    private titleService: Title,
    private meta: Meta,
    // private ngxService: NgxUiLoaderService,
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService,
  ) {
    // set dynamic title and meta from title provider service
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        console.log(event, 'event');
        console.log(TITLE_PROVIDER[event.url]?.meta);

        this.titleService.setTitle(
          TITLE_PROVIDER[event.url]
            ? TITLE_PROVIDER[event.url].title
            : this.defaultTitle
        );
        TITLE_PROVIDER[event.url]?.meta.forEach((cur: any) => {
          this.meta.updateTag(cur);
        });
        // this.meta.addTags(TITLE_PROVIDER[event.url] ? (TITLE_PROVIDER[event.url].meta) : [],false);
      }
    });



  }
  ngOnInit() {
    this.router.events.subscribe((data) => {
      if(data instanceof NavigationEnd){
        console.log(data);
              // Track referrer and page visits on each load
        this.trackingService.trackReferrer();
        this.trackingService.trackPageVisit();

      }

      if (data instanceof RoutesRecognized) {
     Object.entries(this.linkKey).forEach(([key, value]) => {
          if(data.url == key){
            const linkdata = value;
            const tag = linkdata.tag;
            if (tag == 'link') {
              this.addlinkWithHead(value)
            }
          }else{
            let links = document.head.getElementsByTagName('link');
            for (let link in links) {
               if (links.hasOwnProperty(link)) {
                 let lnk = links[link];
                 if (lnk.rel == value.attributes.rel) {
                       this.doc.head.removeChild(lnk);
                    }
             }
           }
       }
       });
    }
    });

    // this.ngxService.start();
    document.addEventListener('touchstart', this.onTouchStart, {
      passive: true,
    });


    this.router.events
      .pipe(
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.isHomeNewPage = event.urlAfterRedirects === '/home-new/';
      });

  }
  addlinkWithHead(value: any) {
    throw new Error('Method not implemented.');
  }
  linkKey(linkKey: any) {
    throw new Error('Method not implemented.');
  }
  onTouchStart() {}

  show = false;
  fullScreen = true;
  template = ``;
}
