<div class="blog-right-side">
  <app-newsletter-form></app-newsletter-form>
    <!-- <div class="blog-contact mb-4">
      <div class="metaverse_heading">
        <h4><span>Newsletter</span></h4>
      </div>
      <h5>
        Get all the latest posts
        <span class="d-block">delivered straight to your inbox.</span>
      </h5>
      <input type="text" class="form-control" placeholder="Your email address" />
      <a class="blog_submit">Submit</a>
    </div> -->
    <!-- <div class="categories_list">
      <div class="metaverse_heading">
        <h4><span>Categories</span></h4>
      </div>
      <div class="categories_list_view">
        <ul>
          <li>
            <a>NFT</a>
          </li>
  
          <li>
            <a>Metaverse</a>
          </li>
  
          <li>
            <a>NFT Marketplace</a>
          </li>
  
          <li>
            <a>DEFI</a>
          </li>
  
          <li>
            <a>DAO</a>
          </li>
  
          <li>
            <a>Cryptocurrency</a>
          </li>
  
          <li>
            <a>Wallet</a>
          </li>
          <li>
            <a>Crypto Exchange</a>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="my-4">
      <div class="blog-author-wrap">
        <div class="blog-author-detail">
          <div>
            <img
              src="assets-new/images/blog/yokesh-shankar.svg"
              alt="blog author"
              width="150"
              height="150"
            />
          </div>
          <div>
            <div class="metaverse_heading">
              <h4><span>About Author</span></h4>
            </div>
            <h5>Yokesh Sankar</h5>
            <div class="author-social-connect">
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/yokesh.sankar/"
                    target="_blank"
                    class="fs-5"
                    rel="noopener noreferrer"
                    alt="instagram"
                    aria-label="Instagram"><i class="bi bi-instagram"></i></a>
                </li>
                <li>
                  <a
                    href="https://in.linkedin.com/in/yokeshsankar"
                    target="_blank"
                    class="fs-5"
                    rel="noopener noreferrer"
                    alt="Linkedin"
                    aria-label="Linkedin"><i class="bi bi-linkedin"></i></a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/yokesh.shankar"
                    target="_blank"
                    class="fs-5"
                    rel="noopener noreferrer"
                    alt="facebook"
                    aria-label="facebook"><i class="bi bi-facebook"></i></a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/yokesh_sankar_v"
                    target="_blank"
                    class="fs-5"
                    rel="noopener noreferrer"
                    alt="Twitter"
                    aria-label="twitter">
                    <!-- <picture>
                        <source
                          type="image/webp"
                          srcset="assets-new/images/twitterx.webp"
                        />
        
                        <source
                          type="image/png"
                          srcset="assets-new/images/twitterx.svg"
                        />
        
                        <img loading="lazy"
                          data-src="assets-new/images/twitterx.svg"
                          
                          alt="twitter"
                          width="32"
                          height="32"
                          class="contact-logo img-fluid"
                        />
                      </picture> -->
                    <i class="bi bi-twitter-x"></i>
                    <!-- <i class="bi bi-twitter"></i> -->
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p>
          I am Yokesh Sankar. Co-Founder of BlockchainX, My vision and time to
          offer the best products for our clients without apprehensions at
          economic rates.
        </p>
      </div>
      <div class="social-shares">
        <div>
          <a href="https://twitter.com/Blockchainxtech" target="_blank"
                rel="noopener noreferrer"
            >Follow us on Twitter
            <span class="mx-2"
              ><i class="bi bi-twitter-x"></i></span
          ></a>
        </div>
      </div>
    </div>
  </div>
  