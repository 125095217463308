<section class="banners-section" #about>

    <div class="banner_wrapper">
      <div class="container" id="common-relative">
        <div class="green-vector"></div>
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12 col-sm-12 col-12">
              <div class="banner_contentWrapper">
                <div class="caption">
                  <h1>
                    <span><strong>OP Stacks Enabling </strong></span> Your Tailored Layer 2 Blockchain
                  </h1>
                  <p>
                    We help you uncover the true potential of your tailored blockchain with OP Stack Roll up development integrations scaling new possibilities.
                  </p>

                  <div class="contact-link">
                    <a [routerLink]="['/contact']" class="home-link"
                      >Talk To Our Experts</a
                    >
                    <a [routerLink]="['/case-studies']" class="home-link-alt"
                      >View Case Study</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 text-center">
              <div class="banner-right-section">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/launch-your-token.webp" />
                  <source type="image/png" srcset="assets-new/images/token-images/launch-your-token.png" />
                  <img loading="lazy" data-src="assets-new/images/token-images/launch-your-token.png" width="500" height="500" class="img-fluid" alt="op stack rollups development" title="op stack rollups development" />
                </picture>
              </div>
            </div>

        </div>
        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp"
              />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png"
              />
              <img
                loading="lazy"
                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="scroll-icon"
                title="scroll-icon"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
    <div class="container">
      <div class="whys_section mb-0 text-center">
        <h2 class="pb-lg-5 pb-4"><span>What Is OP Stack</span> Roll Up Development?</h2>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
          <div class="bep-work-section">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/why-should-you-choose.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/why-should-you-choose.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/why-should-you-choose.png"
                width="350" height="350" alt="op stack development"
                title="op stack development" class="BSC-Tokens-image img-fluid">
            </picture>
          </div>
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <p>
                OP stack is the software component building the infrastructure of the Optimism Mainnet to empower the network and its ecosystem. The main objective of OP stack roll up development is to create a shared, high quality, open source system to conveniently deploy new roll up networks.
            </p>
          </div>

          <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  Helps in streamlining layer2 blockchain networks and ecosystem
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Encompasses array of tools for the layer on its top
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Tailored blockchain solutions enabling your requirement
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Improved efficiency, scalability and customization
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<div class="section-service-main">
    <div class="tending-bep-20">
      <div class="container">
        <div class="row trending-token-list">
          <div class="col-lg-6 text-center order-lg-first order-last">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
              <source type="image/png"
                srcset="assets-new/images/erc/trending-erc20-token-list.png" />
              <img loading="lazy"
                data-src="assets-new/images/erc/trending-erc20-token-list.png"
                width="405" height="405" alt="op stack rollups"
                title="op stack rollups" class="img-fluid mx-auto">
            </picture>
          </div>
          <div class="col-lg-6 mb-lg-0 mb-4">
            <h3 class="main-sub-heading text-start"><span>Check Out Layer2 Blockchain </span> Platform using OP Stack</h3>
            <div class="row trending-bep">
              <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
                <ul>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span><a href="#" target="_blank"
                rel="noopener noreferrer">Base by Coinbase</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="#" target="_blank"
                rel="noopener noreferrer">L2 Blockchain  Using OP Stacks</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="#" target="_blank"
                rel="noopener noreferrer">Mantle by BitDAO and Bybit</a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
                <ul>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="#" target="_blank"
                rel="noopener noreferrer">ZORA by sector veterans</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="#" target="_blank"
                rel="noopener noreferrer">Worldcoin by Sam Altman</a>
                  </li>
                  <li>
                    <span><i class="bi bi-hexagon-fill"></i></span> <a href="#" target="_blank"
                rel="noopener noreferrer">MANTA by BinanceLabs</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-flex pt-3">
              <a [routerLink]="['/contact']" class="home-link-alt">Connect With
                Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 metaverse_heading mx-auto">
          <h3><span>Why Should You Opt OP Stack RollUps </span>Integration For Your Layer2 Blockchain Development?</h3>
        </div>
      </div>
      <div class="row types-of-token-detail">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/project-consulting.webp">
                <source type="image/png" srcset="assets-new/images/token images/project-consulting.png">
                <img loading="lazy" data-src="assets-new/images/token-images/project-consulting.png" width="75" height="75" alt="Open to Customize" title="Open to Customize" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Open to Customize</h4>
            <p>
                Based on the diverse needs of the business the modularity inherited in chain allows OP stack Design sail through customization meeting requirements.
            </p>
            <!-- <div class="content-link">
              <a [routerLink]="['/']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                    loading="lazy" />
                </picture>
              </a>
              <hr />
            </div> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/staking-platform.webp">
                <source type="image/png" srcset="assets-new/images/token-images/staking-platform.png">
                <img loading="lazy" data-src="assets-new/images/token-images/staking-platform.png" width="75" height="75" alt="Deployment has no huddle" title="Deployment has no huddle" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">
                Deployment has no huddle
            </h4>
            <p>
                In order to make the entire process easy and seamless to deploy in the most convenient and effortless manner. This cut down time in the blockchain launch.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/farming-platform.webp">
                <source type="image/png" srcset="assets-new/images/token-images/farming-platform.png">
                <img loading="lazy" data-src="assets-new/images/token-images/farming-platform.png" width="75" height="75" alt="Supreme level of Scalability" title="Supreme level of Scalability" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Supreme level of Scalability</h4>
            <p>
                OP stack addresses scalability issues at the lowest cost, optimizes blockchain and enables faster and efficient operations and avoids congestion.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-images/token-bridge.webp">
                <source type="image/png" srcset="assets-new/images/token-images/token-bridge.png">
                <img loading="lazy" data-src="assets-new/images/token-images/token-bridge.png" width="75" height="75" alt="Highly efficient Resources" title="Highly efficient Resources" class="img-fluid">
              </picture>
            </div>
            <h4 class="erc-20-head-alt text-left">Highly efficient Resources</h4>
            <p>
                OP stack has the features to utilize the security of available data and operate by single sequencer. Also it can use available tools rather than new resources efficiently.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/layer-i-blockchain.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/layer-i-blockchain.png" width="75" height="75" alt="Extremely Cost Effective" title="Extremely Cost Effective" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Extremely Cost Effective </h4>
              <p>
                Utilizing OP Stack is a cost effective business option in the crypto space as it reduces gas fees and cuts down protocol cost, batch submission cost and more.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/nft-markeplace.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/nft-markeplace.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/nft-markeplace.png" width="75" height="75" alt="Versatile performance" title="Versatile performance" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">
                Versatile performance
              </h4>
              <p>
                OP Staack development ensures the performance of the platform or layer 2 blockchain is versatile enabling seamless integrations and optimal performance.
              </p>
            </div>
          </div>
      </div>

    </div>
</section>
<!-- Related Services -->

  <!-- why-choose-section -->
  <section class="section-service-main">
      <div class="green-vector"></div>
      <div class="orange-vector"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 metmb-3">
            <div class="whys_section mb-0">
              <h2>
                <span>OP Stack Development </span>With BlockchainX
              </h2>
              <p class="pb-3">
                We have an active proficient team to work on your Projects with hands-on experience in Blockchain development and ensure to serve the best for your operations. We are a team of highly motivated, experienced and open to learn individuals with the knack to transform your possibilities into reality. Over 70 plus projects in the past few years, we have successfully achieved our milestones in customizing and sculpting our clients requirements.
              </p>
              <div class="whysection_text_wrap">
              <ul>
                <li>A strategic and experienced team</li>
                <li>Expert field knowledge</li>
                <li>Access your project on time</li>
                <li>Post launch and Maintenance support</li>
              </ul>
            </div>
              <div class="content-link">
                <a [routerLink]="['/contact']">
                  <!-- <span>Read More</span> -->
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                       />
                  </picture>
                  <picture>
                    <source type="image/webp"
                      srcset="assets-new/images/right-arrow-dark.webp">
                    <source type="image/png"
                      srcset="assets-new/images/right-arrow-dark.png">
                    <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                      width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                       />
                  </picture>
                </a>
                <hr />
              </div>
            </div>
          </div>
          <div class="col-lg-6 metmb-3 Why-section">
            <div class="why_right_side">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

                <img
                  data-src="assets-new/images/token-images/why-choose-blockchainx.png"
                  width="624"
                  height="521"
                  class="img-fluid"
                  alt="OP Stack Development"
                  title="OP Stack Development"
                  loading="lazy" />
              </picture>
            </div>
          </div>
        </div>
      </div>
  </section>
  <!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              FAQ <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    What is OP Stacks Development?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        OP stacks refer to integrating new updated versions of each feature and functionality as one to improve the performance of the software. Highly utilized for its scalability in developing Layer2 blockchain.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    What is the cost of developing your blockchain platform in layer 2 ?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        The time and cost of developing a blockchain platform depend on your performance expectations, and advancement in functions, technologies, and features included.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    What are the advantages of developing with BlockchainX ?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                        The main advantage of choosing BlockchainX as your technical partner is that we are open to new learning, advancements, and customizations, and ensure security and on-time delivery.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
  </section>
  <!-- FAQ End -->

    <!-- Blog Start -->
<section class="section-service-main">
    <div class="green-vector"></div>
    <div class="container">
      <div class="row blog-list">
        <div class="col-lg-12">
          <div class="commontext_subhead">
            <h3>Blog<span class="dots">.</span></h3>
          </div>
          <div class="feature_wrap">
            <div class="commontext_wrap">
              <h2>Check out our Latest Blogs </h2>
            </div>
            <div class="deploy-link">
              <a [routerLink]="['/blog']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 left-vector">
          <owl-carousel-o [options]="blogs_details">
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                  <picture>
                    <!-- <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                      "
                    /> -->
                    <source
                      type="image/jpg"
                      srcset="
                      assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                      "
                    />
                    <img
                      loading="lazy"
                      data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                      width="404"
                    height="246"
                      class="img-fluid"
                      alt="Web3 Gaming"
                      title="AI Transforming Web3 Gaming And Metaverse"
                    />
                  </picture>
                  <h3>
                    AI Transforming Web3 Gaming And Metaverse
                  </h3>
                  <p>
                    In the 1990s, the introduction of the internet, and the evolution of something beyond reality kick-started ...
                  </p>
                </a>
                <div class="content-link">
                  <a [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="[
                    '/what-is-gamefi'
                  ]"
                >
                  <picture>
                    <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.webp
                      "
                    /> -->
                    <source
                      type="image/jpg"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.jpg
                      "
                    />
                    <img
                      loading="lazy"
                      data-src="assets-new/images/blog/what-is-gamefi.jpg"
                      width="404"
                    height="246"
                      alt="What is GameFi?"
                      title="What is GameFi?"
                    />
                  </picture>
                  <h3>
                    What is GameFi? Diving into the world of Blockchain Games
                  </h3>
                  <p>
                    From the earlier days of the video game industry, developers have aimed to create worlds as powerful as real life....</p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="[
                      '/what-is-gamefi'
                    ]"
                  >
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="blog_wrap">
                <a
                  [routerLink]="[
                    '/blockchain-game-development'
                  ]"
                >
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                      assets-new/images/industries/blockchain-in-gaming.webp
                      "
                    />
                    <source
                      type="image/jpg"
                      srcset="
                      assets-new/images/industries/blockchain-in-gaming.jpg
                      "
                    />
                    <img
                      loading="lazy"
                      data-src="assets-new/images/industries/blockchain-in-gaming.jpg"
                      width="404"
                    height="246"
                      class="img-fluid"
                      alt="Blockchain Technology"
                      title=" The Potential of Blockchain Technology to Enhance Industry Profits "
                    />
                  </picture>
                  <h3>
                    The Potential of Blockchain Technology to Enhance Industry Profits
                  </h3>
                  <p>
                    Mario video games in the year 1981 became very famous among game lovers by the name of Jumpman. In today’s world,...
                  </p>
                </a>
                <div class="content-link">
                  <a
                    [routerLink]="[
                      '/blockchain-game-development'
                    ]"
                  >
                    <!-- <span>Read More</span> -->
                    <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                  </a>
                  <hr />
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog End -->

  <!-- Contact Start -->
  <section class="section-service-alt">
    <div class="app-get-in-touch-form">
      <div class="container">
          <div class="contact-form">
              <div class="row reverse-colum">
                <div class="col-lg-5 col-md-12 contact-social-links">
                  <h3 class="main-sub-heading"><span>High-end build quality for your Software with</span></h3>
                  <p>OP Stack Development, Bringing all together. Reach out to avail now!</p>
                  <div class="contact-main-list">
                    <div class="social-links">
                      <a href="mailto:contact@blockchainx.tech"  target="_blank"
                rel="noopener noreferrer" class="contact-mail" >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img
                            data-src="assets-new/images/contact/mail.png"
                            alt="linkedin"
                            width="32"
                            height="32"
                          />
                        </picture>
                      </a>

                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer" class="whatsapp">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img
                            data-src="assets-new/images/contact/whatsapp-icon.png"
                            alt="contact-Whatsapp"
                            width="32"
                            height="32"
                          />
                        </picture>
                      </a>

                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer" class="twitter" >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/svg"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img
                            data-src="assets-new/images/contact/telegram.png"
                            alt="twitter"
                            width="40"
                            height="40"
                          />
                        </picture>
                      </a>
                    </div>
                    <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img
                            src="assets-new/images/contact/mail.png"
                            loading="lazy"
                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>

                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img
                            src="assets-new/images/contact/whatsapp-icon.png"
                            loading="lazy"
                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div>

                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img
                            src="assets-new/images/contact/telegram.png"
                            loading="lazy"
                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
                  </div>
                  <div class="contact-links">
                    <h4>Follow us on</h4>
                    <div class="social-links">
                      <a
                        href="https://www.linkedin.com/company/blockchainxtech/"
                        target="_blank"
                rel="noopener noreferrer"
                        class="linkdin"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/linkdin.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/linkdin.png"
                          />

                          <img
                            data-src="assets-new/images/contact/linkdin.png"
                            alt="linkedin"
                            width="22"
                            height="22"
                          />
                        </picture>
                      </a>

                      <a
                        href="https://www.instagram.com/blockchainxtech/"
                        target="_blank"
                rel="noopener noreferrer"
                        class="instagram"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/instagram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/instagram.png"
                          />

                          <img
                            data-src="assets-new/images/contact/instagram.png"
                            alt="instagram"
                            width="22"
                            height="22"
                          />
                        </picture>
                      </a>

                      <a
                        href="https://twitter.com/Blockchainxtech"
                        target="_blank"
                rel="noopener noreferrer"
                        class="twitter"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/twitterx.webp"
                          />

                          <source
                            type="image/svg"
                            srcset="assets-new/images/contact/twitterx.svg"
                          />

                          <img
                            data-src="assets-new/images/contact/twitterx.svg"
                            alt="twitter"
                            width="25"
                            height="25"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 col-md-12 contact-inputs">
                  <div class="getintouch getintochform bitrix-snippet-form">
                    <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
  </section>
  <!-- Contact End -->
