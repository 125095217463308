import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute,Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-launch-your-meme-coin',
  templateUrl: './launch-your-meme-coin.component.html',
  styleUrls: ['./launch-your-meme-coin.component.css']
})
export class LaunchYourMemeCoinComponent implements OnInit {

  selectedLink:number;
  title ='How to Launch Your Own Meme Coin: A Comprehensive Guide';

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  isDarkmode:boolean=false;

  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'Did the meme coin buzz hit you and you wanted to launch your meme coin in the market? Then this guide can perfectly navigate to launch your token. Tap to read!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'launch your own meme coin, launch meme coin, how to launch your own meme coin, launch your meme coin, how to launch a meme coin, how to launch meme coin, how to launch my meme coin, easy way to launch a meme coin ',
    });

    
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'How to Launch Your Own Meme Coin: A Comprehensive Guide ',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/launch-your-own-meme-coin/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Did the meme coin buzz hit you and you wanted to launch your meme coin in the market? Then this guide can perfectly navigate to launch your token. Tap to read! ',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/launch-your-meme-coin/launch-your-meme-coin.webp',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/launch-your-own-meme-coin/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'How to Launch Your Own Meme Coin: A Comprehensive Guide ',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Did the meme coin buzz hit you and you wanted to launch your meme coin in the market? Then this guide can perfectly navigate to launch your token. Tap to read! ',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/launch-your-meme-coin/launch-your-meme-coin.webp',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/launch-your-own-meme-coin/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/launch-your-own-meme-coin.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }
}
