<!-- Banner Section -->
<div class="banners-section">

  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Dapps Development </strong> Services</span>
              </h1>

              <p>
                Launch your Dapp project without long turnaround times. Our core
                tech team is ready to deliver with the right expertise.
              </p>

              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>

                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/dapps-development-services.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/dapps-development-services.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/dapps-development-services.png"
                class="img-fluid"
                alt="Dapps-Development"
                title="Dapps-Development"
                width="350"
                height="350"
              />
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp"
            />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png"
            />
            <img
              loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon"
            />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Banner Section ends -->

<section class="section-service-alt about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          Our<span>Clients</span>
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>

<!--The dApp Revolution start -->
<div class="section-service-main" #about>
  <div class="container">
    <div class="row reverse dao-Platform align-items-center">
      <div class="col-lg-5 mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/dapps-development-services/the-dapp-revolution.webp
              "
            />

            <source
              type="image/png"
              srcset="
                assets-new/images/dapps-development-services/the-dapp-revolution.png
              "
            />

            <img loading="lazy"
              data-src="assets-new/images/dapps-development-services/the-dapp-revolution.png"
              class="img-fluid"
              alt="Process of Decentalization"
              title="Process of Decentalization"
              width="400"
              height="400"
            />
          </picture>
        </div>
      </div>
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 about-content"
      >
        <div class="common-sub-heading">
          <h2 class="main-sub-heading text-start">
            The Dapp Revolution: Redefining Processes through Decentralization
          </h2>
          <p>
            We are BlockchainX, a leader in Dapps development services.
            Blockchain can streamline your business process greatly. All you
            need is a Dapp to integrate a smart contract into your business.
            Dapp implementation can reduce costs and save time by automating
            operations with minimal human intervention. Our Dapp experts design
            and build applications with high-end security and scalable for a
            wide range of firms. We customize Web3-based dApp to harness the
            capabilities of blockchain technology.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- The dApp Revolution end -->

<!-- Product Features One -->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Our <span>DApps Development</span> Services</h3>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/dapp-consulting-services.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/dapp-consulting-services.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/dapp-consulting-services.png"
                class="img-fluid"
                alt="dApp Consulting Services"
                title="dApp Consulting Services"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Dapp Consulting Services</h4>
          <p>
            Contact our expert blockchain consultants who specialize in
            decentralized application development. We provide comprehensive
            guidance on the technical and business aspects of your project.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/decentralized-exchange-development.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/decentralized-exchange-development.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/decentralized-exchange-development.png"
                class="img-fluid"
                alt="Decentralized Exchange Development"
                title="decentralized Exchange Development"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Decentralized Exchange Development
          </h4>
          <p>
            Our Dapp development experts develop high-quality DEXs for various
            organizations. We customize Dapps as per clients' business needs
            with better functionality and enhanced features.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/smart-contracts-creation.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/smart-contracts-creation.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/smart-contracts-creation.png"
                class="img-fluid"
                alt="Smart Contracts Creation"
                title="Smart Contracts Creation"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Smart Contracts Creation</h4>
          <p>
            Our solidity experts will be right at your disposal to build the
            right smart contracts for your business. We revamp your existing
            smart contracts to support any desired functionality.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/user-interface-designing.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/user-interface-designing.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/user-interface-designing.png"
                class="img-fluid"
                alt="User Interface Designing"
                title="User Interface Designing"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">User Interface Designing</h4>
          <p>
            We have a team of mind-blowing designers who follow a structured
            process to develop UI/UX. From ideation to design, we create
            high-fidelity designs with interactive prototypes.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/cloud-storage-services.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/cloud-storage-services.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/cloud-storage-services.png"
                class="img-fluid"
                alt="Cloud Services Offerings"
                title="Cloud Services Offerings"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Cloud Storage Services</h4>
          <p>
            We focus more on choosing an apt cloud storage platform. Our Dapps
            are ready to record securely stored transactions and enable exchange
            transactions on peer-to-peer networks.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/decentralized-storage-services.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/decentralized-storage-services.png
                "
              />

              <img loading="lazy"
                src="assets-new/images/dapps-development-services/decentralized-storage-services.png"
                class="img-fluid"
                alt="Decentralized Storage Services"
                title="Decentralized Storage Services"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Decentralized Storage Services
          </h4>
          <p>
            ERC827 is one of the latest upgrades to ERC20 and implements many
            improvements. One of them being that you can now transact not only
            values but also data.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/dapp-upgrade-solutions.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/dapp-upgrade-solutions.png
                "
              />

              <img loading="lazy"
                src="assets-new/images/dapps-development-services/dapp-upgrade-solutions.png"
                class="img-fluid"
                alt="Dapp Upgrade Solutions"
                title="Dapp Upgrade Solutions"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Dapp Upgrade Solutions</h4>
          <p>
            As a pioneer in Dapp development services, we check up-to-date
            functionality and timely updates of Dapps to ensure that all
            processes and functions of your Dapp run smoothly.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/dapp-porting-services.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/dapp-porting-services.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/dapp-porting-services.png"
                class="img-fluid"
                alt="dApp Upgrade Solutions"
                title="dApp Upgrade Solutions"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Dapp Porting Services</h4>
          <p>
            The future is cross-chain and you don't want to fall behind. Migrate
            your existing Dapp to any blockchain. We offer full support for dApp
            porting by providing seamless migration.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/dapp-integration-services.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/dapp-integration-services.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/dapp-integration-services.png"
                class="img-fluid"
                alt="dApp integrate Services"
                title="dApp integrate Services"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Dapp Integration Services</h4>
          <p>
            Our experienced Dapp development experts can develop robust and
            interoperable applications and provide seamless Dapp integration
            support to deliver a top-tech application.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Product Features One -->

<!-- Product Features Two -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Business Benefits of <span>Building dApp</span></h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/automation.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/automation.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/automation.png"
                class="img-fluid"
                alt="Automation"
                title="Automation"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Automation</h4>
          <p>
            Build automated systems that require minimal human input through
            smart contracts. Automated Dapps are the perfect choice for solving
            real-world problems in many industries.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/transparency.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/transparency.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/transparency.png"
                class="img-fluid"
                alt="Transparency"
                title="Transparency"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Transparency</h4>
          <p>
            You can track and verify all transactions made by your users through
            your Dapp on the blockchain explorer. Each transaction is recorded
            immutably on the blockchain ledger.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/privacy.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/privacy.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/privacy.png"
                class="img-fluid"
                alt="Privacy"
                title="Privacy"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Privacy</h4>
          <p>
            The main benefit is censorship resistance. With no single entity
            controlling the Dapp, it’s hard for anyone to access your data.
            Blockchain ensures the security and privacy of your data.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/user-governance.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/user-governance.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/user-governance.png"
                class="img-fluid"
                alt="User Governance"
                title="User Governance"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">User Governance</h4>
          <p>
            Another benefit of dApp users is that user governance, holding the
            dapp native governance token gives access to community discussion
            boards, suggestion systems, and voting rights.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/fault-tolerance.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/fault-tolerance.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/fault-tolerance.png"
                class="img-fluid"
                alt="Fault-tolerance"
                title="Fault-tolerance"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Fault-tolerance</h4>
          <p>
            The dApps we build are open source. Existing in a P2P computer
            network, it does not rely on a single point of failure such as a
            hosting server. This confirms no downtime or restrictions.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/dapps-development-services/incentivization.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/dapps-development-services/incentivization.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/dapps-development-services/incentivization.png"
                class="img-fluid"
                alt="Incentivization"
                title="Incentivization"
                width="75"
                height="75"
              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Incentivization</h4>
          <p>
            Another highlighting feature of dApps is incentivization. The dApps
            can incentivize its users through rewards. Contributors of dApps get
            some internal currency or token as rewards.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Product Features Two -->

<!-- Development Services starts -->
<div class="section-service-alt">
  <div class="our-process-erc">
    <div class="container">
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="metaverse_heading">
            <h3>The End-To-End <span>Dapp Development</span> Process</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row erc-processing">
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Build the Blockchain Concept</h4>
            <p>
              We gather the required information & define the technical
              component and roadmap of the project.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Proof of Concept Development</h4>
            <p>
              We identify the technology that suits your project and the
              essential use cases for developing a PoC.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process cmb-5">
            <h4>Technical Design</h4>
            <p>
              We allocate our design team and architect to design the UI/UX and
              identify the level of user interest.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Development</h4>
            <p>
              Once the architecture and designs are done, then it’s off to the
              development team for the end-to-end development process.
            </p>
          </div>
        </div>
        <div class="col-lg-4 metmb-3">
          <div class="virtual_process">
            <h4>Deployment</h4>
            <p>
              We deploy the smart contracts on the testnet and integrate them
              into Dapp. Then pass this Dapp to UAT.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Development Services ends -->

<!-- business-tech-stack -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading">
          <h3>Applications of<span> Dapps</span></h3>
        </div>
      </div>
    </div>
    <div
      class="row row-cols-3 row-cols-sm-4 row-cols-md-6 row-cols-lg-7 justify-content-center text-center app-dapps-common"
    >
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/apecoin.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/apecoin.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/apecoin.png"
              alt="Apecoin"
              title="Apecoin"
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/cosmos.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/cosmos.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/cosmos.png"
              alt="Cosmos"
              title="Cosmos"
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/cronos.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/cronos.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/cronos.png"
              alt="Cronos"
              title="Cronos"
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/daicoin.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/daicoin.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/daicoin.png"
              alt="Daicoin"
              title="Daicoin"
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/decentraland.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/decentraland.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/decentraland.png"
              alt="Decentraland"
              title="Decentraland"
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/elrond.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/elrond.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/elrond.png"
              alt="Elrond"
              title="Elrond"
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/flow.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/flow.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/flow.png"
              alt="Flow"
              title="Flow"
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/flow.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/flow.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/flow.png"
              alt="Flow"
              title="Flow"
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/hedera.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/hedera.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/hedera.png"
              alt="Hedera"
              title="Hedera"
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/business-tech/internet-computer.webp
              "
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/internet-computer.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/internet-computer.png"
              alt="Internet computer "
              title="Internet computer "
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/filecoin.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/filecoin.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/filecoin.png"
              alt="Filecoin "
              title="Filecoin "
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
      <div class="col">
        <div class="techstack_img">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/business-tech/kucoin.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/kucoin.png"
            />

            <img loading="lazy"
              data-src="assets-new/images/business-tech/kucoin.png"
              alt="KuCoin"
              title="KuCoin"
              width="80"
              height="80"
            />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- business-tech-stack -->

<!-- Our Development Process -->
<div class="section-service-alt">
  <div class="container">
    <div class="row mb-5 dap-expert-one">
      <div class="col-lg-12">
        <div class="metaverse_heading">
          <h3>Why Choose BlockchainX for <span> Dapp Development?</span></h3>
        </div>
      </div>
    </div>
    <div class="row align-items-center dap-experts pt-3">
      <div class="col-lg-4 metmb-3">
        <div class="virtual_process cmb-5">
          <h4>We are Blockchain Experts</h4>
          <p>
            Our team of industry-experienced experts works round-the-clock and
            provides end-to-end support for your projects.
          </p>
        </div>
        <div class="virtual_process">
          <h4>Expert Development Team</h4>
          <p>
            Our hard-working team provides all-over development services with
            the fastest turnaround times while maintaining quality.
          </p>
        </div>
      </div>
      <div class="col-lg-4 metmb-3">
        <div class="our_dev_process text-center" id="development-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/metaverse/metaverse-demo.webp"
            />

            <source
              type="image/png"
              srcset="assets-new/images/business-tech/metaverse-demo.png"
            />

            <img
              src="assets-new/images/metaverse/metaverse-demo.png"
              width="275"
              height="275"
              class="img-fluid"
              alt="why choose dapps development"
            />
          </picture>
        </div>
      </div>
      <div class="col-lg-4 virual-list">
        <div class="virtual_process cmb-5">
          <h4>Project-oriented Model</h4>
          <p>
            We follow a project-oriented model while developing dApp, which will
            give you a better understanding of your project.
          </p>
        </div>
        <div class="virtual_process">
          <h4>Top UX Solutions for Your Dapp</h4>
          <p>
            Build your next dApp with the latest tools and excellent UI/UX
            designs and trained experts from the industry.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Our Development Process -->

<!-- Tech Stak Update -->
<div class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="commontext_subhead mb-1">
          <h3>We Work On<span class="dots">.</span></h3>
        </div>
        <h3 class="main-sub-heading">Top Dapp Development Platforms</h3>
      </div>
    </div>
    <div class="business-stak-list-section">
      <div class="row row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/home/stak/frameworks.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/home/stak/frameworks.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/home/stak/frameworks.png"
                  height="23"
                  width="200"
                  class="img-fluid"
                  alt="Ethereum "
                  title="Ethereum "
                />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/home/stak/caliber.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/home/stak/caliber.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/home/stak/caliber.png"
                  height="23"
                  width="200"
                  class="img-fluid"
                  alt="hyperledger "
                  title="hyperledger "
                />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/home/stak/hyperledger-composer.webp
                  "
                />

                <source
                  type="image/png"
                  srcset="
                  assets-new/images/home/stak/hyperledger-composer.png
                  "
                />

                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger-composer.png"
                  height="23"
                  width="200"
                  class="img-fluid"
                  alt="Hyperledger Composer"
                  title="Hyperledger Composer"
                />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/home/stak/hyperledger-explorer.webp
                  "
                />

                <source
                  type="image/png"
                  srcset="
                  assets-new/images/home/stak/hyperledger-explorer.png
                  "
                />

                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger-explorer.png"
                  height="23"
                  width="200"
                  class="img-fluid"
                  alt="Hyperledger Explorer"
                  title="Hyperledger Explorer"
                />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/home/stak/hyperledger.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/home/stak/hyperledger.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger.png"
                  height="23"
                  width="200"
                  class="img-fluid"
                  alt="Hyperledger"
                  title="Hyperledger"
                />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/home/stak/sawtooth.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/home/stak/sawtooth.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/home/stak/sawtooth.png"
                  height="23"
                  width="200"
                  class="img-fluid"
                  alt="Hyperledger"
                  title="Hyperledger"
                />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/home/stak/iroha.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/home/stak/iroha.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/home/stak/iroha.png"
                  height="23"
                  width="200"
                  class="img-fluid"
                  alt="Hyperledger"
                  title="Hyperledger"
                />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/home/stak/fabric.webp"
                />

                <source
                  type="image/png"
                  srcset="assets-new/images/home/stak/fabric.png"
                />

                <img loading="lazy"
                  data-src="assets-new/images/home/stak/fabric.png"
                  height="23"
                  width="200"
                  class="img-fluid"
                  alt="Tron"
                  title="Tron"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Tech Stak Update -->
<section class="section-service-alt">
<app-blog></app-blog>
</section>
<!-- <section class="section-service-alt-padd">
<app-contactform></app-contactform>
</section> -->
<section class="section-service-alt">
  <app-get-in-touch></app-get-in-touch>
</section>