import {
  Component,
  OnInit
} from '@angular/core';
import {
  Title,
  Meta,
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-mev-protection',
  templateUrl: './mev-protection.component.html',
  styleUrls: ['./mev-protection.component.css']
})
export class MevProtectionComponent implements OnInit {
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService
  ) {}


  isDarkmode:boolean=false;
  title = 'For Secured Fair Transaction MEV Protection Development';

  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        "No more unfair pricing for transactions from the miner side, now it's regulated and screened with the help of MEV Protection solutions developed to make fair trade and transactions in the blockchain space. Tap to know more.",
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'mev protection,mev protection services,mev protection development,mev protection solution,mev protection development solution',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'For Secured Fair Transaction MEV Protection Development',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/mev-protection-development-solution/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
      "No more unfair pricing for transactions from the miner side, now it's regulated and screened with the help of MEV Protection solutions developed to make fair trade and transactions in the blockchain space. Tap to know more.",
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/mev-protection.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/mev-protection-development-solution/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'For Secured Fair Transaction MEV Protection Development',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
      "No more unfair pricing for transactions from the miner side, now it's regulated and screened with the help of MEV Protection solutions developed to make fair trade and transactions in the blockchain space. Tap to know more.",
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/mev-protection.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/mev-protection-development-solution/'
    );
  }

  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };

}
