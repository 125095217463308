import {
  Component,
  OnInit,
} from '@angular/core';
import {
  Title,
  Meta
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-coinstore-exchange-clone-script',
  templateUrl: './coinstore-exchange-clone-script.component.html',
  styleUrls: ['./coinstore-exchange-clone-script.component.css']
})
export class CoinstoreExchangeCloneScriptComponent implements OnInit {
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService
  ) {}


  isDarkmode:boolean=false;
  title = 'Coinstore Exchange Clone Development To Empower';

  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Elevate your DEX game with a perfect Coinstore Exchange Clone development, customized for your needs. Tap to explore its features.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Coin exchange clone script, Coinstore exchange clone script, Coinstore exchange clone, Exchange platform development, Custom exchange development',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Coinstore Exchange Clone Development To Empower',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/coinstore-exchange-clone-script/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Elevate your DEX game with a perfect Coinstore Exchange Clone development, customized for your needs. Tap to explore its features.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/coinstore-clone.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/coinstore-exchange-clone-script/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Coinstore Exchange Clone Development To Empower',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Elevate your DEX game with a perfect Coinstore Exchange Clone development, customized for your needs. Tap to explore its features.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/coinstore-clone.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/coinstore-exchange-clone-script/'
    );
  }

  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };

}
