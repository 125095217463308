<section class="banners-section">

  <div class="green-vector">
    <!-- <img src="assets-new/images/home/green-gradient-1.png" alt="Gradient" /> -->
  </div>
  <div class="banner_wrapper banner-casestudy">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>Case Studies</h1>
              <p>
                Every project strikes to seek solutions that fit its needs. To
                make it easier, we have found a full range of end-to-end
                solutions that are provided to our clients. Here, you'll find
                case studies that help you reach the potential and the solutions
                we have come up with.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="scroll-section text-right">
              <a
                (click)="
                  about.scrollIntoView({ behavior: 'smooth', block: 'start' })
                ">
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/home/scroll.webp" />
                  <source type="image/png"
                    srcset="assets-new/images/home/scroll.png" />
                  <img loading="lazy"
                    data-src="assets-new/images/home/scroll.png" width="25"
                    height="48" class="img-fluid" alt="scroll-icon"
                    title="Scroll icon" />
                </picture>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Case-Study-Banner">
      <picture>
        <source
          type="image/webp"
          srcset="assets-new/images/case/webp/main-case-study-banner.webp" />

        <source
          type="image/png"
          srcset="assets-new/images/case/main-case-study-banner.png" />

        <img loading="lazy"
        data-src="assets-new/images/case/main-case-study-banner.png"
          class="img-fluid"
          alt="Ekta banner"
          title="Daicoin"
          width="640"
          height="515"/>
      </picture>
    </div>
  </div>
</section>
<!-- <section>
  <div class="main-case-about">
    <div class="violet-vector"></div>
    <div class="green-vector"></div>
    <div class="container">
      <div class="row">
        <div class="metaverse_heading">
          <h3>
            Let our vision move up with writing, which will show you the way
          </h3>
          <div class="Case-About-Picture">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/case/webp/case-about.webp"
              />

              <source
                type="image/png"
                srcset="assets-new/images/case/case-about.png"
              />

              <img
                src="assets-new/images/case/case-about.png"
                class="img-fluid"
                width="100%"
                height="100%"
                alt="About-Picture"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<div class="Case-Star-Card" #about>
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row">
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 starcard"
        data-delay="100">
        <h3 class="common-sub-heading-alt">
          Starcard sports : Building future interactions between Athletes and
          Gamers
        </h3>
        <p>
          StarCard Sports are building a robust, scalable digital economy in
          which players, investors, and gamers can profit. It is the first
          play-to-earn social impact gaming platform that connects real-life
          athletes and gamers of all levels with their digital avatars.
        </p>
        <div class="content-link">
          <a href="starcard-sports-games">
            <span>Read More</span>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/right-arrow-dark.webp">
              <source type="image/png" srcset="assets-new/images/right-arrow-dark.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
            </picture>
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/right-arrow.webp">
              <source type="image/png" srcset="assets-new/images/right-arrow.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
            </picture>
          </a>
          <hr />
        </div>
      </div>
    </div>
  </div>
  <div class="Starcard-Banner">
    <!-- <img
        src="assets-new/images/case/starcard.png"
        class="img-fluid"
        height="100%"
        alt="Starcard"
      /> -->
    <picture>
      <source
        type="image/webp"
        srcset="assets-new/images/case/webp/starcard.webp" />

      <source type="image/png" srcset="assets-new/images/case/starcard.png" />

      <img
        src="assets-new/images/case/starcard.png"
        class="img-fluid"
        width="740"
        height="585"
        alt="Starcard sports" />
    </picture>
  </div>
</div>
<div class="Case-Zuki">
  <div class="violet-vector"></div>
  <div class="container">
    <div class="row" data-delay="100">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12">
        <h3 class="common-sub-heading-alt">
          Zuki : Integrating the play-to-earn ecosystem into user-generated
          content
        </h3>
        <div class="content-link">
          <a href="zuki">
            <span>Read More</span>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/right-arrow-dark.webp">
              <source type="image/png" srcset="assets-new/images/right-arrow-dark.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
            </picture>
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/right-arrow.webp">
              <source type="image/png" srcset="assets-new/images/right-arrow.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
            </picture>
          </a>
          <hr />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12">
        <p>
          Zuki is a decentralized economy-based MOBA esports game and will get
          worldwide attention as an upcoming esports game. It is a
          blockchain-based gaming platform that has been expanding every year
          and combines cutting-edge technologies, including NFTs, blockchain,
          and the metaverse.
        </p>
      </div>
      <div class="row Case-Alt px-0">
        <div class="col-lg-7 Case-Zuki-Banner-alt">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/nft/webp/nft-left.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/nft/nft-left.png" />

            <img
              src="assets-new/images/nft/nft-left.png"
              class="img-fluid"
              width="732"
              height="442"
              alt="NFT left" />
          </picture>
        </div>
        <div class="col-lg-5"></div>
      </div>
      <div class="row px-0">
        <div class="col-lg-5"></div>
        <div class="col-lg-7 Case-Zuki-Banner">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/nft/webp/nft-right.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/nft/nft-right.png" />

            <img
              src="assets-new/images/nft/nft-right.png"
              class="img-fluid"
              width="732"
              height="480"
              alt="NFT right" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="Case-Minosis">
  <div class="violet-vector"></div>
  <div class="orange-vector"></div>
  <div class="Minosis-Banner">
    <picture>
      <source
        type="image/webp"
        srcset="assets-new/images/case/webp/minosis.webp" />

      <source type="image/png" srcset="assets-new/images/case/minosis.png" />

      <img
        src="assets-new/images/case/minosis.png"
        class="img-fluid"
        width="692"
        height="616"
        alt="Minosis" />
    </picture>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6"></div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12">
        <h3 class="common-sub-heading-alt">
          Minosis : Enhance the futuristic highly profitable crypto mining of
          the Minosis community
        </h3>
        <p class="py-2">
          Minosis is a publicly traded crypto mining startup that allows users
          to join mining pools and earn incentives. Minosis seeks to provide
          clients with lucrative chance to mine through its unique payment
          system and sophisticated hash power method, which allows users to
          mine more coins.
        </p>
        <div class="content-link">
          <a href="minosis">
            <span>Read More</span>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/right-arrow-dark.webp">
              <source type="image/png" srcset="assets-new/images/right-arrow-dark.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
            </picture>
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/right-arrow.webp">
              <source type="image/png" srcset="assets-new/images/right-arrow.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
            </picture>
          </a>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="Case-ekta case-ekta-model">
  <div class="Starcard-Banner">
    <picture>
      <source
        type="image/webp"
        srcset="assets-new/images/case/webp/main-case-study-banner.webp" />

      <source
        type="image/png"
        srcset="assets-new/images/case/main-case-study-banner.png" />

      <img
        src="assets-new/images/case/main-case-study-banner.png"
        class="img-fluid"
        width="608"
        height="489"
        alt="starcard" />
    </picture>
  </div>
  <div class="orange-vector"></div>
  <div class="green-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12">
        <h3 class="common-sub-heading-alt">
          EKTA : A complete ecosystem that bridges the physical world with the
          virtual world
        </h3>
        <p class="py-2">
          EKTA is a new decentralized protocol that aims to build a bridge
          between the physical and virtual worlds, and it has rolled out a new
          NFT marketplace with credibility and decentralization for NFT
          enthusiasts to engage and transact.
        </p>
        <div class="content-link">
          <a href="ektachain">
            <span>Read More</span>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/right-arrow-dark.webp">
              <source type="image/png" srcset="assets-new/images/right-arrow-dark.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
            </picture>
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/right-arrow.webp">
              <source type="image/png" srcset="assets-new/images/right-arrow.png">
              <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid" width="50" height="15" alt="Link-arrow" title="Link-arrow"/>
            </picture>
          </a>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-service-main">
  <app-blog></app-blog>
</section>
<!-- <app-contactform></app-contactform> -->
<app-get-in-touch></app-get-in-touch>
