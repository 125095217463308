import {
  Component,
  OnInit,
} from '@angular/core';
import {
  Title,
  Meta
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-gaming-governance-token',
  templateUrl: './gaming-governance-token.component.html',
  styleUrls: ['./gaming-governance-token.component.css']
})
export class GamingGovernanceTokenComponent implements OnInit {
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService
  ) {}


  isDarkmode:boolean=false;
  title = 'Top Gaming Governance Token Services - BlockchainX';

  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'A gaming governance token is key to democratizing web3 games for users. Take your p2e experience to new heights with our gaming governance token development services.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Gaming Governance Token, Gaming governance token, Gaming governance token development, Gaming governance token services, Gaming governance token platforms, Gaming governance token solutions',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Top Gaming Governance Token Services - BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/gaming-governance-token-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'A gaming governance token is key to democratizing web3 games for users. Take your p2e experience to new heights with our gaming governance token development services.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/gaming-governance-token-development.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/gaming-governance-token-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Top Gaming Governance Token Services - BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'A gaming governance token is key to democratizing web3 games for users. Take your p2e experience to new heights with our gaming governance token development services.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/og/gaming-governance-token-development.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/gaming-governance-token-development/'
    );
  }

  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };

}
