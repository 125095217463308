  import { HttpClient } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { Observable } from 'rxjs';
  
  @Injectable({
    providedIn: 'root'
  })
  export class CommonNewsletterService {
  
  private apiUrl = 'https://crmapi.sparkouttech.com/api/v1/leads/onboard-lead/6732f9e1103649bd1933b0e3/67358a823e1e13749fc04c7e';
    constructor(private http: HttpClient) { }
  
    submitContactForm(formData: any): Observable<any> {
      return this.http.post(this.apiUrl, formData);
    }
  }
  