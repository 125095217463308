import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FooterServiceService } from '../service/footer-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
 
  Theme='dark-theme';
  isDarkmode:boolean=false;
  constructor(@Inject(DOCUMENT) private document:Document, private renderer:Renderer2, private footservice:FooterServiceService){}
  ngOnInit(): void {
    this.intializetheme();
  }
  switchtheme(){
    this.isDarkmode= !this.isDarkmode;
    console.log(this.isDarkmode);
    this.footservice.toggleLogo(this.isDarkmode)
    this.document.body.classList.replace(this.Theme, this.Theme === 'dark-theme' ?(this.Theme='light-theme') :(this.Theme='dark-theme')  );
  }
  intializetheme=():void=>this.renderer.addClass(this.document.body,this.Theme);

  public isCollapsed = false;
  menuBtn(event:any){
   event.classList.toggle('bi-x');
 }

//  onClick(){
//   this.route.navigate(['/contact-us'])
//  }
//  onClick2(){
//   this.route.navigate(['/development-methodology'])
//  }

// Social Share
 @HostListener('document:click')
clickout() {
}
show:boolean=false
toggleView() {
  this.show = !this.show;
  console.log(this.show);
  
}

}
export type Theme = 'light-mode' | 'dark-mode';