  import { Component, OnInit,} from '@angular/core';
  import { Router } from '@angular/router';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { ToastrService } from 'ngx-toastr';
  import { TrackingService } from '../service/tracking.service';
  import { FooterServiceService } from '../service/footer-service.service';
import { CommonConsultationService } from '../service/common-consultation.service';

  @Component({
  selector: 'app-consultation-form1',
  templateUrl: './consultation-form1.component.html',
  styleUrls: ['./consultation-form1.component.css']
  })
  export class ConsultationForm1Component implements OnInit {
    contactmail1: FormGroup;
    submittedForm1 = false;
      isDarkmode: boolean = false;

      constructor(
        private formBuilder:FormBuilder,
        private CommonConsultationService:CommonConsultationService,
        private toastr:ToastrService,
        private trackingService: TrackingService,

        public router: Router,
        private footerservice: FooterServiceService  ) {
      }
      ngOnInit(): void {

        this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
          this.isDarkmode = response;
        });
        this.contactmail1 = this.createForm();
      }
      createForm(): FormGroup {
        return this.formBuilder.group({
          name: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email]],
          tell_us_about_your_project: ['', [Validators.required]]
        });
      }
      onSubmit(formNumber: number) {
        let form, submittedFlag;

        if (formNumber === 1) {
          form = this.contactmail1;
          submittedFlag = 'submittedForm1';
          this.submittedForm1 = true;
        }

        if (form && form.valid) {
          const formData = {
            ...form.value,
            source: localStorage.getItem('userReferrer') || 'direct',
            page_visited: localStorage.getItem('lastPageVisited') || window.location.href,
            pages_visited: JSON.parse(localStorage.getItem('pagesVisited') || '[]')
          };

          console.log(`Form Data (${formNumber}):`, formData);


          this.CommonConsultationService.submitContactForm(formData).subscribe(
            (response: any) => {
              this[submittedFlag] = false;
              if (response.success) {
                this.toastr.success('Mail Sent Successfully!', 'Success!');
                form.reset();
                this.trackingService.clearPageVisits();

                // Redirect to the "Thank You" page after 2 seconds
                setTimeout(() => {
                  this.router.navigate(['/thank-you']); // Replace '/thank-you' with your actual route
                }, 2000);
              }
            },
            (exception: any) => {
              this.toastr.error('Mail Not Sent. Please try again later.', 'Failure!');
            }
          );
        }
      }
    }

