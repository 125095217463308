import { Component, Inject, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from 'src/app/shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-liquid-staking-protocol-development',
  templateUrl: './liquid-staking-protocol-development.component.html',
  styleUrls: ['./liquid-staking-protocol-development.component.css']
})
export class LiquidStakingProtocolDevelopmentComponent implements OnInit {

  selectedLink:number;
  title = 'Liquid Staking Protocol Development';
about: any;
Connect: any;
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Overcoming the odds of traditional staking mechanisms, the Liquid Staking Protocol Development multifolds your liquidity pool in a secured and robust manner.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Liquid staking protocol development, Liquid staking protocol, Liquid Staking Protocol Development Company, Liquid staking protocol development services, Liquid staking protocol development platform, Liquid staking protocol development solution',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Liquid Staking Protocol Development',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/liquid-staking-protocol-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Overcoming the odds of traditional staking mechanisms, the Liquid Staking Protocol Development multifolds your liquidity pool in a secured and robust manner.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/liquid-staking-protocol-development/liquid-staking-protocol-development.webp',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/liquid-staking-protocol-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Liquid Staking Protocol Development',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Overcoming the odds of traditional staking mechanisms, the Liquid Staking Protocol Development multifolds your liquidity pool in a secured and robust manner.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/liquid-staking-protocol-development/liquid-staking-protocol-development.webp',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/liquid-staking-protocol-development/'
    );
  }

  fieldOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    slideBy: 1,
    dots: false,
    // autoHeight: true,
    margin: 20,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 2,
      },

      1024: {
        items: 3,
      },

      1366: {
        items: 3,
      },
    },
    nav: false,
  };

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    navSpeed: 500,
    // margin: 30,
    dots: false,
    center: true,
    navText: [],
    responsive: {
      0: {
        items: 1.5,
      },
      640: {
      items: 2,
      },
      740: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    nav: false,
  };
  addClass(id:number){
    this.selectedLink=id;
  }
}
