import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
import {
  Title,
  Meta,
  DomSanitizer,
  SafeScript,
} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from 'src/app/shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

declare var bootstrap: any;

@Component({
  selector: 'app-meme-coin-development',
  templateUrl: './meme-coin-development.component.html',
  styleUrls: ['./meme-coin-development.component.css']
})
export class MemeCoinDevelopmentComponent implements OnInit {

  selectedLink:number;
  bitrixSnippetScript: SafeScript;

  title = 'Meme Coin Development | Meme Coin Development Company';

  technologystack = ['Blockchains Platform','Other Platform'];
  techlisted: string = 'Blockchains Platform';
  selectedlisted: any;
  viewportScroller: any;
  menulist: any;
about: any;

  constructor(public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService:CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer) {
      const scriptUrl = `<script data-b24-form="inline/34/a10l11" data-skip-moving="true">${
        (function (w, d, u) {
          var s = d.createElement('script');
          s.async = true;
          s.src = u + '?' + (Date.now() / 180000 | 0);

          // Perform a null check before calling parentNode.insertBefore
          var h = d.getElementsByTagName('script')[0];
          if (h && h.parentNode) {
            h.parentNode.insertBefore(s, h);
          } else {
            // Handle the case where the parent node is not found (e.g., script is at the top of <body>)
            // You can append the script to the <body> element or take another appropriate action.
            document.body.appendChild(s);
          }
        })(window, document, 'https://cdn.bitrix24.in/b26760065/crm/form/loader_34.js')
      };</script>`;
      this.bitrixSnippetScript = this.sanitizer.bypassSecurityTrustHtml(`${scriptUrl}`);

     }

     clientSuccess: OwlOptions = {
      loop: false,
      margin: 34,
      autoplay: true,
      center: false,
      dots: true,
      autoplaySpeed: 4000,
      nav: false,
      items: 1
    };


  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });

    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Are you interested in creating a fun economy with Meme Coin development? If so, tap to read about our meme coin development services, and connect with us. ',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Meme Coin DevelopmentLSI, Meme Coin Development Company, Top Meme Coin Development Company, Meme Coin Development Services, Meme Coin Development Solutions, Meme Coin Developers, Create your Meme Coin, Create your own meme Coin, Launch your meme coin, Develop your own meme coin',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Meme Coin Development | Meme Coin Development Company',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/meme-coin-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Are you interested in creating a fun economy with Meme Coin development? If so, tap to read about our meme coin development services, and connect with us. ',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/meme-coin-development/meme-coin-development.webp',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/meme-coin-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Meme Coin Development | Meme Coin Development Company',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Are you interested in creating a fun economy with Meme Coin development? If so, tap to read about our meme coin development services, and connect with us. ',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/meme-coin-development/meme-coin-development.webp',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/meme-coin-development/'
    );

  }
  opentechnology(techmenu: any) {
    console.log(techmenu);
    this.techlisted = techmenu;
  }
  showTab = 1;
  tabToggle(showTab: any) {
    this.showTab = showTab;
  }

  show = true;
  scroll() {
    this.viewportScroller.scrollToAnchor('travel');

    console.log('test top');
  }
  addClass(id:number){
    this.selectedLink=id;
  }


}

