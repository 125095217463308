import {
  Component,
  OnInit
} from '@angular/core';
import {
  Title,
  Meta
} from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-solana-meme-coin-development',
  templateUrl: './solana-meme-coin-development.component.html',
  styleUrls: ['./solana-meme-coin-development.component.css']
})
export class SolanaMemeCoinDevelopmentComponent implements OnInit {


  selectedLink: number;

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService
  ) {
  }


  isDarkmode:boolean=false;
  title = 'Solana Meme Coin Development Company | BlockchainX';

  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Solana meme coin development helps you build a meme coin from scratch, unlocking high-speed, low-cost transactions for your meme coin project.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'solana meme coin development, solana meme coin development services, solana meme coin development company, solana meme coin development solution, launch your own solana meme coin, create your own solana meme coin, build your own solana meme coin, create solana meme coin, build solana meme coin',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Solana Meme Coin Development Company | BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/solana-meme-coin-development/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Solana meme coin development helps you build a meme coin from scratch, unlocking high-speed, low-cost transactions for your meme coin project.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://www.blockchainx.tech/assets-new/images/launch-your-solona/launch-your-solana.webp',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/solana-meme-coin-development/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Solana Meme Coin Development Company | BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Solana meme coin development helps you build a meme coin from scratch, unlocking high-speed, low-cost transactions for your meme coin project.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://www.blockchainx.tech/assets-new/images/launch-your-solona/launch-your-solana.webp',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/solana-meme-coin-development/'
    );
  }

  blogs_details: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    margin: 30,
    dots: true,
    navText: [],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      740: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
    nav: false,
  };
  addClass(id:number){
    this.selectedLink=id;
  }
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  }
}
