<!-- Banner Section -->
<section class="banners-section">
  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span>
                  <strong> Token Development </strong> Company</span>
              </h1>
              <p>
                Customize crypto Token development with experts at BlockchainX
                curating your distinct tokens with the ability to fundraise,
                govern, and do more.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Connect Now</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Schedule call</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/token-development/token-development.webp" />
              <source type="image/png"
                srcset="assets-new/images/token-development/token-development.png" />
              <img loading="lazy"
                data-src="assets-new/images/token-development/token-development.png"
                class="img-fluid" alt="Token Development Company"
                title="Token Development Company" width="500" height="500" />
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp"
                srcset="assets-new/images/token-development/light-theme/token-development.webp" />
              <source type="image/png"
                srcset="assets-new/images/token-development/light-theme/token-development.png" />
              <img loading="lazy"
                data-src="assets-new/images/token-development/light-theme/token-development.png"
                class="img-fluid" alt="Token Development Company"
                title="Token Development Company" width="500" height="500" />
            </picture>
          </div>
        </div>
      </div>

      <!-- <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div> -->
    </div>
  </div>
</section>
<!-- Banner Section ends -->
<section class="section-service-alt about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          Our<span>Clients</span>
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose Start -->
<section class="section-service-main" #about>
  <div class="container">
    <div class="row align-items-center">
      <div
        class="col-lg-5 mx-auto col-md-12 order-lg-first order-last">
        <div class="bep-work-section text-center pb-0">
          <picture *ngIf="!isDarkmode">
            <source
              type="image/webp"
              srcset="assets-new/images/token-development/crypto-token-development.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-development/crypto-token-development.png" />

            <img loading="lazy"
              src="assets-new/images/token-development/crypto-token-development.png"
              class="img-fluid"
              alt="Crypto Token Development"
              width="500"
              height="500" />
          </picture>
          <picture *ngIf="isDarkmode">
            <source
              type="image/webp"
              srcset="assets-new/images/token-development/light-theme/crypto-token-development.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-development/light-theme/crypto-token-development.png" />

            <img loading="lazy"
              src="assets-new/images/token-development/light-theme/crypto-token-development.png"
              class="img-fluid"
              alt="Crypto Token Development"
              width="500"
              height="500" />
          </picture>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 about-content">
        <div class="whys_section mb-0">
          <h2><span>Crypto Token</span> Development</h2>
          <p class="pb-3">
            Tokens are digital currency that holds value, and bring a sense of
            identity to your brand, in decentralized spaces. Token development
            is a process that blends liquidity and efficiency. There are
            different purposes for a crypto project, from fundraising, as a
            security token, to governing, rewarding, and more. Crypto token
            development navigates the project to a better scene with
            comprehensive functionalities.
          </p>
          <p class="pb-3">
            Every bit of token matters and alters the operation from blockchain
            choice to feature incorporation. Carving the best in every nuance of
            token development contributes to developing a mission-driven token
            that serves the projects whose minute choices build on erecting the
            Token efficiently to manage the requirements and upscale the project
            in the crypto market.
          </p>
          <p>
            BlockchainX as your Token development company, ensures support from
            scratch. Starting with ideation to launching and post that, our
            services are an assured package that is trustable and with our years
            of experience, we dedicate the best for you.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose End -->

<!-- Types Of DAOs starts -->
<div class="section-service-alt">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h2><span>Our Crypto Token </span> Development Services</h2>
        </div>
      </div>
      <div class="row types-of-token-detail justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                 <source type="image/webp" srcset="assets-new/images/token-development/crypto-token-development-consultation.webp" />
                 <img loading="lazy" data-src="assets-new/images/token-development/crypto-token-development-consultation.png"  class="img-fluid"
                 alt="Crypto Consulting" title="Crypto Consulting" width="75" height="75">
              </picture>

            </div>
            <div class="h-4 erc-20-head-alt text-left">
              Crypto Token Development consultation
            </div>
            <p>
              We provide you with consultation and advisory for your Crypto token development project. Understanding your requirements, needs, and growth parameters we suggest the right plan of action that meets your requirements and benefits your projects.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-development/crypto-token-creation.webp" />
                <img loading="lazy" data-src="assets-new/images/token-development/crypto-token-creation.png"  class="img-fluid"
                alt="Crypto Token Creation" title="Crypto Token Creation" width="75" height="75" >
              </picture>
            </div>
            <div class="h-4 erc-20-head-alt text-left">Crypto token Creation</div>
            <p>
              As a Crypto token development company, we are open to developing and launching your token on Ethereum, Binance, Tether, Solana, etc., in the network of your choice, with the built-in ability to perform designated tasks and operations.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-development/multichain-interoperability.webp" />
                <img loading="lazy" data-src="assets-new/images/token-development/multichain-interoperability.png"  class="img-fluid" alt="Crypto-wallet" title="Crypto-wallet" width="75" height="75" >
              </picture>

            </div>
            <div class="h-4 erc-20-head-alt text-left">Multichain Interoperability</div>
            <p>
              As a part of our Crypto token development service, we also provide you the option to enhance the operation of your token with multichain interoperability functions. We create tokens that are more compatible, flexible, and adaptable.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-development/token-liquidity-lock-assistance.webp" />
                <img loading="lazy" data-src="assets-new/images/token-development/token-liquidity-lock-assistance.png"  class="img-fluid" alt="Token Liquidity Lock Assistance" title="Token Liquidity Lock Assistance" width="75" height="75" >
              </picture>
            </div>
            <div class="h-4 erc-20-head-alt text-left">
              Token Liquidity Lock Assistance
            </div>
            <p>
              Liquidity being the primary concern we provide you with assistance in token liquidity lock. This is where the liquidity of a certain portion of the asset is locked and immovable. This helps from being scammed, liquidity pulls, token dumps, etc.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-development/nft-other-toke-creation.webp" />
                <img loading="lazy" data-src="assets-new/images/token-development/nft-other-toke-creation.png" class="img-fluid" alt="Altcoin Development-img" title="Altcoin Development"  width="75" height="75" >
              </picture>
            </div>
            <div class="h-4 erc-20-head-alt text-left">
              NFT and other Token creation
            </div>
            <p>
              We also convert assets into digital tokens. Which include Arts, video content, gaming features, attributes, etc. that can be tokenized and listed for sales as NFTs, which have added functionalities and bring in a good income for the same.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-development/token-smart-contract-optimization.webp" />
                <img loading="lazy" data-src="assets-new/images/token-development/token-smart-contract-optimization.png"  class="img-fluid" alt="Token smart Contract Optimization" title="Token smart Contract Optimization" width="75" height="75" >
              </picture>
            </div>
            <div class="h-4 erc-20-head-alt text-left">Token smart Contract Optimization</div>
            <p>
              We also extend Token smart contract optimization as a part of our Crypto token development services, where based on the operations and functionalities, we optimize the contracts to be gas efficient.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-development/token-audit-assurance.webp" />
                <img loading="lazy" data-src="assets-new/images/token-development/token-audit-assurance.png"  class="img-fluid" alt="Token Audit and Assurance" title="Token Audit and Assurance" width="75" height="75" >
              </picture>
            </div>
            <div class="h-4 erc-20-head-alt text-left">Token Audit and Assurance</div>
            <p>
              We also do Token audit and assurance services, generate reports on the smart contracts, provide insight on the performance, and help you better your contracts and Token performance through upgrades.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
          <div class="types-erc-detail">
            <div class="top-icon">
              <picture>
                <source type="image/webp" srcset="assets-new/images/token-development/token-migration-upgradation.webp" />
                <img loading="lazy" data-src="assets-new/images/token-development/token-migration-upgradation.png"  class="img-fluid" alt="Token Migration with Upgradation" title="Token Migration with Upgradation" width="75" height="75" >
              </picture>
            </div>
            <div class="h-4 erc-20-head-alt text-left">Token Migration with Upgradation</div>
            <p>
              For any version 1 or preexisting token in any network, we extend the service to migrate it as a new token with better features and upgrade it to perform better than before to enhance the performance.
            </p>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
<!-- Types Of DAOs ends -->
<section class="section-service-main">
  <div class="container">
    <div class="metaverse_heading">
      <h3>Our Exclusive <span>Types Of Crypto Token Development </span> On Different Blockchain
        Tokenization</h3>
    </div>
    <div class="row process-erc justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <div class="h-4">BEP20 Token Development </div>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/bep20-token-devlopment.webp" />
              <img loading="lazy"
                data-src="assets-new/images/token-development/bep20-token-devlopment.png"
                width="80" height="80" alt="Bep20 Token Devlopment" title="Bep20 Token Devlopment"
                class="img-fluid">
            </picture>
            <p>
              The exclusive <a href="https://www.blockchainx.tech/bep20-token-development/" target="_blank" class="internal-links">BEP20 Token development</a> is tailored to meet your Defi expectations on the Binance smart chain network. With robust infrastructure, we design your token to carry out operations effortlessly.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <div class="h-4">ERC20 Token Development</div>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/erc20-token-development.webp" />
              <img loading="lazy"
                data-src="assets-new/images/token-development/erc20-token-development.png"
                width="80" height="80" alt="ERC20Token Development"
                title="ERC20Token Development"
                class="img-fluid">
            </picture>
            <p>
              One of the most common token standards on the Ethereum network, <a href="https://www.blockchainx.tech/erc20-token-development/" target="_blank" class="internal-links">ERC20 token development</a> is a predominant token chosen to develop, meeting various requirements and that precisely is tailored to meet the needs. 
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <div class="h-4">TRC20 Token Development</div>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/trc20-token-development.webp" />
              <img loading="lazy"
                data-src="assets-new/images/token-development/trc20-token-development.png"
                width="80" height="80" alt="TRC20 Token Development" title="TRC20 Token Development"
                class="img-fluid">
            </picture>
            <p>
              Like ERC20 and BEP20, this <a href="https://www.blockchainx.tech/tron-token-development/" target="_blank" class="internal-links">TRC20 token development</a> is the particular token built on the Tron network with identical individuality in the network and is accepted to be equivalent to USDT.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <div class="h-4">SOL Token Development</div>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/sol-token-development.webp" />
              <img loading="lazy"
                data-src="assets-new/images/token-development/sol-token-development.png"
                width="80" height="80" alt="SOL Token Development" title="SOL Token Development"
                class="img-fluid">
            </picture>
            <p>
              SOL token development on Solana ensures efficient transactions, and seamless interoperability and is customized to cater to your needs with the benefits of the network, which we do as a part of our Token development services. 
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <div class="h-4">AVAX Token Development</div>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/avax-token-development.webp" />
              <img loading="lazy"
                data-src="assets-new/images/token-development/avax-token-development.png"
                width="80" height="80" alt="AVAX Token Development" title="AVAX Token Development"
                class="img-fluid">
            </picture>
            <p>
              AVAX token development is created on Avalanche which is well-known for its throughput and low latency in transactions. These tokens are often used to make payments, fees for staking, transactions, participation, network governance, etc.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="erc-card text-center">
          <div class="erc-card-header">
            <div class="h-4">Polygon Token Development</div>
          </div>
          <div class="erc-card-content">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/polygon-token-development.webp" />
              <img loading="lazy"
                data-src="assets-new/images/token-development/polygon-token-development.png"
                width="80" height="80" alt="Polygon Token Development" title="Polygon Token Development"
                class="img-fluid">
            </picture>
            <p>
              We also extend our services to Polygon token development, layer 2 Etheruem network. With its ability to process transactions quickly at a low cost, they help you advance with their scalability.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center col-lg-12">
      <a class="our_expert_talk" href="https://www.blockchainx.tech/token-development-company/#item-1" (click)="addClass(1)">Schedule a Meeting</a>
    </div>
  </div>
</section>

<!-- Functionality Of Crypto Token starts -->
<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h2><span> Functionality Of Crypto Token </span></h2>
        </div>
      </div>
    </div>
    <div class="row erc-about-content">
      <div class="col-sm-6 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-development/asset-token.webp" />

            <img loading="lazy"
              src="assets-new/images/token-development/asset-token.png"
              class="img-fluid"
              alt="Asset Token"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head pb-2">Asset Token</div>
          <p class="text-center">
            Tokenizing assets in the blockchain networks for easy trade.
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-development/equity-token.webp" />

            <img loading="lazy"
              src="assets-new/images/token-development/equity-token.png"
              class="img-fluid"
              alt="Equity Token"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head pb-2">Equity Token</div>
          <p class="text-center">
            Making investments easy, equity tokens represent assets.
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-development/security-token.webp" />

            <img loading="lazy"
              src="assets-new/images/token-development/security-token.png"
              class="img-fluid"
              alt="Security Token"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head pb-2">Security Token</div>
          <p class="text-center">
            Legally regulated cryptocurrency for business enhances finance.
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-development/utility-token.webp" />

            <img loading="lazy"
              src="assets-new/images/token-development/utility-token.png"
              class="img-fluid"
              alt="Utility Token"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head pb-2">Utility Token</div>
          <p class="text-center">
            With extensive functionality, utility tokens enhance operations
            seamlessly.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Ends -->

<!-- Related Services -->
<section class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading text-center">
          <h2 class="mb-3"><span>Different Blockchains </span>We Work For Token
            Development</h2>
          <p>
            Based on your choice and project requirements the choice of
            Blockchain also varies Token development process and we are open to
            customize it for you.
          </p>
        </div>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <img src="assets-new/images/token-development/ethereum.svg"
              loading="lazy"
              width="75" height="75" alt="Ethereum" title="Ethereum"
              class="img-fluid">
          </div>
          <div class="h-4 erc-20-head-alt text-left">Ethereum</div>
          <p>
            Basic gathering of information, jotting requirements, and analyzing
            the time and professionals involved are made clear from both ends.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <img loading="lazy"
              src="assets-new/images/token-development/binance.svg" width="75"
              height="75" alt="Binance" title="Binance" class="img-fluid">
          </div>
          <div class="h-4 erc-20-head-alt text-left">
            Binance
          </div>
          <p>
            Rendering a test phase to check for requirements, a review process
            to analyze if the requirements are met and additionalities are
            addressed and planned.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <img loading="lazy"
              src="assets-new/images/token-development/cardano.svg" width="75"
              height="75" alt="Cardano" title="Cardano" class="img-fluid">
          </div>
          <div class="h-4 erc-20-head-alt text-left">Cardano</div>
          <p>
            Tech-savvy professionals at BlockchainX involve themself in the STO
            development process. Coding and iterating smart contracts are done
            here.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <img loading="lazy"
              src="assets-new/images/token-development/solana.svg" width="75"
              height="75" alt="Solana" title="Solana" class="img-fluid">
          </div>
          <div class="h-4 erc-20-head-alt text-left">Solana</div>
          <p>
            Sending the codes and structure for testing, and ensuring the
            contracts are all validated. The QA team goes through and generates
            a report for better usage.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <img loading="lazy"
              src="assets-new/images/token-development/avalanche.svg" width="75"
              height="75" alt="Avalanche" title="Avalanche" class="img-fluid">
          </div>
          <div class="h-4 erc-20-head-alt text-left">Avalanche</div>
          <p>
            Once the STO development is done and after the final approval, your
            STO token is all set to launch, and we also help with its
            maintenance.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <img loading="lazy"
              src="assets-new/images/token-development/fantom.svg" width="75"
              height="75" alt="Fantom" title="Fantom" class="img-fluid">
          </div>
          <div class="h-4 erc-20-head-alt text-left">
            Fantom
          </div>
          <p>
            In the future, we also render added support in terms of updating and
            grading up the STO that we developed for you.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <img loading="lazy"
              src="assets-new/images/token-development/tron.svg" width="75"
              height="75" alt="Tron" title="Tron" class="img-fluid">
          </div>
          <div class="h-4 erc-20-head-alt text-left">
            Tron
          </div>
          <p>
            In the future, we also render added support in terms of updating and
            grading up the STO that we developed for you.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- Token Standards Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h2>
          <span>Token Standards </span> Used in Crypto Token Development
        </h2>
      </div>
    </div>
    <div
      class="row types-of-token-detail justify-content-center real-world-check">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="types-erc-detail">
          <div class="pb-3">
            <img loading="lazy"
              src="assets-new/images/token-development/eth.svg"
              class="img-fluid"
              alt="ETH"
              width="75"
              height="75" />
          </div>
          <div class="h-4 erc-20-head-alt text-left">ETH</div>
          <div class="token-unlist">
            <ul>
              <li><span><i class="bi bi-check-circle-fill"></i></span>
                ERC-20 </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span>
                ERC-223 </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span> ERC
                720/721 </li>
            </ul>
            <ul>
              <li><span><i class="bi bi-check-circle-fill"></i></span>
                ERC-777
              </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span>
                ERC-998 </li>
              <li><span><i class="bi bi-check-circle-fill"></i></span> ERC 1155
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="types-erc-detail">
          <div class="pb-3">
            <img loading="lazy"
              src="assets-new/images/token-development/bsc.svg"
              class="img-fluid"
              alt="BSC"
              width="75"
              height="75" />
          </div>
          <div class="h-4 erc-20-head-alt text-left">BSC</div>
          <ul>
            <li><span><i class="bi bi-check-circle-fill"></i></span> BEP-20
            </li>
            <li><span><i class="bi bi-check-circle-fill"></i></span> BEP-721
            </li>
            <li><span><i class="bi bi-check-circle-fill"></i></span> BEP-1155
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="types-erc-detail">
          <div class="pb-3">
            <img loading="lazy"
              src="assets-new/images/token-development/tron1.svg"
              class="img-fluid"
              alt="TRON"
              width="75"
              height="75" />
          </div>
          <div class="h-4 erc-20-head-alt text-left">TRON</div>
          <ul>
            <li><span><i class="bi bi-check-circle-fill"></i></span> TRC-10
            </li>
            <li><span><i class="bi bi-check-circle-fill"></i></span> TRC-20
            </li>
            <li><span><i class="bi bi-check-circle-fill"></i></span> TRC 721
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12">
        <div class="types-erc-detail">
          <div class="pb-3">
            <img loading="lazy"
              src="assets-new/images/token-development/brc-721e.png"
              class="img-fluid"
              alt="brc_721e"
              width="75"
              height="75" />
          </div>
          <div class="h-4 erc-20-head-alt text-left">Other Popular Token</div>
          <ul>
            <li><span><i class="bi bi-check-circle-fill"></i></span> BRC-20
            </li>
            <li><span><i class="bi bi-check-circle-fill"></i></span> BRC-721E
            </li>
            <li><span><i class="bi bi-check-circle-fill"></i></span> SPL
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-6 col-sm-12 col-12">
            <div class="types-erc-detail">
              <div class="pb-3">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/real-world-asset-tokenization/intangible-assets.webp" />
  
                  <source
                    type="image/png"
                    srcset="assets-new/images/real-world-asset-tokenization/intangible-assets.png" />
  
                  <img loading="lazy"
                    src="assets-new/images/real-world-asset-tokenization/intangible-assets.png"
                    class="img-fluid"
                    alt="intangible-assets"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Intangible Assets</h4>
              <ul>
                <li><span><i class="bi bi-check-circle-fill"></i></span> Patents
                </li>
                <li><span><i class="bi bi-check-circle-fill"></i></span> Licenses
                </li>
                <li><span><i class="bi bi-check-circle-fill"></i></span>
                  Trademarks </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12">
            <div class="types-erc-detail">
              <div class="pb-3">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/real-world-asset-tokenization/consumables.webp" />
  
                  <img loading="lazy"
                    src="assets-new/images/real-world-asset-tokenization/consumables.png"
                    class="img-fluid"
                    alt="consumables"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Consumables</h4>
              <ul>
                <li><span><i class="bi bi-check-circle-fill"></i></span> Coffee
                </li>
                <li><span><i class="bi bi-check-circle-fill"></i></span> Food and
                  beverages </li>
                <li><span><i class="bi bi-check-circle-fill"></i></span>
                  Pharmaceuticals </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12">
            <div class="types-erc-detail">
              <div class="pb-3">
  
                  <source
                    type="image/png"
                    srcset="assets-new/images/real-world-asset-tokenization/agriculture.png" />
  
                  <img loading="lazy"
                    src="assets-new/images/real-world-asset-tokenization/agriculture.png"
                    class="img-fluid"
                    alt="agriculture"
                    width="75"
                    height="75"
                     />
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Agriculture</h4>
              <ul>
                <li><span><i class="bi bi-check-circle-fill"></i></span> Crop and Production
                </li>
                <li><span><i class="bi bi-check-circle-fill"></i></span> Land
                </li>
                <li><span><i class="bi bi-check-circle-fill"></i></span> Equipment
                </li>
              </ul>
            </div>
          </div> -->
    </div>
  </div>
</section>
<!-- End -->

<!-- Token Development Process Start -->
<section class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="casetudies_common">
          <div class="commontext_subhead">
            <h2>Our Token Development Process<span class="dots">.</span></h2>
          </div>
          <div class="casestudies_para">
            <p>
              We provide single chain and multi chain integrated Token
              development service through a streamlined process.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row our-approach">
          <div class="col-sm-6 col-12">
            <div class="erc-about-picture">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-development/ideation.webp" />

                <img loading="lazy"
                  src="assets-new/images/token-development/ideation.png"
                  class="img-fluid"
                  alt="Ideation"
                  width="75"
                  height="75" />
              </picture>
              <div class="erc-20-head pb-2">Ideation</div>
              <p>
                Gather the requirements, from raw data we add on to build a
                bigger value on the token.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="erc-about-picture">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-development/discuss-the-nuances.webp" />

                <img loading="lazy"
                  src="assets-new/images/token-development/discuss-the-nuances.png"
                  class="img-fluid"
                  alt="Discuss the nuances"
                  width="75"
                  height="75" />
              </picture>
              <div class="erc-20-head pb-2">Discuss the nuances</div>
              <p>
                Based on the token development requirement for the projects we
                plan the needful.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="erc-about-picture">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-development/choose-blockchain.webp" />

                <img loading="lazy"
                  src="assets-new/images/token-development/choose-blockchain.png"
                  class="img-fluid"
                  alt="Choose Blockchain"
                  width="75"
                  height="75" />
              </picture>
              <div class="erc-20-head pb-2">Choose Blockchain </div>
              <p>
                Choose the blockchain/blockchain networks based on their
                functionality, benefits and other support it extends.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="erc-about-picture">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-development/opt-the-features.webp" />

                <img loading="lazy"
                  src="assets-new/images/token-development/opt-the-features.png"
                  class="img-fluid"
                  alt="Opt the features"
                  width="75"
                  height="75" />
              </picture>
              <div class="erc-20-head pb-2">Opt the features</div>
              <p>
                Curate the features of the crypto token based on the primary
                requirement of the project.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="erc-about-picture">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-development/token-smart-contracts.webp" />

                <img loading="lazy"
                  src="assets-new/images/token-development/token-smart-contracts.png"
                  class="img-fluid"
                  alt="Token smart contracts"
                  width="75"
                  height="75" />
              </picture>
              <div class="erc-20-head pb-2">Token smart contracts</div>
              <p>
                Code token smart contracts on the blockchain and ensure the
                codes are audited and error-free.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="erc-about-picture">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-development/integrations.webp" />

                <img loading="lazy"
                  src="assets-new/images/token-development/integrations.png"
                  class="img-fluid"
                  alt="Integrations"
                  width="75"
                  height="75" /> To Empower Your Business Finances 
              </picture>
              <div class="erc-20-head pb-2">Integrations</div>
              <p>
                Integrate the functions and smart contract on the network, test
                its performances.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="erc-about-picture">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-development/initial-coin-offering.webp" />

                <img loading="lazy"
                  src="assets-new/images/token-development/initial-coin-offering.png"
                  class="img-fluid"
                  alt="Initial coin offering"
                  width="75"
                  height="75" />
              </picture>
              <div class="erc-20-head pb-2">Initial coin offering</div>
              <p>
                Once everything is tested the Token developed is ready for the
                ICO launch.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-12">
            <div class="erc-about-picture">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-development/expanding-functions.webp" />

                <img loading="lazy"
                  src="assets-new/images/token-development/expanding-functions.png"
                  class="img-fluid"
                  alt="Expanding functions"
                  width="75"
                  height="75" />
              </picture>
              <div class="erc-20-head pb-2">Expanding functions</div>
              <p>
                In need of any additional features or functioning, the token is
                modified and upgraded.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading text-center">
          <h2 class="mb-3"><span>Features In </span>Crypto Token
            Development</h2>
          <p>
            We curated crypto tokens with the utmost efficient value added
            functions that empower and improve business operations in the
            blockchain network.
          </p>
        </div>
      </div>
    </div>
    <div class="row types-of-token-detail justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/token-minting.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-development/token-minting.png"
                width="75" height="75" alt="Token Minting" title="Token Minting"
                class="img-fluid">
            </picture>
          </div>
          <div class="h-4 erc-20-head-alt text-left">Token Minting</div>
          <p>
            Through consensus, and state of art mechanisms, developers are free
            to mine crypto tokens in desired blockchain.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/token-listing.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-development/token-listing.png"
                width="75" height="75" alt="Token Listing" title="Token Listing"
                class="img-fluid">
            </picture>
          </div>
          <div class="h-4 erc-20-head-alt text-left">
            Token Listing
          </div>
          <p>
            By listing on different exchanges after the token creation process
            the token developed gets widened attention and traction.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/token-burned.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-development/token-burned.png"
                width="75" height="75" alt="Token Burned" title="Token Burned"
                class="img-fluid">
            </picture>
          </div>
          <div class="h-4 erc-20-head-alt text-left">Token Burned</div>
          <p>
            Token burning operation removes the excess tokens from the market
            creating a demand that drives value based on optimal supply.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/token-paused.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-development/token-paused.png"
                width="75" height="75" alt="Token Paused" title="Token Paused"
                class="img-fluid">
            </picture>
          </div>
          <div class="h-4 erc-20-head-alt text-left">Token Paused</div>
          <p>
            This pausing technique halts the token minting process and ensures
            to preserve the during any malfunctioning of the token.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/capped-supply.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-development/capped-supply.png"
                width="75" height="75" alt="Capped Supply" title="Capped Supply"
                class="img-fluid">
            </picture>
          </div>
          <div class="h-4 erc-20-head-alt text-left">Capped Supply</div>
          <p>
            Based on need and requirements, the token can be capped to limit the
            supply, and so no tokens are minted par the capped value.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-development/wallet-compatibility.webp">
              <img loading="lazy"
                data-src="assets-new/images/token-development/wallet-compatibility.png"
                width="75" height="75" alt="Wallet Compatibility"
                title="Wallet Compatibility" class="img-fluid">
            </picture>
          </div>
          <div class="h-4 erc-20-head-alt text-left">
            Wallet Compatibility
          </div>
          <p>
            Integrating with popular ERC wallet the token is compatible, making
            it seamless to store and transact when in need.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Related Services -->

<section class="section-service-main">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <h2 class="main-sub-heading text-center pb-0">Types of Crypto <span>Token Development</span></h2>
        <p class="text-center my-4">We curate a variety of Crypto Tokens based on the Project and operation recruitments, which are 100% customizable, ensuring efficiency in their performance.</p>
      </div>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-5 types-of-token-detail justify-content-center industry-web row-gap">
      <div class="col">
        <div class="detail text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-development/stable-coin.svg" class="img-fluid" alt="Stable Coin" width="70" height="70">
          </div>
          <p class="erc-20-head-alt fw-semibold">Stable Coin</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-development/governance-token.svg" class="img-fluid" alt="Governance Token" width="60" height="60" >
          </div>
          <p class="erc-20-head-alt fw-semibold">Governance Token</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-development/utility-token.svg" class="img-fluid" alt="Utility token" width="70" height="70" >
          </div>
          <p class="erc-20-head-alt fw-semibold">Utility token</p>
        </div>
      </div>
      <div class="col">
        <div class="detail text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-development/nft.svg" class="img-fluid" alt="NFT" width="70" height="70">
          </div> 
          <p class="erc-20-head-alt fw-semibold">NFT</p>             
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-development/rwa-tokens.svg" class="RWA tokens" alt="Rust" width="70" height="70">
          </div>
          <p class="erc-20-head-alt fw-semibold">RWA tokens</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-development/reward-coins.svg" class="img-fluid" alt="Reward Coins" width="70" height="70">
          </div>
          <p class="erc-20-head-alt fw-semibold">Reward Coins</p>
        </div>
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-development/native-tokens.svg" class="img-fluid" alt="Native tokens" width="70" height="70">
          </div>
          <p class="erc-20-head-alt fw-semibold">Native tokens</p>
        </div> 
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-development/security-token.svg" class="img-fluid" alt="Security token" width="60" height="60" >
          </div>
          <p class="erc-20-head-alt fw-semibold">Security token</p>
        </div> 
      </div>
      <div class="col">
        <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
          <div>
            <img loading="lazy" src="assets-new/images/token-development/equity-token.svg" class="img-fluid" alt="Equity token" width="70" height="70" >
          </div>
          <p class="erc-20-head-alt fw-semibold">Equity token</p>
        </div>
      </div>
        <div class="col">
          <div class="detail  text-center d-flex justify-content-center gap-3 p-4 rounded-3 flex-column h-100">
            <div>
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/token-development/dia.webp" />
              <img loading="lazy" src="assets-new/images/token-development/dia.png" class="img-fluid" alt="DIA" width="70" height="70">
              </picture>
            </div>
            <p class="erc-20-head-alt fw-semibold">DIA</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Purpose Of Crypto Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="metaverse_heading text-center">
          <h2>Purpose Of Crypto <span>Token Development</span></h2>
          <p class="my-4">
            With our trained, equipped, and experienced developers in token
            development, we ensure to impart the true purpose of developing
            crypto tokens with our customized support to maximize the potential
            of your venture.
          </p>
        </div>
      </div>
    </div>
    <div
      class="row mt-lg-4 mt-md-2 mt-0 justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 ">
        <ul class="investment-para">
          <li>
            We create adhesive brand value
          </li>
          <li>
            Meet marketing requirements with airdrops
          </li>
          <li>
            Token rewards systems initiating sign-ups
          </li>
          <li>
            Expansions based on user base
          </li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-6 ">
        <ul class="investment-para">
          <li>
            Assured token liquidity
          </li>
          <li>
            Improved market efficiency.
          </li>
          <li>Intermediaries are totally excluded</li>
          <li>Global bandwidth of investors</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Additional Functionalities Start -->
<section class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="metaverse_heading mx-auto">
          <h2>Additional <span>Functionalities Earned</span> With Token
            Development</h2>
        </div>
      </div>
    </div>
    <div class="row benefits-of-token">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-development/token-sale-dapp.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-development/token-sale-dapp.png" />

            <img loading="lazy"
              src="assets-new/images/token-development/token-sale-dapp.png"
              class="img-fluid"
              alt="Token Sale Dapp"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head pb-2">Token Sale Dapp</div>
          <p class="text-center">
            Token sales offer exclusive rewards and bonuses for early investors,
            through a transparent, user-friendly interface, which empowers
            creating a strong community for the. Token sale decentralized apps
            attract the traction of investors and increase the value of tokens
            in the sale.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-development/token-staking-dapp.webp" />

            <img loading="lazy"
              src="assets-new/images/token-development/token-staking-dapp.png"
              class="img-fluid"
              alt="Token Staking Dapp"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head pb-2">Token Staking Dapp</div>
          <p class="text-center">
            The token sale is the first step, while involvement in Token staking
            Dapp development allows users to earn rewards through the process of
            staking tokens and participating actively in the network operations.
            This is an incentivizing strategy that holds on for a longer term.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-development/farming-dapp.webp" />

            <img loading="lazy"
              src="assets-new/images/token-development/farming-dapp.png"
              class="img-fluid"
              alt="Farming Dapp"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head pb-2">Farming Dapp</div>
          <p class="text-center">
            Farming Dapps is nothing but Decentralized exchanges that bring more
            value for the tokens when it's traded with other cryptocurrencies.
            With decentralized exchanges, your token can be better directed for
            sales, exchange, and governance in the market, therefore providing
            better utility for the same.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
        <div class="erc-about-picture">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-development/token-launchpad.webp" />

            <img loading="lazy"
              src="assets-new/images/token-development/token-launchpad.png"
              class="img-fluid"
              alt="Token Launchpad"
              width="75"
              height="75" />
          </picture>
          <div class="erc-20-head pb-2">Token Launchpad</div>
          <p class="text-center">
            Token launchpads are where new tokens/ crypto projects are listed to
            gain traction in the market and eventually improve their utility,
            functioning, permanence, and more through the exposure earned in the
            listing phase on a token launchpad.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Benefits Of Tokenizing Real Estate End -->

<!-- Contact Us Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="contact-overall d-flex flex-column gap-lg-5 gap-4 rounded-3">
      <div class="commontext_wrap">
        <p class="text-start h-2">Get to know more intricate nuances of our token
          development services from our COO.</p>
      </div>

      <div class="author-detail d-flex justify-content-md-between justify-content-center align-items-center flex-md-nowrap flex-wrap gap-md-0 gap-4">
        <div class="author-imgs d-flex align-items-center justify-content-center gap-lg-5 gap-4 flex-wrap">
          <div>
            <img
              loading="lazy"
              src="assets-new/images/blog/yokesh-shankar.svg"
              alt="blog author"
              width="150"
              height="150" class="img-fluid rounded-circle"/>
          </div>
          <div class="para-text">
            <p class="text-sm-start text-center"><span class="d-block fw-semibold">Yokesh Sankar</span> <span class="d-block fw-semibold">Co - Founder and COO</span> </p>
            <a href="mailto:contact@blockchainx.tech" target="_blank"
              rel="noopener noreferrer">contact&#64;blockchainx.tech</a>
          </div>
        </div>
        <div class="discuss-link pt-0">
          <a [routerLink]="['/contact']">
            <span>Contact Us</span>
            <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- <div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading">
        <h3 class="text-center">
          Crypto token<span> standard  </span>
        </h3>
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/token-development/erc_6551.webp">
          <img loading="lazy" data-src="assets-new/images/token-development/erc_6551.png"
            width="100" height="100" alt="ERC-6551" title="ERC-6551"
            class="img-fluid">
        </picture>
        <h4 class="process-head py-3">ERC-6551</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/token-development/erc_7683.webp">
          <img loading="lazy"
            data-src="assets-new/images/token-development/erc_7683.png" width="100"
            height="100" alt="ERC-7683" title="ERC-7683"
            class="img-fluid">
        </picture>
        <h4 class="process-head py-3">ERC-7683</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/token-development/erc_721c.webp">
          <img loading="lazy"
            data-src="assets-new/images/token-development/erc_721c.png" width="100"
            height="100" alt="ERC-721C" title="ERC-721C"
            class="img-fluid">
        </picture>
        <h4 class="process-head py-3">ERC-721C</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/token-development/spl.webp">
          <img loading="lazy"
            data-src="assets-new/images/token-development/spl.png" width="100"
            height="100" alt="SPL" title="SPL"
            class="img-fluid">
        </picture>
        <h4 class="process-head py-3">SPL</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/token-development/bep_2.webp">
          <img loading="lazy"
            data-src="assets-new/images/token-development/bep_2.png" width="100"
            height="100" alt="BEP-2" title="BEP-2"
            class="img-fluid">
        </picture>
        <h4 class="process-head py-3">BEP-2</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/token-development/brc_721e.webp">
          <img loading="lazy"
            data-src="assets-new/images/token-development/brc_721e.png"
            width="100" height="100" alt="BRC-721E"
            title="BRC-721E" class="img-fluid">
        </picture>
        <h4 class="process-head py-3">BRC-721E</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/token-development/brc_20.webp">
          <img loading="lazy"
            data-src="assets-new/images/token-development/brc_20.png"
            width="100" height="100" alt="BRC-20"
            title="BRC-20" class="img-fluid">
        </picture>
        <h4 class="process-head py-3">BRC-20</h4>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center process-image">
        <picture>
          <source type="image/webp"
            srcset="assets-new/images/token-development/src_20.webp">
          <img loading="lazy"
            data-src="assets-new/images/token-development/src_20.png"
            width="100" height="100" alt="SRC-20"
            title="SRC-20" class="img-fluid">
        </picture>
        <h4 class="process-head py-3">SRC-20</h4>
      </div>
    </div>
  </div>
</div> -->


<section class="section-service-main">
  <div class="container">
    <div class="row mb-lg-5 mb-4">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h2 class="text-center pb-0 mb-3 h-2">Benefits of Crypto <span>Token Development</span></h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 d-lg-block d-none">
        <div class="casetudies_common">
          <picture>
            <source type="image/webp" srcset="assets-new/images/token-development/benefits-crypto-token-development.webp" />
            <img loading="lazy" data-src="assets-new/images/token-development/benefits-crypto-token-development.png" width="500" height="500" class="img-fluid pb-md-0 pb-3" alt="Benefits of Crypto Token Development" title="Benefits of Crypto Token Development" *ngIf="!isDarkmode" />
          </picture>
          <picture>
            <source type="image/webp" srcset="assets-new/images/token-development/benefits-crypto-token-development-light.webp" />
            <img loading="lazy" data-src="assets-new/images/token-development/benefits-crypto-token-development-light.png" width="500" height="500" class="img-fluid pb-md-0 pb-3" alt="Benefits of Crypto Token Development" title="Benefits of Crypto Token Development" *ngIf="isDarkmode" />
          </picture>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3">Market without Friction</div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  To represent attests on the blockchain, tokenization is a basic step that can be easily traded and exchanged for another asset without the need for intermediaries. Creating a frictionless market.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3">Deep integration </div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  The advantage of humanity with web ushered is the ability to integrate with different services bringing meaningful experience. Through decentralized tokenization, the standards and channels are stitched together by blockchain. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3" >Disrupt</div>
                </div>
              </div>
            </div>
            <div class="casestudy_para">
              <p>
                Through blockchain, there is a new business model eliminating middlemen, ensuring royalty payments, double security, and more, as it eventually brews in different sectors and industries bridging new possibilities. 
              </p>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-lg-12">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <div class="text-decoration-none h-3" >Pseudo confidentiality</div>
                </div>
              </div>
              <div class="casestudy_para">
                <p>
                  Through independent wallets, crypto token development allows the token to store transaction information and data that ensures to hold privacy and security standards using the blockchain while being traceable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div>
</section>

<!-- Industries Benefited Start -->
<section class="section-service-alt">
  <div class="types-of-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h2>
              <span>Industries Benefited </span> From Token Development
            </h2>
          </div>
        </div>
      </div>
      <div
        class="row types-of-token-detail justify-content-center real-estate-token">
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/medicine.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/token-development/medicine.png" />

              <img loading="lazy"
                src="assets-new/images/token-development/medicine.png"
                class="img-fluid"
                alt="Medicine"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Medicine</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/agriculture.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/agriculture.png"
                class="img-fluid"
                alt="Agriculture"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Agriculture</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/finance.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/finance.png"
                class="img-fluid"
                alt="Finance"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Finance</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/insurance.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/insurance.png"
                class="img-fluid"
                alt="Insurance"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Insurance</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/real-estate.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/real-estate.png"
                class="img-fluid"
                alt="Real estate"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Real estate</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/logistics.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/logistics.png"
                class="img-fluid"
                alt="Logistics"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Logistics</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/supply-chain.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/supply-chain.png"
                class="img-fluid"
                alt="Supply Chain"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Supply Chain</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/gaming.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/gaming.png"
                class="img-fluid"
                alt="Gaming"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Gaming</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/e-commerce.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/e-commerce.png"
                class="img-fluid"
                alt="E-commerce"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">E-commerce</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/art-and-design.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/art-and-design.png"
                class="img-fluid"
                alt="Art and design"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Art and design</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/fintech.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/fintech.png"
                class="img-fluid"
                alt="Fintech"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Fintech</div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="types-erc-detail text-center">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/token-development/dapps.webp" />

              <img loading="lazy"
                src="assets-new/images/token-development/dapps.png"
                class="img-fluid"
                alt="Dapps"
                width="60"
                height="60" />
            </picture>
            <div class="h-4 erc-20-head-alt pb-0">Dapps</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="whys_section mb-0">
          <h2 class="text-center h-2">
            Why choose BlockchainX for<span> Token Development? </span>
          </h2>
          <p class="pt-3 text-center">
            BlockchainX has been a prominent participant and active player in the sector for the last seven years, curating avant-garde solutions. Our expertise lies in curating tailored decentralized solutions for projects on Web3. This also applies to our Crypto token development services; we provide end-to-end token solutions. From tokenomic consultation to crypto token launch and upgradation, we are readily available to create the best solution efficiently.
          </p>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <div class="row token d-flex">
            <p>We provide</p>
            <div class="projects">
              <div class="row">
                <ul class="d-flex row-gap-3 flex-column flex-md-row">
                  <li class="d-flex w-50"><span><i class="bi bi-check-circle-fill"></i></span>Token Consultation & Advisory</li>
                  <li class="d-flex w-50"><span><i class="bi bi-check-circle-fill"></i></span>Token Upgradation Services</li>
                </ul>
                <ul class="d-flex row-gap-3 flex-column flex-md-row">
                  <li class="d-flex w-50"><span><i class="bi bi-check-circle-fill"></i></span>Token Migration Services</li>
                  <li class="d-flex w-50"><span><i class="bi bi-check-circle-fill"></i></span>Customized Project solutions</li>
                </ul>
                <ul class="d-flex row-gap-3 flex-column flex-md-row">
                  <li class="d-flex w-50"><span><i class="bi bi-check-circle-fill"></i></span>Uniquely Craft Requirements</li>
                  <li class="d-flex w-50"><span><i class="bi bi-check-circle-fill"></i></span>On-time service provider</li>
                </ul>
                <ul class="d-flex row-gap-3 flex-column flex-md-row">
                  <li class="d-flex w-50"><span><i class="bi bi-check-circle-fill"></i></span>24/7 Maintenance support</li>
                  <li class="d-flex w-50"><span><i class="bi bi-check-circle-fill"></i></span>Team with Hands-on Experience</li>
                </ul>
            </div>
            </div>
          </div>
          <!-- <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt d-flex justify-content-center">Connect With Us</a>
          </div> -->
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side d-flex justify-content-center">
          <picture>
            <source type="image/webp" srcset="assets-new/images/token-development/why-choose-blockchainx-token-development.webp" />
            <img loading="lazy" data-src="assets-new/images/token-development/why-choose-blockchainx-token-development.png" width="500" height="500" class="img-fluid" alt="Why Choose Blockchainx" title=" Why choose BlockchainX for Token Development?" *ngIf="!isDarkmode" />
          </picture>
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-development/why-choose-blockchainx-token-development-light.webp">
            <source type="image/png"
              srcset="assets-new/images/token-development/why-choose-blockchainx-token-development-light.png">
            <img loading="lazy" data-src="assets-new/images/token-development/why-choose-blockchainx-token-development-light.png" width="500" height="500" class="img-fluid" alt="Why Choose Blockchainx" title=" Why choose BlockchainX for Token Development?" *ngIf="isDarkmode"
               />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- FAQ Section -->
<section class="section-service-alt">
  <div class="container">
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading sub-title">
            FAQ <span class="dots">.</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingFirst">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFirst"
                  aria-expanded="true"
                  aria-controls="collapseFirst">
                  Why develop crypto tokens for business?
                </button>
              </div>
              <div
                id="collapseFirst"
                class="accordion-collapse collapse show"
                aria-labelledby="headingFirst"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Crypto token development acts as a business identity, a
                    financial instrument and more in the blockchain space,
                    thereby adding value and easing operations effectively.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingSecond">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSecond"
                  aria-expanded="false"
                  aria-controls="collapseSecond">
                  What is token development?
                </button>
              </div>
              <div
                id="collapseSecond"
                class="accordion-collapse collapse"
                aria-labelledby="headingSecond"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Token development is a process of creating a blockchain
                    based token for a business for its distinct use case. These
                    tokens are transferables, represent value and can be stored.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThird">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThird"
                  aria-expanded="false"
                  aria-controls="collapseThird">
                  How do you choose your token development company?
                </button>
              </div>
              <div
                id="collapseThird"
                class="accordion-collapse collapse"
                aria-labelledby="headingThird"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    It's important to understand if your Token development
                    company meets your requirements. They must be experienced
                    and have proven successful token launches done in the past.
                    BlockchainX can be a package you are expecting, from
                    customization to hands on experience, we have that.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFourth">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourth"
                  aria-expanded="false"
                  aria-controls="collapseFourth">
                  What is the cost involved in Crypto token development?
                </button>
              </div>
              <div
                id="collapseFourth"
                class="accordion-collapse collapse"
                aria-labelledby="headingFourth"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The cost of developing tokens varies based on different
                    aspects like the functionality, token advancements, the team
                    involved, the choice of blockchain and much more. Hence it
                    is not possible to state a particular price for the token
                    development.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive">
                  How long does it typically take to develop a crypto token?
                </button>
              </div>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    The time involved in crypto token development is a bit tricky to analyze, as we create customized solutions based on requirements, your inputs, expectations, and feature inclusions, and decide the time involved in development.
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="showFaq" class="faq-section"> 
            <div class="accordion-item">
              <div class="accordion-header" id="headingSix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix">
                  What security measures do you implement during token development?
                </button>
              </div>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Based on the requirements, we ensure the smart contracts coded are error-free, and first-handed codes to ensure authenticity. Apart from that, we strictly maintain the regulatory norm and are keen on meeting them for token security.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingSeven">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven">
                  What is the process for creating a token with specific functionalities?
                </button>
              </div>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Creating a crypto token with specific functionalities would give a better understanding of its purpose, instead of using a specific token for all operations. Defining the purpose, makes it seamless to eventually divide the investor pool, gather better traction, and ensure the growth of the same. 
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingEight">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight">
                  Can you assist with cross-chain token development and interoperability?
                </button>
              </div>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Yes, of course, we can assist you with cross-chain token development and interoperability. Kindly reach out now to amplify your growth with us. 
                  </p>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div (click)="toggleFaq()" class="faq-button">
              <span *ngIf="!showFaq" class="fs-3">
                <i class="bi bi-mouse"></i>
              </span>
              <span *ngIf="showFaq" class="fs-4">
                <i class="bi bi-caret-up-fill"></i> 
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- FAQ Section -->
<!-- Blog Start -->
<section class="section-service-main">
  <app-blog></app-blog>
</section>
<!-- Blog End -->
<!-- Contact Start -->
<section class="section-service-main" id="item-1" [ngClass]="selectedLink === 1 ? 'scrollHeading' : 'scrollheadingone'">
  <app-get-in-touch></app-get-in-touch>
</section>
<!-- Contact End -->