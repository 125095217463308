import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from 'src/app/shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-asset-tokenization-company',
  templateUrl: './asset-tokenization-company.component.html',
  styleUrls: ['./asset-tokenization-company.component.css']
})
export class AssetTokenizationCompanyComponent implements OnInit {

  selectedLink:number;
  title = 'Asset Tokenization Company | Asset Tokenization Services';
  about: any;
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Maximize the Profit ratio of your illiquid asset with us, as your Asset Tokenization company we extend customized services on blockchain to scale up, connect now!',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'asset tokenization company, asset tokenization services, asset tokenization development, asset tokenization solutions, asset tokenization development company, asset token development company'
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Asset Tokenization Company | Asset Tokenization Services',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/asset-tokenization-company/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Maximize the Profit ratio of your illiquid asset with us, as your Asset Tokenization company we extend customized services on blockchain to scale up, connect now!',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/asset-tokenization-services/asset-tokenization-services.png',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/asset-tokenization-company/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Asset Tokenization Company | Asset Tokenization Services',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Maximize the Profit ratio of your illiquid asset with us, as your Asset Tokenization company we extend customized services on blockchain to scale up, connect now!',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/asset-tokenization-services/asset-tokenization-services.png',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/asset-tokenization-company/'
    );
  }

  addClass(id:number){
    this.selectedLink=id;
  }
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    navSpeed: 500,
    // margin: 30,
    dots: false,
    center: true,
    navText: [],
    responsive: {
      0: {
        items: 1.5,
      },
      640: {
      items: 2,
      },
      740: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  }
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  }
}