<!-- Banner Section -->
<div class="banners-section">

  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Hire Solana Blockchain </strong> Developers&#64;BlockchainX</span>
              </h1>
              <p>
                From NFTs, De-fi, Dapps, and others we at BlockchainX involve in
                developing user-friendly Solana Blockchain-based solutions. We
                help you curate and launch scalable, speedy, and secured
                applications.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="link-arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/hire-solana-blockchain-developers.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/hire-solana-blockchain-developers.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/hire-solana-blockchain-developers.png"
                width="350"
                height="350"
                class="img-fluid"
                alt="hire-solana-blockchain-developers" />
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- Banner Section ends -->
<section class="section-service-alt about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          Our<span>Clients</span>
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Our Blockchain Smart Contracts Focused Industries -->
<div class="section-service-main" #about>
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Varied Solana Blockchain </span>Development Services</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/solana-blockchain-consulting.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/solana-blockchain-consulting.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/solana-blockchain-consulting.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="solana-blockchain-consulting" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Solana Blockchain Consulting
          </h4>
          <p>
            As a part of Solana consulting services, we help enterprises jot
            their role in the market and help them curate avant-grade solutions
            benefiting business and the world in the Solana network.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/dapp-development.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/dapp-development.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/dapp-development.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="erc-20" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">DApp Development</h4>
          <p>
            Our development services are customized and we almost develop Dapps
            in any blockchain network. Meanwhile, we help you curate
            extraordinary applications on the Solana blockchain based on your
            custom choices.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/nft-marketplace.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/nft-marketplace.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/nft-marketplace.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="nft-marketplace" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">NFT Marketplace</h4>
          <p>
            Customized and reliable NFT trading space, we deploy completely
            unique and safe NFT marketplaces on the Solana blockchain allowing
            creators and your token project to scale up in the competitive
            market.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/spl-token-development.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/spl-token-development.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/spl-token-development.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="spl-token-development" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">SPL Token Development</h4>
          <p>
            We create new Solona Program Library tokens, a secured, expedited
            token development method with the ability to exchange these tokens
            in DEX and empower Solana Dapps.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/exchange-integration.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/exchange-integration.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/exchange-integration.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="exchange-integration" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Exchange Integration</h4>
          <p>
            Employing the unique Solana protocol - Serum, we help you integrate
            the Solana Exchange system into your platform. Allowing cross-chain
            swapping wit optimal order processing in the application.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/wallet-development.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/wallet-development.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/wallet-development.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="wallet-development" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Wallet Development</h4>
          <p>
            We extend both we and mobile wallet development services for all
            blockchain networks. With an integrated payment solution, your
            wallets are safe, secure, and abides by the regulation.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/node-development.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/node-development.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/node-development.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="node-development" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Node Development and Maintenance
          </h4>
          <p>
            ERC827 is one of the latest upgrades to ERC20 and implements many
            improvements. One of them being that you can now transact not only
            values but also data.We help you build and launch nodes on the
            Solana mainnet. Thus you could become a validator and facilitate
            your contribution to the network security and help you earn SOL.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/smart-contract.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/smart-contract.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/smart-contract.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="smart-contract" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Smart Contract Development and Audit
          </h4>
          <p>
            Our experienced developers create valid and functional smart
            contracts with Rust and C++ programming languages. This allows users
            to carry out their business contracts more viable, safely, and in a
            trusted manner.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/defi-development.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/defi-development.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/defi-development.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="defi-development" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Defi Development</h4>
          <p>
            We render scalable and reliable Defi solutions on the Solana
            blockchain, for P2P, decentralized lending, making crypto payments,
            and DEXs we integrate every possible feature in your platform.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Our Blockchain Smart Contracts Focused Industries -->

<!-- DeFi Value Propositions -->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="nft-heading-section" id="deploy">
        <div class="nft-heading-section-alt">
          <h3 class="main-sub-heading">Working Of Solana Blockchain Network</h3>
        </div>
        <div class="nft-heading-section-alt">
          <div class="deploy-link">
            <a [routerLink]="['/contact']">
              <span>Read More</span>
              <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
      <div class="defi-value-propos">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12 col-12 value-propos">
            <div class="nft_market">
              <div class="item-image">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/erc/solona-blockchain/pos-consensus.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/erc/solona-blockchain/pos-consensus.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/erc/solona-blockchain/pos-consensus.png"
                    width="150"
                    height="150"
                    class="img-fluid"
                    alt="(PoS)-consensus" />
                </picture>
              </div>
              <h4 class="common-sub-heading-alt">PoS Consensus</h4>
              <p>
                Proof of Stake mechanism - a key feature of the network is
                backed by timer consensus and its variant is Practical Byzantine
                Fault Tolerance, which allows reaching to consensus rather than
                being attacked. The PBFT renders global source time through PoH
                facilitating the introduction to the before events.
              </p>
              <div class="content-link">
                <a [routerLink]="['/contact']">
                  <span>Read More</span>
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-12 col-sm-12 col-12 value-propos">
            <div class="nft_market">
              <div class="item-image">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/erc/solona-blockchain/tower-consensus.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/erc/solona-blockchain/tower-consensus.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/erc/solona-blockchain/tower-consensus.png"
                    width="150"
                    height="150"
                    class="img-fluid"
                    alt="tower-consensus" />
                </picture>
              </div>
              <h4 class="common-sub-heading-alt">Tower Consensus</h4>
              <p>
                The synchronized clock, the Tower consensus mechanism basically
                reduces the power process to validate the transaction by
                eliminating the need to timestamp the previous transactions.
                Helping Solana beat the competition.
              </p>
              <div class="content-link">
                <a [routerLink]="['/contact']">
                  <span>Read More</span>
                  <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-12 col-sm-12 col-12 value-propos">
            <div class="nft_market">
              <div class="item-image">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/erc/solona-blockchain/sealevel.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/erc/solona-blockchain/sealevel.png" />

                  <img loading="lazy"
                    data-src="assets-new/images/erc/solona-blockchain/sealevel.png"
                    width="150"
                    height="150"
                    class="img-fluid"
                    alt="sealevel" />
                </picture>
              </div>
              <h4 class="common-sub-heading-alt">Sealevel</h4>
              <p>
                Collaborating number of innovations, helps Solana stand out from
                the competition and Sealevel is one of them. It's a transaction
                parallelization technology allowing a parallel smart contract
                execution in the environment optimizing the resources and
                ensuring Solana’s horizontal scalability in the SSDs.
              </p>
              <div class="content-link">
                <a [routerLink]="['/contact']">
                  <span>Read More</span>
                  <img loading="lazy"
                  src="assets-new/images/right-arrow.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="!isDarkmode" />
                <img loading="lazy"
                  src="assets-new/images/right-arrow-dark.png"
                  alt="link-arrow"
                  class="img-fluid"
                  width="50"
                  height="15"
                  *ngIf="isDarkmode" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- DeFi Value Propositions -->

<!-- NFT About Section -->
<div class="section-service-main">
  <div class="section-about" id="about">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-lg-6 mx-auto col-md-12 col-sm-12 col-12 about-section-image">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/erc/solona-blockchain/solana-benefit.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/erc/solona-blockchain/solana-benefit.png" />

            <img loading="lazy"
              data-src="assets-new/images/erc/solona-blockchain/solana-benefit.png"
              width="400"
              height="400"
              class="img-fluid"
              alt="solana-benefits" />
          </picture>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <h3 class="main-sub-heading">
            Benefits Of Choosing Solana Blockchain Development Services
          </h3>
          <p>
            The Solana network implies the Proof-of-Stake mechanism that
            synchronizes the transaction order with timestamps solving the
            issue.
          </p>
          <p>
            With the ability to support over 50,000 transactions per second, the
            network is scalable and reliable.
          </p>
          <p>
            The Solana application run on the validator's approval and is
            secured. Its a completely permissionless space despite the minimum
            software requirements.
          </p>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>Read More</span>
              <img loading="lazy"
            src="assets-new/images/right-arrow.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="!isDarkmode" />
          <img loading="lazy"
            src="assets-new/images/right-arrow-dark.png"
            alt="link-arrow"
            class="img-fluid"
            width="50"
            height="15"
            *ngIf="isDarkmode" />
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- NFT About Section -->

<!-- Our Blockchain Smart Contracts Focused Industries -->
<div class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Different Industries we Work With</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/travel.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/travel.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/travel.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="travel" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Travel</h4>
          <p>
            With the travel industry at its peak the market, infusing blockchain
            technology can make things easy, transparent, and remunerative at
            the same time.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/banking.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/banking.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/banking.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="banking" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Banking</h4>
          <p>
            The sector which requires great security and is also expected to
            have transparency. With Blockchain influences, the industry can be
            made more functional.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/e-commerce.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/e-commerce.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/e-commerce.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="e-commerce" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">E-commerce</h4>
          <p>
            In order to track and locate shipments, the technology is widely
            supported in the market. And its advantages can be tracked down to
            yield better efficiency.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/education.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/education.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/education.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="education" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Education</h4>
          <p>
            The Education system needs to be empowered with the technological
            trend and improvements to create the future generation with the
            abilities of the world.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/realestate.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/realestate.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/realestate.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="realestate" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Realestate</h4>
          <p>
            With a highly monetizing market, Real estate is at its peak,
            infusing the abilities of web3 and decentralization in their
            operation can double its streams.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/gaming.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/gaming.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/gaming.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="gaming" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Gaming</h4>
          <p>
            Yields better income and reproduces a lively experience in the hands
            of the users, Gaming with blockchain technology takes the industry
            to new heights.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/telecommunication.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/telecommunication.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/telecommunication.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="telecommunication" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Telecommunication</h4>
          <p>
            To bind security and transparency in the telecommunication sector
            one can simply gain it with blockchain infusions that are breeding
            better opportunities.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/healthcare.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/healthcare.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/healthcare.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="healthcare" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Healthcare</h4>
          <p>
            Healthcare with technological improvement can make things better and
            easy. Blockchain infusions get their operations to the next level
            with wide opportunities.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/solona-blockchain/entertainment.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/solona-blockchain/entertainment.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/solona-blockchain/entertainment.png"
                width="75"
                height="75"
                class="img-fluid"
                alt="entertainment" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Entertainment</h4>
          <p>
            Entertainment is a part and parcel of every block of life. With web3
            infusion, the user can experience better things more advance than
            reality.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Our Blockchain Smart Contracts Focused Industries -->

<section class="why_choose_us section-service-main">
  <div class="why-alt-section light-background">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container choosing-section">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-2">
            <h2>Choose BlockchainX As Your <span> Solana Developers</span></h2>
          </div>
          <div class="whysection_text_wrap">
            <p>
              We are trained, experienced Blockchain developers with expertise
              in SDKs and frameworks. Our in-depth knowledge of the ecosystem
              gets us through the process well and helps you customize better.
              Hiring us as your Solana blockchain development company we help
              you launch scalable and highly efficient platforms supporting your
              business. A one-stop spot for all your web3 requirements, we
              render complete services.
            </p>
            <div class="content-link my-3">
              <a [routerLink]="['/contact']">
                <span>Read More</span>
                <img loading="lazy"
                src="assets-new/images/right-arrow.png"
                alt="link-arrow"
                class="img-fluid"
                width="50"
                height="15"
                *ngIf="!isDarkmode" />
              <img loading="lazy"
                src="assets-new/images/right-arrow-dark.png"
                alt="link-arrow"
                class="img-fluid"
                width="50"
                height="15"
                *ngIf="isDarkmode" />
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/metaverse/why_blockchain.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/metaverse/why_blockchain.png" />

              <img loading="lazy"
                data-src="assets-new/images/metaverse/why_blockchain.png"
                width="550"
                height="550"
                class="img-fluid"
                alt="why-blockchainX" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-service-alt">
  <app-blog></app-blog>
</section>

<section class="section-service-alt">
  <app-contactform></app-contactform>
</section>
