import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute,Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-precious-metal-tokenization',
  templateUrl: './precious-metal-tokenization.component.html',
  styleUrls: ['./precious-metal-tokenization.component.css']
})
export class PreciousMetalTokenizationComponent implements OnInit {
  selectedLink:number;
  title ='Precious Metal Tokenization - Complete Guide';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  isDarkmode:boolean=false;

  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'Precious metal tokenization is a great opportunity to multiply with web3 intervention through blockchain and smart contract, Wondering how? Discover it here, tap to read.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Precious Metals Tokenization, Precious Metals Tokenization service, Precious Metals Tokenization solution, Precious Metal Token development, Precious Metal Token development company',
    });

    
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Precious Metal Tokenization - Complete Guide ',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/precious-metal-tokenization-guide/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Precious metal tokenization is a great opportunity to multiply with web3 intervention through blockchain and smart contract, Wondering how? Discover it here, tap to read. ',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/precious-metal-token/precious-metals-tokenization.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/precious-metal-tokenization-guide/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Precious Metal Tokenization - Complete Guide ',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Precious metal tokenization is a great opportunity to multiply with web3 intervention through blockchain and smart contract, Wondering how? Discover it here, tap to read. ',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/precious-metal-token/precious-metals-tokenization.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/precious-metal-tokenization-guide/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/precious-metal-tokenization-guide.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }
}
