<section class="banners-section" #about>

      <div class="banner_wrapper">
        <div class="container" id="common-relative">
          <div class="green-vector"></div>
          <div class="row align-items-center">
              <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                <div class="banner_contentWrapper">
                  <div class="caption">
                    <h1>
                      <span><strong>Derivatives Development </strong></span> Company
                    </h1>
                    <p>
                        Derivative Development opens up the possibility of curating effective solutions and safeguarding assets, enhancing traceability, security, accessibility, and efficiency.
                    </p>

                    <div class="contact-link">
                      <a [routerLink]="['/contact']" class="home-link"
                        >Talk To Our Experts</a
                      >
                      <a [routerLink]="['/case-studies']" class="home-link-alt"
                        >View Case Study</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-12 col-sm-12 text-center">
                <div class="banner-right-section">
                  <picture>
                    <source type="image/webp" srcset="assets-new/images/token-images/launch-your-token.webp" />
                    <source type="image/png" srcset="assets-new/images/token-images/launch-your-token.png" />
                    <img loading="lazy" data-src="assets-new/images/token-images/launch-your-token.png" width="500" height="500" class="img-fluid" alt="derivatives-development-image" title="Derivatives Development" />
                  </picture>
                </div>
              </div>

          </div>
          <div class="row d-lg-block d-none">
            <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/home/scroll.webp"
                />
                <source
                  type="image/png"
                  srcset="assets-new/images/home/scroll.png"
                />
                <img
                  loading="lazy"
                  data-src="assets-new/images/home/scroll.png"
                  width="25"
                  height="48"
                  class="img-fluid"
                  alt="scroll-icon"
                  title="scroll-icon"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
  </section>

  <!-- What is ERC20 start-->
  <section class="section-service-alt">
      <div class="container">
        <div class="whys_section mb-0 text-center">
          <h2 class="pb-lg-5 pb-4"><span>Derivatives </span>Development</h2>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
            <div class="bep-work-section">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/token-images/why-should-you-choose.webp">
                <source type="image/png"
                  srcset="assets-new/images/token-images/why-should-you-choose.png">
                <img loading="lazy"
                  data-src="assets-new/images/token-images/why-should-you-choose.png"
                  width="350" height="350" alt="derivatives-development-image"
                  title="Derivatives Development" class="BSC-Tokens-image img-fluid">
              </picture>
            </div>
          </div>
          <div
            class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <p>
                In Blockchain, Derivative development refers to financial derivative creations as products or platforms through blockchain technology which is used to derive the value of an underlying asset, index, and/or benchmark. Blockchain-based derivatives exchange development vests on the unique features of Blockchain, theory ensuring security, transparency, and programmability, which enhances the efficiency, accessibility, and trust of the derivatives in the market.
              </p>
            </div>
          </div>
        </div>

      </div>
  </section>
  <!-- End -->

  <!-- Popular Ganming List Start -->
  <div class="section-service-main">
      <div class="tending-bep-20">
        <div class="container">
          <div class="row align-items-center trending-token-list">
            <div class="col-lg-6 text-center order-lg-first order-last">
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
                <source type="image/png"
                  srcset="assets-new/images/erc/trending-erc20-token-list.png" />
                <img loading="lazy"
                  data-src="assets-new/images/erc/trending-erc20-token-list.png"
                  width="405" height="405" alt="few-derivative-platforms-image"
                  title="Few Derivative Platforms" class="img-fluid mx-auto">
              </picture>
            </div>
            <div class="col-lg-6 mb-lg-0 mb-4">
              <h3 class="main-sub-heading text-start"><span>Here Are A Few Derivative </span>  Exchange Platforms In The Market</h3>

              <div class="row trending-bep">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                  <ul>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> Globe
                    </li>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> DeepCoin
                    </li>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> HitBTC
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                  <ul>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> Jupiter
                    </li>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> Aerodrome Finance
                    </li>
                    <li>
                      <span><i class="bi bi-hexagon-fill"></i></span> THORchain
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex pt-3">
                <a [routerLink]="['/contact']" class="home-link-alt">Connect With
                  Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End -->

  <!-- Related Services -->
  <section class="section-service-alt-padd">
      <div class="container">
        <div class="row">
          <div class="metaverse_heading mx-auto">
            <h3><span>Different Blockchain</span> Based Services We Offer</h3>
          </div>
        </div>
        <div class="row types-of-token-detail">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/project-consulting.webp">
                  <source type="image/png" srcset="assets-new/images/token images/project-consulting.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/project-consulting.png" width="75" height="75" alt="blockchain-image" title="Blockchain Consultation" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Blockchain Consultation</h4>
              <p>
                Do you want to upgrade or step into the emerging web3 trend? Here we at BlockchainX provide you with end to end consultation with no exaggeration but the reality
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/staking-platform.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/staking-platform.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/staking-platform.png" width="75" height="75" alt="token-development-image" title="Token Development" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">
                Token Development
              </h4>
              <p>
                For our project and to step up the operations of your organization through decentralization, token development and tokenization can be a go to choice.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/farming-platform.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/farming-platform.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/farming-platform.png" width="75" height="75" alt="smart-contract-audit-image" title="Smart Contract Audit" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Smart Contract Audit</h4>
              <p>
                We ensure all the codes and smart contracts we deliver and those that come for audit are perfectly scrutinized and given a clear report on it's to get the contract more efficiently.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/token-bridge.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/token-bridge.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/token-bridge.png" width="75" height="75" alt="blockchain-ecosystem-image" title="Blockchain Ecosystem Development" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Blockchain Ecosystem Development </h4>
              <p>
                From developing a blockchain, layer or 3, gaming, metaverse or anything to embrace the operations, business, and community we provide you with custom built solutions.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/layer-i-blockchain.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/layer-i-blockchain.png" width="75" height="75" alt="decentralised-finance-platforms-image" title="Decentralised Finance Platforms" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">Decentralised Finance Platforms </h4>
              <p>
                To buy,sell, create, exchange tokens, DeFi platform plays a vital role, we curate your desired solution with avant grade advancements and abilities.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
            <div class="types-erc-detail">
              <div class="top-icon">
                <picture>
                  <source type="image/webp" srcset="assets-new/images/token-images/nft-markeplace.webp">
                  <source type="image/png" srcset="assets-new/images/token-images/nft-markeplace.png">
                  <img loading="lazy" data-src="assets-new/images/token-images/nft-markeplace.png" width="75" height="75" alt="NFT-marketplaces-image" title="NFT Marketplaces" class="img-fluid">
                </picture>
              </div>
              <h4 class="erc-20-head-alt text-left">
                NFT Marketplaces
              </h4>
              <p>
                To create, list, buy, sell, trade and stake your exclusive art and related unique collectibles with added functionality and utilities, our NFT marketplace service would drive better.
              </p>
            </div>
          </div>
        </div>

      </div>
  </section>
  <!-- Related Services -->

    <!-- why-choose-section -->
    <section class="section-service-main">
        <div class="green-vector"></div>
        <div class="orange-vector"></div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 metmb-3">
              <div class="whys_section mb-0">
                <h2>
                  <span>BlockchainX as Derivatives</span> Development Company
                </h2>
                <p class="pb-3">
                    BlockchainX is A team of creative, young technophiles with a keen determination to upskill and endorse with any updated technology. We ensure to serve on time with your customized development request. With 7 plus years of experience in decentralized platform development, We have hands-on experience in curating Derivative exchange platform development, with advancement, accessibility, and efficiency. Reach out to us to understand more about our development style and project approaches.
                </p>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <!-- <span>Read More</span> -->
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                  <hr />
                </div>
              </div>
            </div>
            <div class="col-lg-6 metmb-3 Why-section">
              <div class="why_right_side">
                <picture>
                  <source
                    type="image/webp"
                    srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

                  <source
                    type="image/png"
                    srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

                  <img
                    src="assets-new/images/token-images/why-choose-blockchainx.png"
                    width="624"
                    height="521"
                    class="img-fluid"
                    alt="blockchainX-derivatives-development-company-image"
                    title="BlockchainX as Derivatives Development Company"
                    loading="lazy" />
                </picture>
              </div>
            </div>
          </div>
        </div>
    </section>
    <!-- End -->

  <!-- FAQ Start -->
  <section class="section-service-alt">
      <div class="container">
        <!-- Accordion Section -->
        <div class="nft-acc">
          <div class="green-vector"></div>
          <div class="violet-vector"></div>
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-10 col-md-12 col-sm-12 col-12">
              <div class="common-sub-heading sub-title">
                FAQ <span class="dots">.</span>
              </div>
              <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
            </div>
          </div>
          <div class="row align-items-center justify-content-center pt-md-5 pt-4">
            <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <div class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne">
                      Is Equity an option in Derivatives Investments?
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>
                        Yes, any investment that is derived from the underlying asset, it's a derivative investment. The smart contract offers the right to buy, sell and trade.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo">
                      How long will it take for Derivative Exchange Development
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>
                        The time for development usually depends on the integrations, customizations, advancements and feature components demanded.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree">
                      Where can we sell derivatives?
                    </button>
                  </div>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p>
                        They are usually bought and sold on centralized exchanges, and OTC. They are also sold on Blockchain Derivatives platforms which are from regulations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Accordion Section -->
      </div>
    </section>
    <!-- FAQ End -->

      <!-- Blog Start -->
  <section class="section-service-main">
    <app-blog></app-blog>
    </section>
    <!-- Blog End -->

    <!-- Contact Start -->
    <section class="section-service-alt">
      <div class="app-get-in-touch-form">
        <div class="container">
            <div class="contact-form">
                <div class="row reverse-colum">
                  <div class="col-lg-5 col-md-12 contact-social-links">
                    <h3 class="main-sub-heading"><span>Amplify security and regulating risk with </span>Derivatives Exchange Development </h3>
                    <div class="contact-main-list">
                      <div class="social-links">
                        <a href="mailto:contact@blockchainx.tech"  target="_blank"
                rel="noopener noreferrer" class="contact-mail" >
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/mail.webp"
                            />

                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/mail.png"
                            />

                            <img
                              src="assets-new/images/contact/mail.png"
                              alt="linkedin"
                              width="32"
                              height="32"
                            />
                          </picture>
                        </a>

                        <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer" class="whatsapp">
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                            />

                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/whatsapp-icon.png"
                            />

                            <img
                              src="assets-new/images/contact/whatsapp-icon.png"
                              alt="contact-Whatsapp"
                              width="32"
                              height="32"
                            />
                          </picture>
                        </a>

                        <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer" class="twitter" >
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/telegram.webp"
                            />

                            <source
                              type="image/svg"
                              srcset="assets-new/images/contact/telegram.png"
                            />

                            <img
                              src="assets-new/images/contact/telegram.png"
                              alt="twitter"
                              width="40"
                              height="40"
                            />
                          </picture>
                        </a>
                      </div>
                      <!-- <div class="contact-list">
                        <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/mail.webp"
                            />

                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/mail.png"
                            />

                            <img
                              src="assets-new/images/contact/mail.png"
                              loading="lazy"
                              alt="contact-mail"
                              width="50"
                              height="50"
                              class="contact-logo img-fluid"
                            />
                          </picture>
                          Contact@blockchainx.tech</a
                        >
                      </div>

                     <div class="contact-list">
                        <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                            />

                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/whatsapp-icon.png"
                            />

                            <img
                              src="assets-new/images/contact/whatsapp-icon.png"
                              loading="lazy"
                              alt="contact-Whatsapp"
                              width="50"
                              height="50"
                              class="contact-logo img-fluid"
                            />
                          </picture>
                          Connect on Whatsapp</a
                        >
                      </div>

                      <div class="contact-list">
                        <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/telegram.webp"
                            />

                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/telegram.png"
                            />

                            <img
                              src="assets-new/images/contact/telegram.png"
                              loading="lazy"
                              alt="contact-skype"
                              width="50"
                              height="50"
                              class="contact-logo1 img-fluid"
                            />
                          </picture>
                          Connect on Telegram</a
                        >
                      </div> -->
                    </div>
                    <div class="contact-links">
                      <h4>Follow us on</h4>
                      <div class="social-links">
                        <a
                          href="https://www.linkedin.com/company/blockchainxtech/"
                          target="_blank"
                rel="noopener noreferrer"
                          class="linkdin"
                        >
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/linkdin.webp"
                            />

                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/linkdin.png"
                            />

                            <img
                              src="assets-new/images/contact/linkdin.png"
                              alt="linkedin"
                              width="22"
                              height="22"
                            />
                          </picture>
                        </a>

                        <a
                          href="https://www.instagram.com/blockchainxtech/"
                          target="_blank"
                rel="noopener noreferrer"
                          class="instagram"
                        >
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/instagram.webp"
                            />

                            <source
                              type="image/png"
                              srcset="assets-new/images/contact/instagram.png"
                            />

                            <img
                              src="assets-new/images/contact/instagram.png"
                              alt="instagram"
                              width="22"
                              height="22"
                            />
                          </picture>
                        </a>

                        <a
                          href="https://twitter.com/Blockchainxtech"
                          target="_blank"
                rel="noopener noreferrer"
                          class="twitter"
                        >
                          <picture>
                            <source
                              type="image/webp"
                              srcset="assets-new/images/contact/webp/twitterx.webp"
                            />

                            <source
                              type="image/svg"
                              srcset="assets-new/images/contact/twitterx.svg"
                            />

                            <img
                              src="assets-new/images/contact/twitterx.svg"
                              alt="twitter"
                              width="25"
                              height="25"
                            />
                          </picture>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 col-md-12 contact-inputs">
                    <div class="getintouch getintochform bitrix-snippet-form">
                      <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    <!-- Contact End -->
