<section class="banners-section" #about>

    <div class="banner_wrapper">
        <div class="container" id="common-relative">
            <div class="green-vector"></div>
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                    <div class="banner_contentWrapper">
                        <div class="caption">
                            <h1>
                                <span><strong>Polygon CDK
                                        Development</strong></span>
                            </h1>
                            <p>
                                Build sovereign chains inside the Polygon
                                Ecosystem and create scalable dApps for your
                                users. We help you take your startup ideas from
                                start to finish with the right technologies and
                                expert guidance.
                            </p>

                            <div class="contact-link">
                                <a [routerLink]="['/contact']"
                                    class="home-link">Talk To Our Experts</a>
                                <a [routerLink]="['/case-studies']"
                                    class="home-link-alt">View Case Study</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12 col-sm-12 text-center">
                    <div class="banner-right-section">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/launch-your-token.webp" />
                            <source type="image/png"
                                srcset="assets-new/images/token-images/launch-your-token.png" />
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/launch-your-token.png"
                                width="500" height="500" class="img-fluid"
                                alt="Polygon CDK Development"
                                title="Polygon CDK Development" />
                        </picture>
                    </div>
                </div>

            </div>
            <div class="row d-lg-block d-none">
                <div class="scroll-section text-center"
                    (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="assets-new/images/home/scroll.webp" />
                        <source
                            type="image/png"
                            srcset="assets-new/images/home/scroll.png" />
                        <img loading="lazy"
                            data-src="assets-new/images/home/scroll.png"
                            width="25"
                            height="48"
                            class="img-fluid"
                            alt="scroll-icon"
                            title="scroll-icon" />
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
    <div class="container">
        <div class="whys_section mb-0 text-center">
            <h2 class="pb-lg-5 pb-4"><span>Why Choose Polygon</span> CDK
                Development</h2>
        </div>
        <div class="row align-items-center">
            <div
                class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
                <div class="bep-work-section">
                    <picture>
                        <source type="image/webp"
                            srcset="assets-new/images/token-images/why-should-you-choose.webp">
                        <source type="image/png"
                            srcset="assets-new/images/token-images/why-should-you-choose.png">
                        <img loading="lazy"
                            data-src="assets-new/images/token-images/why-should-you-choose.png"
                            width="350" height="350"
                            alt="Why Choose Polygon CDK Development"
                            title="Why Choose Polygon CDK Development"
                            class="BSC-Tokens-image img-fluid">
                    </picture>
                </div>
            </div>
            <div
                class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
                <div class="common-sub-heading">
                    <p>
                        Building decentralized applications is half the battle.
                        You have to build scalable dApps that can handle
                        real-world volumes when multiple users start using your
                        solution. Polygon CDK allows you to create your own side
                        chain to run your decentralized applications and achieve
                        the high throughput required to minimize downtime and
                        improve user experience. What’s more? You can leverage
                        the Zero-Knowledge technology to bring the perfect
                        balance of privacy and transparency to your users.
                    </p>
                </div>

                <!-- <div class="row token d-flex">
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span>
                  Globalized access and popularity
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Trade volume at the peak
                </li>
              </ul>
            </div>
            <div class="col-md-6 projects">
              <ul>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Multiple trading options
                </li>
                <li>
                  <span><i class="bi bi-check-circle-fill"></i></span> Futuristic Approach
                </li>
              </ul>
            </div>
          </div> -->
            </div>
        </div>

    </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<section class="section-service-main">
    <div class="tending-bep-20">
        <div class="container">
            <div class="row trending-token-list">
                <div class="col-lg-6 text-center order-lg-first order-last">
                    <picture>
                        <source type="image/webp"
                            srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
                        <source type="image/png"
                            srcset="assets-new/images/erc/trending-erc20-token-list.png" />
                        <img loading="lazy"
                            data-src="assets-new/images/erc/trending-erc20-token-list.png"
                            width="405" height="405"
                            alt="Popular CDK Development Solutions"
                            title="Popular CDK Development Solutions"
                            class="img-fluid mx-auto">
                    </picture>
                </div>
                <div class="col-lg-6 mb-lg-0 mb-4">
                    <h3 class="main-sub-heading text-start"><span>Popular CDK
                        </span>Development Solutions</h3>
                    <div class="row trending-bep">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                            <ul>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span><a
                                        href="https://www.coingecko.com/en/coins/celestia"
                                        target="_blank"
                                        rel="noopener noreferrer">Celestia</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://coingecko.com/en/exchanges/okx"
                                        target="_blank"
                                        rel="noopener noreferrer">OKX</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://www.coingecko.com/en/coins/near"
                                        target="_blank"
                                        rel="noopener noreferrer">Near
                                        Protocol</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-0">
                            <ul>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://www.coingecko.com/en/coins/kyve-network"
                                        target="_blank"
                                        rel="noopener noreferrer">KYVE
                                        Network</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://www.coingecko.com/en/coins/symbiosis"
                                        target="_blank"
                                        rel="noopener noreferrer">Symbiosis</a>
                                </li>
                                <li>
                                    <span><i
                                            class="bi bi-hexagon-fill"></i></span>
                                    <a
                                        href="https://www.coingecko.com/en/coins/bim"
                                        target="_blank"
                                        rel="noopener noreferrer">BIM
                                        Finance</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex pt-3">
                        <a [routerLink]="['/contact']"
                            class="home-link-alt">Connect With
                            Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 metaverse_heading mx-auto">
                <h3><span>Various Other Polygon </span>CDK services</h3>
            </div>
        </div>
        <div class="row types-of-token-detail">
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/project-consulting.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token images/project-consulting.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/project-consulting.png"
                                width="75" height="75"
                                alt="Decentralized Exchange"
                                title="Decentralized Exchange"
                                class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Decentralized
                        Exchange</h4>
                    <p>
                        Launch a secure and efficient decentralized exchange
                        protocol on your own Polygon CDK side chai for seamless
                        trading.
                    </p>
                    <!-- <div class="content-link">
              <a [routerLink]="['/']">
                <span>View More</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow"
                     />
                </picture>
              </a>
              <hr />
            </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/staking-platform.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/staking-platform.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/staking-platform.png"
                                width="75" height="75" alt="NFT Marketplace"
                                title="NFT Marketplace" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">
                        NFT Marketplace
                    </h4>
                    <p>
                        Launch an NFT Marketplace platform that is scalable and
                        efficient with our Polygon CDK development services.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/farming-platform.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/farming-platform.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/farming-platform.png"
                                width="75" height="75" alt="Token Launchpad"
                                title="Token Launchpad" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Token Launchpad</h4>
                    <p>
                        Allow Web3 founders to easily raise funds for their
                        projects with a token launchpad that delivers the best
                        uptimes and gas-efficiency.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/token-bridge.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/token-bridge.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/token-bridge.png"
                                width="75" height="75" alt="NFT Launchpad"
                                title="NFT Launchpad" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">NFT Launchpad</h4>
                    <p>
                        Fundraising through NFTs is a great way to build a
                        community while you fuel your project. Create a secure
                        NFT launchpad with our Polygon CDK solution.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/layer-i-blockchain.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                                width="75" height="75" alt="Order Book DEX"
                                title="Order Book DEX" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">Order Book DEX</h4>
                    <p>
                        Launch a hybrid decentralized exchange that can handle
                        large volumes on-chain with a leading Polygon CDK
                        development company.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
                <div class="types-erc-detail">
                    <div class="top-icon">
                        <picture>
                            <source type="image/webp"
                                srcset="assets-new/images/token-images/nft-markeplace.webp">
                            <source type="image/png"
                                srcset="assets-new/images/token-images/nft-markeplace.png">
                            <img loading="lazy"
                                data-src="assets-new/images/token-images/nft-markeplace.png"
                                width="75" height="75"
                                alt="Product Traceability"
                                title="Product Traceability" class="img-fluid">
                        </picture>
                    </div>
                    <h4 class="erc-20-head-alt text-left">
                        Product Traceability
                    </h4>
                    <p>
                        Bring the power of Zero-Knowledge proofs to your
                        business by implementing a traceability solution that
                        can handle real-world situations.
                    </p>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 metmb-3">
                <div class="whys_section mb-0">
                    <h2>
                        <span>Why Choose BlockchainX for </span>Polygon CDK
                        Development
                    </h2>
                    <p class="pb-3">
                        Our Polygon CDK Development experts have years of
                        experience building scalable blockchain solutions for
                        global businesses. Bring an industry partner with
                        Polygon Labs, we have worked closely and launched
                        bleeding-edge Web3 applications with some of the most
                        innovative projects in the space. Take your Web3 project
                        to the next level with our Polygon CDK development
                        services.
                    </p>
                    <!-- <div class="whysection_text_wrap">
              <ul>
                <li>50plus successful live project</li>
                <li>Open to customizing your solution </li>
                <li>On-time delivery and support</li>
                <li>Open to learn and implement</li>
              </ul>
            </div> -->
                    <div class="content-link">
                        <a [routerLink]="['/contact']">
                            <!-- <span>Read More</span> -->
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/right-arrow.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/right-arrow.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/right-arrow.png"
                                    class="img-fluid"
                                    width="50" height="15" alt="Link-arrow"
                                    title="Link-arrow" *ngIf="!isDarkmode" />
                            </picture>
                            <picture>
                                <source type="image/webp"
                                    srcset="assets-new/images/right-arrow-dark.webp">
                                <source type="image/png"
                                    srcset="assets-new/images/right-arrow-dark.png">
                                <img loading="lazy"
                                    data-src="assets-new/images/right-arrow-dark.png"
                                    class="img-fluid"
                                    width="50" height="15" alt="Link-arrow"
                                    title="Link-arrow" *ngIf="isDarkmode" />
                            </picture>
                        </a>
                        <hr />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 metmb-3 Why-section">
                <div class="why_right_side">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

                        <source
                            type="image/png"
                            srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

                        <img loading="lazy"
                            data-src="assets-new/images/token-images/why-choose-blockchainx.png"
                            width="624"
                            height="521"
                            class="img-fluid"
                            alt="Why Choose BlockchainX for Polygon CDK Development"
                            title="Why Choose BlockchainX for Polygon CDK Development" />
                    </picture>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
    <div class="container">
        <!-- Accordion Section -->
        <div class="nft-acc">
            <div class="green-vector"></div>
            <div class="violet-vector"></div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12">
                    <div class="common-sub-heading sub-title">
                        FAQ <span class="dots">.</span>
                    </div>
                    <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
                </div>
            </div>
            <div
                class="row align-items-center justify-content-center pt-md-5 pt-4">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingOne">
                                <button
                                    class="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne">
                                    What is Polygon CDK?
                                </button>
                            </div>
                            <div
                                id="collapseOne"
                                class="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Polygon CDK is a chain development kit
                                        provided by Polygon Labs that allows you
                                        to create custom side chains inside the
                                        Polygon Ecosystem to run scalable
                                        decentralized applications.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingTwo">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo">
                                    How long does it take to launch a dApp using
                                    Polygon CDK?
                                </button>
                            </div>
                            <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Depending on the complexity of your
                                        project, Polygon CDK development can
                                        take anywhere from 2-6 Months to build,
                                        test, and launch.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingThree">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree">
                                    Does my project need Polygon CDK
                                    Development?
                                </button>
                            </div>
                            <div
                                id="collapseThree"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        If you’re a Web3 project with a
                                        decentralized application that expects
                                        to have thousands or even millions of
                                        users, Polygon CDK allows you to keep
                                        your transactions affordable, throughput
                                        high, and speeds fast.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingFour">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour">
                                    How much does Polygon CDK Development cost?
                                </button>
                            </div>
                            <div
                                id="collapseFour"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        The cost for Polygon CDK Development
                                        depends on the complexity of your
                                        project. You can connect with one of our
                                        expert consultants to get a free quote.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header" id="headingFive">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive">
                                    Can I launch on other chains if my dApp is
                                    on Polygon CDK?
                                </button>
                            </div>
                            <div
                                id="collapseFive"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingFive"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>
                                        Yes, since Polygon supports EVM, you can
                                        launch your dApps on multiple chains
                                        that support EVM.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Accordion Section -->
    </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
    <div class="green-vector"></div>
    <div class="container">
        <div class="row blog-list">
            <div class="col-lg-12">
                <div class="commontext_subhead">
                    <h3>Blog<span class="dots">.</span></h3>
                </div>
                <div class="feature_wrap">
                    <div class="commontext_wrap">
                        <h2>Check out our Latest Blogs </h2>
                    </div>
                    <div class="deploy-link">
                        <a [routerLink]="['/blog']">
                            <!-- <span>Read More</span> -->
                            <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode" />
                            <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="link-arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 left-vector">
                <owl-carousel-o [options]="blogs_details">
                    <ng-template carouselSlide>
                        <div class="blog_wrap">
                            <a
                                [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                                <picture>
                                    <!-- <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                      "
                    /> -->
                                    <source
                                        type="image/jpg"
                                        srcset="
                      assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                      " />
                                    <img loading="lazy"
                                        data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                                        width="404"
                                        height="246"
                                        class="img-fluid"
                                        alt="Web3 Gaming"
                                        title="AI Transforming Web3 Gaming And Metaverse" />
                                </picture>
                                <h3>
                                    AI Transforming Web3 Gaming And Metaverse
                                </h3>
                                <p>
                                    In the 1990s, the introduction of the
                                    internet, and the evolution of something
                                    beyond reality kick-started ...
                                </p>
                            </a>
                            <div class="content-link">
                                <a
                                    [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                                    <!-- <span>Read More</span> -->
                                    <img loading="lazy"
                                        src="assets-new/images/right-arrow.png"
                                        alt="link-arrow"
                                        class="img-fluid"
                                        width="50"
                                        height="15"
                                        *ngIf="!isDarkmode" />
                                    <img loading="lazy"
                                        src="assets-new/images/right-arrow-dark.png"
                                        alt="link-arrow"
                                        class="img-fluid"
                                        width="50"
                                        height="15"
                                        *ngIf="isDarkmode" />
                                </a>
                                <hr />
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="blog_wrap">
                            <a
                                [routerLink]="[
                    '/what-is-gamefi'
                  ]">
                                <picture>
                                    <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.webp
                      "
                    /> -->
                                    <source
                                        type="image/jpg"
                                        srcset="
                      assets-new/images/blog/what-is-gamefi.jpg
                      " />
                                    <img loading="lazy"
                                        data-src="assets-new/images/blog/what-is-gamefi.jpg"
                                        width="404"
                                        height="246"
                                        alt="What is GameFi?"
                                        title="What is GameFi?" />
                                </picture>
                                <h3>
                                    What is GameFi? Diving into the world of
                                    Blockchain Games
                                </h3>
                                <p>
                                    From the earlier days of the video game
                                    industry, developers have aimed to create
                                    worlds as powerful as real life....</p>
                            </a>
                            <div class="content-link">
                                <a
                                    [routerLink]="[
                      '/what-is-gamefi'
                    ]">
                                    <!-- <span>Read More</span> -->
                                    <img loading="lazy"
                                        src="assets-new/images/right-arrow.png"
                                        alt="link-arrow"
                                        class="img-fluid"
                                        width="50"
                                        height="15"
                                        *ngIf="!isDarkmode" />
                                    <img loading="lazy"
                                        src="assets-new/images/right-arrow-dark.png"
                                        alt="link-arrow"
                                        class="img-fluid"
                                        width="50"
                                        height="15"
                                        *ngIf="isDarkmode" />
                                </a>
                                <hr />
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="blog_wrap">
                            <a
                                [routerLink]="[
                    '/blockchain-game-development'
                  ]">
                                <picture>
                                    <source
                                        type="image/webp"
                                        srcset="
                      assets-new/images/Industries/blockchain-in-gaming.webp
                      " />
                                    <source
                                        type="image/jpg"
                                        srcset="
                      assets-new/images/Industries/blockchain-in-gaming.jpg
                      " />
                                    <img loading="lazy"
                                        data-src="assets-new/images/Industries/blockchain-in-gaming.jpg"
                                        width="404"
                                        height="246"
                                        class="img-fluid"
                                        alt="Blockchain Technology"
                                        title=" The Potential of Blockchain Technology to Enhance Industry Profits " />
                                </picture>
                                <h3>
                                    The Potential of Blockchain Technology to
                                    Enhance Industry Profits
                                </h3>
                                <p>
                                    Mario video games in the year 1981 became
                                    very famous among game lovers by the name of
                                    Jumpman. In today’s world,...
                                </p>
                            </a>
                            <div class="content-link">
                                <a
                                    [routerLink]="[
                      '/blockchain-game-development'
                    ]">
                                    <!-- <span>Read More</span> -->
                                    <img loading="lazy"
                                        src="assets-new/images/right-arrow.png"
                                        alt="link-arrow"
                                        class="img-fluid"
                                        width="50"
                                        height="15"
                                        *ngIf="!isDarkmode" />
                                    <img loading="lazy"
                                        src="assets-new/images/right-arrow-dark.png"
                                        alt="link-arrow"
                                        class="img-fluid"
                                        width="50"
                                        height="15"
                                        *ngIf="isDarkmode" />
                                </a>
                                <hr />
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
    <div class="app-get-in-touch-form">
        <div class="container">
            <div class="contact-form">
                <div class="row reverse-colum">
                    <div class="col-lg-5 col-md-12 contact-social-links">
                        <h3 class="main-sub-heading"><span>Get a free quote
                                today!</span></h3>
                        <p>Connect with one of our expert consultants today to
                            learn how you can leverage Polygon CDK solutions to
                            boost your business. </p>
                        <div class="contact-main-list">
                            <div class="social-links">
                                <a href="mailto:contact@blockchainx.tech"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="contact-mail">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/mail.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/mail.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/mail.png"
                                            alt="linkedin"
                                            width="32"
                                            height="32" />
                                    </picture>
                                </a>

                                <a href="https://wa.me/7708889555"
                                    target="_blank"
                                    rel="noopener noreferrer" class="whatsapp">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/whatsapp-icon.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/whatsapp-icon.png"
                                            alt="contact-Whatsapp"
                                            width="32"
                                            height="32" />
                                    </picture>
                                </a>

                                <a href="https://t.me/sparkouttechblockchainx"
                                    target="_blank"
                                    rel="noopener noreferrer" class="twitter">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/telegram.webp" />

                                        <source
                                            type="image/svg"
                                            srcset="assets-new/images/contact/telegram.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/telegram.png"
                                            alt="twitter"
                                            width="40"
                                            height="40" />
                                    </picture>
                                </a>
                            </div>
                            <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"

                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>

                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"

                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div>

                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"

                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
                        </div>
                        <div class="contact-links">
                            <h4>Follow us on</h4>
                            <div class="social-links">
                                <a
                                    href="https://www.linkedin.com/company/blockchainxtech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="linkdin">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/linkdin.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/linkdin.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/linkdin.png"
                                            alt="linkedin"
                                            width="22"
                                            height="22" />
                                    </picture>
                                </a>

                                <a
                                    href="https://www.instagram.com/blockchainxtech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="instagram">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/instagram.webp" />

                                        <source
                                            type="image/png"
                                            srcset="assets-new/images/contact/instagram.png" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/instagram.png"
                                            alt="instagram"
                                            width="22"
                                            height="22" />
                                    </picture>
                                </a>

                                <a
                                    href="https://twitter.com/Blockchainxtech"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="twitter">
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcset="assets-new/images/contact/webp/twitterx.webp" />

                                        <source
                                            type="image/svg"
                                            srcset="assets-new/images/contact/twitterx.svg" />

                                        <img loading="lazy"
                                            data-src="assets-new/images/contact/twitterx.svg"
                                            alt="twitter"
                                            width="25"
                                            height="25" />
                                    </picture>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 contact-inputs">
                        <div
                            class="getintouch getintochform bitrix-snippet-form">
                            <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact End -->
