
<!-- Banner Section -->
<section class="banners-section">

    <div class="banner_wrapper">
      <div class="container" id="banner-page-vector-main">
        <div class="green-vector"></div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-12 col-sm-12 col-12">
            <div class="banner_contentWrapper">
              <div class="caption">
                <h1 class="pb-3">
                    Highly Efficient <span><strong>Blockchain Development</strong></span> Company In USA
                </h1>
                <!-- <div class="common-sub-heading-alt pb-2">Expanding Opportunities
                  For Real Assets</div> -->
                <p class="text-justify">
                    Start your game in the decentralized ecosystem with efficient web3 solutions, developed at BlockchainX, a leading Blockchain development company in USA.
                </p>
                <div class="erc-link">
                  <div class="content-link">
                    <a
                      href="/contact">
                      <span>Connect with our Experts now!</span>
                      <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-12">
            <div class="mobile-app-page-form bitrix-snippet-form position-relative" id="consultation-form">
              <div class="form-title">
                <p class="h3">Book a Free <span>Consultation</span></p>
              </div>
              <!-- <div [innerHTML]="bitrixSnippetScript"></div> -->
              <app-consultation-form1></app-consultation-form1>
            </div>
          </div>
        </div>

        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center"
            (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png" />
              <img loading="lazy"
                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="Scroll"
                title="Scroll" />
            </picture>
          </div>
        </div>

      </div>
    </div>
</section>
<!-- Banner Section end -->

  <!-- Why Choose Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row mb-lg-5 mb-4">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h2 class="text-center pb-0 mb-3">
            Why Are We The Best <span>Blockchain Development</span> Company in USA?
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 d-lg-block d-none">
        <div class="casetudies_common">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/home-v2/blockchain-app-development-process-includes.webp
              " />
            <source
              type="image/png"
              srcset="
                assets-new/images/home-v2/blockchain-app-development-process-includes.png
              " />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/blockchain-app-development-process-includes.png"
              width="500"
              height="500"
              class="img-fluid pb-md-0 pb-3"
              alt="Blockchain App Development Process Includes"
              title="Blockchain App Development Process Includes" />
          </picture>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/field.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/field.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/field.png"
                  class="img-fluid"
                  alt="field"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Varied Decentralised Solutions </h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                    We curate different blockchain solutions, from token development, NFT marketplace, Metaverse development, blockchain development and much more with versatility, efficiency and ability to perform above and beyond in the market.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/real-estate.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/real-estate.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/real-estate.png"
                  class="img-fluid"
                  alt="Real estate"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Customized Blockchain Development </h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                    As a blockchain development company in USA we provide customized decentralized solutions that are curated for your business, exclusively addressing the shortcomings you want to excel, eventually making it more a sustainable solution for all.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/education.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/education.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/education.png"
                  class="img-fluid"
                  alt="education"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Experienced Team of Developers</h3>
                </div>
              </div>
            </div>
            <div class="casestudy_para">
              <p class="text-justify">
                Our team of developers is keen to learn and explore blockchain development in all possible ways. Our developers, have hands-on working experience in blockchain development, are open to learning new aspects, and are ready to get their hands on dust to learn and endure the fullest of the technology.
              </p>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
              <picture>
                <source
                    type="image/webp"
                    srcset="assets-new/images/web3-development/fashion.webp" />

                <source
                    type="image/png"
                    srcset="assets-new/images/web3-development/fashion.png" />

                <img loading="lazy"
                    data-src="assets-new/images/web3-development/fashion.png"
                    class="img-fluid"
                    alt="fashion"
                    width="70"
                    height="70" />
              </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Successful Deliverables</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                    As a blockchain development company, we have proven to excel in our service. In this 7-year course, we have delivered 50-plus successful decentralized solutions that have significantly contributed to the growth of the business.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
              <picture>
                <source
                    type="image/webp"
                    srcset="assets-new/images/web3-development/game-development.webp" />

                <source
                    type="image/png"
                    srcset="assets-new/images/web3-development/game-development.png" />

                <img loading="lazy"
                    data-src="assets-new/images/web3-development/game-development.png"
                    class="img-fluid"
                    alt="game development"
                    width="70"
                    height="70" />
              </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Open to Upgrade Pre-existing platform</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                    We not only develop decentralized platforms from scratch but also extend our service in upgrading pre-existing solutions. Integrate advanced technology, bring in decentralized properties, and upgrade to a much better platform with transparency, accessibility, and security.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
              <picture>
                <source
                    type="image/webp"
                    srcset="assets-new/images/web3-development/marketing.webp" />

                <source
                    type="image/png"
                    srcset="assets-new/images/web3-development/marketing.png" />

                <img loading="lazy"
                    data-src="assets-new/images/web3-development/marketing.png"
                    class="img-fluid"
                    alt="Marketing"
                    width="70"
                    height="70" />
              </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Single-Hand Verified Contracts</h3>
                </div>
              </div>
              <div class="casestudy_para border-0">
                <p class="text-justify">
                    All our platforms are integrated with self-executable smart contracts that are single-handed verified codes. At our blockchain development company in USA, we ensure the contracts are uniquely coded, tested, and verified to perform its particular task.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
  <!-- Why Choose End -->

  <!-- Start -->
  <section class="section-service-main p-0">
    <div class="meta-expert">
      <div class="violet-vector"></div>
      <div class="orange-vector"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="our_experts">
              <h2><span>Excel above your League with our expertise </span>  in developing Decentralised solutions with our Blockchain development company in USA.</h2>
              <div class="text-center my-4">
                <a class="our_expert_talk" href="https://www.blockchainx.tech/contact/">Contact Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
    <!-- End -->
    <div class="section-service-alt" #about>
      <div class="container">
        <div class="row dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/interplanetary-file-system-blockchain.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/interplanetary-file-system-blockchain.png" />

                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/interplanetary-file-system-blockchain.png"
                  width="400"
                  height="400"
                  class="img-fluid"
                  alt="IPFS Blockchain Development Services"
                  title="IPFS Blockchain Development Services" />
              </picture>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">
                Premier Tokenization Services in USA
              </h3>
              <p class="text-justify">
                Our premier token development services include NFT tokenization which itself covers a wider group. From art, videos, covers, GIFs, gaming attributes, in-game assets, real estate, and more. Eventually, we also extend our support with cryptocurrency tokenization, Real-world tokenization, and many more. As a blockchain development company in USA, with our proficient knowledge and experience with tokenization, we facilitate you with custom build, unique solutions on the choice of your network.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="section-service-main">
      <div class="container">
        <div class="row reverse dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">Top ICO Services Provider in the USA</h3>
              <p class="text-justify">
                As a Top ICO service provider, through our Blockchain development company facilitate services that include ICO token development, ICO platform services, ICO platform for token sale, and more, bringing more investors. As funding plays a vital role in any business, our ICO service provider shall be the best fit to develop and upgrade. These ICO platforms are customized and effective in terms of bringing better investments, collaborating with a pool of Investors, and eventually exploring a lot more.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/why-IPFS.png" />

                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                  width="400"
                  height="400"
                  class="img-fluid"
                  alt="Why IPFS Development Services"
                  title="Why IPFS Development Services" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- End -->

  <!-- Start -->
  <div class="section-service-alt">
    <div class="container">
      <div class="row dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                width="300"
                height="300"
                class="img-fluid"
                alt="How IPFS Works"
                title="How IPFS Works" />
            </picture>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Cutting-edge DApps Development Company in USA</h3>
            <p class="text-justify">
                Integrating high-tech solutions, with new-gen advancements, on your blockchain platform, making it a robust platform. Our tailored Dapp built here at our blockchain development company in USA is feature-rich, highly competent, and can stand out in the global market. With our proficient team, we also customize and upgrade your platform into a functional, trusted, and secured space to carry out your required operations, seamlessly and confidently.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="section-service-main">
    <div class="container">
      <div class="call-to-main">
          <div class="orange-vector"></div>
        <div class="row justify-content-center mb-5">
          <div class="col-lg-10">
            <div class="metaverse_heading">
              <h3><span>Lead Your Sector,</span> Avail Our Blockchain Development Services </h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 text-center">
            <!-- <p class="mb-sm-4 mb-3">
              The process of converting an asset’s ownership rights into digital
              tokens on a blockchain network is Asset tokenization. On the other
              hand, the process of selling tokens in the marketplace to raise funds
              for a particular project or business is a Token sale.
            </p> -->
            <p class="mb-5">
                Take your business hassle-free in Blockchain which is more secure and efficient.
            </p>
            <div class="token-vs-btn">
              <a href="https://wa.me/7708889555" class="our_expert_talk"
                target="_blank"
                rel="noopener noreferrer">Contact to Upgrade Now</a>
            </div>
          </div>
        </div>
      </div>

    </div>

  </section>

    <!-- End -->
    <div class="section-service-alt">
      <div class="container">
        <div class="row reverse dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">Expert Smart Contracts Development in USA</h3>
              <p class="text-justify">
                Add layers of security with smart contract development from Our blockchain development company in USA. We provide you with a highly capable team with the ability to understand your requirement, and code smart contracts that function for it. And ensures the process is automated. We also test, review generate report on smart contracts and provide you with the best fine tuned ones.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/why-IPFS.png" />

                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                  width="400"
                  height="400"
                  class="img-fluid"
                  alt="Why IPFS Development Services"
                  title="Why IPFS Development Services" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-service-main">
      <div class="container">
        <div class="row dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />

                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                  width="300"
                  height="300"
                  class="img-fluid"
                  alt="How IPFS Works"
                  title="How IPFS Works" />
              </picture>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">Advanced Layer 1 and Layer 2 Blockchain Solutions in USA</h3>
              <p class="text-justify">
                Layer 1 and Layer 2 blockchains are brought to reduce network overcrowding. By opting us for your layer 1 and 2 blockchains development you get to build a whole new ecosystem on the decentralized networks and ensure there is seamless flow and progress in your operation without any disturbance. This has the ability to captivate attention among the global audience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  <!-- Start -->
  <div class="section-service-alt">
    <div class="container">
      <div class="row reverse dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Top Cryptocurrency Service Providers in USA</h3>
            <p class="text-justify">
                As a cryptocurrency service provider, our services expand from Token development and token upgradation for versatile needs. We are expertise in stable coin development, governing token development, upgrading V1 token to a better V2 and do much more. If you are looking to develop your crypto currency , we got you covered with our customized, cryptocurrency development in USA and ensure they attain great value and contribute to your business effectively.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/why-IPFS.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                width="400"
                height="400"
                class="img-fluid"
                alt="Why IPFS Development Services"
                title="Why IPFS Development Services" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-service-main">
    <div class="container">
      <div class="row dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                width="300"
                height="300"
                class="img-fluid"
                alt="How IPFS Works"
                title="How IPFS Works" />
            </picture>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Hire Blockchain Development Specialists From USA</h3>
            <p class="text-justify">
                With 7 plus years of Experience in blockchain development, we have a versatile team of developers with hands-on-training and experience in curating effective blockchain solutions for different niches. As a blockchain development company in the USA, we are also very keen about ensuring your blockchain solution meet your requirements, meets your needs and effectively contributes to the progress of your operations. If you're looking for blockchain developers in USA, we got you covered.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="section-service-alt">
    <div class="container">
      <div class="build-web3-solution">
        <div class="row">
          <div class="col-lg-10 mx-auto">
            <div class="title-wrap text-center">
              <h2 id="item-5"
              [ngClass]="selectedLink===5?'scrollHeading':'scrollheadingone'">
                <span>Tech Stack We Use</span>  As Blockchain Development Company In USA
                <span class="dots"></span>
              </h2>
            </div>
          </div>
        </div>
        <div class="row mb-3 ">
          <div class="col-lg-12">
            <div class="tech-stack-details ">
              <ul class="techstack d-flex flex-column flex-sm-row justify-content-center align-items-center gap-3">
                <li class="our_expert_talk tech-list" *ngFor="let techmenu of technologystack">
                  <p
                    (click)="opentechnology(techmenu)"
                    [ngClass]="{ 'actives-tech': techlisted === techmenu }"
                    >{{ techmenu }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="techlisted === 'Blockchains Platform'">
              <div class="row d-flex justify-content-center align-items-center g-4">
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>
                    <p>Ethereum</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>BNB Smart Chain</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Polygon</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Arbitrum</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Optimism</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Avalanche</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>MoonBeam</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Solana</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Hyperledger</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Corda</p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="techlisted === 'Other Platform'">
              <div class="row d-flex justify-content-center align-items-center g-4">
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>HardHat</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Solidity</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Rust</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Anchor</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Moralis</p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

    <!-- End -->
    <div class="section-service-main">
      <div class="types-of-erc">
          <div class="container">
              <div class="row">
                  <h3 class="main-sub-heading text-center pb-0">Our
                      <span>Blockchain Development</span>Solutions  are Transforming Key Industries Across the USA
                  </h3>
                  <!-- <p>We Provide Tailored Web3 Development for Any</p> -->
                   <p class="text-center">
                    Blockchain is a highly influential technology today that has proven to support almost every sector. Here are the top industries we actively extend support as a blockchain development company in UAE.
                   </p>
              </div>
              <div
                  class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 types-of-token-detail justify-content-center industry-web">
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/field.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/field.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/field.png"
                                      class="img-fluid"
                                      alt="Field"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">E-commerce</h4>
                          <p>
                            Incorporating blockchain and cryptocurrency in E-commerce, has a great amplifying effect on the progress, transactions, and security that eliminates the involvement of third parties. Therefore promoting direct sales and connects the direct seller and buyer in a transparent way.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/real-estate.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/real-estate.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/real-estate.png"
                                      class="img-fluid"
                                      alt="Real Estate"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Software development</h4>
                          <p>
                            Bringing decentralization to software development takes a swift step in amplifying security, transparency, and accountability. There is a difference in overall functioning and enables us to solve a variety of problems, while simultaneously maximizing new opportunities.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/education.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/education.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/education.png"
                                      class="img-fluid"
                                      alt="Education"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Renewable energy</h4>
                          <p>
                            Incorporating Blockchain technology in renewable energy allows the ability to easily track and navigate the resources effectively. As a blockchain development company in USA, we are infusing blockchain solutions into renewable energy to back the data and transactions of the same.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/fashion.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/fashion.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/fashion.png"
                                      class="img-fluid"
                                      alt="Fashion"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Healthcare</h4>
                          <p>
                            Dealing with a humongous amount of data, the Healthcare industry has a great deal to manage, while it also requires fast transfer to share knowledge equally. Bringing blockchain intervention, this can be elevated and made seamless.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/marketing.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/marketing.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/marketing.png"
                                      class="img-fluid"
                                      alt="Marketing"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">AI</h4>
                          <p>
                            Blockchain and AI are a perfect 21st-century duo that is completely reshaping the economy in the best way possible. We as a blockchain development company in the USA have been consistently working towards getting the vivid possibility into reality through our services.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/game-development.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/game-development.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/game-development.png"
                                      class="img-fluid"
                                      alt="Game Development"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Biotechnology</h4>
                          <p>
                            An intense research industry that requires a continuous flow of content, information, and data, which is expected to be confidential, needs to be validated. To extend all of this, Blockchain technology can step up and provide the needful.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/e-commerce.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/e-commerce.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/e-commerce.png"
                                      class="img-fluid"
                                      alt="E-commerce"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Cybersecurity</h4>
                          <p>
                            Incorporating blockchain into cyber security, there are a lot of knots that can be untied with its risk mitigation ability. Integrating with AI, IoT, and other similar comprehensive technologies, there are proven possibilities to explore.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/fintech.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/fintech.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/fintech.png"
                                      class="img-fluid"
                                      alt="FinTech"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Fintech</h4>
                          <p>
                            As a blockchain development company in USA, bringing new Fintech-based solutions is a wonderment that improves the way each of us handles money or the economy as an instrument and there is more value absorbed.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/event.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/event.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/event.png"
                                      class="img-fluid"
                                      alt="Event"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">AR/VR</h4>
                          <p>
                            With a secure, safe, transparent, and seamless environment, that can replicate the real world - is possible with Blockchain integration in AR/VR. With the ability to outshine this combination shall bring a lot of entertainment and innovation on the grounds.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/travel-tourism.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/travel-tourism.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/travel-tourism.png"
                                      class="img-fluid"
                                      alt="Travel & Tourism"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Robotics</h4>
                          <p>
                            Robotics is a part and parcel of life, and their evolution with technological advancement is wide. Eventually bringing blockchain into the scene, can bring a lot more wider opportunities in handling data, and ensuring precision in the process of Action.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/manufacturing.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/manufacturing.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/manufacturing.png"
                                      class="img-fluid"
                                      alt="Manufacturing"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">EVs</h4>
                          <p>
                            Electric vehicles are the latest buzz in the market with Blockchain collaboration, there is no more hassle in locating the nearest charge port, analyzing the travel distance, and estimating the future of the vehicle.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/manufacturing.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/manufacturing.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/manufacturing.png"
                                      class="img-fluid"
                                      alt="Manufacturing"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">EdTech</h4>
                          <p>
                            Education with technology is a popular aspect of learning, while Blockchain integration brings much more transparent, secure, and very precise and creative learning experience. We at our blockchain development company in USA extend our service for the same.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <section class="section-service-alt">
    <div class="types-of-erc text-center">
        <div class="container">
            <div class="build-web3-solution">
                <div class="row justify-content-center mb-3">
                    <div class="col-lg-10">
                        <h3 class="main-sub-heading text-center">Lead Your Sector With Our
                            <span>Blockchain Development Services In USA</span>
                        </h3>
                    </div>
                </div>
                <p>
                    Grab your opportunity and break the barriers, kickstart with our blockchain Development services that contribute towards growth and progress!
                </p>
                <div class="discuss-link">
                    <a href="/contact"><span>Book your Call</span>
                        <img loading="lazy"
                                            src="assets-new/images/right-arrow.png"
                                            alt="Arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="!isDarkmode" />
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow-dark.png"
                                            alt="Arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="isDarkmode" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-service-main">
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-10 mx-auto">
          <div class="whys_section mb-0 text-center">
            <h2 class="text-center pb-0 mb-3">
              Client <span> Success Stories </span>
            </h2>
            <p>
              Catch up on our client's success stories at BlockchainX. We have
              worked with various clients and on various projects in blockchain
              platform development. And here are some of the top projects in which
              we were involved.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="client-success">
            <owl-carousel-o [options]="clientSuccess">
              <ng-template carouselSlide>
                <div class="row pb-lg-3 pb-0">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Farmsent</h3>
                      <p class="pb-lg-3 pb-0">
                        Agriculture-based blockchain business solution. Connecting
                        farmers and customers directly benefits both in terms of
                        quality, price, and profit. Farmsent drives the
                        agriculture sector to the forefront with transparency and
                        agility.
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <!-- <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/farmsent.webp"
                      />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/farmsent.png"
                      />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/farmsent.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Farmsent"
                        title="Farmsent"

                      />
                    </picture> -->

                    <picture>
                      <source
                        srcset="
                        assets-new/images/home-v2/farmsent-400.webp 400w,
                        assets-new/images/home-v2/farmsent-600.webp 600w,
                        assets-new/images/home-v2/farmsent-800.webp 800w,
                        assets-new/images/home-v2/farmsent-1000.webp 1000w,
                        assets-new/images/home-v2/farmsent-1200.webp 1200w,
                        assets-new/images/home-v2/farmsent-1400.webp 1400w,
                        assets-new/images/home-v2/farmsent-1600.webp 1600w,
                        assets-new/images/home-v2/farmsent-1800.webp 1800w,
                        assets-new/images/home-v2/farmsent-2000.webp 2000w"
                        sizes="(min-width: 800px) 400px, 95vw"
                        type="image/webp" />
                      <source
                        srcset="
                        assets-new/images/home-v2/farmsent-400.png 400w,
                        assets-new/images/home-v2/farmsent-600.png 600w,
                        assets-new/images/home-v2/farmsent-800.png 800w,
                        assets-new/images/home-v2/farmsent-1000.png 1000w,
                        assets-new/images/home-v2/farmsent-1200.png 1200w,
                        assets-new/images/home-v2/farmsent-1400.png 1400w,
                        assets-new/images/home-v2/farmsent-1600.png 1600w,
                        assets-new/images/home-v2/farmsent-1800.png 1800w,
                        assets-new/images/home-v2/farmsent-2000.png 2000w"
                        sizes="(min-width: 800px) 400px, 95vw"
                        type="image/png" />
                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/farmsent-400.png"
                        class="img-fluid pb-md-0 pb-4"
                        width="400"
                        height="237"
                        alt="Farmsent"
                        title="Farmsent" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Real World NFT</h3>
                      <p class="pb-lg-3 pb-0">
                        Tokenizing real-world assets into NFTs is a trending
                        project. With the perks of transparency, fractional
                        ownership, and multiple revenue streaming options, there
                        is high traction in the market among investors and owners.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  data-src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  data-src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/real-world.webp" />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/real-world.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/real-world.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Real world"
                        title="Real world" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Layer One X</h3>
                      <p class="pb-lg-3 pb-0">
                        A community for developers to interact with fellow
                        developers, project holders, and investors. The platform's
                        ability to actively connect and interact drives the
                        community with consistent engagement.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  data-src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  data-src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/l1x.webp" />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/l1x.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/l1x.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Layer One X"
                        title="Layer One X" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Elfin Games</h3>
                      <p class="pb-lg-3 pb-0">
                        A hub of web3 games for users to explore and expand the
                        Play to Earn economy.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  data-src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  data-src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/elfin.webp" />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/elfin.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/elfin.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Elfin Games"
                        title="Elfin Games" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">BOBNet</h3>
                      <p class="pb-lg-3 pb-0">
                        Meme coin market intelligence helps users identify trading
                        trends and maximize their profits eventually.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/bobnet.webp" />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/bobnet.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/bobnet.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="BOBNet"
                        title="BOBNet" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Big Time Coin</h3>
                      <p class="pb-lg-3 pb-0">
                        The real estate tokenization platform benefits investors,
                        rentals, users, and the entire community with different
                        modules of operations.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/big-time.webp" />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/big-time.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/big-time.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Big Time Coin"
                        title="Big Time Coin" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Ekta</h3>
                      <p class="pb-lg-3 pb-0">
                        A decentralized ecosystem built with the ability to
                        perform everything and anything. Being a blockchain
                        itself, it renders and supports DAO and has its own
                        marketplace and token to interact with.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/ekta.webp" />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/ekta.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/ekta.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Ekta"
                        title="Ekta" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">StarCard Sports Games</h3>
                      <p class="pb-lg-3 pb-0">
                        A play-to-earn decentralized gaming arena was developed to
                        connect real-world players through digital gaming. This
                        game is based on football, along with metaverse abilities
                        that draw income.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/starcard.webp" />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/starcard.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/starcard.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="StarCard Sports Games"
                        title="StarCard Sports Games" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Bounty Temple</h3>
                      <p class="pb-lg-3 pb-0">
                        The play-to-earn gaming community for gaming enthusiasts
                        to explore the endless possibilities of the web3 arena,
                        providing opportunities to monetize and maximize benefits.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/bounty.webp" />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/bounty.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/bounty.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Bounty Temple"
                        title="Bounty Temple" />
                    </picture>
                  </div>
                </div>
              </ng-template>
              <ng-template carouselSlide>
                <div class="row">
                  <div class="col-lg-6 col-md-6 order-md-first order-last">
                    <div class="client-details">
                      <h3 class="main-sub-heading">Zukiverse</h3>
                      <p class="pb-lg-3 pb-0">
                        Zuki is a P2E gaming platform that is a community-oriented
                        space that builds characters, virtual goods, puzzle games,
                        quizzes, and metaverse structures all in one.
                      </p>
                      <!-- <div class="discuss-link">
                        <a [routerLink]="['/contact']">
                          <span>View Case Study</span>
                          <img loading="lazy"
                                  src="assets-new/images/right-arrow.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="isDarkmode"
                                />
                                <img loading="lazy"
                                  src="assets-new/images/right-arrow-dark.png"
                                  alt="Arrow"
                                  class="img-fluid"
                                  width="50"
                                  height="15"
                                  *ngIf="!isDarkmode"
                                />
                        </a>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <picture>
                      <source
                        type="image/webp"
                        srcset="assets-new/images/home-v2/zuki.webp" />

                      <source
                        type="image/png"
                        srcset="assets-new/images/home-v2/zuki.png" />

                      <img loading="lazy"
                        data-src="assets-new/images/home-v2/zuki.png"
                        width="579"
                        height="343"
                        class="img-fluid pb-md-0 pb-4"
                        alt="Zukiverse"
                        title="Zukiverse" />
                    </picture>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </section>

<section class="section-service-alt">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mb-5">
        <div class="c-animation">
          <div class="common-sub-heading sub-title">
            Principles <span class="dots">.</span>
          </div>
          <h3 class="main-sub-heading">
            Our Holistic Approach As<span> Blockchain Development Company in USA</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="green-vector"></div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Open to Ideate and <span class="d-block">get in-depth</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
                Ideation is the key to understanding your requirements and we, as a blockchain development company in USA, are open for discussing and formulating solutions like never before.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Customized solution with <span class="d-block"> flexibility</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
                Based on your need, our customization will be contributed in each layer of curation, ensuring to bring your dream solution to life. The infrastructure of the platform is assembled with such precision.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Keen about technology and<span class="d-block">  integration </span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
                The team of technophiles is curious and passionate about learning and implementing new technologies and getting them in hands with effective use. As a blockchain development company in USA we provide advanced integration based on your needs.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Efficient solutions <span class="d-block">Developed on Time</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
                We don't compromise in our blockchain development services and ensure to provide robust solutions that are handed over on time. Post the integration, we undergo a testing phase to ensure the platform can execute the right solution
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['dao-development-company']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Friendly Team Open To <span class="d-block">cater to your needs</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
                Our team of young professionals and Blockchain Developers have hands-on experience providing blockchain development services in the USA. Post the testnet launch, we support the main launch, upgrade, and maintenance as well.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main">
  <div class="container">
    <div class="contact-overall d-flex flex-column gap-lg-5 gap-4 rounded-3">
      <div class="commontext_wrap">
        <h2 class="text-start">Meet our COO Yokesh Sankar, Expert Blockchain Consultant</h2>
        <p>Here is our extremely skilled professional Blockchain expert, Mr.Yokesh Shankar the COO of BlockchainX. You can now book a slot and have a direct one-on-one consultation with him by</p>
      </div>

      <div class="author-detail d-flex justify-content-md-between justify-content-center align-items-center flex-md-nowrap flex-wrap gap-md-0 gap-4">
        <div class="author-imgs d-flex align-items-center justify-content-center gap-lg-5 gap-4 flex-wrap">
          <div>
            <img loading="lazy"
              src="assets-new/images/blog/yokesh-shankar.svg"
              alt="blog author"
              width="150"
              height="150" class="img-fluid rounded-circle"/>
          </div>
          <div class="para-text">
            <p class="text-sm-start text-center"><span class="d-block fw-semibold">Yokesh Sankar</span>
            <span class="d-block fw-semibold"><a href="https://www.yokesh.tech/" target="_blank" class="py-2"
                  rel="noopener noreferrer">Co - Founder and COO</a></span>
           <span class="d-block fw-semibold"><a href="https://www.yokesh.tech/" target="_blank" class="pb-2"
                  rel="noopener noreferrer">Blockchain Consultant</a></span></p>
            <a href="mailto:contact@blockchainx.tech" target="_blank"
              rel="noopener noreferrer">contact</a>
          </div>
        </div>
        <div class="discuss-link pt-0">
          <a [routerLink]="['/contact']">
            <span>Connect for a Call</span>
            <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
          </a>
        </div>
      </div>
      <div>
        <h2 class="text-start">Hire Our Blockchain Developer In USA</h2>
        <p>
            We have efficient and professionally skilled Blockchain developers in house, and if you are looking for a skilled blockchain developer, with hands-on experience and who is creative with blockchain development, we have you covered. <a class="hire-link" href="https://www.blockchainx.tech/contact/" target="_blank">Get in Touch with us now</a>
        </p>
      </div>
    </div>
  </div>
</section>

  <!-- why-choose-section -->
    <!-- End -->

  <!-- FAQ Start -->
  <section class="section-service-alt">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              FAQ <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionEx">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    How Can Blockchain Benefit My Business?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        There are no restrictions that blockchain supports only a particular business. It has branched out to almost every sector possible and has gained great support in all. There irrespective of the sector you belong to or operate, Blockchain will be a great addition to your operation, because of its transparency, accuracy, and immutable nature.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    How can I start blockchain development?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        To start with blockchain development for your business, or to support any operations, my suggestion would be to kick-start your journey now with us, the best blockchain development company in USA.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    Can I create my own blockchain?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        Yes, yes that is very much possible, and here we have discussed how you can start and navigate your blockchain network to action. Contact us
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour">
                    What is the cost to build a blockchain?
                  </button>
                </div>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        The cost of blockchain development is subjective, completely based on the requirements, specializations, additional inputs efficiency, and more.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive">
                    What is the Future of Blockchain Technology?
                  </button>
                </div>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        The future of blockchain technology seems to be promising, innovative, and highly interactive, as its ecosystem is structured in a manner that can benefit the users and maximize revenue and income through the same.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix">
                    What are the different Industries that benefited from Blockchain?
                  </button>
                </div>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                        Industries include, healthcare, education, manufacturing, fintech, agriculture, green resources, gaming, finance, real estate, and much more are benefitted from blockchain.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
</section>
  <!-- FAQ End -->

  <section class="section-service-main">
    <app-blog></app-blog>
    </section>

  <!-- Contact Start -->
  <section class="section-service-alt">
    <app-get-in-touch></app-get-in-touch>
  </section>
  <!-- Contact End -->
    <!-- Contact End -->
