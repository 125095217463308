<section class="banners-section">

  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span>
                  <strong
                    >Cross-chain Bridge Smart Contract Development
                  </strong>
                  Services
                </span>
              </h1>
              <p>
                Go cross-chain with your DeFi project and allow users to send
                your tokens across independent blockchains with Bridge smart
                contracts.
              </p>
              <!-- <div class="erc-link app-links">
                <a href="https://bridge.blockchainx.tech/" target="_blank"
                rel="noopener noreferrer">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/play-store-icon2.webp"
                    />
                    <source
                      type="image/png"
                      srcset="assets-new/images/play-store-icon2.png"
                    />
                    <img loading="lazy"
                      data-src="assets-new/images/play-store-icon2.png"
                      class="img-fluid"
                      width="150"
                      height="50"
                      alt="Link-arrow"
                      title="Link-arrow"

                    />
                  </picture>
                </a>
              </div> -->
              <div class="erc-link">
                <div class="content-link">
                  <a
                    href="https://t.me/sparkouttechblockchainx"
                    target="_blank"
                rel="noopener noreferrer"
                  >
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/bridge-smart-contract/webp/cross-chain-bridge-smart-contract-development-services.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/bridge-smart-contract/cross-chain-bridge-smart-contract-development-services.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/bridge-smart-contract/cross-chain-bridge-smart-contract-development-services.png"
                width="350"
                height="350"
                class="img-fluid"
                alt="bridge-smart-contract-development"
                title="bridge-smart-contract-development"

              />
            </picture>
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center" (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp"
            />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png"
            />
            <img loading="lazy"

              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon"
            />
          </picture>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- Banner Ends -->
<section class="section-service-alt about-section-clients">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>
          Our<span>Clients</span>
        </h3>
      </div>
    </div>
    <div class="row client-carousel">
      <div class="col-lg-12 mx-auto">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/layer-onex.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/layer-onex.png" width="233" height="108" class="img-fluid" alt="Layer OneX" title="Layer OneX" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/farmsent.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/farmsent.png" width="150" height="60" class="img-fluid" alt="Farmsent" title="Farmsent" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/real-world.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/real-world.png" width="150" height="60" class="img-fluid" alt="Realworld" title="Realworld" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/archax.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/archax.png" width="233" height="108" class="img-fluid" alt="Archax" title="Archax" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/neighbourhoods.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/neighbourhoods.png" width="233" height="108" class="img-fluid" alt="Neighbourhoods" title="neighbourhoods" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/alpha-omega-coin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/alpha-omega-coin.png" width="233" height="108" class="img-fluid" alt="Alpha Omega Coin" title="Alpha Omega Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/bountytemple.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/bountytemple.png" width="233" height="108" class="img-fluid" alt="Bounty Temple" title="Bounty Temple" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wonkly.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wonkly.png" width="233" height="108" class="img-fluid" alt="Wonkly" title="Wonkly" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/elfin-metaverse.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/elfin-metaverse.png" width="233" height="108" class="img-fluid" alt="Elfin Metaverse" title="Elfin Metaverse" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/akita.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/akita.png" width="233" height="108" class="img-fluid" alt="Akita" title="Akita" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/sunwavecoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/sunwavecoin.png" width="233" height="108" class="img-fluid" alt="Sunwave Coin" title="Sunwave Coin" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/transgenie.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/transgenie.png" width="233" height="108" class="img-fluid" alt="Transgenie" title="Transgenie" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/special-metalx.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/special-metalx.png" width="182" height="40" class="img-fluid" alt="Special Metal" title="Special Metal" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/ekta.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/ekta.png" width="155" height="72" class="img-fluid" alt="Ekta" title="Ekta" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/wafercoin.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/wafercoin.png" width="auto" height="auto" class="img-fluid" alt="Wafercoin" title="Wafercoin" />
              </picture>
            </div>
          </ng-template>

          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/reflex-finance.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/reflex-finance.png" width="auto" height="auto" class="img-fluid" alt="Reflex finance" title="Reflex finance" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/zuki.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/zuki.png" width="auto" height="auto" class="img-fluid" alt="Zuki" title="Zuki" />
              </picture>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="ourclients">
              <picture>
                <source type="image/webp" srcset="assets-new/images/home/clients/polkadog.webp" />
                <img loading="lazy" data-src="assets-new/images/home/clients/polkadog.png" width="auto" height="auto" class="img-fluid" alt="Polkadog" title="Polkadog" />
              </picture>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Product About Section -->
<section>
  <div class="section-service-main" #about>
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>
            What is the need for a <span> Cross-Chain Bridge Platform?</span>
          </h3>
        </div>
      </div>
      <div class="row align-items-center product-about-detail">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center uni-clone">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/bridge-smart-contract/webp/bridge-platform-for-your-token.webp
              "
            />

            <source
              type="image/png"
              srcset="
                assets-new/images/bridge-smart-contract/bridge-platform-for-your-token.png
              "
            />

            <img loading="lazy"
              data-src="assets-new/images/bridge-smart-contract/bridge-platform-for-your-token.png"
              width="350"
              height="350"
              class="img-fluid"
              alt="bridge-smart-contract"
              title="bridge-smart-contract"

            />
          </picture>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <p>
            What if users that use other chains want to invest in your tokens?
            Catering to a wide range of users is now a bit easier, all you need
            to do is go cross-chain. Bridge smart contract development allows
            you to create cross-chain DeFi projects that any user can use with
            low gas fees. It also provides greater reach of assets, better user
            experience, and maximum liquidity for dApps.
          </p>

          <div class="content-link">
            <a [routerLink]="['/contact']">
              <span>Read More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product About Section -->
<!-- Product List Feature -->
<section>
  <div class="section-service-alt">
    <div class="container">
      <div class="metaverse_heading mx-auto pb-3">
        <h3>Our Demo<span> Bridge Smart Contract</span> Platform</h3>
      </div>
      <div class="row metmb-3 video-section-top">
        <div class="col-lg-5">
          <div class="metaverse_subheading">
            <h3 class="video-heading">Cross Chain Bridge Dapp Demo</h3>
            <ul class="demo-list">
              <li><i class="bi bi-check-lg"></i> Instant Swap</li>
              <li><i class="bi bi-check-lg"></i> Multichain asset bridge</li>
              <li><i class="bi bi-check-lg"></i> Transaction History</li>
              <li>
                <i class="bi bi-check-lg"></i> Wallets supported - Metamask &
                Wallet Connect
              </li>
            </ul>

            <div class="content-link">
              <a href="https://bridge.blockchainx.tech/" target="_blank"
                rel="noopener noreferrer">
                <span>View Demo</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-7 orders-1 video-meta-right">
          <div class="video_right_side">
            <a href="https://bridge.blockchainx.tech/" target="_blank"
                rel="noopener noreferrer">
              <picture>
                <source
                  type="image/webp"
                  srcset="
                    assets-new/images/product/webp/brdige-smart-contract-demo.webp
                  "
                />

                <source
                  type="image/png"
                  srcset="
                    assets-new/images/product/brdige-smart-contract-demo.png
                  "
                />

                <img loading="lazy"
                  data-src="assets-new/images/product/brdige-smart-contract-demo.png"
                  width="732"
                  height="431"
                  class="img-fluid"
                  alt="brdige-smart-contract-demo"
                  title="brdige-smart-contract-demo"

                />
              </picture>
            </a>
          </div>
        </div>
      </div>
      <div class="row align-items-end mt-4 pt-4 mt-lg-5 pt-lg-5">
        <div class="col-lg-7 orders-2 video-meta-left">
          <div class="video_left_side">
            <iframe
              class="video-demo"
              src="https://www.youtube.com/embed/5-3NnxFjgQw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-lg-5 orders-1 video-left">
          <div class="metaverse_subheading">
            <h3 class="video-heading">
              Multi Chain Bridge Development Demo Video
            </h3>
            <ul class="demo-list">
              <li>
                <i class="bi bi-check-lg"></i> Admin liquidity pools keys stored
                securely in AWS Secrets
              </li>
              <li>
                <i class="bi bi-check-lg"></i> Supports All EVM based
                Blockchains
              </li>
              <li>
                <i class="bi bi-check-lg"></i> Completely Decentralized - Smart
                Contracts
              </li>
              <li>
                <i class="bi bi-check-lg"></i> Customizable & 100% source codes
              </li>
            </ul>
            <div class="content-link">
              <a href="https://bridge.blockchainx.tech/" target="_blank"
                rel="noopener noreferrer">
                <span>View Demo</span>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product List Feature -->
<!-- Product About Section -->
<section>
  <div class="section-service-main">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3>
            Advantages of <span>Multichain and Cross Chain Token Project</span>
          </h3>
        </div>
      </div>
      <div
        class="row align-items-center product-about-detail"
        id="product-abot-det"
      >
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <p>
            Cross-chain projects allow users to send their tokens across
            multiple blockchain. Whether it's the highly secure Ethereum
            blockchain, or the highly affordable Binance smart chain, our bridge
            platform is the perfect solution for you.
          </p>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center uni-clone">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/bridge-smart-contract/webp/advantages-of-multichain-and-cross-chain-token-project.webp
              "
            />

            <source
              type="image/png"
              srcset="
                assets-new/images/bridge-smart-contract/advantages-of-multichain-and-cross-chain-token-project.png
              "
            />

            <img loading="lazy"
              data-src="assets-new/images/bridge-smart-contract/advantages-of-multichain-and-cross-chain-token-project.png"
              width="350"
              height="350"
              class="img-fluid"
              alt="bridge-smart-contract"
              title="bridge-smart-contract"

            />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product About Section -->

<!-- Product Features -->
<section>
  <div class="section-service-alt">
    <div class="container">
      <div class="row">
        <div class="metaverse_heading mx-auto">
          <h3><span>Workflow</span> Process</h3>
        </div>
        <p class="py-3 text-center product-para">
          The workflow of our Bridge Smart Contract development process is very
          systematic and user-friendly.
        </p>
      </div>
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="row produt-feature-detail g-3">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
              <div class="product-detail-com">
                <div class="product-feature-image">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/bridge-smart-contract/webp/requirement-gathering.webp
                      "
                    />

                    <source
                      type="image/png"
                      srcset="
                        assets-new/images/bridge-smart-contract/requirement-gathering.png
                      "
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/bridge-smart-contract/requirement-gathering.png"
                      width="110"
                      height="126"
                      class="img-fluid py-2"
                      alt="Requirement-gathering"

                      title="Requirement-gathering"
                    />
                  </picture>
                </div>
                <h4 class="common-sub-heading-alt">Gathering Information</h4>
                <p>
                  Our Bridge smart contract development process starts with
                  gathering information to build the top-end cross-chain project
                  you are aiming for.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
              <div class="product-detail-com">
                <div class="product-feature-image">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/bridge-smart-contract/webp/dev-team-allocation.webp
                      "
                    />

                    <source
                      type="image/png"
                      srcset="
                        assets-new/images/bridge-smart-contract/dev-team-allocation.png
                      "
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/bridge-smart-contract/dev-team-allocation.png"
                      width="110"
                      height="126"
                      class="img-fluid py-2"
                      alt="Dev-team allocation"
                      title="Dev-team allocation"

                    />
                  </picture>
                </div>
                <h4 class="common-sub-heading-alt">Team Allotment</h4>
                <p>
                  We allocate a dedicated team for you including a development
                  team, testing team, project manager, and business development
                  executive.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
              <div class="product-detail-com">
                <div class="product-feature-image">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/bridge-smart-contract/webp/token-development.webp
                      "
                    />

                    <source
                      type="image/png"
                      srcset="
                        assets-new/images/bridge-smart-contract/token-development.png
                      "
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/bridge-smart-contract/token-development.png"
                      width="110"
                      height="126"
                      class="img-fluid py-2"
                      alt="Token-Development"
                      title="Token-Development"

                    />
                  </picture>
                </div>
                <h4 class="common-sub-heading-alt">Token Development</h4>
                <p>
                  We develop a smart contract for your new token on the new
                  blockchain network, with all gas optimizations done for
                  seamless transactions.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
              <div class="product-detail-com">
                <div class="product-feature-image">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/bridge-smart-contract/webp/smart-contract-writing.webp
                      "
                    />

                    <source
                      type="image/png"
                      srcset="
                        assets-new/images/bridge-smart-contract/smart-contract-writing.png
                      "
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/bridge-smart-contract/smart-contract-writing.png"
                      width="110"
                      height="126"
                      class="img-fluid py-2"
                      alt="Smart-contract writing"
                      title="Smart-contract writing"

                    />
                  </picture>
                </div>
                <h4 class="common-sub-heading-alt">
                  Smart Contract Development
                </h4>
                <p>
                  Our expert developers write the bridge smart contract for your
                  project with a highly gas-optimized smart contract design.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
              <div class="product-detail-com">
                <div class="product-feature-image">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/bridge-smart-contract/webp/front-end-development.webp
                      "
                    />

                    <source
                      type="image/png"
                      srcset="
                        assets-new/images/bridge-smart-contract/front-end-development.png
                      "
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/bridge-smart-contract/front-end-development.png"
                      width="110"
                      height="126"
                      class="img-fluid py-2"
                      alt="Front-end development"
                      title="Front-end development"

                    />
                  </picture>
                </div>
                <h4 class="common-sub-heading-alt">Front-end Design</h4>
                <p>
                  Our front-end developing experts work to build the front-end
                  portion of your bridge platform where your users will interact
                  using their wallets.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
              <div class="product-detail-com">
                <div class="product-feature-image">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/bridge-smart-contract/webp/smart-contact-deployment.webp
                      "
                    />

                    <source
                      type="image/png"
                      srcset="
                        assets-new/images/bridge-smart-contract/smart-contact-deployment.png
                      "
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/bridge-smart-contract/smart-contact-deployment.png"
                      width="110"
                      height="126"
                      class="img-fluid py-2"
                      alt="Smart-contact deployment"
                      title="Smart-contact deployment"

                    />
                  </picture>
                </div>
                <h4 class="common-sub-heading-alt">Smart Contact Deployment</h4>
                <p>
                  The final step of our bridge smart contract is smart contract
                  deployment, which creates a smart contract on the blockchain
                  network by sending a transaction to the network.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
              <div class="product-detail-com">
                <div class="product-feature-image">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/bridge-smart-contract/webp/token-mainnet-deployment.webp
                      "
                    />

                    <source
                      type="image/png"
                      srcset="
                        assets-new/images/bridge-smart-contract/token-mainnet-deployment.png
                      "
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/bridge-smart-contract/token-mainnet-deployment.png"
                      width="110"
                      height="126"
                      class="img-fluid py-2"
                      alt="Token-mainnet deployment"
                      title="Token-mainnet deployment"

                    />
                  </picture>
                </div>
                <h4 class="common-sub-heading-alt">Token Mainnet Deployment</h4>
                <p>
                  In this process, we deploy the token smart contract on the new
                  blockchain after thorough testing, and then we integrate it
                  with the bridge.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
              <div class="product-detail-com">
                <div class="product-feature-image">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="
                        assets-new/images/bridge-smart-contract/webp/front-end-to-contract-integration.webp
                      "
                    />

                    <source
                      type="image/png"
                      srcset="
                        assets-new/images/bridge-smart-contract/front-end-to-contract-integration.png
                      "
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/bridge-smart-contract/front-end-to-contract-integration.png"
                      width="110"
                      height="126"
                      class="img-fluid py-2"
                      alt="Front-end to contract-integration"
                      title="Front-end to contract-integration"

                    />
                  </picture>
                </div>
                <h4 class="common-sub-heading-alt">
                  Integrating Front-end & Contract
                </h4>
                <p>
                  After the contract deployment, our experts securely integrate
                  your smart contract with the front end, where everything comes
                  to reality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Features -->
<!-- Types of Ethereum Token -->
<section class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>Bridge Blockchain </span>Networks we Work on</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-erc-comm">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/bridge-smart-contract/webp/bsc-to-ethereum-bridge.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/bridge-smart-contract/bsc-to-ethereum-bridge.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/bridge-smart-contract/bsc-to-ethereum-bridge.png"
                width="76"
                height="76"
                class="img-fluid"
                alt="Bsc to ethereum-bridge"
                title="Bsc to ethereum-bridge"

              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Bsc to ethereum bridge</h4>
          <p>
            It's easy for your users to send tokens seamlessly from BSC to the
            Ethereum network with our BSC to Ethereum bridge smart contract
            development platform with high security.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-erc-comm">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/bridge-smart-contract/webp/ethereum-to-bsc-bridge.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/bridge-smart-contract/ethereum-to-bsc-bridge.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/bridge-smart-contract/ethereum-to-bsc-bridge.png"
                width="76"
                height="76"
                class="img-fluid"
                alt="Ethereum-to-Bsc-bridge"
                title="Ethereum-to-Bsc-bridge"

              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Ethereum to Bsc bridge</h4>
          <p>
            Enable Ethereum users to enjoy the low transaction costs of the BSC
            network and exchange tokens with our robust bridge smart contract
            development services.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-erc-comm">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/bridge-smart-contract/webp/polkadot-bridge-to-ethereum.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/bridge-smart-contract/polkadot-bridge-to-ethereum.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/bridge-smart-contract/polkadot-bridge-to-ethereum.png"
                width="76"
                height="76"
                class="img-fluid"
                alt="Polkadot-bridge to ethereum"
                title="Polkadot-bridge to ethereum"

              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Polkadot bridge to ethereum</h4>
          <p>
            Leverage the power of multichain and build a Polkadot bridge to
            Ethereum bridge smart contract with our bridge smart contract
            development service.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-erc-comm">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/bridge-smart-contract/webp/cosmos-to-ethereum-bridge.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/bridge-smart-contract/cosmos-to-ethereum-bridge.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/bridge-smart-contract/cosmos-to-ethereum-bridge.png"
                width="76"
                height="76"
                class="img-fluid"
                alt="Cosmos to ethereum-bridge"
                title="Cosmos to ethereum-bridge"

              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Cosmos to ethereum bridge</h4>
          <p>
            Create a cross-chain project for tokens exchange with Cosmos -
            Ethereum bridge smart contract and explore the innovative world of
            Cosmos blockchain.
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-erc-comm">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/bridge-smart-contract/webp/ethereum-to-matic-bridge.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/bridge-smart-contract/ethereum-to-matic-bridge.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/bridge-smart-contract/ethereum-to-matic-bridge.png"
                width="76"
                height="76"
                class="img-fluid"
                alt="Ethereum to matic-bridge"
                title="Ethereum to matic-bridge"

              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Ethereum to matic bridge</h4>
          <p>
            Access the Matic's low gas costs and the security of the Ethereum
            blockchain with our Bridge smart contract development service
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-erc-comm">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                type="image/webp"
                srcset="
                  assets-new/images/bridge-smart-contract/webp/ethereum-to-cardano-bridge.webp
                "
              />

              <source
                type="image/png"
                srcset="
                  assets-new/images/bridge-smart-contract/ethereum-to-cardano-bridge.png
                "
              />

              <img loading="lazy"
                data-src="assets-new/images/bridge-smart-contract/ethereum-to-cardano-bridge.png"
                width="76"
                height="76"
                class="img-fluid"
                alt="Ethereum-to-cardano-bridge"
                title="Ethereum-to-cardano-bridge"

              />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Ethereum to cardano bridge</h4>
          <p>
            Take a step into the future with Cardano and send your ETH tokens
            across chains using bridge smart contract development.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Types of Ethereum Token -->
<section class="section-service-alt">
<app-blog></app-blog>
</section>
<!-- <section class="section-service-alt-padd">
  <app-contactform></app-contactform>
</section> -->
<section class="section-service-alt">
  <app-get-in-touch></app-get-in-touch>
</section>
