<section class="blog-detail">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 mb-4">
          <div class="blog-single-wrap">
            <picture>
              <source type="image/webp" srcset="assets-new/images/blog/create-solana-meme/create-solana-meme-coin.webp">
              <img loading="lazy" src="assets-new/images/blog/create-solana-meme/create-solana-meme-coin.jpg" alt="Create your own Solana meme coin" title="Create your own Solana meme coin" class="img-fluid" width="856" height="522"/>
            </picture>
            <h1>7 Steps To Create Your Solana Meme Coin  : Detailed Guide</h1>
            <div class="h4">
              Listen to the Audio :
            </div>
            <div class="w-100">
              <audio controls >
                  <source src="assets-new/images/blog/create-solana-meme/create-solana-meme-coin.mp3" type="audio/mpeg">
              </audio>
            </div>
            <p>With its volatile nature, Meme coin for its trend and engagement has caught the interest of many developers to create Solana meme coin. With a $6.01B market cap, Solana meme coin development has a massive audience, value, and hype.</p>
            <p>While that is happening, are you looking to create your Solana meme coin? Here is a detailed guide to assist you with the same.</p>
            <p>Let's dig in!</p>

            <h2 id="item-1" [ngClass]="selectedLink===1?'scrollHeading':'scrollheadingone'">What is a meme coin?</h2>
            <p>Meme coins are one of the most profitable investments that in recent times have had great traction. Came to the scene as a fun and engaging crypto asset, yes meme coins are a type of cryptocurrency with a humorous twist to engage the community. These meme coins are inspired by the concept of memes, characters, and trends in the market, highly influenced by social media.</p>
            <p>They can create enthusiasm and hype bring traction in a very organic and sustainable manner for the project. While its abilities are evolving based on the hype that the market holds for it.  Over the past couple of years, meme coin development has been a buzz, and the emergence of meme coins in DEX has taken its incubation to new parameters, inventing opportunities to thrive across the network and ecosystem with additional capabilities. </p>
            <p>This has also brought many to create their meme coin on different networks, while <a href="https://www.blockchainx.tech/solana-meme-coin-development/" target="_blank"> Solana meme coin development</a> is getting popular for its attributes.</p>

            <h2 id="item-2" [ngClass]="selectedLink===2?'scrollHeading':'scrollheadingone'">What is the Solana meme coin?</h2>
            <p>Solana is a potential challenger for Ethereum, even though it's been longer in the market. The transactional speed and cost involved in gas is a highlighting feature for Solana making it stand on top of the pedestal. Choosing to create a Solana meme coin can be your chance to upgrade with the trend and bounce on the crypto market, unlike others.</p>
            <p>The invasion of Solana meme coin development in the crypto space has unveiled several phenomena of the market and things revolving around the crypto space. Meme coin gaining significant hype in the market for its humorous and fun engaging attributes, it also looks for anything new. Solana is here to unleash additional potential as it works with twisted twig processing technical capabilities and fun, it also fosters a strong sense of community bonding.</p>

            <h2 id="item-3" [ngClass]="selectedLink===3?'scrollHeading':'scrollheadingone'">Consensus Mechanism Of Solana</h2>
            <p>The unique aspect of the Solana blockchain is its consensus mechanism which stands out from the rest. A combination of Proof-of-stake and Proof-of-work - Proof-of History (PoH) is a time-based algorithm that creates a historical record of activities on the blockchain. Here the tree structure is used to determine the analogy enabling the nodes to quickly complete the transaction. Solana blockchain utilizes Rust more than Solidity, the programming language is more convenient for developers.</p>

            <h2 id="item-4" [ngClass]="selectedLink===4?'scrollHeading':'scrollheadingone'">Solana Meme coins vs. Other Meme coins</h2>
            <p>Meme coins in general possess common attributes and are designed to perform defined tasks based on the smart contracts. If we have to compare the Solana meme coin with another meme coin, here are a few factors explained on behalf of Solana to prove meme coin development on Solana is best, while it's open to you to make wild choices yourself.</p>

            <p class="d-flex gap-2">
                <span>1.</span>
                <span>To create a token on Solana is comparatively less, while most of the other platforms' gas fees are more based on their transaction efficiency.</span>
            </p>
            <p class="d-flex  gap-2">
                <span>2.</span>
                <span>The transactions on Solana are lightning-fast, and other networks so far haven't met the metric concerning this meter.</span>
            </p>
            <p class="d-flex  gap-2">
                <span>3.</span>
                <span>With a booming economy around Solana, the ecosystem is also nurturing and building a good rapport in the community. Eventually, they have also been supportive and at the same time growing.</span>
            </p>
            <p class="d-flex  gap-2">
                <span>4.</span>
                <span>Memes being a powerful marketing tool, the more attractive it is the more traction and buzz it could create, so far Solana has had buzzing tokens compared to the others.</span>
            </p>
            <p class="d-flex  gap-2">
                <span>5.</span>
                <span>As new concepts are explored, Solana is a platform provider for working out on that innovation, the current protocol of Solana is open to Defi integrations, utility, and more, while the other network meme coins still haven't had that opportunity completely.</span>
            </p>


            <h2 id="item-5" [ngClass]="selectedLink===5?'scrollHeading':'scrollheadingone'">7 Steps To Create your own Solana meme coin</h2>
            <p>Here we have given a detailed guide on the steps to create your Solana Meme coin in just 7 steps. As the process involves different layers it's important to pay keen attention to each of the processes to build the most efficient Solana meme coin at a cost-effective price.</p>

            <picture>
              <source type="image/webp" srcset="assets-new/images/blog/create-solana-meme/own-solana-meme-coin.webp">
              <img loading="lazy" src="assets-new/images/blog/create-solana-meme/own-solana-meme-coin.jpg" alt="Steps To Create your own Solana meme coin" title="Steps To Create your own Solana meme coin" class="img-fluid" width="840" height="613"/>
            </picture>

            <h4>1. Understanding the Requirements</h4>
            <p class="pb-2 ps-4">It's important to start with understanding the goal of initiating Solana Meme coin development. There are different outcomes like Fundraising, community rewards, fun, or anything else. Based on the need the toke prep can happen.</p>
            <p class="pb-2 ps-4">Proceeding to this, we will also have to understand the market value, market expectation, demand, and technology that have been influential in the sector. This helps in making our planning for the Solana meme coin more relatable.</p>
            <p class="pb-2 ps-4">It's also important to decide with the purpose and vision of the token, how its engagements will be in the community. Based on that the ideal working of the Solana meme coin developed can be decided.</p>
            <p class="pb-2 ps-4">We also ensure that the basic and functional features of the token, its tokenomics, and integration choice are all jotted down.</p>

            <h4>2. Building The Technical Foundation</h4>
            <p class="pb-2 ps-4">As we are to create your meme coin on the Solana network it's important to learn or at least have a basic idea of the RUST programming language. This will help drive conversation and allow you to collaborate with developers seamlessly. </p>
            <p class="pb-2 ps-4">Solana CLI, Rust, and Anchor, are a few popular frameworks for Solana, so it is important to have them handy.</p>

            <h4>3. Start With Solana Meme Coin Development</h4>
            <p class="pb-2 ps-4">The actual process to start the Solana meme coin is here. Starting with smart contract coding, which will be the governing agent of the token, where the token properties, and execution tasks are all fed.</p>
            <p class="pb-2 ps-4">The name, symbol, supply of the token and the rules and regulations that the token has to follow are all defined with key precision and clarity here.</p>
            <p class="pb-2 ps-4">Once the coding is done for the Solana meme coin you create, deploy it on the Solana testnet, where the token can run to ensure its functionalities are rightly met.</p>
            <p class="pb-2 ps-4">This test can help in identifying the potential risk and rectifying it before it launches in the mainnet.</p>

            <h4>4. Deploying The Meme Coin On Mainnet </h4>
            <p class="pb-2 ps-4">The Solana meme coin is all set to go for its main launch after the testnet verification, which involves interacting with the blockchain to get your meme coin launched. </p>
            <p class="pb-2 ps-4">It is also better to get your smart contract audited to be on the safer side ensuring security and functionality. While this is an important step to build trust and safeguard from vulnerabilities.</p>

            <h4>5. Wallet Integration</h4>
            <p class="pb-2 ps-4">Create your Solana wallet to interact with your tokens, which also helps in managing the meme coins efficiently.</p>
            <p class="pb-2 ps-4">It's important to ensure there is enough SOL, the native coin of Solana, to pay the transaction fee and other charges that come by.</p>

            <h4>6. Marketing and Distribution planning </h4>
            <p class="pb-2 ps-4">Going forward, plan out your token distribution, either be it through airdrops, sales, for initial investors, or as a reward.</p>
            <p class="pb-2 ps-4">Eventually get your community active on social media, discussion forums, online platforms, and elsewhere, because it's important to drive awareness and interest over the meme coin for better reach and traffic. </p>
            <p class="pb-2 ps-4">By implementing marketing and partnership for your Solana meme coin there are immense opportunities and traffic that your Solana meme coin boosts visibility.</p>

            <h4>7. Regular Monitoring for Upgrade</h4>
            <p class="pb-2 ps-4">It's important to regularly track the performance of your mem coin despite the launch. This will help in understanding the performance and planning the future accordingly. </p>
            <p class="pb-4 ps-4">Collecting, addressing, and acting upon the feedback is very important for the meme coin you create, contributing towards long-term success eventually. </p>

            <p>These are simple yet intricate 7 steps involved in Solana Meme coin development, which ensures the token is efficient and has a great performance in the market.</p>

            <h2 id="item-6" [ngClass]="selectedLink===6?'scrollHeading':'scrollheadingone'">Why create meme coins on Solana?</h2>
            <p>The technical nuances of Solana blockchain superior to other Blockchain networks, make it a primary choice for <a href="https://www.blockchainx.tech/meme-coin-development/" target="_blank"> meme coin development</a> in the market. On the contrary, the other network in the market does face a lot of issues with traffic overloading the ecosystem, higher transaction fees, and gas fees, while Solana can charge lower gas fees and process transactions at a considerably quicker rate ensuring efficiency. </p>
            <p>Most of the meme coins are floated on Solana for its exceptional support provided to developers, low cost for entering the market, and of course, its consensus mechanism which is Proof of History, while the perks are availed based on the history and not on other parameters.</p>
            <p>Go forward, the minimized yet strong entry levels maximize the network speed and contribute towards the scalability of the token. Here in the case of meme coin developed on Solana, being highly volatile the performance of the token can swing in any direction, while the network's support is standard and reliable. </p>
            <p>Eventually ensuring to complete the transaction on time contributing to the growth and success of the token. While meme coins rest on low-value, massive transactions, which have to be cost-effective and paramount, - Solana appears to be the significant choice for all developers, traders, and investors. </p>

            <h2 id="item-7" [ngClass]="selectedLink===7?'scrollHeading':'scrollheadingone'">Benefits of Solana Meme Coin Development</h2>
            <p>Meme coins come with the potential to surprise and shock you, if you are someone who wants to create the possibility of taking a risk and winning over it, investment in Solana meme coin can be the best choice, while here we share a few benefits of Solana meme coin development.</p>

            <picture>
              <source type="image/webp" srcset="assets-new/images/blog/create-solana-meme/benefits-solana-meme-coin.webp">
              <img loading="lazy" src="assets-new/images/blog/create-solana-meme/benefits-solana-meme-coin.jpg" alt="Benefits of Solana Meme Coin Development" title="Benefits of Solana Meme Coin Development" class="img-fluid" width="840" height="613"/>
            </picture>

            <h4>1. High Potential Returns </h4>
            <p>The track record has proven an example of significant growth in the value of the meme coin. Meme coins like Dogecoin, Shina Inu, and more have seen a rocketry shift in their growth at a very constrained time, and that is almost possible for your token as well. </p>

            <h4>2. Entry at a Low cost </h4>
            <p>At an affordable price, the investor can easily enter the market of Solana meme coins. This allows investors to make comparatively bigger purchases at low investments. Therefore opening up to a much more broader audience.</p>

            <h4>3. Community support and Engagement</h4>
            <p>There is a strong community backing the token, with enthusiastic and active communities being the core driver of demand and support, this contributes to better traffic for their meme coin and gains popularity in the global market with the right social media and marketing strategy.</p>

            <h4>4. Hyped Market With speculation</h4>
            <p>With Solana meme coin development, the opportunity to seize short-term gains is easy. As the meme coin is itself highly speculative it benefits from the trends and hype created in the market. This buzz can be your strategy to yield effective returns for investors who can place their token purchase and sale understanding the market nuances.</p>

            <h4>5. Media Attention and Awareness</h4>
            <p>For a successful meme coin, the coverage in the media is very much provided, while to gain that traction towards your, with perfect marketing and niche it is possible. Eventually, investor traction increases in the market for the meme coins and the buzz created compliments in the growth of the same.</p>

            <h4>6. Quick Adoption</h4>
            <p>As meme coin itself has a great crowd to draw attention to, the rate of adoption and reach among the investors for a new or existing meme coin is potentially quick if there is hype around it.  Eventually as early adopters, the mem coin holders also gain considerable rewards and perks.</p>

            <h4>7. Considerable Market Barriers</h4>
            <p>Accessing meme coins in the market is considerably easy, and the entry barriers are fewer. While Solana meme coins can be listed on popular exchanges, trading platforms, and other accessible spaces for better reach which is also not hard while there are not many barriers to intermediaries.</p>

            <h4>8. Community Centric Initiations</h4>
            <p>The rewards and experience are all based on community member participation, which eventually also contributes to the growth of the project which also includes staking tokens, rewards, airdrops, content access, and more.</p>

            <h4>9. Innovative Experience</h4>
            <p>The more unique, creative, and innovative a token concept is, it's also important to be resonant and relatable for the audience. Most of the meme coins do meet that parameter and gain traction effectively among investors.</p>



            <h2 id="item-8" [ngClass]="selectedLink===8?'scrollHeading':'scrollheadingone'">What features should be present in the Solana-based Meme coin?</h2>
            <p>Bringing to you the most insisted features to incorporate when you create your Solana meme coin and launch it in the market for better captivation and reach. </p>
            <p><span class="fw-bold">Relevant Theme:</span> The foremost aspect that has to be ticked in the checklist is the Resonating theme. To gather traction among wider people. To make a wise choice here is very important. When the theme is more popular and relatable it connects and captivates. </p>
            <p><span class="fw-bold">Defined Use Cases:</span> it's eventually better to define the use case of the Solana Meme coin you are developing. On a wider captivating lens, the token adopting real-world asset tokenization concept is more often seen to be received with a positive thrive.  </p>
            <p><span class="fw-bold">Rust Development:</span> Rust-based Solana meme coin development ensures the project is smoothly carried on the platform while the operations are easily carried away by the contracts on the network. </p>
            <p><span class="fw-bold">Multi-layered Security: </span> Bringing in multi-channel security when creating your Solana meme coin can guard your token with high-end protocol protecting the token from threats, rugs, and malicious acts.</p>
            <p><span class="fw-bold">Tokenomics: </span> It's important to get your tokenomic right, which includes understanding minting opportunities, quantity caps, coin burning, functionalities, etc. that are all fed and automated to the smart contract for easy execution.  </p>
            <p><span class="fw-bold">Instant Minting Options: </span> By providing an instant Solana meme coin minting option, by allowing multiple transactions quickly and fast.  </p>
            <p><span class="fw-bold">Lesser Gas Fee:  </span> Eventually, Solana has comparatively lesser gas fees Solana makes the meme coin launch smooth. </p>
            <p><span class="fw-bold">Fast Transactions: </span>  With improved transaction speed, the Solana network ensures the completion of transactions quickly and improves user experience on DEX. </p>


            <h2 id="item-9" [ngClass]="selectedLink===9?'scrollHeading':'scrollheadingone'">Top 10 Solana Meme Coins IN 2024 as per Market Cap</h2>
            <picture>
              <source type="image/webp" srcset="assets-new/images/blog/create-solana-meme/market-cap.webp">
              <img loading="lazy" src="assets-new/images/blog/create-solana-meme/market-cap.jpg" alt="Top 10 Solana Meme Coins in 2024 as per Market Cap" title="Top 10 Solana Meme Coins in 2024 as per Market Cap" class="img-fluid" width="840" height="613"/>
            </picture>

            <h2 id="item-10" [ngClass]="selectedLink===10?'scrollHeading':'scrollheadingone'">Is this Solana Meme Coin a Good Investment For Retirement?</h2>
            <p>If you are wondering if Solana meme coin Development is a good retirement investment, here are a few pointers on how the token's future is for you to assess long-term investments.</p>

            <h4>Growth Potential</h4>
            <p class="ps-4">1. Their popularity and the concept around the Solana meme coins ensure a major possibility in the global market. The early adopters of meme coins have earned substantial returns in the market.</p>
            <p class="ps-4">2. On the other hand with respect to community-based growth, the ability to interact, and stay on top of trends, engagements, and social media hype, altogether drives the value of the token. </p>

            <h4>Solana’s Ecosystem</h4>
            <p class="ps-4">1. The Solana network itself has a high throughput at a low fee, where the transaction speed is comparatively high for a lower fee there is evident efficiency that attracts more investors. </p>
            <p class="ps-4">2. As the Solana network expands to support more Decentralized Applications, projects, etc., you can create a very progressive environment for memes and flourish. </p>

            <h4>Diverse opportunities</h4>
            <p class="ps-4">1. Adding Solana meme coins into your investment portfolio with others creates a great opportunity for a versatile portfolio. This also gives space to balance out traditional investment drawbacks. And improve the portfolio performance. </p>
            <p class="ps-4">2. Solana meme coin development is also an innovative investment choice representing a modern investment tool that aligns with trends, and technology. </p>

            <h4>Accessibility and Participation </h4>
            <p class="ps-4">1. With an active community around meme coins, the support system you gain along with in-depth information is massive. Investing is an enjoyable, beneficial choice to keep up with the trends and developments happening. </p>
            <p class="ps-4">2. The mem coin also provides an opportunity to potentially stake tokens, where they can earn rewards in return for it, like an additional income source for investors.  </p>

            <h4>Community Interactions </h4>
            <p class="ps-4">1. Solana meme coin is very accessible and at the same time, it is also very easy to invest in, including beginners. With a supportive platform and exchange it's easy and quick to be part of the community.  </p>
            <p class="ps-4">2. Eventually, the initial investment in tokens is relatively low, attracting many investors and creating their chance to invest in crypto. </p>

            <p>Solana meme coin being a fun investment eventually, has the potential to lucratively add on to your retirement investment strategy. Its growth potential is on high radars, along with the fact of developing meme coins on the Solana blockchain, having a vibrant community, and diversified, innovative, and benefiting opportunities to explore.  But it's important to do in-and-out research from your end to make better and wise decisions when entering into crypto for long-term investments. </p>
            <p>However, now it's open to you to take a call if Solana Meme coin development i the right choice for retirement investments.</p>

            <h2 id="item-11" [ngClass]="selectedLink===11?'scrollHeading':'scrollheadingone'">Why choose BlockchainX for Solana meme coin development? </h2>
            <p>These couple of years the evolution of meme coin has carved the economic growth of cryptocurrency with a massive impact. Its elements of humor and community-driven potential with significant returns are now even expanded to earning utility, and governance. While choosing to develop Solana Meme coin with an amazing developer and effective marketing strategy, reduces the risk, volatility, and scams that it could build around.</p>
            <p>With the ability to be exciting and enable potentially profitable options in the crypto market, Solana meme coin development is a promising choice. Choosing to do it with our esteemed team of developers at <a href="https://www.blockchainx.tech/" target="_blank"> BlockchainX</a> can give you a completely stress-free experience, while catering to your needs, ensuring custom-build solutions for your requirements, and meeting the desired outcomes. Our equipped team has hands-on experience in developing various crypto projects and has been a part of many successful platforms and solutions in the 7 years of our journey. </p>
            <p>With the potential to globally attract investors, and community and benefit the token owner for their unique trait,  Solana meme coin development is a perfect choice that is effective, efficient, and customizable. What you want more for, now that you know how to create your Solana meme coin, get started to innovate your dynamic web3 world, with us!</p>
          </div>
          <app-social></app-social>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="blog-right-side table-content">
            <div class="table-content-main">
              <div class="metaverse_heading">
                <h3 class="pb-0"><span>Table of Contents</span></h3>
              </div>
              <div class="categories_list_view table-contens">
                <ul>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-1" (click)="addClass(1)">What is a meme coin?</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-2" (click)="addClass(2)">What is the Solana meme coin?</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-3" (click)="addClass(3)">Consensus Mechanism Of Solana</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-4" (click)="addClass(4)">Solana Meme coins vs. Other Meme coins</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-5" (click)="addClass(5)">7 Steps To Create your own Solana meme coin</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-6" (click)="addClass(6)">Why create meme coins on Solana?</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-7" (click)="addClass(7)">Benefits of Solana Meme Coin Development</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-8" (click)="addClass(8)">What features should be present in the Solana-based Meme coin?</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-9" (click)="addClass(9)">Top 10 Solana Meme CoinS IN 2024 aS per Market Cap</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-10" (click)="addClass(10)">Is this Solana Meme Coin a Good Investment For Retirement?</a>
                  </li>
                  <li>
                    <a href="https://www.blockchainx.tech/create-solana-meme-coin/#item-11" (click)="addClass(11)">Why choose BlockchainX for Solana meme coin development?</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="blog-author-wrap">
              <div class="blog-author-detail">
                <div>
                  <img src="assets-new/images/blog/yokesh-shankar.svg" alt="blog author" title="About Author" width="150" height="150"/>
                </div>
                <div>
                  <div class="metaverse_heading">
                    <h4>
                      <span>About Author</span>
                    </h4>
                  </div>
                  <h5>Yokesh Sankar</h5>
                  <div class="author-social-connect">
                    <ul>
                      <li>
                        <a href="https://www.instagram.com/yokesh.sankar/" target="_blank" class="fs-5" rel="noopener noreferrer" alt="instagram" aria-label="Instagram">
                          <i class="bi bi-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://in.linkedin.com/in/yokeshsankar" target="_blank" class="fs-5" rel="noopener noreferrer" alt="Linkedin" aria-label="Linkedin">
                          <i class="bi bi-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/yokesh.shankar" target="_blank" class="fs-5" rel="noopener noreferrer" alt="facebook" aria-label="facebook">
                          <i class="bi bi-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/yokesh_sankar_v" target="_blank" class="fs-5" rel="noopener noreferrer" alt="Twitter" aria-label="twitter">
                          <i class="bi bi-twitter-x"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>I am Yokesh Sankar. Co-Founder of BlockchainX, My vision and time tooffer the best products for our clients without apprehensions ateconomic rates.</p>
            </div>
            <div class="social-shares">
              <div>
                <a href="https://twitter.com/Blockchainxtech" target="_blank" rel="noopener noreferrer">Follow us on Twitter<span class="mx-2 cyan-300"><i class="bi bi-twitter-x color cyan-300"></i></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-lg-8 col-md-12 col-sm-12 col-12">
          <app-comments></app-comments>
        </div>
      </div> -->
    </div>
  </section>
  <app-blog></app-blog>
  <section class="section-service-alt-main">
    <app-get-in-touch></app-get-in-touch>
  </section>
