<div class="contact_detail">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <div class="contact_head">
          <p class="h-3">Let's Talk</p>
        </div>
      </div>
      <div class="col-lg-9 main-contact-form">
        <div class="getintouch getintochform  bitrix-snippet-form">
          <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
          <div class="content-link crm-form-contact">
            <a [routerLink]="['/contact']">
              <span>Contact Us</span>
              <img
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode"
                  />
                  <img
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode"
                  />
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
