<form id="quickcontactForm" [formGroup]="contactmail" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12">
      <div class="form-floating mb-4">
        <input type="text" class="form-control" id="floatingInput" formControlName="name"
          placeholder="Name" [ngClass]="{
            'is-invalid': submitted && f['name'].errors
          }" />
        <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
          <div *ngIf="f['name'].errors['required']"> Name is required </div>
        </div>
        <label for="floatingInput" class="px-0">Name <span class="red">*</span></label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-floating mb-4">
        <input type="email" class="form-control" id="floatingemail" placeholder="Email"
          formControlName="email" [ngClass]="{
            'is-invalid': submitted && f['email'].errors
          }" />
        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
          <div *ngIf="f['email'].errors['required']"> Email is required </div>
          <div *ngIf="f['email'].errors['email']"> Email must be a valid email address </div>
        </div>
        <label for="floatingemail" class="px-0">Email <span class="red">*</span></label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-floating mb-4">
        <input type="text" class="form-control" id="floatingWhatsapp" formControlName="whatsapp_telegram"
          placeholder="Whatsapp"  [ngClass]="{
            'is-invalid': submitted && f['whatsapp_telegram'].errors
          }" />
        <div *ngIf="submitted && f['whatsapp_telegram'].errors" class="invalid-feedback">
          <div *ngIf="f['whatsapp_telegram'].errors['required']"> Whatsapp/Telegram Id is required </div>
        </div>
        <label for="floatingWhatsapp" class="px-0">Whatsapp/Telegram Id</label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-floating mb-4">
        <input type="text" class="form-control" id="floatingCountry" formControlName="country"
          placeholder="Country"  [ngClass]="{
            'is-invalid': submitted && f['country'].errors
          }" />
        <div *ngIf="submitted && f['country'].errors" class="invalid-feedback">
          <div *ngIf="f['country'].errors['required']"> Country is required </div>
        </div>
        <label for="floatingCountry" class="px-0">Country</label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-floating mb-4">
        <input type="text" class="form-control" placeholder="Tell us about your project" id="floatingTextarea2"
          formControlName="tell_us_about_your_project" [ngClass]="{
            'is-invalid': submitted && f['tell_us_about_your_project'].errors
          }">
        <div *ngIf="submitted && f['tell_us_about_your_project'].errors" class="invalid-feedback">
          <div *ngIf="f['tell_us_about_your_project'].errors['required']"> Message is required </div>
        </div>
        <label for="floatingTextarea2" class="px-0">Tell us about your project <span class="red">*</span></label>
      </div>
    </div>
    <div class="col-12">
      <div class="position-relative mb-4">
        <select id="floatingEnquiry" name="enquiry_type" class="form-control enquiry-type" formControlName="enquiry_type"
        [ngClass]="{
          'is-invalid': submitted && f['enquiry_type'].errors
        }" >
          <option value="">Enquiry Type<span class="red">*</span></option>
          <option value="Business Enquiry">Business Enquiry</option>
          <option value="Partnership">Partnership</option>
          <option value="Career Opportunities">Career Opportunities</option>
          <option value="Event/Media Enquiry">Event/Media Enquiry</option>
          <option value="Investment">Investment</option>
          <option value="I want to sell a service">I want to sell a service</option>
        </select>
        <div *ngIf="submitted && f['enquiry_type'].errors" class="invalid-feedback">
          <div *ngIf="f['enquiry_type'].errors['required']">Please select Enquiry Type</div>
        </div>
      </div>

      <!-- <div class="form-group custom-select" (click)="toggleDropdown()">
        <div class="selected">{{ selectedOption || 'Choose an option' }}</div>
        <ul class="options" *ngIf="isDropdownOpen">
          <li *ngFor="let option of options" 
              (click)="selectOption(option, $event)"
              [ngClass]="{'selected-option': option === selectedOption}">
            {{ option }}
          </li>
        </ul>
        <div *ngIf="submitted && !selectedOption" class="invalid-feedback">
          Please select an enquiry type
        </div>
      </div> -->
      
      

      
    </div>
    <div class="col-12">
      <button type="submit" class="b24-form-btn">Send</button>
    </div>
  </div>
</form>
