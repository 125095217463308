<section class="banners-section" #about>

  <div class="banner_wrapper">
    <div class="container" id="common-relative">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>BRC20 Token</strong></span> Development Platform
              </h1>
              <p>
                Empower your crypto journey with the BRC20 Token Development
                Platform ensuring the projects surge up with value and progress
                in a certain period with our expertise in curation.
              </p>

              <div class="contact-link">
                <a [routerLink]="['/contact']" class="home-link">Talk To Our
                  Experts</a>
                <a [routerLink]="['/case-studies']" class="home-link-alt">View
                  Case Study</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <picture *ngIf="!isDarkmode">
              <source type="image/webp" srcset="assets-new/images/brc20-token/brc20-token.webp" />
              <source type="image/png" srcset="assets-new/images/brc20-token/brc20-token.png" />
              <img loading="lazy"  data-src="assets-new/images/brc20-token/brc20-token.png" width="500" height="500" class="img-fluid"  alt="BRC20 Token Development Platform" title="BRC20 Token Development Platform" />
            </picture>
            <picture *ngIf="isDarkmode">
              <source type="image/webp" srcset="assets-new/images/brc20-token/light-theme/brc20-token.webp" />
              <source type="image/png" srcset="assets-new/images/brc20-token/light-theme/brc20-token.png" />
              <img loading="lazy"  data-src="assets-new/images/brc20-token/light-theme/brc20-token.png" width="500" height="500" class="img-fluid"  alt="BRC20 Token Development Platform" title="BRC20 Token Development Platform" />
            </picture>
          </div>
        </div>

      </div>
      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- What is ERC20 start-->
<section class="section-service-alt">
  <div class="container">
    <div class="metaverse_heading">
      <h3 class="pb-lg-5 pb-4"><span>What Is BRC20</span> Token
        Development?</h3>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-4 text-center mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/token-images/why-should-you-choose.webp">
            <source type="image/png"
              srcset="assets-new/images/token-images/why-should-you-choose.png">
            <img loading="lazy"
              data-src="assets-new/images/token-images/why-should-you-choose.png"
              width="350" height="350"
              alt="Asset-backed Tokenization Development Service"
              title="Asset-backed Tokenization Development Service"
              class="BSC-Tokens-image img-fluid">
          </picture>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <p>
            Level up your Bitcoin-based project with the BRC20 token, utilizing
            the PoW mechanism. BRC20 token development, unlike other networks,
            utilizes data inscription via ordinals instead of the smart
            contract. A sleek, robust, and safe method to be secured from data
            breaches. With its compatibility, fungibility, and security, you can
            confidently step up in the global market. BRC20 Token Development
            Solutions are
          </p>
        </div>

        <div class="row token d-flex">
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span>
                Custom-developed based on your projects
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Highly
                efficient tokens with innate compatibility
              </li>
            </ul>
          </div>
          <div class="col-md-6 projects">
            <ul>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Leveraging
                on new gen technology with experts
              </li>
              <li>
                <span><i class="bi bi-check-circle-fill"></i></span> Open to
                ideate and innovate better at every stage!
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- End -->

<!-- Popular Ganming List Start -->
<div class="section-service-main">
  <div class="tending-bep-20">
    <div class="container">
      <div class="row trending-token-list">
        <div class="col-lg-6 text-center order-lg-first order-last">
          <picture>
            <source type="image/webp"
              srcset="assets-new/images/erc/trending-erc20-token-list.webp" />
            <source type="image/png"
              srcset="assets-new/images/erc/trending-erc20-token-list.png" />
            <img loading="lazy"
              data-src="assets-new/images/erc/trending-erc20-token-list.png"
              width="405" height="405" alt="Asset Backed Token"
              title="Asset Backed Token" class="img-fluid mx-auto">
          </picture>
        </div>
        <div class="col-lg-6 mb-lg-0 mb-4">
          <h3 class="main-sub-heading text-start"><span>Successful BRC20 Token
            </span> Development Solution In The Market</h3>
          <div class="row trending-bep">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span><a
                    href="https://coinmarketcap.com/currencies/ordi/"
                    target="_blank"
                    rel="noopener noreferrer">ORDI</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://coinmarketcap.com/currencies/sats/"
                    target="_blank"
                    rel="noopener noreferrer">SATs</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://coinmarketcap.com/currencies/multibit/"
                    target="_blank"
                    rel="noopener noreferrer">MultiBit</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 p-0">
              <ul>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://coinmarketcap.com/currencies/lever/"
                    target="_blank"
                    rel="noopener noreferrer">LeverFi</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://coinmarketcap.com/currencies/ordiswap/"
                    target="_blank"
                    rel="noopener noreferrer">Ordiswap</a>
                </li>
                <li>
                  <span><i class="bi bi-hexagon-fill"></i></span> <a
                    href="https://coinmarketcap.com/currencies/mmss/"
                    target="_blank"
                    rel="noopener noreferrer">MMSS Ordinals</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex pt-3">
            <a [routerLink]="['/contact']" class="home-link-alt">Connect With
              Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- Related Services -->
<section class="section-service-alt-padd">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3><span>BRC20 Token Development </span>Service We Offer</h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/project-consulting.webp">
              <source type="image/png"
                srcset="assets-new/images/token images/project-consulting.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/project-consulting.png"
                width="75" height="75" alt="BRC20 Token Development"
                title="BRC20 Token Development" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">BRC20 Token Development</h4>
          <p>
            We curate highly efficient BRC20 Tokens for your project with utmost
            nuances ensuring to level your game in the crypto space with the
            bitcoin network.
          </p>
          <div class="content-link">
            <a [routerLink]="['/token-development-company']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/staking-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/staking-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/staking-platform.png"
                width="75" height="75" alt="Token Sale Dashboard"
                title="Token Sale Dashboard" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Token Sale Dashboard
          </h4>
          <p>
            Develop a Token sale dashboard for BRC20 token hosting multiple
            projects and investors in the network to be benefited and enabled.
          </p>
          <div class="content-link">
            <a [routerLink]="['/ready-made-ico-software']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/farming-platform.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/farming-platform.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/farming-platform.png"
                width="75" height="75" alt="BRC20 Staking" title="BRC20 Staking"
                class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">BRC20 Staking</h4>
          <p>
            We provide a BRC20 Staking platform for businesses and projects
            willing to stake their tokens and surge the demand for their project
            and tokens.
          </p>
          <div class="content-link">
            <a [routerLink]="['/nft-staking-platform-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/token-bridge.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/token-bridge.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/token-bridge.png"
                width="75" height="75" alt="Tokenomics Consultations"
                title="Tokenomics Consultations" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Tokenomics Consultations</h4>
          <p>
            Our experts in tokenomics provide you with the utmost interest to
            take your project or business to new heights, done with all market
            research and ideation assistance.
          </p>
          <div class="content-link">
            <a [routerLink]="['/blockchain-consulting-services']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/layer-i-blockchain.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/layer-i-blockchain.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/layer-i-blockchain.png"
                width="75" height="75" alt="Bitcoin Ordinals Marketplace"
                title="Bitcoin Ordinals Marketplace" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Bitcoin Ordinals
            Marketplace</h4>
          <p>
            Unlock the possibilities of the Bitcoin network with the Ordinals
            marketplace customized for your business and operations, ensuring
            active trade.
          </p>
          <div class="content-link">
            <a [routerLink]="['/nft-marketplace-development']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source type="image/webp"
                srcset="assets-new/images/token-images/nft-markeplace.webp">
              <source type="image/png"
                srcset="assets-new/images/token-images/nft-markeplace.png">
              <img loading="lazy"
                data-src="assets-new/images/token-images/nft-markeplace.png"
                width="75" height="75" alt="Cross Chain Bridge"
                title="Cross Chain Bridge" class="img-fluid">
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Cross Chain Bridge
          </h4>
          <p>
            To swap and exchange BRC20 tokens, we provide cross-chain bridging
            services to make your token cross-chain in your desired networks.
          </p>
          <div class="content-link">
            <a [routerLink]="['/']">
              <span>View More</span>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- Related Services -->

<!-- why-choose-section -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="orange-vector"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 metmb-3">
        <div class="whys_section mb-0">
          <h2>
            <span>BRC20 Token </span>Development Company
          </h2>
          <p class="pb-3">
            BlockchainX is a pioneer in blockchain-based development, we have
            curated 50-plus successful projects and are actively working on a
            bunch more. Our expert developers are keen to update them with
            technology and ensure they render effective, efficient, and durable
            solutions, backing your business and projects. Eventually, with the
            BRC20 token, we have the expertise to develop and launch your
            desired BRC20 token for your projects and extend it to meet your
            requirements on point.
          </p>
          <div class="whysection_text_wrap">
            <ul>
              <li>Experienced developers with hands-on experience</li>
              <li>Secured and safe development process</li>
              <li>24/7 support and maintenance</li>
              <li>Providing Efficient creative solutions</li>
            </ul>
          </div>
          <div class="content-link">
            <a [routerLink]="['/contact']">
              <!-- <span>Read More</span> -->
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                   />
              </picture>
              <picture>
                <source type="image/webp"
                  srcset="assets-new/images/right-arrow-dark.webp">
                <source type="image/png"
                  srcset="assets-new/images/right-arrow-dark.png">
                <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                  width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                   />
              </picture>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="col-lg-6 metmb-3 Why-section">
        <div class="why_right_side">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/token-images/why-choose-blockchainx.webp" />

            <source
              type="image/png"
              srcset="assets-new/images/token-images/why-choose-blockchainx.png" />

            <img loading="lazy"
              data-src="assets-new/images/token-images/why-choose-blockchainx.png"
              width="624"
              height="521"
              class="img-fluid"
              alt="BRC20 Token Development Company"
              title="BRC20 Token Development Company" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- FAQ Start -->
<section class="section-service-alt">
  <div class="container">
    <!-- Accordion Section -->
    <div class="nft-acc">
      <div class="green-vector"></div>
      <div class="violet-vector"></div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12">
          <div class="common-sub-heading">
            <h4>FAQ <span class="dots">.</span></h4>
          </div>
          <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
        </div>
      </div>
      <div class="row align-items-center justify-content-center pt-md-5 pt-4">
        <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <div class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                  What do you mean by BRC20 token?
                </button>
              </div>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Bitcoin blockchain-based experimental standard token is
                    BRC20, which has similar patterns to Ethereum ERC20. BRC20
                    tokens allow coders to build and send fungible tokens using
                    ordinal protocols.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  Difference between ERC20 and BRC20 token
                </button>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    ERC20 and BRC20 are not similar tokens, ERC20 is established
                    on the Ethereum Virtual Machine (EVM), and BRC20 on the
                    Bitcoin network. BRC20 tokens do not employ smart contracts
                    while the other does. Bitcoin wallets are required to create
                    and transfer BRC20 tokens.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  How to trade BRC20 Tokens?
                </button>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    With a Bitcoin wallet, create an account, and the users can
                    store the tokens after minting them on the blockchain and
                    trade them using ordinal protocol and data inscriptions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Accordion Section -->
  </div>
</section>
<!-- FAQ End -->

<!-- Blog Start -->
<section class="section-service-main">
  <div class="green-vector"></div>
  <div class="container">
    <div class="row blog-list">
      <div class="col-lg-12">
        <div class="commontext_subhead">
          <h3>Blog<span class="dots">.</span></h3>
        </div>
        <div class="feature_wrap">
          <div class="commontext_wrap">
            <h2>Check out our Latest Blogs </h2>
          </div>
          <div class="deploy-link">
            <a [routerLink]="['/blog']">
              <!-- <span>Read More</span> -->
              <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 left-vector">
        <owl-carousel-o [options]="blogs_details">
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                        assets-new/images/blog/ai-in-web3-gaming-and-metaverse.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/ai-in-web3-gaming-and-metaverse.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Web3 Gaming"
                    title="AI Transforming Web3 Gaming And Metaverse" />
                </picture>
                <h3>
                  AI Transforming Web3 Gaming And Metaverse
                </h3>
                <p>
                  In the 1990s, the introduction of the internet, and the
                  evolution of something beyond reality kick-started ...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="['/ai-blockchain-web3-revolutionizing-gaming-metaverse']">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/what-is-gamefi'
                  ]">
                <picture>
                  <!-- <source
                      type="image/webp"
                      srcset="
                      assets-new/images/blog/what-is-gamefi.webp
                      "
                    /> -->
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/blog/what-is-gamefi.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/blog/what-is-gamefi.jpg"
                    width="404"
                    height="246"
                    alt="What is GameFi?"
                    title="What is GameFi?" />
                </picture>
                <h3>
                  What is GameFi? Diving into the world of Blockchain Games
                </h3>
                <p>
                  From the earlier days of the video game industry, developers
                  have aimed to create worlds as powerful as real life....</p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/what-is-gamefi'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="blog_wrap">
              <a
                [routerLink]="[
                    '/blockchain-game-development'
                  ]">
                <picture>
                  <source
                    type="image/webp"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.webp
                      " />
                  <source
                    type="image/jpg"
                    srcset="
                      assets-new/images/industries/blockchain-in-gaming.jpg
                      " />
                  <img loading="lazy"
                    data-src="assets-new/images/industries/blockchain-in-gaming.jpg"
                    width="404"
                    height="246"
                    class="img-fluid"
                    alt="Blockchain Technology"
                    title=" The Potential of Blockchain Technology to Enhance Industry Profits " />
                </picture>
                <h3>
                  The Potential of Blockchain Technology to Enhance Industry
                  Profits
                </h3>
                <p>
                  Mario video games in the year 1981 became very famous among
                  game lovers by the name of Jumpman. In today’s world,...
                </p>
              </a>
              <div class="content-link">
                <a
                  [routerLink]="[
                      '/blockchain-game-development'
                    ]">
                  <!-- <span>Read More</span> -->
                  <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
                </a>
                <hr />
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Blog End -->

<!-- Contact Start -->
<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Upscale Your Business Bitcoin
                Network Token</span></h3>
            <p>Connect with us to get more insight on BRC20 Token
              Development!</p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/mail.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/whatsapp-icon.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/telegram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>
              <!-- <div class="contact-list">
                      <a href="mailto:contact@blockchainx.tech" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/mail.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/mail.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/mail.png"

                            alt="contact-mail"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Contact@blockchainx.tech</a
                      >
                    </div>

                   <div class="contact-list">
                      <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/whatsapp-icon.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/whatsapp-icon.png"

                            alt="contact-Whatsapp"
                            width="50"
                            height="50"
                            class="contact-logo img-fluid"
                          />
                        </picture>
                        Connect on Whatsapp</a
                      >
                    </div>

                    <div class="contact-list">
                      <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer">
                        <picture>
                          <source
                            type="image/webp"
                            srcset="assets-new/images/contact/webp/telegram.webp"
                          />

                          <source
                            type="image/png"
                            srcset="assets-new/images/contact/telegram.png"
                          />

                          <img loading="lazy"
                            data-src="assets-new/images/contact/telegram.png"

                            alt="contact-skype"
                            width="50"
                            height="50"
                            class="contact-logo1 img-fluid"
                          />
                        </picture>
                        Connect on Telegram</a
                      >
                    </div> -->
            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/linkdin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/instagram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/twitterx.svg" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->
