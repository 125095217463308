import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Title, Meta} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from 'src/app/shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';


@Component({
  selector: 'app-erc3643-token-development-services',
  templateUrl: './erc3643-token-development-services.component.html',
  styleUrls: ['./erc3643-token-development-services.component.css']
})
export class Erc3643TokenDevelopmentServicesComponent implements OnInit {

  selectedLink:number;

  title = 'ERC3643 Token Development Company | BlockchainX';

  technologystack = ['Blockchains Platform','Other Platform'];
  techlisted: string = 'Blockchains Platform';
  selectedlisted: any;
  viewportScroller: any;
  menulist: any;
  about: any;

  constructor(public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService:CanonicalService,
    private footservice:FooterServiceService,) {
      }

     clientSuccess: OwlOptions = {
      loop: false,
      margin: 34,
      autoplay: true,
      center: false,
      dots: true,
      autoplaySpeed: 4000,
      nav: false,
      items: 1
    };


  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });

    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Build the credibility of NFT on Ethereum with our ERC3643 Token Development Services, customized to cater to efficient performance and security. Tap to Learn more.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
       'ERC3643 RWA  Token Development, ERC3643 RWA  Token Development Company, ERC3643 RWA  Token Development Services, ERC3643 RWA Token Development Solution, ERC3643 World Asset Tokenization Services',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'ERC3643 Token Development Company | BlockchainX',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/erc3643-token-development-company/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Build the credibility of NFT on Ethereum with our ERC3643 Token Development Services, customized to cater to efficient performance and security. Tap to Learn more.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/erc3643-token-development-services/erc3643-token-development-services.webp',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/erc3643-token-development-company/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'ERC3643 Token Development Company | BlockchainX',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Build the credibility of NFT on Ethereum with our ERC3643 Token Development Services, customized to cater to efficient performance and security. Tap to Learn more.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/erc3643-token-development-services/erc3643-token-development-services.webp',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/erc3643-token-development-company/'
    );

  }
  opentechnology(techmenu: any) {
    console.log(techmenu);
    this.techlisted = techmenu;
  }
  showTab = 1;
  tabToggle(showTab: any) {
    this.showTab = showTab;
  }

  show = true;
  scroll() {
    this.viewportScroller.scrollToAnchor('travel');

    console.log('test top');
  }
  addClass(id:number){
    this.selectedLink=id;
  }
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    slideBy: 1,
    navSpeed: 500,
    // margin: 30,
    dots: false,
    center: true,
    navText: [],
    responsive: {
      0: {
        items: 1.5,
      },
      640: {
      items: 2,
      },
      740: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    nav: false,
  };
}
