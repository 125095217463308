<!-- Banner Section -->
<section class="banners-section">

  <div class="banner_wrapper">
    <div class="container" id="banner-page-vector-main">
      <div class="green-vector"></div>
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
          <div class="banner_contentWrapper">
            <div class="caption">
              <h1>
                <span><strong>Blockchain Consulting </strong> services</span>
              </h1>

              <p>
                Uncover your true ability to be in web3 and take your stand to
                be in the spotlight with expert advice and blockchain
                consultations.
              </p>
              <div class="erc-link">
                <div class="content-link">
                  <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                    rel="noopener noreferrer">
                    <span>Telegram</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
                <div class="content-link">
                  <a [routerLink]="['/contact']">
                    <span>Contact Us</span>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                         />
                    </picture>
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/right-arrow-dark.webp">
                      <source type="image/png"
                        srcset="assets-new/images/right-arrow-dark.png">
                      <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                        width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                         />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 text-center">
          <div class="banner-right-section">
            <!-- <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/service-solutions.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/service-solutions.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/service-solutions.png"
                class="img-fluid" alt="Blockchain Consulting Service"
                title="Blockchain Consulting Service" width="500"
                height="500" />
            </picture> -->
            <img loading="lazy"
                src="assets-new/images/blockchain-consulting/service-solutions.webp"
                class="img-fluid" alt="Blockchain Consulting Service"
                title="Blockchain Consulting Service" width="500"
                height="500" />
          </div>
        </div>
      </div>

      <div class="row d-lg-block d-none">
        <div class="scroll-section text-center"
          (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
          <picture>
            <source
              type="image/webp"
              srcset="assets-new/images/home/scroll.webp" />
            <source
              type="image/png"
              srcset="assets-new/images/home/scroll.png" />
            <img loading="lazy"
              data-src="assets-new/images/home/scroll.png"
              width="25"
              height="48"
              class="img-fluid"
              alt="scroll-icon"
              title="scroll-icon" />
          </picture>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- Banner Section ends -->

<!-- Why Choose DAO Platform Development start -->
<section class="section-service-alt" #about>
  <div class="container">
    <div class="row dao-Platform align-items-center">
      <div class="col-lg-5 mx-auto col-md-12 col-sm-12 col-12">
        <div class="bep-work-section">
          <picture>
            <source
              srcset="assets-new/images/blockchain-consulting/blockchain-consulting-services.webp"
              type="image/webp" />
            <source
              srcset="assets-new/images/blockchain-consulting/blockchain-consulting-services.png"
              type="image/png" />
            <img loading="lazy"
              data-src="assets-new/images/blockchain-consulting/blockchain-consulting-services.png"
              class="img-fluid" alt="Blockchain Consulting "
              title="Blockchain Consulting " width="400" height="400" />
          </picture>
        </div>
      </div>
      <div
        class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
        <div class="common-sub-heading">
          <h2 class="main-sub-heading text-start">Blockchain <span>Consulting
              Services</span></h2>
          <p>
            We bring to light your true potential and opportunity to explore
            your capabilities in the blockchain and web3 space. As BlockchainX
            consultant service provider we have hands-on experience in your
            niche development. We also make sure to give proper, relevant,
            authentic, and fruitful progress in the space. From technological
            insights to market trends, we support you in the progression you
            take.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose DAO Platform Development end -->

<!-- Product Features -->
<section class="section-service-alt-main">
  <div class="container">
    <div class="row">
      <div class="metaverse_heading mx-auto">
        <h3>Our Blockchain <span>Consulting Services</span></h3>
      </div>
    </div>
    <div class="row types-of-token-detail">
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/tactical-analysis.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/tactical-analysis.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/tactical-analysis.png"
                class="img-fluid" alt="Tactical Analysis"
                title="Tactical Analysis" width="76" height="76" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Tactical Analysis</h4>
          <p>
            By gathering your requirements and checking your objectives, we
            conduct an in-depth study to implement the applicability of
            blockchain methodology. It helps your business to higher levels.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/custom-application-development.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/custom-application-development.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/custom-application-development.png"
                class="img-fluid" alt="Custom Application"
                title="Custom Application" width="76" height="76" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Custom Application Development
          </h4>
          <p>
            Our team spots your functional and nonfunctional requirements and
            ensures to offer your customized solution that will back your
            business effectively in this time, a sustainable and effective
            solution.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/quick-poc-creation.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/quick-poc-creation.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/quick-poc-creation.png"
                class="img-fluid" alt="Quick-PoC-Creation"
                title="Quick-PoC-Creation" width="76" height="76" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">Quick POC Creation</h4>
          <p>
            Based on the goals and objectives of the business we help you build
            quick prototype ideation for the deployment and validate the
            application feasibility of the business in blockchain.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/blockchain-solution-consultancy.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/blockchain-solution-consultancy.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/blockchain-solution-consultancy.png"
                class="img-fluid" alt="Blockchain-Solution"
                title="Blockchain-Solution" width="76" height="76" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Blockchain Solution Consultancy
          </h4>
          <p>
            We also guide you through the different blockchains available in the
            market, and the best choice you can choose to support your sector
            and enjoy the perks eventually. We give you apt suggestions about
            every nuance.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/professional-training-guidance.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/professional-training-guidance.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/professional-training-guidance.png"
                class="img-fluid" alt="Professional-Training &-Guidance"
                title="Professional-Training &-Guidance" width="76"
                height="76" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">
            Professional Training & Guidance
          </h4>
          <p>
            From Tech stack to market scenario our team guides you, with
            professional training and inputs in all sectors to upscale and get
            clarity on what our actionables and how we are doing it, and for
            what.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-12 types-of-token">
        <div class="types-erc-detail">
          <div class="top-icon">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/mvp-development.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/mvp-development.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/mvp-development.png"
                class="img-fluid" alt="MVP Development" title="MVP Development"
                width="76" height="76" />
            </picture>
          </div>
          <h4 class="erc-20-head-alt text-left">MVP Development</h4>
          <p>
            We as your Blockchain consultant help you achieve your most viable
            pointers items of operational agility, we customize solutions
            focussing on maximizing the ROI with the integration of new
            technologies.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Features -->

<!-- Development Services starts -->
<section class="section-service-alt-padd">
  <div class="our-process-erc">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="metaverse_heading mx-auto">
            <h3><span>Our Comprehensive </span> Blockchain Consulting
              Process</h3>
          </div>
        </div>
      </div>

      <div class="row erc-processing">
        <div class="col-lg-4 col-md-6 col-sm-12 metmb-3">
          <div class="virtual_process cmb-5 process-image">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/ideation.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/ideation.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/ideation.png"
                class="img-fluid pb-2" alt="Ideation" title="Ideation"
                width="100" height="100" />
            </picture>
            <h4>Ideation</h4>
            <p>
              We start the process with a long brief discussion on the Project,
              your requirements, expectations, and success meter from your
              perspectives and other components.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/assessment.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/assessment.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/assessment.png"
                class="img-fluid pb-2" alt="Assessment" title="Assessment"
                width="100" height="100" />
            </picture>
            <h4>Assessment</h4>
            <p>
              Further, we do a detailed analysis of the projects, market
              situations, competitor analysis, trends analyses, and others to
              get on track with technology and updates.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 metmb-3">
          <div class="virtual_process cmb-5 process-image">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/project-elucidation.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/project-elucidation.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/project-elucidation.png"
                class="img-fluid pb-2" alt="Assessment" title="Assessment"
                width="100" height="100" />
            </picture>
            <h4>Project Elucidation</h4>
            <p>
              The next step is to form the infrastructure of the project based
              on the client request and market, we proceed from its
              confirmation.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source srcset="assets-new/images/blockchain-consulting/poc.webp"
                type="image/webp" />
              <source srcset="assets-new/images/blockchain-consulting/poc.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/poc.png"
                class="img-fluid pb-2" alt="Assessment" title="Poc" width="100"
                height="100" />
            </picture>
            <h4>POC</h4>
            <p>
              We assign Project managers and teams to get started with the
              project. The Point of Contact loops with our clients and takes
              care of communication thoroughly.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/integration.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/integration.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/integration.png"
                class="img-fluid pb-2" alt="Assessment" title="Integration"
                width="100" height="100" />
            </picture>
            <h4>Integration</h4>
            <p>
              Integrating the features, blockchain, tech stack, and all others
              get on the lane, and it's headed over to development.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 metmb-3">
          <div class="virtual_process process-image">
            <picture>
              <source
                srcset="assets-new/images/blockchain-consulting/Development.webp"
                type="image/webp" />
              <source
                srcset="assets-new/images/blockchain-consulting/Development.png"
                type="image/png" />
              <img loading="lazy"
                data-src="assets-new/images/blockchain-consulting/Development.png"
                class="img-fluid pb-2" alt="Development" title="Development"
                width="100" height="100" />
            </picture>
            <h4>Development</h4>
            <p>
              The development phase is cursing the actual project with creative
              tools and after that, it's sent to test, and after there is a
              green signal from all the sides the project goes live.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Development Services ends -->

<!-- Contact Us Start -->
<section class="section-service-main">
  <div class="container">
    <div class="contact-overall d-flex flex-column gap-lg-5 gap-4 rounded-3">
      <div class="commontext_wrap">
        <h2 class="text-start">Level-up the Power of Innovation, as a Blockchain
          consulting firm we guarantee full progression!</h2>
      </div>

      <div class="author-detail d-flex justify-content-md-between justify-content-center align-items-center flex-md-nowrap flex-wrap gap-md-0 gap-4">
        <div class="author-imgs d-flex align-items-center justify-content-center gap-lg-5 gap-4 flex-wrap">
          <div>
            <img loading="lazy"
              src="assets-new/images/blog/yokesh-shankar.svg"
              alt="blog author"
              width="150"
              height="150" class="img-fluid rounded-circle"/>
          </div>
          <div class="para-text">
            <p class="text-sm-start text-center"><span class="d-block fw-semibold">Yokesh Sankar</span>
            <span class="d-block fw-semibold"><a href="https://www.yokesh.tech/" target="_blank" class="py-2"
                  rel="noopener noreferrer">Co - Founder and COO</a></span>
           <span class="d-block fw-semibold"><a href="https://www.yokesh.tech/" target="_blank" class="pb-2"
                  rel="noopener noreferrer">Blockchain Consultant</a></span></p>
            <a href="mailto:contact@blockchainx.tech" target="_blank"
              rel="noopener noreferrer">contact&#64;blockchainx.tech</a>
          </div>
        </div>
        <div class="discuss-link pt-0">
          <a [routerLink]="['/contact']">
            <span>Contact Us</span>
            <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="link-arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<!-- Tech Stak Update -->
<section class="section-service-alt">
  <div class="container">
    <div class="metaverse_heading">
      <h2><span>Blockchain Development </span> Platforms We Work On</h2>
    </div>
    <div class="business-stak-list-section">
      <div class="row row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/frameworks.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/frameworks.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/frameworks.png"
                  width="200" height="73" class="img-fluid" alt="Frameworks"
                  title="Frameworks" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/frameworks.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/frameworks.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/frameworks.png"
                  width="200" height="73" class="img-fluid" alt="Frameworks"
                  title="Frameworks" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/caliber.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/caliber.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/caliber.png" width="185"
                  height="104" class="img-fluid" alt="Caliber"
                  title="Caliber" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/caliber.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/caliber.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/caliber.png" width="185"
                  height="54" class="img-fluid" alt="Caliber"
                  title="Caliber" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/hyperledger-composer.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/hyperledger-composer.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger-composer.png"
                  width="235" height="101" class="img-fluid" alt="Hyperledger Composer"
                  title="Hyperledger Composer" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger-composer.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger-composer.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/hyperledger-composer.png"
                  width="235" height="101" class="img-fluid" alt="Hyperledger Composer"
                  title="Hyperledger Composer" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/hyperledger-explorer.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/hyperledger-explorer.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger-explorer.png"
                  width="217" height="93" class="img-fluid"
                  alt="hyperledger-explorer" title="hyperledger-explorer" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger-explorer.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger-explorer.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/hyperledger-explorer.png"
                  width="217" height="93" class="img-fluid"
                  alt="hyperledger-explorer" title="hyperledger-explorer" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/hyperledger.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/hyperledger.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/hyperledger.png"
                  width="253" height="100" class="img-fluid" alt="hyperledger"
                  title="hyperledger" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/hyperledger.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/hyperledger.png"
                  width="253" height="100" class="img-fluid" alt="hyperledger"
                  title="hyperledger" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/sawtooth.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/sawtooth.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/sawtooth.png"
                  width="232" height="100" class="img-fluid" alt="sawtooth"
                  title="sawtooth" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/sawtooth.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/sawtooth.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/sawtooth.png"
                  width="232" height="100" class="img-fluid" alt="sawtooth"
                  title="sawtooth" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/iroha.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/iroha.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/iroha.png" width="175"
                  height="98" class="img-fluid" alt="Iroha"
                  title="Iroha" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/iroha.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/iroha.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/iroha.png" width="175"
                  height="98" class="img-fluid" alt="Iroha"
                  title="Iroha" />
              </picture>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="list-one">
            <div class="list-images">
              <picture *ngIf="!isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/fabric.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/fabric.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/fabric.png" width="174"
                  height="99" class="img-fluid" alt="Fabric" title="Fabric" />
              </picture>
              <picture *ngIf="isDarkmode">
                <source type="image/webp"
                  srcset="assets-new/images/home/stak/light-theme/fabric.webp" />
                <source type="image/png"
                  srcset="assets-new/images/home/stak/light-theme/fabric.png" />
                <img loading="lazy"
                  data-src="assets-new/images/home/stak/light-theme/fabric.png" width="174"
                  height="99" class="img-fluid" alt="Fabric" title="Fabric" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Tech Stak Update -->

<!-- Benefits -->
<section class="section-service-main">
  <div class="container">
    <div class="metaverse_heading mx-auto mb-5">
      <h2 class="mb-3">Industries We Collaborated <span>For Blockchain Business
          Consultants</span></h2>
      <p class="text-center">
        We ensure to provide end-to-end Blockchain consultation service for your
        <br>project in dynamic fields and niches.
      </p>
    </div>

    <div class="industry-points-main">
      <ul class="industry-points">
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg" alt="nft-icon"
            width="40" height="40">
          <p class="fw-normal lh-sm">NFT</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="defi-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Defi</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="exchange-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Decentralized Exchanges</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="metaverse-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Metaverse</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="gaming-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Gaming</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="supply-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Supply Chain</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="healthcare-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Healthcare</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="insurance-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Insurance</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="finance-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Finance</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="logistics-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Logistics</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="automotives-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Automotives</p>
        </li>
        <li class="industry-point">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/nft.svg"
            alt="loyalty-icon" width="40" height="40">
          <p class="fw-normal lh-sm">Loyalty Programs</p>
        </li>
      </ul>
    </div>
  </div>
</section>
<!-- Benefits -->

<section class="section-service-main">
  <div class="container">
    <div class="metaverse_heading mb-5">
      <h2><span>Different Technologies </span> We Work With</h2>
    </div>
    <div class="stack-techs stack-techs-17-items">
      <div class="stack-line-wrap flex stack-line-five">
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/hyperledger.svg"
            alt="Hyperledger-icon" width="40" height="40">
          <span>Hyperledger</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/tron.svg"
            alt="Tron-icon" width="40" height="40">
          <span>Tron</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/graphene.svg"
            alt="Graphene-icon" width="40" height="40">
          <span>Graphene</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/binance.svg"
            alt="Binance-icon" width="40" height="40">
          <span>Binance</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/solana.svg"
            alt="Solana-icon" width="40" height="40">
          <span>Solana</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/corda.svg"
            alt="Corda-icon" width="40" height="40">
          <span>Corda</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/ethereum.svg"
            alt="Ethereum-icon" width="40" height="40">
          <span>Ethereum</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/polygon.svg"
            alt="Polygon-icon" width="40" height="40">
          <span>Polygon</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/fantom.svg"
            alt="Fantom-icon" width="40" height="40">
          <span>Fantom</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/ton.svg" alt="Ton-icon"
            width="40" height="40">
          <span>Ton</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/python.svg"
            alt="Python-icon" width="40" height="40">
          <span>Python</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/solidity.svg"
            alt="Solidity-icon" width="40" height="40">
          <span>Solidity</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/net.svg" alt="Net-icon"
            width="40" height="40">
          <span>.NET</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/c.svg" alt="C-icon"
            width="40" height="40">
          <span>C++</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/c1.svg" alt="C1-icon"
            width="40" height="40">
          <span>C#</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/react.svg"
            alt="React-icon" width="40" height="40">
          <span>React.js</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/node.svg"
            alt="Node-icon" width="40" height="40">
          <span>Node.js</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/java.svg"
            alt="Java-icon" width="40" height="40">
          <span>Java</span>
        </div>
        <div class="stack-line">
          <img loading="lazy"
            src="assets-new/images/blockchain-consulting/angular.svg"
            alt="Angular-icon" width="40" height="40">
          <span>Angular</span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="why_choose_us">
  <div class="why-alt-section light-background">
    <div class="green-vector"></div>
    <div class="orange-vector"></div>
    <div class="container choosing-section">
      <div class="row align-items-center">
        <div class="col-lg-6 metmb-3">
          <div class="whys_section mb-2">
            <h2>
              BlockchainX Your Go-to <span> Blockchain Consultant </span>
            </h2>
          </div>
          <div class="whysection_text_wrap">
            <p>
              Over 7 years now, BlockchainX has successfully sailed in the web3
              space with 50 plus live successful projects and ultimately a
              leading developer and consultant of course. We have hands-on
              experience in Blockchain consultation and development where we
              deal with your projects from end-to-end and ensure prominent
              growth and reach in the market. We do intense research to bind
              your business well with technology and suggest the best-in-class
              solution for your requirements.
            </p>
            <div class="content-link my-3">
              <a [routerLink]="['/contact']">
                <!-- <span>Read More</span> -->
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="!isDarkmode"
                     />
                </picture>
                <picture>
                  <source type="image/webp"
                    srcset="assets-new/images/right-arrow-dark.webp">
                  <source type="image/png"
                    srcset="assets-new/images/right-arrow-dark.png">
                  <img loading="lazy" data-src="assets-new/images/right-arrow-dark.png" class="img-fluid"
                    width="50" height="15" alt="Link-arrow" title="Link-arrow" *ngIf="isDarkmode"
                     />
                </picture>
              </a>
              <hr />
            </div>
          </div>
        </div>
        <div class="col-lg-6 metmb-3 Why-section">
          <div class="why_right_side">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/metaverse/why_blockchain.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/metaverse/why_blockchain.png" />

              <img loading="lazy"
                data-src="assets-new/images/metaverse/why_blockchain.png"
                width="550"
                height="450"
                class="img-fluid"
                alt="why-blockchainX" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-service-main">
  <app-blog></app-blog>
</section>

<section class="section-service-alt">
  <div class="app-get-in-touch-form">
    <div class="container">
      <div class="contact-form">
        <div class="row reverse-colum">
          <div class="col-lg-5 col-md-12 contact-social-links">
            <h3 class="main-sub-heading"><span>Improvise Your Web3 Idea with
                us</span></h3>
            <p>Our expert team is professionally well-equipped with hands-on
              experience as a blockchain development company. Connect with us;
              at BlockchainX, we curate your desired sustainable solution! </p>
            <div class="contact-main-list">
              <div class="social-links">
                <a href="mailto:contact@blockchainx.tech" target="_blank"
                  rel="noopener noreferrer" class="contact-mail">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/mail.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/mail.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/mail.png"
                      alt="linkedin"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://wa.me/7708889555" target="_blank"
                  rel="noopener noreferrer" class="whatsapp">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/whatsapp-icon.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/whatsapp-icon.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/whatsapp-icon.png"
                      alt="contact-Whatsapp"
                      width="32"
                      height="32" />
                  </picture>
                </a>

                <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                  rel="noopener noreferrer" class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/telegram.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/telegram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/telegram.png"
                      alt="twitter"
                      width="40"
                      height="40" />
                  </picture>
                </a>
              </div>

            </div>
            <div class="contact-links">
              <h4>Follow us on</h4>
              <div class="social-links">
                <a
                  href="https://www.linkedin.com/company/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkdin">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/linkdin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/linkdin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/linkdin.png"
                      alt="linkedin"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://www.instagram.com/blockchainxtech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="instagram">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/instagram.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/contact/instagram.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/instagram.png"
                      alt="instagram"
                      width="22"
                      height="22" />
                  </picture>
                </a>

                <a
                  href="https://twitter.com/Blockchainxtech"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="twitter">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/contact/webp/twitterx.webp" />

                    <source
                      type="image/svg"
                      srcset="assets-new/images/contact/twitterx.svg" />

                    <img loading="lazy"
                      data-src="assets-new/images/contact/twitterx.svg"
                      alt="twitter"
                      width="25"
                      height="25" />
                  </picture>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 contact-inputs">
            <div class="getintouch getintochform bitrix-snippet-form">
              <!-- <div #crmForm></div> -->
                  <app-getintouch-form></app-getintouch-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
