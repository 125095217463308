import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute,Router } from '@angular/router';
import { CanonicalService } from '../../shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-how-to-launch-crypto-token',
  templateUrl: './how-to-launch-crypto-token.component.html',
  styleUrls: ['./how-to-launch-crypto-token.component.css']
})
export class HowToLaunchCryptoTokenComponent implements OnInit {

  selectedLink:number;
  title ='Learn How to Launch Crypto Token from Scratch';

  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private footservice:FooterServiceService,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  isDarkmode:boolean=false;

  ngOnInit(): void {
    //darkmode services 
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);

    this.meta.updateTag({
      name: 'description',
      content:
        'How to launch crypto token? The answer lies in our blog through a detailed, business focused guide. Check out our website to find out more and get expert assistance.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'How to launch crypto token, Launch your crypto token, Launch crypto token, Launch an crypto token, Launch your own crypto token, Launch your own token, launching crypto token, new crypto coin launch',
    });

    
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content:
        'Learn How to Launch Crypto Token from Scratch',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content:
        'https://www.blockchainx.tech/how-to-launch-crypto-token/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'How to launch crypto token? The answer lies in our blog through a detailed, business focused guide. Check out our website to find out more and get expert assistance.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/how-to-launch-crypto-token/how-to-launch-crypto-token.webp',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content:
        'https://www.blockchainx.tech/how-to-launch-crypto-token/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content:
        'Learn How to Launch Crypto Token from Scratch',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'How to launch crypto token? The answer lies in our blog through a detailed, business focused guide. Check out our website to find out more and get expert assistance.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/blog/how-to-launch-crypto-token/how-to-launch-crypto-token.webp',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/how-to-launch-crypto-token/'
    );
    this.createLink();
  }
  createLink() {
    this.canonicalService.destroyLinkForAmpURL();
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'amphtml');
    link.setAttribute(
      'href',
      'https://www.blockchainx.tech/amp/how-to-launch-crypto-token.html'
    );
    this.doc.head.appendChild(link);
  }
  addClass(id:number){
    this.selectedLink=id;
  }
}
