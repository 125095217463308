<div class="app-get-in-touch-form">
    <div class="container">
        <div class="contact-form">
            <div class="row">
              <div class="col-lg-6 col-md-12 contact-social-links">
                <p class="main-sub-heading"><span>Get In Touch</span></p>
                <p>Our expert team is professionally well-equipped with hands-on experience as a blockchain development company. Connect with us; at BlockchainX, we curate your desired sustainable solution! </p>
                <div class="contact-main-list">
                  <div class="social-links">
                    <a href="mailto:contact@blockchainx.tech"  target="_blank"
                rel="noopener noreferrer" class="contact-mail" >
                      <picture>
                        <source
                          type="image/webp"
                          srcset="assets-new/images/contact/webp/mail.webp"
                        />

                        <source
                          type="image/png"
                          srcset="assets-new/images/contact/mail.png"
                        />

                        <img loading="lazy"
                          data-src="assets-new/images/contact/mail.png"
                          alt="linkedin"
                          width="32"
                          height="32"
                        />
                      </picture>
                    </a>

                    <a href="https://wa.me/7708889555" target="_blank"
                rel="noopener noreferrer" class="whatsapp">
                      <picture>
                        <source
                          type="image/webp"
                          srcset="assets-new/images/contact/webp/whatsapp-icon.webp"
                        />

                        <source
                          type="image/png"
                          srcset="assets-new/images/contact/whatsapp-icon.png"
                        />

                        <img loading="lazy"
                          data-src="assets-new/images/contact/whatsapp-icon.png"
                          alt="contact-Whatsapp"
                          width="32"
                          height="32"
                        />
                      </picture>
                    </a>

                    <a href="https://t.me/sparkouttechblockchainx" target="_blank"
                rel="noopener noreferrer" class="twitter" >
                      <picture>
                        <source
                          type="image/webp"
                          srcset="assets-new/images/contact/webp/telegram.webp"
                        />

                        <source
                          type="image/svg"
                          srcset="assets-new/images/contact/telegram.png"
                        />

                        <img loading="lazy"
                          data-src="assets-new/images/contact/telegram.png"
                          alt="twitter"
                          width="40"
                          height="40"
                        />
                      </picture>
                    </a>
                  </div>
                </div>
                <div class="contact-links">
                  <p class="h-4">Follow us on</p>
                  <div class="social-links">
                    <a
                      href="https://www.linkedin.com/company/blockchainxtech/"
                      target="_blank"
                rel="noopener noreferrer"
                      class="linkdin"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          srcset="assets-new/images/contact/webp/linkdin.webp"
                        />

                        <source
                          type="image/png"
                          srcset="assets-new/images/contact/linkdin.png"
                        />

                        <img loading="lazy"
                          data-src="assets-new/images/contact/linkdin.png"
                          alt="linkedin"
                          width="22"
                          height="22"
                        />
                      </picture>
                    </a>

                    <a
                      href="https://www.instagram.com/blockchainxtech/"
                      target="_blank"
                rel="noopener noreferrer"
                      class="instagram"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          srcset="assets-new/images/contact/webp/instagram.webp"
                        />

                        <source
                          type="image/png"
                          srcset="assets-new/images/contact/instagram.png"
                        />

                        <img loading="lazy"
                          data-src="assets-new/images/contact/instagram.png"
                          alt="instagram"
                          width="22"
                          height="22"
                        />
                      </picture>
                    </a>

                    <a
                      href="https://twitter.com/Blockchainxtech"
                      target="_blank"
                rel="noopener noreferrer"
                      class="twitter"
                    >
                      <picture>
                        <source
                          type="image/webp"
                          srcset="assets-new/images/contact/webp/twitterx.webp"
                        />

                        <source
                          type="image/svg"
                          srcset="assets-new/images/contact/twitterx.svg"
                        />

                        <img loading="lazy"
                          data-src="assets-new/images/contact/twitterx.svg"
                          alt="twitter"
                          width="25"
                          height="25"
                        />
                      </picture>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 contact-inputs">
                <div class="getintouch getintochform bitrix-snippet-form">
                  <!-- <div #crmForm></div> -->
                  <!-- <div [innerHTML]="bitrixSnippetScript"></div> -->
                  <app-getintouch-form></app-getintouch-form>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
