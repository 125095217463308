import { Component, OnInit,} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../service/common.service';
import { TrackingService } from '../service/tracking.service';
import { FooterServiceService } from '../service/footer-service.service';

@Component({
  selector: 'app-getintouch-form',
  templateUrl: './getintouch-form.component.html',
  styleUrls: ['./getintouch-form.component.css']
})
export class GetintouchFormComponent implements OnInit {
    public contactmail!: FormGroup;
    submitted = false;
    countryCode: any;
    formdata:[];
    isDarkmode: boolean = false;

    // isDropdownOpen = false;
    // selectedOption: string | null = null;
    // options = [
    //   'Business Enquiry',
    //   'Partnership',
    //   'Career Opportunities',
    //   'Event/Media Enquiry',
    //   'Investment',
    //   'I want to sell a service'
    // ];

    constructor(
      private formBuilder:FormBuilder,
      private commonService:CommonService,
      private toastr:ToastrService,
      private trackingService: TrackingService,

      public router: Router,
      private footerservice: FooterServiceService  ) {
    }
    ngOnInit(): void {

      this.footerservice.toggleLogoObservable.subscribe((response: boolean) => {
        this.isDarkmode = response;
      });

      this.contactmail = this.formBuilder.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        whatsapp_telegram: ['', [Validators.required]],
        country: ['', [Validators.required]],
        tell_us_about_your_project: ['', [Validators.required]],
        enquiry_type: ['', [Validators.required]],
      });
    }

    

    // toggleDropdown(): void {
    //   this.isDropdownOpen = !this.isDropdownOpen;
    // }
  
    // selectOption(option: string, event: MouseEvent): void {
    //   event.stopPropagation(); // Prevent the parent click handler from toggling dropdown again
    //   this.selectedOption = option;
    //   this.isDropdownOpen = false; // Close the dropdown automatically
    // }

     //easy to access form control value
  get f(){
    return this.contactmail.controls;
  }

  public onSubmit(){
    this.submitted=true;
    if(this.contactmail.valid){
      let formData= {
        ... this.contactmail.value,
        source: localStorage.getItem('userReferrer') || 'direct',
        page_visited: localStorage.getItem('lastPageVisited') || window.location.href,
        pages_visited: JSON.parse(localStorage.getItem('pagesVisited') || '[]')
      };
      console.log(formData);

      this.commonService.submitContactForm(formData).subscribe((response:any)=>{
        this.submitted = false;
        if(response.status=['SUCCESS']){
          this.toastr.success('Mail Sent Successfully!', 'Success!');
          this.contactmail.reset();
          this.trackingService.clearPageVisits();

          // Redirect to the "Thank You" page after 2 seconds
          setTimeout(() => {
            this.router.navigate(['/thank-you']); // Replace '/thank-you' with your actual route
          }, 2000);
        }
      },
      (exception:any) =>{
        this.toastr.error('Mail Not Sent. Pls try again after some times!', 'Failure!');
      }
      )
    }
  }
    numericOnly(event:any) {
      let pattern = /^([0-9])$/;
      return pattern.test(event.key);
    }
  }