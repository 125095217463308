import { Component, OnInit } from '@angular/core';
import {
  Title,
  Meta
} from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CanonicalService } from 'src/app/shared/canonical.service';
import { FooterServiceService } from 'src/app/shared/service/footer-service.service';

@Component({
  selector: 'app-real-estate',
  templateUrl: './real-estate.component.html',
  styleUrls: ['./real-estate.component.css']
})
export class RealEstateComponent implements OnInit {

  title = 'Real Estate Tokenization Services';
  constructor(
    public router: Router,
    private titleService: Title,
    private meta: Meta,
    private canonicalService:CanonicalService,
    private footservice:FooterServiceService
  ) {

  }

  isDarkmode:boolean=false;
  ngOnInit(): void {
    //darkmode services
    this.footservice.toggleLogoObservable.subscribe((response: boolean) => {
      if (response) {
        this.isDarkmode = response;
      }
      else {
        this.isDarkmode = response;
      }
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag({
      name: 'description',
      content:
        'Real Estate Tokenization Services is a fully customized end-to-end tokenization platform designed to improve asset management and create highly scalable business opportunities in the real estate industry.',
    });
    this.meta.updateTag({
      name: 'keywords',
      content:
        'Real estate tokenization development, Real estate tokenization services, Real estate tokenization development company, Real Estate Tokenization solutions, Real estate tokenization service, Create your own real estate tokenization, Launch your own Real Estate Tokenization, Develop your own Real Estate Tokenization, real estate tokenization platform development, real estate tokenization platform development company',
    });
    this.meta.updateTag({ name: 'author', content: 'BlockchainX' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({
      property: 'og:title',
      content: 'Real Estate Tokenization Services',
    });
    this.meta.updateTag({ property: 'og:site_name', content: 'BlockchainX' });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://www.blockchainx.tech/real-estate-tokenization/',
    });
    this.meta.updateTag({
      property: 'og:description',
      content:
        'Real Estate Tokenization Services is a fully customized end-to-end tokenization platform designed to improve asset management and create highly scalable business opportunities in the real estate industry.',
    });
    this.meta.updateTag({ property: 'og:type', content: 'Website' });
    this.meta.updateTag({
      property: 'og:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/real-estate-tokenization.jpg',
    });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({
      name: 'twitter:site',
      content: 'https://www.blockchainx.tech/real-estate-tokenization/',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: 'Real Estate Tokenization Services',
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content:
        'Real Estate Tokenization Services is a fully customized end-to-end tokenization platform designed to improve asset management and create highly scalable business opportunities in the real estate industry.',
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content:
        'https://www.blockchainx.tech/assets-new/images/og/real-estate-tokenization.jpg',
    });

    // Add Canonical tag
    this.canonicalService.setCanonicalURL(
      'https://www.blockchainx.tech/real-estate-tokenization/'
    );
  }
  showFaq: boolean = false;

  toggleFaq() {
    this.showFaq = !this.showFaq;
  }
}
