<!-- Banner Section -->
<section class="banners-section">

    <div class="banner_wrapper">
      <div class="container" id="banner-page-vector-main">
        <div class="green-vector"></div>
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-12 col-sm-12 col-12">
            <div class="banner_contentWrapper">
              <div class="caption">
                <h1 class="pb-3">
                  The Best <span><strong>Blockchain Development</strong></span> Company In UAE
                </h1>
                <!-- <div class="common-sub-heading-alt pb-2">Expanding Opportunities
                  For Real Assets</div> -->
                <p class="text-justify">
                  Get your customized blockchain solution developed with us. As a Blockchain Development Company in UAE, we provide end-to-end efficient solutions meeting your operational requirements completely.
                </p>
                <div class="erc-link">
                  <div class="content-link">
                    <a
                      href="/contact">
                      <span>Connect with our Experts now!</span>
                      <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-12">
            <div class="mobile-app-page-form bitrix-snippet-form position-relative" id="consultation-form">
              <div class="form-title">
                <p class="h3">Book a Free <span>Consultation</span></p>
              </div>
              <!-- <div [innerHTML]="bitrixSnippetScript"></div> -->
              <app-consultation-form1></app-consultation-form1>
            </div>
          </div>
        </div>

        <div class="row d-lg-block d-none">
          <div class="scroll-section text-center"
            (click)="about.scrollIntoView({ behavior: 'smooth', block: 'start' })">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/home/scroll.webp" />
              <source
                type="image/png"
                srcset="assets-new/images/home/scroll.png" />
              <img loading="lazy"
                data-src="assets-new/images/home/scroll.png"
                width="25"
                height="48"
                class="img-fluid"
                alt="Scroll"
                title="Scroll" />
            </picture>
          </div>
        </div>

      </div>
    </div>
</section>
  <!-- Banner Section end -->

  <!-- Why Choose Start -->
<section class="section-service-alt">
  <div class="container">
    <div class="row mb-lg-5 mb-4">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h2 class="text-center pb-0 mb-3">
            Why Consider BlockchainX As Your <span>Blockchain Development</span> Company In UAE?
          </h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 d-lg-block d-none">
        <div class="casetudies_common">
          <picture>
            <source
              type="image/webp"
              srcset="
                assets-new/images/home-v2/blockchain-app-development-process-includes.webp
              " />
            <source
              type="image/png"
              srcset="
                assets-new/images/home-v2/blockchain-app-development-process-includes.png
              " />
            <img loading="lazy"
              data-src="assets-new/images/home-v2/blockchain-app-development-process-includes.png"
              width="500"
              height="500"
              class="img-fluid pb-md-0 pb-3"
              alt="Blockchain App Development Process Includes"
              title="Blockchain App Development Process Includes" />
          </picture>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/field.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/field.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/field.png"
                  class="img-fluid"
                  alt="field"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>AI Integration</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                  As a Blockchain Development company in UAE, we extend high end solutions based on your requirements inclusive of all new tech integrations. With Artificial intelligence buzzing around every sector, we provide appropriate API integration for your decentralized solution easing operations providing better flexibility and helping efficiently with analysis.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/real-estate.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/real-estate.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/real-estate.png"
                  class="img-fluid"
                  alt="Real estate"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Large-Scale Implementations</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                  Blockchain is comprehensible for any industry irrespective of its size. Our proficient services have benefited many large scale businesses to manage their operations effectively, contribute towards time efficiency, and sustainability. And eventually supporting any business to promote better fluency within the ecosystem.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
            <picture>
              <source
                  type="image/webp"
                  srcset="assets-new/images/web3-development/education.webp" />

              <source
                  type="image/png"
                  srcset="assets-new/images/web3-development/education.png" />

              <img loading="lazy"
                  data-src="assets-new/images/web3-development/education.png"
                  class="img-fluid"
                  alt="education"
                  width="70"
                  height="70" />
            </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>DeFi Solutions</h3>
                </div>
              </div>
            </div>
            <div class="casestudy_para">
              <p class="text-justify">
                Our one-of-a-kind DeFi Solutions with robust security layers, and efficiency, get us standing tall among other blockchain development company in UAE.  DeFi solutions are perfectly audited and encrypted to securely carry out designate actions. With successful token launches and better performing projects, as our milestone, we ensure to provide scalable solutions.
              </p>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
              <picture>
                <source
                    type="image/webp"
                    srcset="assets-new/images/web3-development/fashion.webp" />

                <source
                    type="image/png"
                    srcset="assets-new/images/web3-development/fashion.png" />

                <img loading="lazy"
                    data-src="assets-new/images/web3-development/fashion.png"
                    class="img-fluid"
                    alt="fashion"
                    width="70"
                    height="70" />
              </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Real-World Use Cases</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                  Blockchain technology is branching out almost everywhere with the ability to regulate, secure and be transparent in all possibilities. We help you to better utilize your ideas and expand the utility of real world assets in the digital space with Blockchain development services.  From real time production tracking, to end customer feedback, our blockchain services extend to all sectors.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
              <picture>
                <source
                    type="image/webp"
                    srcset="assets-new/images/web3-development/game-development.webp" />

                <source
                    type="image/png"
                    srcset="assets-new/images/web3-development/game-development.png" />

                <img loading="lazy"
                    data-src="assets-new/images/web3-development/game-development.png"
                    class="img-fluid"
                    alt="game development"
                    width="70"
                    height="70" />
              </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Leading Enterprise Blockchain Provider</h3>
                </div>
              </div>
              <div class="casestudy_para">
                <p class="text-justify">
                  As a blockchain development company in UAE, with 7 plus years of experience in developing blockchains solutions our expertise expands from token development and building the entire blockchain ecosystem for your business, assisting and co-creating new opportunities. We can also employ permissioned networks based on requirements and customize for the same.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3 mb-md-4 mb-lg-5 trusted-detail">
          <div class="col-sm-2 col-3">
            <div class="d-flex justify-content-end">
              <picture>
                <source
                    type="image/webp"
                    srcset="assets-new/images/web3-development/marketing.webp" />

                <source
                    type="image/png"
                    srcset="assets-new/images/web3-development/marketing.png" />

                <img loading="lazy"
                    data-src="assets-new/images/web3-development/marketing.png"
                    class="img-fluid"
                    alt="Marketing"
                    width="70"
                    height="70" />
              </picture>
            </div>
          </div>
          <div class="col-lg-10">
            <div class="casestudy_wrap">
              <div class="casestudy_head">
                <div class="case-sub-head">
                  <h3>Innovative Integrations Expert Advisory</h3>
                </div>
              </div>
              <div class="casestudy_para border-0">
                <p class="text-justify">
                  One of the prominent services among blockchain development is Innovative integration expert advisory. We provide knowledge, insights, facts and stats on the current market trends, and technology that help you decide the best for your business and growth. We are expertised in supply chain management across different sectors, solving major traceability issues and providing solutions to enhance security more efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
  <!-- Why Choose End -->

  <!-- Start -->
  <section class="section-service-main p-0">
    <div class="meta-expert">
      <div class="violet-vector"></div>
      <div class="orange-vector"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="our_experts">
              <h2><span>Scale-up your Opportunities with Blockchain</span> Development Services in UAE with Our Possibilities.</h2>
              <div class="text-center my-4">
                <a class="our_expert_talk" href="https://www.blockchainx.tech/contact/">Check-out Our Demo Platform</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
    <!-- End -->
    <div class="section-service-alt" #about>
      <div class="container">
        <div class="row dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/interplanetary-file-system-blockchain.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/interplanetary-file-system-blockchain.png" />

                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/interplanetary-file-system-blockchain.png"
                  width="400"
                  height="400"
                  class="img-fluid"
                  alt="IPFS Blockchain Development Services"
                  title="IPFS Blockchain Development Services" />
              </picture>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">
                Premium Tokenization Services in UAE
              </h3>
              <p class="text-justify">
                Art creatives, music albums, videos, gifs, gaming assets, to real world assets, our premium tokenization service in UAE stands out with the most precision and perfection. They gain the ability to create demand and increase a buzz over your unique collectibles. Blockchain development company in UAE, we also do asset tokenization  with attributes and abilities complementing your operations and the use cases. Branching out for every sector, inviting artists and creative to monetize through blockchain and digital abilities.
              </p>
              <p class="text-justify">
                Our premium tokenization services also extend to incorporate additional token abilities like fractionalising assets, creating bulk tokens, entrusting on one particular niche like governance tokens, tokens for rewarding, tokens for fundraising, to maximize liquidity, and more. We also extend token upgrading services that empower your tokens with new standards, abilities and functionalities ensuring to perform significantly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="section-service-main">
      <div class="container">
        <div class="row reverse dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">Top ICO Services in the UAE</h3>
              <p class="text-justify">
                To promote your crypto project and ace funding for the same, our ICO services in the UAE are keenly curated to meet your requirements. From crypto coin tokenomics to network choice, deploying and listing in ICO  platform our services include a full fledged package that ensures to build a good liquidity for the token and promotes your projects vicinity. This is a perfect way to raise funds for your new crypto projects irrespective of your niche. The ICO service is not about token development, but also includes listing, ensuring token distribution to investors.
              </p>
              <p class="text-justify">
                Through our Blockchain development company in UAE, we bring multi currency support at your service, along with customized ICO token development, ensuring security around token loss, uniquely curated ICO launchpad, with admin and investor dashboard, and much more services that put together to serve at the best for your business and eventually meet the global market standard to attract traction.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/why-IPFS.png" />

                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                  width="400"
                  height="400"
                  class="img-fluid"
                  alt="Why IPFS Development Services"
                  title="Why IPFS Development Services" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- End -->

  <!-- Start -->
  <div class="section-service-alt">
    <div class="container">
      <div class="row dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                width="300"
                height="300"
                class="img-fluid"
                alt="How IPFS Works"
                title="How IPFS Works" />
            </picture>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Innovative DApps Development Company in the UAE</h3>
            <p class="text-justify">
              From token development, to decentralized Exchanges, staking platforms, NFT marketplace, and many Decentralized applications, we have the ability to serve the quality of immutability, transparency and security with our services.  As a blockchain development company in UAE we provide seamless efficient high performing solutions. Entrust on the blockchain capabilities we bring in decentralized quality integrating qualified, tested and original single hand coded smart contracts to perform predefined tasks.
            </p>
            <p class="text-justify">
              Our DApp development services are robust and customized for your to ensure your operation in business are effectively carried out. From stock management, supply chain traceability, handling logistics, resource management,etc we curate Decentralized applications integrated to perform all tasks effectively and function as one whole ecosystem catering your needs in a fully secured network.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
    <!-- End -->
    <div class="section-service-main">
      <div class="container">
        <div class="row reverse dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">Smart Contracts Development Experts in the UAE</h3>
              <p class="text-justify">
                For any decentralized platform, Smart contracts are the back bone that supports and ensures credibility over operations. Our smart contract development experts in the UAE help businesses to curate single handed, audited and perfectly executing smart contracts. Our smart contract development service keenly focuses on gathering your precise operation flow and requirements, understand the need for automation, align codes with protocol, and based on the network choice the precise proof mechanism is employed and integrated with the platform, it's also tested and verified to execute the transaction seamlessly in the right flow.
              </p>
              <p class="text-justify">
                We at our blockchain development company in UAE, we extend to deliver smart contract auditing services for the existing codes, ensure they are written in right proximities, identify errors, provide a complete report on the same. We also help you upgrade your smart contracts for better efficiency of the platform.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/why-IPFS.png" />

                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                  width="400"
                  height="400"
                  class="img-fluid"
                  alt="Why IPFS Development Services"
                  title="Why IPFS Development Services" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-service-alt">
      <div class="container">
        <div class="row dao-Platform align-items-center">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
            <div class="bep-work-section">
              <picture>
                <source
                  type="image/webp"
                  srcset="assets-new/images/erc/ipfs/webp/how-IPFS-works.webp" />

                <source
                  type="image/png"
                  srcset="assets-new/images/erc/ipfs/how-IPFS-works.png" />

                <img loading="lazy"
                  data-src="assets-new/images/erc/ipfs/how-IPFS-works.png"
                  width="300"
                  height="300"
                  class="img-fluid"
                  alt="How IPFS Works"
                  title="How IPFS Works" />
              </picture>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
            <div class="common-sub-heading">
              <h3 class="main-sub-heading text-start">100% Trusted Blockchain Development Agency in the UAE</h3>
              <p class="text-justify">
                Over the years of experience, we have hands-on expertise with blockchain and its subordinates, helping businesses with end-to-end encryption through a reliable journey. We are expertised in blockchain development, decentralized applications, tokenomics services and provide customized solutions to perform more effectively. We keep up our standard in services, ensuring to be the most trusted Blockchain development company in UAE providing customizable, proficient blockchain solutions.
              </p>
              <p class="text-justify">
                Our trained and qualified blockchain developers and tech engineers are highly passionate and curious to learn new things. The team provides you with A grade solutions and is open to hear your requirements, understand the need and suggest the best solutions. We also ensure on time deployment and render authentic blockchain services.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  <!-- Start -->
  <div class="section-service-main">
    <div class="container">
      <div class="row reverse dao-Platform align-items-center">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 about-content">
          <div class="common-sub-heading">
            <h3 class="main-sub-heading text-start">Advanced Layer 1 and Layer 2 Blockchain Solutions in the UAE</h3>
            <p class="text-justify">
              Blockchain Layers 1 and 2 solutions often build on the purpose better the functions, nurture the operation and enable seamless flow of transactions. Our expertise spread across in developing advanced layer1 and layer 2 blockchain solutions in the UAE with the prominent ideations and dedicative involvement of our professionals. The team ensures to retain the quality of the network and build another on top, that enhances operations while not overloading the network with hefty transactions.
            </p>
            <p class="text-justify">
              At our blockchain development company in UAE, we provide end-to-end encrypted services with layer 1 and 2 development starting off with requirement gathering to deployment. We also provide enough support in terms of doubling the security measures, other technological integrations and much more.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
          <div class="bep-work-section">
            <picture>
              <source
                type="image/webp"
                srcset="assets-new/images/erc/ipfs/webp/why-IPFS.webp" />

              <source
                type="image/png"
                srcset="assets-new/images/erc/ipfs/why-IPFS.png" />

              <img loading="lazy"
                data-src="assets-new/images/erc/ipfs/why-IPFS.png"
                width="400"
                height="400"
                class="img-fluid"
                alt="Why IPFS Development Services"
                title="Why IPFS Development Services" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="section-service-alt">
    <div class="container">
      <div class="build-web3-solution">
        <div class="row">
          <div class="col-lg-10 mx-auto">
            <div class="title-wrap text-center">
              <h2 id="item-5"
              [ngClass]="selectedLink===5?'scrollHeading':'scrollheadingone'">
                <span>Tech Stack We Are Expertised </span> For Blockchain Development Services In UAE
                <span class="dots"></span>
              </h2>
            </div>
          </div>
        </div>
        <div class="row mb-3 ">
          <div class="col-lg-12">
            <div class="tech-stack-details ">
              <ul class="techstack d-flex flex-column flex-sm-row justify-content-center align-items-center gap-3">
                <li class="our_expert_talk tech-list" *ngFor="let techmenu of technologystack">
                  <p
                    (click)="opentechnology(techmenu)"
                    [ngClass]="{ 'actives-tech': techlisted === techmenu }"
                    >{{ techmenu }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ng-container *ngIf="techlisted === 'Blockchains Platform'">
              <div class="row d-flex justify-content-center align-items-center g-4">
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>
                    <p>Ethereum</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>BNB Smart Chain</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Polygon</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Arbitrum</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Optimism</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Avalanche</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>MoonBeam</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Solana</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Hyperledger</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Corda</p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="techlisted === 'Other Platform'">
              <div class="row d-flex justify-content-center align-items-center g-4">
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>HardHat</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Solidity</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Rust</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Anchor</p>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-10">
                  <div class="stack-border-box">
                    <picture>
                      <source type="image/webp"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.webp" />
                      <source type="image/png"
                        srcset="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png" />
                      <img loading="lazy"
                        data-src="assets-new/images/real-world-asset-tokenization/polkadot-bridge-to-ethereum.png"
                        width="30" height="30" alt="polkadot bridge to ethereum"
                        title="polkadot bridge to ethereum"
                        class="img-fluid">
                    </picture>

                    <p>Moralis</p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

    <!-- End -->
    <div class="section-service-main">
      <div class="types-of-erc">
          <div class="container">
              <div class="row">
                  <h3 class="main-sub-heading text-center pb-0"> Industries Benefited From
                      <span>Blockchain Solutions</span> in United Arab Emirates
                  </h3>
                  <!-- <p>We Provide Tailored Web3 Development for Any</p> -->
                   <p class="text-center">
                    Blockchain is a highly influential technology today that has proven to support almost every sector. Here are the top industries we actively extend support as a blockchain development company in UAE.
                   </p>
              </div>
              <div
                  class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 types-of-token-detail justify-content-center industry-web">
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/field.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/field.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/field.png"
                                      class="img-fluid"
                                      alt="Field"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Supply Chain</h4>
                          <p>
                            Our proficient Blockcahin based supply chain solution carter endless efficiency and effectively cuts down time, energy and labor, but ensuring a smooth flow of operations.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/real-estate.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/real-estate.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/real-estate.png"
                                      class="img-fluid"
                                      alt="Real Estate"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Identity management</h4>
                          <p>
                            Blockchain Identity management is a cohesive concept to securely store identity proofs and at the same time all your identity records without being exploited.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/education.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/education.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/education.png"
                                      class="img-fluid"
                                      alt="Education"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Healthcare</h4>
                          <p>
                            From maintaining medical records of patients, to medicine stock management, doctors and assistance availability, Blockchain in Healthcare is a one step tracker with security.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/fashion.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/fashion.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/fashion.png"
                                      class="img-fluid"
                                      alt="Fashion"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Retail</h4>
                          <p>
                            The retail business through blockchain development solutions gains access to a better global attention, and decentralization helps operations to be much easier.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/marketing.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/marketing.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/marketing.png"
                                      class="img-fluid"
                                      alt="Marketing"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Banking and Finance</h4>
                          <p>
                            From the central system now moving toward decentralization, Blockchain in Fintech has the ability to entirely revolutionize the sector into a more accessible one with security.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/game-development.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/game-development.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/game-development.png"
                                      class="img-fluid"
                                      alt="Game Development"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Green Energy</h4>
                          <p>
                            Blockchain intervention in managing green energy, transparency  brings data from all sources, analyzes future circumstances and tracks each unit of energy spent.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/e-commerce.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/e-commerce.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/e-commerce.png"
                                      class="img-fluid"
                                      alt="E-commerce"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Logistics and Transport</h4>
                          <p>
                            Blockchain traceability plays a vital role in the logistics and transport industry, avoiding delay in deliveries, tracking exact location and status of the products
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/fintech.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/fintech.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/fintech.png"
                                      class="img-fluid"
                                      alt="FinTech"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Real Estate and Constructions</h4>
                          <p>
                            Today with the endless possibilities of blockchain in the real estate and construction industry, the investor pool is vast through fractional ownership and credibility is high in the network
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/event.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/event.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/event.png"
                                      class="img-fluid"
                                      alt="Event"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">E-Commerce</h4>
                          <p>
                            Blockchain technology unleashing its experience in a transformative powerful ecosystem in the digital space the E-commerce industry can vitalise on traceability practices.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/travel-tourism.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/travel-tourism.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/travel-tourism.png"
                                      class="img-fluid"
                                      alt="Travel & Tourism"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Gaming</h4>
                          <p>
                            Not just attributes, but blockchain provides utmost support and supremacy to develop a complete gaming arena in different niches with ability to redefine the entire experience.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/manufacturing.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/manufacturing.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/manufacturing.png"
                                      class="img-fluid"
                                      alt="Manufacturing"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Governance and Voting</h4>
                          <p>
                            Revolutionizing opportunity with blockchain for governance and voting systems, the technology ensures there is transparency, security, trust and credibility.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/manufacturing.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/manufacturing.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/manufacturing.png"
                                      class="img-fluid"
                                      alt="Manufacturing"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Education</h4>
                          <p>
                            The accessibility for education has become flexible, while with blockchain we can enhance its ability to the next level, with managing records, transparency and security.
                          </p>
                      </div>
                  </div>
                  <div class="col">
                      <div class="types-erc-detail  text-center">
                          <div class>
                              <picture>
                                  <source
                                      type="image/webp"
                                      srcset="assets-new/images/web3-development/manufacturing.webp" />

                                  <source
                                      type="image/png"
                                      srcset="assets-new/images/web3-development/manufacturing.png" />

                                  <img loading="lazy"
                                      data-src="assets-new/images/web3-development/manufacturing.png"
                                      class="img-fluid"
                                      alt="Manufacturing"
                                      width="75"
                                      height="75" />
                              </picture>
                          </div>
                          <h4 class="erc-20-head-alt">Metaverse</h4>
                          <p>
                            Decentralized environment recreating the real world experience in the digital, Metaverse platform backed by blockchain unlocks numerous monetizing opportunities.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <section class="section-service-alt">
    <div class="types-of-erc text-center">
        <div class="container">
            <div class="build-web3-solution">
                <div class="row justify-content-center mb-3">
                    <div class="col-lg-10">
                        <h3 class="main-sub-heading text-center">Embark your Web3 journey with our Customized
                            <span>Blockchain Development Service in UAE</span>
                        </h3>
                    </div>
                </div>
                <p>
                    Web2 is the present situation of the internet used every day - a centralized system controlled by dominant tech facilitators.The major drawback in Web2 is control and limited user access, while that is addressed in Web3. It represents the new era of the internet, built on decentralized technology; provides transparency, security, and user control. As a leading service provider among web3 development companies, the team at BlockchainX can help you build customized Web3 solutions with expert guidance to help you deliver the best user experience for your target audience.
                </p>
                <div class="discuss-link">
                    <a href="/contact"><span>Book your Call</span>
                        <img loading="lazy"
                                            src="assets-new/images/right-arrow.png"
                                            alt="Arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="!isDarkmode" />
                                        <img loading="lazy"
                                            src="assets-new/images/right-arrow-dark.png"
                                            alt="Arrow"
                                            class="img-fluid"
                                            width="50"
                                            height="15"
                                            *ngIf="isDarkmode" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-service-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mb-5">
        <div class="c-animation">
          <div class="common-sub-heading sub-title">
            Principles <span class="dots">.</span>
          </div>
          <h3 class="main-sub-heading">
            How Do You Process With <span>Blockchain Development Service?</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="green-vector"></div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Basic Requirement<span class="d-block"> Gathering</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              As a leading blockchain development company in UAE, we have a standard process for onboarding clients. We start off with understanding your niche, getting the outline of the project, the requirement gathering session would be a brief addressing the entire aspect of the platform.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Coming up With A Blockchain<span class="d-block"> Project Roadmap</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              As the requirements are gathered, let step up to see the progress of the blockchain solution through a roadmap detailing  on the outcomes and milestones accurately.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Curating the Design Framework<span class="d-block"> Blockchain Platform</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              Moving on, we curate the design framework of your blockchain solution that replicates the idea and slides through the operational flow.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Customizing based on <span class="d-block">Your suggestions</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              In terms of suggestions and changes, our services extend to changing the designs accordingly to meet your expectations.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['dao-development-company']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Making infrastructural choices for a <span class="d-block">good foundation </span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              We help you choose and build the right infrastructure, analyzing industry trends and best practices to increase efficiency.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Deploying and Test net launch of <span class="d-block">Blockchian solution</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              Going further, at our blockchain development company in UAE, we deploy your blockchain platform in the test net and on mainnet for Alpha launch after integrating smart contracts and relevant subordinates.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Auditing, Correction and <span class="d-block">rectifications</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              From the feedback and suggestions from the alpha user, we work on the corrections, re-audit the codes and rectify the issues
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Mainnet Launch of the <span class="d-block">blockchain solution</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              Then comes the mainnet launch in the main network. Your blockchain solution will be live for users around the world
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Feedback from Beta <span class="d-block">testing</span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              Incase of any additional support, we collect feedback from the next level platform launch, contributing for much better performance.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row service-content">
          <div
            class="col-lg-5 col-md-5 col-sm-12 col-12 service-heading common_page_head">
            <h3>Upgrade and Maintenance<span class="d-block">good foundation </span></h3>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-detail">
            <p>
              Eventually we also support upgrades and maintenance for your platform post the launch based on necessities.
            </p>
            <div class="content-link" id="service-link">
              <a [routerLink]="['/']">
                <!-- <span>Read More</span> -->
                <img loading="lazy"
                      src="assets-new/images/right-arrow.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="!isDarkmode" />
                    <img loading="lazy"
                      src="assets-new/images/right-arrow-dark.png"
                      alt="Arrow"
                      class="img-fluid"
                      width="50"
                      height="15"
                      *ngIf="isDarkmode" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-alt">
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-10 mx-auto">
        <div class="whys_section mb-0 text-center">
          <h2 class="text-center pb-0 mb-3">
            Client <span> Success Stories </span>
          </h2>
          <p>
            Catch up on our client's success stories at BlockchainX. We have
            worked with various clients and on various projects in blockchain
            platform development. And here are some of the top projects in which
            we were involved.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="client-success">
          <owl-carousel-o [options]="clientSuccess">
            <ng-template carouselSlide>
              <div class="row pb-lg-3 pb-0">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Farmsent</h3>
                    <p class="pb-lg-3 pb-0">
                      Agriculture-based blockchain business solution. Connecting
                      farmers and customers directly benefits both in terms of
                      quality, price, and profit. Farmsent drives the
                      agriculture sector to the forefront with transparency and
                      agility.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <!-- <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/farmsent.webp"
                    />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/farmsent.png"
                    />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/farmsent.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Farmsent"
                      title="Farmsent"

                    />
                  </picture> -->

                  <picture>
                    <source
                      srcset="
                      assets-new/images/home-v2/farmsent-400.webp 400w,
                      assets-new/images/home-v2/farmsent-600.webp 600w,
                      assets-new/images/home-v2/farmsent-800.webp 800w,
                      assets-new/images/home-v2/farmsent-1000.webp 1000w,
                      assets-new/images/home-v2/farmsent-1200.webp 1200w,
                      assets-new/images/home-v2/farmsent-1400.webp 1400w,
                      assets-new/images/home-v2/farmsent-1600.webp 1600w,
                      assets-new/images/home-v2/farmsent-1800.webp 1800w,
                      assets-new/images/home-v2/farmsent-2000.webp 2000w"
                      sizes="(min-width: 800px) 400px, 95vw"
                      type="image/webp" />
                    <source
                      srcset="
                      assets-new/images/home-v2/farmsent-400.png 400w,
                      assets-new/images/home-v2/farmsent-600.png 600w,
                      assets-new/images/home-v2/farmsent-800.png 800w,
                      assets-new/images/home-v2/farmsent-1000.png 1000w,
                      assets-new/images/home-v2/farmsent-1200.png 1200w,
                      assets-new/images/home-v2/farmsent-1400.png 1400w,
                      assets-new/images/home-v2/farmsent-1600.png 1600w,
                      assets-new/images/home-v2/farmsent-1800.png 1800w,
                      assets-new/images/home-v2/farmsent-2000.png 2000w"
                      sizes="(min-width: 800px) 400px, 95vw"
                      type="image/png" />
                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/farmsent-400.png"
                      class="img-fluid pb-md-0 pb-4"
                      width="400"
                      height="237"
                      alt="Farmsent"
                      title="Farmsent" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Real World NFT</h3>
                    <p class="pb-lg-3 pb-0">
                      Tokenizing real-world assets into NFTs is a trending
                      project. With the perks of transparency, fractional
                      ownership, and multiple revenue streaming options, there
                      is high traction in the market among investors and owners.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                data-src="assets-new/images/right-arrow.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                data-src="assets-new/images/right-arrow-dark.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/real-world.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/real-world.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/real-world.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Real world"
                      title="Real world" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Layer One X</h3>
                    <p class="pb-lg-3 pb-0">
                      A community for developers to interact with fellow
                      developers, project holders, and investors. The platform's
                      ability to actively connect and interact drives the
                      community with consistent engagement.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                data-src="assets-new/images/right-arrow.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                data-src="assets-new/images/right-arrow-dark.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/l1x.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/l1x.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/l1x.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Layer One X"
                      title="Layer One X" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Elfin Games</h3>
                    <p class="pb-lg-3 pb-0">
                      A hub of web3 games for users to explore and expand the
                      Play to Earn economy.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                data-src="assets-new/images/right-arrow.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                data-src="assets-new/images/right-arrow-dark.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/elfin.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/elfin.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/elfin.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Elfin Games"
                      title="Elfin Games" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">BOBNet</h3>
                    <p class="pb-lg-3 pb-0">
                      Meme coin market intelligence helps users identify trading
                      trends and maximize their profits eventually.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/bobnet.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/bobnet.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/bobnet.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="BOBNet"
                      title="BOBNet" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Big Time Coin</h3>
                    <p class="pb-lg-3 pb-0">
                      The real estate tokenization platform benefits investors,
                      rentals, users, and the entire community with different
                      modules of operations.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/big-time.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/big-time.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/big-time.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Big Time Coin"
                      title="Big Time Coin" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Ekta</h3>
                    <p class="pb-lg-3 pb-0">
                      A decentralized ecosystem built with the ability to
                      perform everything and anything. Being a blockchain
                      itself, it renders and supports DAO and has its own
                      marketplace and token to interact with.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/ekta.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/ekta.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/ekta.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Ekta"
                      title="Ekta" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">StarCard Sports Games</h3>
                    <p class="pb-lg-3 pb-0">
                      A play-to-earn decentralized gaming arena was developed to
                      connect real-world players through digital gaming. This
                      game is based on football, along with metaverse abilities
                      that draw income.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/starcard.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/starcard.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/starcard.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="StarCard Sports Games"
                      title="StarCard Sports Games" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Bounty Temple</h3>
                    <p class="pb-lg-3 pb-0">
                      The play-to-earn gaming community for gaming enthusiasts
                      to explore the endless possibilities of the web3 arena,
                      providing opportunities to monetize and maximize benefits.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/bounty.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/bounty.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/bounty.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Bounty Temple"
                      title="Bounty Temple" />
                  </picture>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="row">
                <div class="col-lg-6 col-md-6 order-md-first order-last">
                  <div class="client-details">
                    <h3 class="main-sub-heading">Zukiverse</h3>
                    <p class="pb-lg-3 pb-0">
                      Zuki is a P2E gaming platform that is a community-oriented
                      space that builds characters, virtual goods, puzzle games,
                      quizzes, and metaverse structures all in one.
                    </p>
                    <!-- <div class="discuss-link">
                      <a [routerLink]="['/contact']">
                        <span>View Case Study</span>
                        <img loading="lazy"
                                src="assets-new/images/right-arrow.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="isDarkmode"
                              />
                              <img loading="lazy"
                                src="assets-new/images/right-arrow-dark.png"
                                alt="Arrow"
                                class="img-fluid"
                                width="50"
                                height="15"
                                *ngIf="!isDarkmode"
                              />
                      </a>
                    </div> -->
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="assets-new/images/home-v2/zuki.webp" />

                    <source
                      type="image/png"
                      srcset="assets-new/images/home-v2/zuki.png" />

                    <img loading="lazy"
                      data-src="assets-new/images/home-v2/zuki.png"
                      width="579"
                      height="343"
                      class="img-fluid pb-md-0 pb-4"
                      alt="Zukiverse"
                      title="Zukiverse" />
                  </picture>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-service-main">
  <div class="container">
    <div class="contact-overall d-flex flex-column gap-lg-5 gap-4 rounded-3">
      <div class="commontext_wrap">
        <h2 class="text-start">To Connect With our Specialist - Yokesh Shankar COO</h2>
        <p>A dynamic personality with keen knowledge and hands-on experience with blockchain development solutions. The knack of handling technical complications smoothly while business in solid terms is an inspiration.</p>
      </div>

      <div class="author-detail d-flex justify-content-md-between justify-content-center align-items-center flex-md-nowrap flex-wrap gap-md-0 gap-4">
        <div class="author-imgs d-flex align-items-center justify-content-center gap-lg-5 gap-4 flex-wrap">
          <div>
            <img loading="lazy"
              src="assets-new/images/blog/yokesh-shankar.svg"
              alt="blog author"
              width="150"
              height="150" class="img-fluid rounded-circle"/>
          </div>
          <div class="para-text">
            <p class="text-sm-start text-center"><span class="d-block fw-semibold">Yokesh Sankar</span>
            <span class="d-block fw-semibold"><a href="https://www.yokesh.tech/" target="_blank" class="py-2"
                  rel="noopener noreferrer">Co - Founder and COO</a></span>
           <span class="d-block fw-semibold"><a href="https://www.yokesh.tech/" target="_blank" class="pb-2"
                  rel="noopener noreferrer">Blockchain Consultant</a></span></p>
            <a href="mailto:contact@blockchainx.tech" target="_blank"
              rel="noopener noreferrer">contact</a>
          </div>
        </div>
        <div class="discuss-link pt-0">
          <a [routerLink]="['/contact']">
            <span>Connect with him Now!</span>
            <img loading="lazy"
                    src="assets-new/images/right-arrow.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="!isDarkmode" />
                  <img loading="lazy"
                    src="assets-new/images/right-arrow-dark.png"
                    alt="Arrow"
                    class="img-fluid"
                    width="50"
                    height="15"
                    *ngIf="isDarkmode" />
          </a>
        </div>
      </div>
      <div>
        <h2 class="text-start">To Hire A Blockchain Developer</h2>
        <p>
          Our creative tech team shall provide you with an effective solution for your operations. Their hand-on experience shall provide extra edge in strategizing and better implementation. With good experience in handling blockchain projects, you can now <a class="hire-link" href="https://www.blockchainx.tech/contact/" target="_blank">HIRE OUR BLOCKCHAIN DEVELOPER</a> for your Projects.
        </p>
      </div>
    </div>
  </div>
</section>

  <!-- why-choose-section -->
    <!-- End -->

  <!-- FAQ Start -->
  <section class="section-service-alt">
    <div class="container">
      <!-- Accordion Section -->
      <div class="nft-acc">
        <div class="green-vector"></div>
        <div class="violet-vector"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="common-sub-heading sub-title">
              FAQ <span class="dots">.</span>
            </div>
            <!-- <h3 class="main-sub-heading">All-in-one NFT Marketplace Platform</h3> -->
          </div>
        </div>
        <div class="row align-items-center justify-content-center pt-md-5 pt-4">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12 acc-list">
            <div class="accordion" id="accordionEx">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    How Can Blockchain Benefit My Business?
                  </button>
                </div>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                      Blockchain with its immutable nature the shared network is transparent and secured. While deploying operations through Blockchian for your business the possibility to unfold multifaceted opportunities is vast through a secured interconnect network.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo">
                    How can I start blockchain development?
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                      Inorder to start with blockchain development, i would simply say contact us! We ll together co-create a masterpiece. A pro-tip would be to understand your niche and requirements that shall fulfill your results.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree">
                    Can I create my own blockchain?
                  </button>
                </div>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                      Of Course yes, you can create your own blockchain network. With our expert blockchain development consultation we can provide you with the best solution. From prototyping to deployment, our services are open.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour">
                    What is the cost to build a blockchain?
                  </button>
                </div>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                      The cost of developing or building a blockchain is influenced by external factors and choices that are very subjective to needs and requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive">
                    What is the Future of Blockchain technology?
                  </button>
                </div>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                      Blockchain technology has a good scope now and that shall exponentially grow in the future with respect to the advancements and the adaptability rating of the current generation. With new gen technology overtaking the developmental solution, the new integration with blockchain including AI, Ml, VR and AR, will scale the metrics of its capabilities.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <div class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix">
                    What are the different Industries benefited from Blockchain?
                  </button>
                </div>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionEx">
                  <div class="accordion-body">
                    <p>
                      Almost every industry has someway or other emerged into this decentralized evolution. From agriculture, finance, banking, real estate, medicine, healthcare, education, security compliances, logistics, retailing, manufacturing and production, and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Accordion Section -->
    </div>
</section>
  <!-- FAQ End -->

  <section class="section-service-main">
    <app-blog></app-blog>
    </section>

  <!-- Contact Start -->
  <section class="section-service-main">
    <app-get-in-touch></app-get-in-touch>
  </section>
  <!-- Contact End -->
    <!-- Contact End -->
